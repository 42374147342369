@import '../global_variables';

.meal-report {
  .menu-construction .header-listing h6:first-child {
    width: 30%;
  }
  .menu-construction .header-listing h6 {
    width: 17.5%;
  }
  .menu-construction .meal-dish section {
    width: 17.5%;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  // display: flex;
  // flex: 1;
  // flex-direction: column;
  // font-size: 20px;

  // @media (max-width: $tablet-max) {
  //   font-size: 16px;
  // }

  // .dishes {
  //   .meal-dish {
  //     display: flex;
  //     align-items: center;

  //     &.deleting {
  //       opacity: 0.3;
  //       transition: all 0.5s ease-in-out;
  //     }

  //     @media (max-width: $tablet-max) {
  //       flex-direction: column;
  //       padding: 5px 15px;
  //     }

  //     margin: 20px 0;

  //     background-color: $light-gray;
  //     border-radius: 8px;

  //     .image {
  //       @media (max-width: $tablet-max) {
  //         display: none;
  //       }
  //     }

  //     section {
  //       display: flex;
  //       width: 100%;
  //       align-items: center;
  //       padding: 10px;

  //       .we-icon {
  //         padding: 0;
  //         margin: 0;

  //         img {
  //           width: 1.5em;
  //           height: 1.5em;
  //         }
  //       }
  //     }

  //     .section1 {
  //       display: flex;
  //       flex: 4;
  //       text-align: start;

  //       @media (max-width: $tablet-max) {
  //         border-bottom: 1px dashed $darker-gray;
  //       }

  //       .name {
  //         flex: 3;

  //         @media (min-width: $tablet-max) {
  //           margin-right: 10px;
  //         }
  //       }

  //       .meal-summary {
  //         flex: 4;
  //         display: flex;
  //         justify-content: center;

  //         p {
  //           margin: 0 5px;
  //         }

  //         @media (max-width: $tablet-max) {
  //           justify-content: flex-end;
  //           p {
  //             margin: 0 2px;
  //           }

  //           .percent {
  //             width: 0;
  //             display: none;
  //           }
  //         }
  //       }
  //     }

  //     .section2 {
  //       flex: 3;
  //       justify-content: space-between;

  //       p {
  //         margin: 0 10px;
  //         @media (max-width: $tablet-max) {
  //           margin: 0;
  //         }
  //       }

  //       .inventory {
  //         display: flex;
  //         flex: 1;
  //         align-items: center;
  //         justify-content: center;

  //         .label {
  //           order: -1;
  //         }

  //         .inventory-input {
  //           display: flex;

  //           @media (max-width: $tablet-max) {
  //             justify-content: flex-start;
  //             div,
  //             img {
  //               display: none;
  //             }
  //           }

  //           input {
  //             width: 70px;
  //             height: 30px;
  //             text-align: center;
  //             cursor: pointer;

  //             margin: 0 10px;

  //             border: 0;
  //             border-radius: 3px;
  //             outline: none;
  //           }

  //           input::-webkit-outer-spin-button,
  //           input::-webkit-inner-spin-button {
  //             -webkit-appearance: none;
  //             margin: 0;
  //           }
  //         }
  //       }

  //       .actions {
  //         display: flex;
  //         justify-content: space-around;
  //         align-items: center;
  //         @media (max-width: $tablet-max) {
  //           flex: 1;
  //         }

  //         .toggle-text {
  //           margin-top: 3px;
  //         }

  //         div {
  //           margin: 2px;
  //         }
  //       }
  //     }
  //   }
  // }
}
// @media screen and (max-width: 991px) {
//   .meal-report .menu-construction .meal-dish section:last-child {
//     display: flex;
//   }
//   .menu-construction .dishes .meal-dish section .mob-icon {
//     display: none !important;
//   }
// }
@media screen and (max-width: 991px) {
  .meal-report .menu-construction .meal-dish section:last-child,
  .meal-report .menu-construction .header-listing h6:last-child {
    display: flex;
    width: 36px;
  }
  .meal-report .menu-construction .meal-dish section:last-child .actions {
    display: none;
  }
  .menu-construction .dishes .meal-dish section .mob-icon {
    display: flex !important;
  }
  .menu-construction .meal-dish {
    justify-content: space-between;
  }
}

@media screen and (max-width: 576px) {
  .meal-report .menu-construction .meal-dish section.dish-name:first-child,
  .meal-report .menu-construction .header-listing h6:nth-child(1) {
    width: 30% !important;
  }
  .meal-report .menu-construction .header-listing h6 {
    width: 17.5% !important;
  }
  .meal-report .menu-construction .meal-dish section {
    width: 17.5% !important;
  }
  .meal-report .menu-construction .meal-dish section:last-child {
    width: fit-content !important;
  }
  .meal-report .menu-construction .meal-dish section:last-child .actions {
    display: none;
  }
  .menu-construction .meal-dish {
    justify-content: space-between;
  }
}
.rtl .menu-construction section.inventroy .inventory input.inv-input,
.user-details-block .inventory input.inv-input {
  text-align: center !important;
}
