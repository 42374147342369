@import './global_variables';

.managers-lobby,
.lobby-kitchen {
  font-size: 24px;
  @media (max-width: $tablet-max) {
    font-size: 16px;
  }

  color: $lighter-black;

  .lobby-cards {
    display: flex;

    @media (max-width: $tablet-max) {
      flex-direction: column;
    }

    justify-content: center;

    .item {
      flex: 0 0 30%;
      margin: 0 15px;

      @media (max-width: $tablet-max) {
        margin: 15px 0;
      }
    }

    .kitchen-lobby-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      background-color: $light-gray;
      background-size: cover;

      border-radius: 8px;
      padding: 20px 0;

      min-height: 300px;

      .title {
        margin: 0;
      }

      .content {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        &.rating {
          flex-direction: column;
        }

        .content-icon {
          max-width: 150px;
          max-height: 150px;
          margin: 20px 0;
          @media (max-width: $tablet-max) {
            max-height: 80px;
            margin: 10px;
          }
        }
      }

      .lobby-button {
        background-color: transparent;
        border: 1.5px solid #14cd30;
        color: $lighter-black;

        &:hover {
          background-color: white;
        }
      }
    }

    .action-blocks {
      display: flex;
      flex-direction: column;

      .block {
        display: flex;
        flex: 1;

        flex-direction: column;
        justify-content: center;

        @media (max-width: $tablet-max) {
          padding: 20px 0;
        }

        border-radius: 8px;
        font-size: 1em;

        color: $white-color;
        font-weight: bold;
        text-decoration: transparent;
        background-color: #14cd31;

        &:hover {
          cursor: pointer;
          background-color: #19b630;
        }
      }
    }
  }
}
.lobby-page .table-header {
  height: 44px;
  background: #eff0f7;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-width: 0 0 0.5px 0;
  border-style: solid;
  border-color: #8289a3;
  padding: 10px 20px;
}
.lobby-page .table-header h6 {
  margin: 0;
  // padding: 10px 20px;
  width: 10%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #2b3147;
  text-align: center;
  min-width: 120px;
}
.lobby-page .table-header h6:first-child {
  text-align: left;
}
.lobby-page .table-header h6:last-child {
  width: 30%;
}
.table-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-title h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #23283c;
  margin: 0;
  text-align: left;
}
.table-title a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
.table-title a:hover {
  color: #23283c;
}
.table-title a i {
  margin-left: -5px;
  font-size: 14px;
}
.meal-row {
  background: #ffffff;
  border-bottom: 0.5px solid #8289a3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.meal-row:last-child {
  border-bottom: none;
}
.meal-row section {
  width: 10%;
  // padding: 10px 20px;
  text-align: center;
}
.meal-row section:first-child {
  text-align: left;
}
.meal-row section.more-img-icon {
  cursor: pointer;
  width: 30%;
  text-align: right;
}
.meal-row section.more-img-icon img {
  height: 14px;
}
.meal-row section p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6d7287;
  margin: 0;
}
.meal-row .makeStylesRating {
  min-width: auto;
}
.past-meal-table {
  margin-top: 20px;
}
.review-score-section {
  margin-top: 20px;
}
.review-score-title h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #23283c;
  text-align: left;
  margin: 0;
}
.review-score-title h6:last-child {
  display: none;
}
.review-score-title h6 button {
  background: transparent !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 10px 10px 5px !important;
  color: #6d7287 !important;
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
  display: none;
}
.review-score-title h6 button:hover {
  opacity: 0.9;
}
.review-score-chart {
  margin-top: 20px;
  max-width: 880px;
  width: 100%;
}
.review-score-header {
  padding: 28px;
  border-bottom: 2px solid #ececec;
  display: flex;
  justify-content: space-between;
}
.review-score-left h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #828282;
  margin: 0;
  text-align: left;
}
.review-score-left h6 {
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: -0.0375em;
  color: #0b1354;
  margin: 0;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 3px;
}
.review-score-left p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  margin: 0;
  text-align: left;
}
.review-score-right {
  cursor: pointer;
}
.review-score-footer {
  text-align: left;
  margin-top: 20px;
}
.review-score-footer button {
  max-width: fit-content;
  width: 100%;
  height: 48px;
  background: #14cd30;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: center !important;
}
.review-score-footer .btn:first-child:hover,
.review-score-footer .btn:first-child:active,
.review-score-footer .btn:focus-visible {
  background: #19b630;
  color: #ffffff;
  border: none;
  box-shadow: none;
}

.meal-row .rating .rating-wrapper .makeStyles-greenStars-7 {
  color: #ffc700;
}

.lobby-page.my-orders-table-section {
  .container {
    @media (min-width: 990px) {
      max-width: 900px;
    }

    @media (min-width: 1100px) {
      max-width: 1000px;
    }

    @media (min-width: 1200px) {
      max-width: 1550px;
    }
  }

  .my-orders-table {
    overflow: hidden;
    .table-text-section {
      // max-width: calc(100% - 50px);
      max-width: 100%;
      gap: 5px;
      justify-content: flex-start;

      h6 {
        min-width: calc(14% - 5px);
        max-width: calc(14% - 5px);

        @media (max-width: 768px) {
          &:nth-child(1) {
            min-width: calc(100% - 240px);
            max-width: calc(100% - 240px);
          }

          &:nth-child(2) {
            min-width: 60px;
            max-width: 60px;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            min-width: 30px;
            max-width: 30px;
          }
        }
      }

      p {
        min-width: calc(14% - 5px);
        max-width: calc(14% - 5px);
      }

      .rating {
        min-width: 14%;
        max-width: 14%;
        .makeStylesRating {
          min-width: 100%;

          p {
            font-size: 12px !important;
            line-height: 18px;
            min-width: 100% !important;
            max-width: 100% !important;
            text-align: center;
            color: #8d92a3;
            &:first-child {
              display: block;
            }
            &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }

  .table-body {
    .my-orders-table-item {
      border-color: #8289a3;

      @media (max-width: 992px) {
        padding: 10px;
      }
    }

    .table-text-section p:nth-child(2) {
      justify-content: center;
    }

    .table-btn-section {
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.ltr {
  .lobby-page.my-orders-table-section {
    .table-btn-section {
      max-width: fit-content;
      min-width: 14px;
    }
  }
}

@media (max-width: 1110px) {
  .lobby-page.my-orders-table-section {
    .my-orders-table {
      .table-text-section {
        h6 {
          min-width: 14% !important;
          max-width: 14% !important;
          font-size: 14px;
        }

        p {
          min-width: 14% !important;
          max-width: 14% !important;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .lobby-page.my-orders-table-section {
    .my-orders-table {
      .table-text-section {
        // max-width: calc(100% - 20px);

        .rating {
          .rating-wrapper {
            min-width: 50px !important;
            max-width: 50px !important;
            .makeStylesRating {
              justify-content: center;
              p {
                &:nth-child(2) {
                  display: none !important;
                }
                &:last-child {
                  display: block !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .lobby-page.my-orders-table-section {
    .my-orders-table {
      .table-header.my-orders-table-head {
        padding: 11px 5px;
      }

      .my-orders-table-item {
        padding: 5px;
      }

      .table-text-section {
        // max-width: calc(100% - 15px);
        h6,
        p {
          font-size: 12px;
          line-height: 18px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: clip;

          &:nth-child(1) {
            min-width: calc(100% - 225px) !important;
            max-width: calc(100% - 225px) !important;
          }

          &:nth-child(2) {
            display: none;
          }

          &:nth-child(3) {
            min-width: 60px !important;
            max-width: 60px !important;
          }

          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            min-width: 30px !important;
            max-width: 30px !important;
          }
        }

        h6 {
          &:nth-child(7) {
            min-width: 50px !important;
            max-width: 50px !important;
          }
        }

        .rating {
          .makeStylesRating {
            min-width: fit-content;

            span {
              display: none;
            }
          }
        }
      }
    }

    .review-score-title {
      h6 {
        &:last-child {
          display: flex;
        }
        &:first-child {
          display: none;
        }
        button {
          display: block;
        }
      }
    }
    .review-score-footer {
      display: none;
    }
    .table-title {
      justify-content: flex-start;
      gap: 9px;
      align-items: flex-end;
    }
    .table-title a {
      font-size: 14px;
    }
  }
}

@media (max-width: 576px) {
  .lobby-page.my-orders-table-section {
    .my-orders-table {
      .table-text-section {
        h6,
        p {
          font-size: 11px;
          &:nth-child(1) {
            min-width: calc(100% - 225px) !important;
            width: 100%;
          }
          &:nth-child(3) {
            min-width: 60px !important;
            max-width: 60px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .lobby-page.my-orders-table-section {
    .my-orders-table {
      .table-text-section {
        h6,
        p {
          font-size: 10px;
        }

        .rating .makeStylesRating {
          p {
            font-size: 10px !important;
          }
        }
      }
    }

    // }
  }
}

.review-score-title {
  h6 {
    display: flex;
    button {
      display: block;
    }
  }
}
.view-lobby-popup .rating-wrapper > div {
  align-items: flex-start !important;
}
// rtl CSS

.rtl {
  .lobby-page .table-header h6 {
    text-align: center !important;
  }
  .lobby-page .table-header h6:first-child {
    text-align: right !important;
  }
  .lobby-page.my-orders-table-section .review-score-title h6 button i,
  .rtl .table-title a i {
    transform: rotate(180deg);
  }
}
