@import 'global_variables';

.my-meals {
  display: flex;
  flex-direction: column;

  font-size: 20px;
  @media (max-width: $tablet-max) {
    font-size: 14px;
  }

  .order-card {
    display: flex;
    flex-direction: row;
    @media (max-width: $tablet-max) {
      flex-direction: column;
    }

    .image {
      min-width: 100px;
      @media (max-width: $tablet-max) {
        display: none;
      }
    }

    margin-top: 20px;
    border-radius: 8px;

    .section1 {
      display: flex;
      flex: 4;
      //width: 100%;
      align-items: center;
      justify-content: space-between;
      margin: 10px;
      padding: 20px;

      @media (max-width: $tablet-max) {
        border-bottom: 1px dashed $darker-gray;
      }

      .date-type {
        display: flex;
        flex: 2;
        flex-wrap: wrap;
        text-align: right;

        @media (max-width: $tablet-max) {
          flex-direction: column;
          align-items: center;
          //text-align: right;
        }

        @media (min-width: $tablet-max) {
          p {
            margin-right: 5px;
          }
        }
      }

      .name-and-kitchen {
        display: flex;
        flex: 4;
        @media (max-width: $tablet-max) {
          flex-direction: column;
        }

        padding: 0 20px;

        .dish-name {
          flex: 2;
          text-align: right;

          @media (max-width: $tablet-max) {
            font-size: 1.2em;
            font-weight: bold;
          }
        }

        .kitchen {
          flex: 1;
          //font-size: 0.8em;

          text-align: right;
        }
      }
    }

    .section2 {
      display: flex;
      flex-wrap: wrap;
      flex: 4;
      @media (min-width: $very-large-screen-min) {
        text-align: end;
        flex: 2.5;
      }

      justify-content: center;
      align-items: center;
      min-height: 50px;
      width: 100%;
      padding: 10px;
      @media (max-width: $tablet-max) {
        padding: 6px;
      }

      .icon-wrapper {
        padding: 0 20px;
        @media (max-width: $tablet-max) {
          padding: 0 8px;
        }
      }

      .rating {
        cursor: pointer;
      }
    }
  }
}

body > iframe {
  display: none;
}
