@import 'global_variables';

.review {
  display: flex;
  flex-direction: column;
  justify-content: right;

  margin-top: 10px;

  background-color: $light-gray;
  padding: 20px 30px;

  border-radius: 8px;

  font-size: 20px;
  @media (max-width: $tablet-max) {
    font-size: 16px;
  }
  text-align: start;

  p {
    font-size: 1em;
  }

  h4 {
    font-size: 1.1em;
  }

  .review-rating {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    p {
      margin-bottom: 0;
      font-weight: 600;
    }
    @media (max-width: $tablet-max) {
      flex-direction: column;
      align-items: flex-start;
    }

    .rating-label {
      font-size: 1em;

      @media (max-width: $tablet-max) {
        align-self: flex-start;
      }
    }
  }

  textarea {
    font-size: 0.8em;
    margin: 20px 0;
    padding: 10px 15px;
    font-family: 'OpenSans', sans-serif;
  }

  .submit-review-btn {
    margin-top: 20px;
    align-self: flex-end;
    font-size: 1.2em;

    @media (max-width: $tablet-max) {
      align-self: center;
      margin-top: 0px;
    }
  }
}

.review-btn button {
  background: #14cd31;
  border: 1px solid #14cd31;
  font-size: 12px;
}
.review-btn button:hover,
.review-btn button:focus,
.review-btn button:active {
  background: #19b630 !important;
  border: 1px solid #14cd31 !important;
}
.review-page {
  padding-bottom: 50px;
}
.review-page img {
  height: 100px;
  width: 160px;
  border-radius: 5px;
  object-fit: cover;
}
.review-details-section .review-details-name {
  width: calc(100% - 190px);
}
.review-page {
  padding-bottom: 50px;
}

.we-footer p {
  margin: 0;
}

.review-details-section .review-details-name h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #212529;
}

.review-details-section .review-details-name p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7e7e7e;
  margin-bottom: 5px;
}

.review-management .dish-name p {
  margin: 0;
}

.review-management .dish-name {
  line-height: initial;
  display: flex;
  flex-direction: column;
}
.review-management .rating-star > div,
.view-review-popup .rating-star > div {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start;
  padding-left: 0 !important;
}
.rtl .review-management .rating-star > div,
.rtl .view-review-popup .rating-star > div {
  padding-right: 0 !important;
}
.user-details-block-para p {
  width: 100% !important;
  word-break: break-word;
}
.review-dish-detail {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}
.review-dish-detail h4,
.review-dish-detail p {
  font-weight: 600;
  margin: 0;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.user-review-page .button-wrapper .we-button {
  border-radius: 8px;
  height: 48px;
  font-weight: 500;
  font-size: 20px;
}
.global-data-table.review-management .ag-row {
  background-color: white !important;
  display: flex;
  align-items: center;
}
.global-data-table.review-management .ag-theme-alpine .ag-ltr .ag-cell span {
  line-height: initial;
  padding: 20px 0px;
}
@media screen and (max-width: 768px) {
  .global-data-table.review-management .active-button {
    justify-content: flex-end !important;
  }
  .review-dish-detail span {
    display: none;
  }
}
