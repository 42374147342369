@import 'global_variables';

.sub-header-wrapper {
  @media screen and (min-width: 768px) {
    // display: none;
  }
  .sub-header {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);

    min-height: 45px;
    @media screen and (max-width: 576px) {
      min-height: auto;
    }
    position: relative;
    z-index: 20;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .user-name {
      font-weight: 600;
      color: #475058;
      text-align: right;
    }
    .title {
      font-weight: 600;
      // text-align: center;
      // color: #14cd31;
    }

    .kitchens-list {
      font-weight: 600;
      text-align: left;
      color: #a6bcd0;
      position: relative;

      &.pointer {
        cursor: pointer;
      }

      .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: right;

        padding: 10px 30px;

        &:hover {
          .kitchen-arrow {
            transform: translateX(-10px);
          }
        }
      }

      .content {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: 0;
        background-color: white;
        border-radius: 20px;
        z-index: 10;
        transition: height 0.3s;
        box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
        width: 100%;
        .list-item {
          .kitchen-arrow {
            transform: rotate(90deg);
          }
          &:hover {
            .kitchen-arrow {
              transform: rotate(90deg) translateY(10px);
            }
          }
        }
      }

      .active {
        display: flex;
      }

      .kitchen-arrow {
        width: 16px;
        height: 16px;
        transition: all 0.2s linear;
      }
    }

    .date-picker-wrapper {
      .date-picker {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .text {
          margin: 0 5px;
          color: #14cd31;
          font-weight: 600;
          text-decoration: underline;
          vertical-align: middle;
        }
        .icon {
          margin: 0 5px;
          width: 28px;
        }
      }
    }
  }
}

.desktop {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  .sub-header {
    padding: 10px 30px;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;

    @media (max-width: $tablet-max) {
      font-size: 0.8em;
    }

    .user-name {
      flex: 3;
    }
    .title {
      flex: 6;
      font-size: 1.2em;

      .title-date {
        font-size: 0.7em;
        color: $lighter-black;

        &.clickable {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .kitchens-list {
      flex: 3;

      .list {
        .content {
          padding: 0 20px;
          min-width: 200px;
          .list-item {
            padding: 10px 0;
          }
        }
        .active {
          display: flex;
        }
      }
    }

    .date-picker-wrapper {
      flex: 1;
      .date-picker {
        .text {
          margin: 0 5px;
          font-size: 17px;
        }
        .icon {
          margin: 0 5px;
          width: 28px;
        }
      }
    }
  }
}

.mobile {
  flex: 1;
  display: flex;
  flex-direction: column;

  .sub-header {
    //padding: 10px 18px;

    &.mobile {
      box-shadow: none;
      padding-top: 26px;
      text-align: center;
      z-index: 19;
      &.has-date-picker {
        text-align: right;
      }
    }

    .user-name {
      flex: 1;
      padding: 0 15px;
      font-size: 16px;
    }
    .title {
      flex: 6;
      font-size: 22px;
      // font-weight: 500;
      text-align: inherit;

      .title-date {
        font-size: 0.7em;
        color: $lighter-black;
        text-align: center !important;

        &.clickable {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .kitchens-list {
      flex: 1;

      .list {
        font-size: 16px;

        .content {
          padding: 0 20px;
          min-width: 200px;
          .list-item {
            padding: 10px 0;
          }
        }
        .active {
          display: flex;
        }
      }
    }

    .date-picker-wrapper {
      flex: 1;
      .date-picker {
        .text {
          margin: 0 5px;
          font-size: 17px;
        }
        .icon {
          margin: 0 5px;
          width: 28px;
        }
      }
    }
  }
}

.we-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 15px 50px 15px;
  font-size: 14px;
  align-items: center;
  z-index: 9;

  .dropdown-lang {
    margin-right: 20px;
    margin-left: 5px;

    .dropdown {
      button.dropdown-toggle {
        background: #fff;
        color: #14cd30 !important;
        padding: 0 !important;
        font-size: 16px !important;
        font-weight: 600;
        border: 0 !important;
        @media (max-width: 576px) {
          font-size: 14px !important;
        }
      }
      .dropdown-menu {
        max-width: 100px;
        box-shadow: #0003 0 4px 6px -1px;
        min-width: unset;
        padding: 5px 0px;
        .dropdown-item {
          color: var(--bs-dropdown-link-color);
          font-weight: 400;
          text-align: inherit;
          text-decoration: none;
          white-space: nowrap;
          background-color: transparent;
          border: 0;
          display: block;
          text-align: center !important;
          padding: 2px 15px;
        }
      }
    }
  }

  @media (max-width: $tablet-max) {
    padding: 10px 10px;
    font-size: 12px;
  }
}

.rtl {
  .dropdown-lang {
    margin-left: 20px;
    margin-right: 5px;
    .dropdown {
      .dropdown-toggle:after {
        margin-right: 0.255em;
        margin-left: 0;
      }
    }
  }
  .login {
    .back-btn {
      img {
        transform: rotate(180deg);
      }
    }
    // .phone-btn {
    //   flex-direction: row-reverse;
    // }
  }
}

.content-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px 100px;
  margin-bottom: 30px;
}

.mobile {
  .content-container {
    padding: 15px;
  }
}

.content-container-bg {
  padding: 15px;
  background: #e0e8f5;
}

// .sub-header-wrapper .sub-header .kitchens-list {
//   max-width: 250px;
//   width: 100%;
// }
.sub-header-wrapper .sub-header .kitchens-list .list-item {
  color: black !important;
  font-size: 18px;
  text-transform: capitalize;
}
.desktop .sub-header {
  padding: 0 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: transparent;
  box-shadow: none;
}
.sub-header-wrapper {
  padding: 5px 50px;
  position: sticky;
  top: 80px;
  background: #ffffff;
  width: 100%;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  z-index: 9;
  @media (max-width: $tablet-max) {
    top: 70px;
  }
}
