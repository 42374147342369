.add-slot-popup .MuiBox-root {
  max-width: 860px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 20px 30px;
}
.add-slot-popup .top-header {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #23283c;
  text-align: left;
}
.add-slot-popup .top-header span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #23283c;
}
.add-slot-popup .item {
  background-color: transparent;
  padding: 0;
  margin: 0;
  margin-top: 30px;
  text-align: left;
  justify-content: space-between;
  border: none !important;
}
.add-slot-popup .input-field input {
  border: none !important;
  background: #ffffff;
  border-bottom: 2px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 0px !important;
  height: 38px !important;
  padding-left: 0px !important;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 18px;
  color: #6d7287;
}
.add-slot-popup .input-field input:focus {
  outline: none;
}
.add-slot-popup .item .input-field label {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2b3147;
  margin-bottom: 10px;
}
.add-slot-popup .addft-btn .add-btn button {
  padding: 12px 24px !important;
  height: 48px !important;
  background: #14cd30 !important;
  border-radius: 6px !important;
}
.add-slot-popup .addft-btn {
  justify-content: flex-start;
}
.add-slot-popup table tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2b3147;
  margin-bottom: 10px;
}
.MuiTableCell-root {
  font-family: 'Poppins', sans-serif !important;
}
.add-slot-popup .item-list table tr td {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #6d7287;
}

.add-slot-popup table tr th:last-child,
.add-slot-popup table tr td:last-child {
  text-align: right;
}
.add-slot-popup table tr th i {
  font-size: 27px;
  margin-left: 12px;
  color: #6d7287;
}
.add-slot-popup table thead {
  height: 44px;
  background: #eff0f7;
  border-bottom: 0.5px solid #8289a3;
  border-radius: 6px 6px 0px 0px;
}
.meal-type-management section.add-btn i {
  color: #6d7287;
  font-size: 25px;
  margin-left: 10px;
  cursor: pointer;
}
.meal-type-management .item {
  margin: 0;
  padding: 0;
  background: #ffffff;
  border-bottom: 0.5px solid #8289a3;
  border-radius: 0px;
}
.meal-type-management section.add-btn {
  margin: 0;
}
.meal-type-management .language-input input {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: left;
  color: #6d7287;
}
.meal-type-management form.add-mealtypes {
  align-items: center !important;
  padding: 0px 10px;
}
.meal-type-management .icon-wrapper.back-button p {
  margin: 0;
  margin-left: 10px;
}
.meal-type-management .table-header {
  display: flex;
  justify-content: space-between;
  height: 44px;
  background: #eff0f7;
  border-bottom: 0.5px solid #8289a3;
  border-radius: 6px 6px 0px 0px;
  align-items: center;
  padding: 0px 10px;
}

.meal-type-management .table-header h6 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #2b3147;
  width: 20%;
  text-align: left;
}
.meal-type-management .table-header h6:first-child {
  width: 15%;
}
.meal-type-management .language-input,
.meal-type-management section.add-btn {
  width: 20%;
  margin: 0 !important;
}

.meal-type-management .language-input:first-child {
  width: 15%;
}
.meal-type-management .language-input input {
  padding: 0;
}
.meal-type-management .add-mealtype {
  margin-bottom: 30px;
}
.meal-type-management .add-mealtype button,
.meal-type-management .add-mealtype button:focus,
.meal-type-management .add-mealtype button:hover {
  background-color: transparent;
  border: 1px solid #6d7287;
  border-radius: 3px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
}

/********** add meal popup *********/
.add-mealtypes-popup .input-field {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 30px;
}

.add-mealtypes-popup .add-mealtype-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
}

.add-mealtypes-popup input {
  padding: 10px 0px;
  grid-gap: 8px;
  gap: 8px;
  height: 38px;
  background: #ffffff;
  box-shadow: none;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6d7287;
  border-radius: 0px !important;
  border-bottom: 2px solid rgb(0 0 0 / 25%);
}
.add-mealtypes-popup input:focus {
  outline: none;
}
.add-mealtypes-popup .input-field label {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #23283c;
  margin-bottom: 13px;
}
.add-mealtypes-popup .add-btn button {
  padding: 12px 24px;
  min-width: 88px;
  height: 48px;
  background: #14cd30;
  border-radius: 6px;
  border: 1px solid #14cd30;
}
.add-mealtypes-popup .add-btn button:hover {
  background: #19b630;
  border: 1px solid #19b630;
}
.add-mealtypes-popup .add-btn {
  display: flex;
  justify-content: flex-start;
}
.add-mealtypes-popup .modal-header {
  border: none;
}
.add-mealtypes-popup .modal-header .h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #23283c;
}
.add-employee-popup.add-slot-popup > div {
  z-index: 999999 !important;
  display: inline-block;
}
.rc-time-picker-panel {
  z-index: 9999999 !important;
}
.meal-type-management .mob-view {
  display: none;
}
.add-mealtypes-popup p.error-msg {
  margin: 0;
  margin-top: 5px;
}
.add-slot-popup .item-list i {
  font-size: 23px;
  margin-left: 5px;
  color: #6d7287;
  cursor: pointer;
}
@media (max-width: 1350px) {
  .kitchens {
    min-width: 80%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .mealtype-table {
    min-width: 1550px;
  }
}
@media screen and (max-width: 767px) {
  .kitchens {
    min-width: 0;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .mealtype-table {
    min-width: 0;
  }
  .meal-slot-new-popup.open-new-popup .input-field {
    width: 48%;
    margin: 0px;
    margin-bottom: 10px !important;
  }
  .meal-type-management .mob-view {
    display: block;
  }
  .kitchen-management.meal-type-management .mob-view {
    width: 15%;
    text-align: center;
  }
  .meal-type-management .language-input,
  .meal-type-management section.add-btn {
    display: none;
  }
  .meal-type-management .language-input:first-child,
  .meal-type-management .language-input:nth-child(2) {
    display: block;
  }
  .meal-type-management .language-input:nth-child(2),
  .meal-type-management .table-header h6:nth-child(2) {
    width: 55%;
  }
  .meal-type-management .table-header h6 {
    font-size: 11px;
    line-height: initial;
    display: none;
  }
  .meal-type-management .table-header h6:first-child,
  .meal-type-management .table-header h6:nth-child(2) {
    display: block;
  }
  .meal-type-management .table-header h6:last-child {
    display: block;
    width: 15%;
  }
  .meal-type-management .table-header h6:first-child,
  .meal-type-management .language-input:first-child {
    width: 30% !important;
  }
  .details-meal-typ-popup .list-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
  }

  .details-meal-typ-popup .list-details p {
    line-height: initial;
    margin: 0;
    font-size: 14px;
    width: 30%;
  }

  .details-meal-typ-popup .list-details h6 {
    line-height: initial;
    margin: 0;
    width: 70%;
    font-weight: 600;
    font-size: 14px;
  }
  .details-meal-typ-popup section.actions {
    display: flex;
    justify-content: space-between;
    max-width: 100px;
    width: 100%;
  }

  .details-meal-typ-popup section.actions i {
    font-size: 24px;
    cursor: pointer;
    color: #727272;
  }
  .add-slot-popup .MuiBox-root {
    padding: 20px 12px;
  }
  .add-mealtypes-popup .input-field {
    width: 48%;
  }
  .add-mealtypes-popup .input-field label {
    margin-bottom: 0;
  }
}
