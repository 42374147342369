@import '../global_variables';

.meal-summary {
  display: flex;
  flex: 5;
  justify-content: flex-start;
  @media (max-width: $tablet-max) {
    flex-direction: column;
    flex: 4;
  }

  .inventory {
    text-align: right;
  }

  .percent-data {
    display: flex;
  }

  p {
    margin: 0 5px;
  }
}

.rtl .kitchen-management .item .kitchen-form h6 {
  text-align: right;
}
// .rtl .meal-type-management .table-header h6{
//   text-align: right;
// }
// .rtl .meal-type-management .language-input.order-index input{
//   text-align: right;
// }
