.dish-management-page .dish {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #8289a3;
}
.flex-container {
  display: flex;
}
button.search-button.margin-t-0,
button.reset-button.margin-t-0 {
  margin: 0 10px;
  color: #fff;
}
img.dish-button-img {
  filter: invert(0) !important;
}
img.dish-button-search-img {
  width: 25px !important;
  height: 25px !important;
}
.dish-search-input {
  position: relative;
  width: 150px;
}
.dish-management-page .dish section .actions {
  display: flex;
  align-items: center;
}
.dish-management-page .dish section .actions i {
  color: #6d7287;
  font-size: 25px;
  cursor: pointer;
  margin-left: 10px;
}
.dish-management-page .dish section:first-child {
  width: 30%;
}
.dish-management-page .dish section {
  width: 15%;
  padding: 10px 20px;
  text-align: left;
}
.dish-management-page .dish section:last-child {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}
.dish-management-page .rating-wrapper > div {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start;
}

.dish-management-page .dish p {
  font-weight: 500;
  font-size: 12px !important;
  line-height: 18px;
  color: #6d7287;
  text-align: left;
  margin: 0;
}
.dish-management-page .dish:first-child .dish-name p {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 18px;
  margin: 0;
}
.dish-management-page .top-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.dish-management-page .top-header-section input {
  padding: 0px 12px 0px 40px;
  gap: 10px;
  width: 100%;
  min-width: 150px;
  height: 40px;
  background: #eff0f7;
  border-radius: 6px;
  border: 1px solid #eff0f7;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6d7287;
}
.dish-management-page .top-header-section input:focus {
  outline: none;
  box-shadow: none;
}
.dish-management-page .search-section img {
  position: absolute;
  top: 12px;
  left: 12px;
  height: 17px;
}
.dish-management-page .search-section {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 500px;
  width: 100%;
}
.dish-management-page .from-date {
  display: grid;
  align-items: center;
  margin-left: 20px;
  max-width: 210px;
  width: 100%;
}
.dish-dropdown-label {
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: left;
  text-transform: capitalize;
}
.dish-management-page .search-section select {
  height: 42px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6e6893;
  box-shadow: none;
  padding: 0 12px 0 12px !important;
  outline: none;
  appearance: auto;
}
.dish-management-page .add-dish-btn button,
.dish-management-page .add-dish-btn button:hover,
.dish-management-page .add-dish-btn button:focus {
  padding: 3px 5px;
  min-width: 113px;
  height: 30px;
  border: 1px solid #6d7287;
  border-radius: 3px;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
}
.dish-management-page .table-header {
  height: 44px;
  background: #eff0f7;
  // border-bottom: 0.5px solid #8289a3;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: #e0e3ef;
}

.dish-management-page .table-header h6 {
  margin: 0;
  padding: 10px 20px;
  width: 15%;
  text-align: left;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dish-management-page .table-header h6:first-child {
  width: 30%;
}
.dish-management-page .table-header h6:last-child {
  width: 40%;
}

.dish-management-page section.image .dish-img {
  height: 50px !important;
  width: 75px !important;
}
.dish-management-page section .actions i {
  font-size: 20px;
  margin: 0px 5px;
}

.dish-management-page section.image {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dish-management-page section.image .dish-name {
  // margin-left: 10px;
  margin-left: 0;
  width: calc(100% - 80px);
}

.dish-management-page section.image .dish-name p {
  margin: 0;
}
.dish-management-page .sorting-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0px;
  margin-top: 7px;
  margin-left: 5px;
}

.dish-management-page .sorting-icons i {
  cursor: pointer;
  color: black;
  font-size: 16px;
  margin-top: -7px;
}

/*********************** dihs management popup **********************/
.view-dish-details .actions-btns i {
  font-size: 18px;
  cursor: pointer;
  margin-right: 7px;
}
.view-dish-details .rating-wrapper > div {
  align-items: flex-start;
}
.view-dish-details .user-details-block .dish-img {
  height: 70px !important;
  background-size: cover;
  width: 120px !important;
}
.dish-description-one p.one-line-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin: 5px 0;
}
@media screen and (max-width: 991px) {
  .dish-search-input {
    position: relative;
    width: 150px;
  }
  .dish-management-page .dish section:nth-child(3) {
    display: none;
  }
  .dish-management-page .table-header h6:nth-child(3) {
    display: none;
    width: 15%;
  }

  .dish-management-page .table-header h6 {
    font-size: 13px;
    padding: 0 5px;
  }
  .dish-management-page .dish section {
    padding: 10px 5px;
  }
  .dish-management-page .dish section p {
    font-size: 12px;
    line-height: initial;
  }
  .dish-management-page section.image .dish-img {
    display: none;
  }
  .dish-management-page section.image .dish-name {
    margin-left: 0px;
    width: 100%;
  }
  .dish-management-page .dish section .actions i {
    font-size: 18px;
  }
  .dish-management-page .add-dish-btn button,
  .dish-management-page .add-dish-btn button:hover,
  .dish-management-page .add-dish-btn button:focus {
    min-width: 85px;
    font-size: 12px;
  }
  .add-dish-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
  }
  .dish-management-page .top-header-section {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .dish-management-page .top-header-section input {
    height: 35px;
    font-size: 14px;
    min-width: 180px;
    max-width: 250px;
    width: 100%;
  }
  .dish-management-page .search-section img {
    position: absolute;
    top: 12px;
    left: 25px;
    height: 14px;
  }
  .dish-management-page .search-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 400px;
  }

  .dish-management-page .table-header h6:last-child {
    width: 20%;
  }
  .dish-management-page .table-header h6 {
    width: 40%;
  }
  .dish-management-page .table-header h6:first-child {
    width: 40%;
  }
  .dish-management-page .dish section:first-child {
    width: 40%;
  }
  .dish-management-page .dish section {
    width: 40%;
  }
  .dish-management-page .dish section:last-child {
    width: 20%;
  }
}

@media screen and (max-width: 767px) {
  .dish-management-page .search-section img {
    position: absolute;
    top: 12px;
    left: 25px;
    height: 14px;
  }

  .dish-management-page .search-section {
    display: flex;
    flex-direction: column;
    width: 400px;
  }
}
@media screen and (max-width: 640px) {
  .dish-management-page .search-section {
    width: 200px;
  }
  .flex {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
  }
}
@media screen and (max-width: 480px) {
  .dish-management-page .add-dish-btn button span,
  .tag-management-page .add-employee-btn .active-button button span,
  .kitchen-management .add-employee-btn .active-button button span,
  .kitchen-device .add-employee-btn .active-button button span {
    display: none;
  }
  .add-dish-btn {
    margin: 0;
  }
  .dish-management-page .add-dish-btn button,
  .dish-management-page .add-dish-btn button:hover,
  .dish-management-page .add-dish-btn button:focus,
  .tag-management-page .add-employee-btn .active-button button,
  .kitchen-management .add-employee-btn .active-button button {
    min-width: 38px;
    height: 38px !important;
  }
  .dish-management-page .top-header-section,
  .tag-management-page .add-employee-btn,
  .kitchen-management .add-employee-btn {
    flex-wrap: unset;
  }
  .kitchen-management .add-employee-btn .active-button {
    width: unset !important;
    margin-top: 0;
  }
}
.rtl {
  .dish-management-page .search-section img {
    left: unset;
    right: 12px;
  }
  .dish-management-page .search-section p {
    padding-right: 45px;
  }
  .dish-management-page .top-header-section input {
    padding-left: 12px;
    padding-right: 40px;
  }
}
