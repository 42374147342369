@import './style/global_variables';
@import './style/rtl';
@import './style/ltr';
@import './style/common';
@import 'style/layout';
@import './style/header';
@import './style/navbar';
@import './style/login';
@import './style/rtl';
@import './style/lobby';
@import './style/calendar';
@import './style/meal_dishes';
@import './style/tags';
@import './style/review';
@import './style/mealTime';
@import './style/order_summary';
@import './style/layout';
@import './style/my_meals';
@import './style/voucher';
@import './style/form';
@import './style/profile';
@import './style/dropzone';
@import './style/checkbox';

@import './style/controlPanel';
@import './style/searchLineWithButton';

/* Guests */
@import './style/GuestScreens/guests';

@import './style/managersLobby';

@import './style/SiteManager/userManagement';
@import './style/SiteManager/kitchenManagement';
/* Kitchen manager */
@import './style/KitchenManager/kitchenCommon';
@import './style/KitchenManager/dish_management';
@import './style/KitchenManager/mealManagement';
@import './style/KitchenManager/mealReport';
@import './style/KitchenManager/menu_construction';
@import './style/KitchenManager/addEditDish.scss';
@import './style/KitchenManager/addDishToMeal.scss';
@import './style/logviewer.scss';
@import 'rsuite/dist/rsuite.css';
@import './style/clientLobby.scss';
html {
  font-size: 100%;
  overflow-x: hidden;
}

/*16px*/
// body {
//   overflow-x: hidden;
// }
.App {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.sidebar-client-view-switcher {
  padding: 0 5px;
  display: block;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.link-css {
  color: #14cd30 !important;
  cursor: pointer;
}
.color-white {
  color: #fff !important;
}
.arrow-down {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: solid lightgray;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin: 4px;
}
.arrow-top {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: solid lightgray;
  border-width: 0 2px 2px 0;
  transform: rotate(225deg);
  margin: 4px;
  margin-top: 10px;
}
/************* new css ******************/
.update-wallet-text {
  color: #14cd30 !important;
  background-color: transparent;
  border: transparent;
  font-weight: 600;
  padding: 0;
}
.audit-general-text button {
  font-size: 14px;
  line-height: initial;
  background: transparent !important;
  border: none !important;
  color: #90939c !important;
  text-align: left;
  padding: 0;
}
.dropdown-label {
  font-size: 13px;
  text-align: left;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
}
.audit-view-more {
  font-size: 14px;
  line-height: initial;
  color: #bbbec9 !important;
}
ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
li.format {
  margin-top: 10px;
  font-size: 18px;
}
.payment-info-popup .extras-listing-section {
  text-align: center !important;
}
.payment-info-popup .payment-info-body {
  padding-top: 20px;
}
.payment-info-popup .payment-info-body p {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  padding: 40px 0px;
}
.payment-info-popup .view-more-popup button.close-modal-btn {
  margin: 0;
}

.employee-management .ag-cell-normal-height {
  height: 100%;
  text-align: left;
}

.employee-management .add-employee-btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}

.add-employee-popup input {
  min-width: 0;
  font-size: 1em;
  color: #333333;
  text-overflow: ellipsis;
  border-radius: 4px;
  padding: 10px 15px;
  box-shadow: none;
}

.add-employee-popup input::placeholder {
  font-size: 14px !important;
}

.add-employee-popup select,
.add-employee-popup select option {
  font-size: 14px !important;
}

.add-employee-popup label {
  font-size: 14px;
}

.add-employee-popup .item {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.add-employee-popup.update-lang .item {
  align-items: center;
}
.add-employee-popup.update-lang .item .input-field {
  width: 48%;
}
.add-kitchen-popup .item {
  row-gap: 20px;
}

.add-employee-popup .item .input-field {
  width: 30%;
}
.add-employee-popup .item .input-field.type-checkbox {
  display: flex;
  gap: 10px;
  margin-top: 25px;
  margin-left: -100px;
}
.add-employee-popup .item .input-field.type-checkbox-he {
  display: flex;
  gap: 10px;
  margin-top: 25px;
  margin-left: 120px;
}
.add-user-roles-block {
  display: flex;
  gap: 15px;
}

@media (min-width: 768px) {
  @media (max-height: 880px) {
    .mobile-height {
      height: 65vh;
      max-height: 65vh;
      overflow-x: auto;
    }
  }
}
.add-employee-popup .input-field input {
  width: 100% !important;
  border: 1px solid #e3e3e3;
}
.add-employee-popup .input-field input[type='checkbox'] {
  min-width: 20px !important;
  height: 20px;
}

.add-employee-popup .MuiBox-root.MuiBox-root-7 {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.add-employee-popup .MuiTypography-h5 {
  font-weight: 600;
  text-align: center;
  color: #14cd31;
  font-family: 'OpenSans', sans-serif;
  font-size: 28px;
}

.add-employee-popup .MuiTypography-body1 {
  font-family: 'OpenSans', sans-serif !important;
}

.add-employee-popup {
  position: relative;
  z-index: 999999 !important;
}

.employee-management .add-employee-btn .icon-wrapper .we-icon,
.add-employee-popup .we-icon {
  margin-right: 0px !important;
  margin-left: 5px;
}

.add-employee-popup label {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #23283c;
}

.add-employee-popup .add-employee-btn {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  @media (max-width: 850px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.add-employee-popup .add-employee-btn button,
.add-employee-popup .add-employee-btn button:focus {
  padding: 12px 24px;
  gap: 10px;
  height: 48px;
  background: #14cd30;
  border-radius: 6px;
  border: 1px solid #14cd30;
  min-width: 100px;
}
.add-employee-popup .add-employee-btn button:hover {
  background: #19b630;
  border: 1px solid #19b630;
}
.new-current-password .add-employee-btn {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-bottom: 0;
}
.new-current-password .add-employee-btn button:first-child,
.new-current-password .add-employee-btn button:focus:first-child {
  background: #757575;
  border: none;
}
.new-current-password .add-employee-btn button:hover:first-child {
  background: #646060;
}
.add-employee-popup.new-current-password label {
  font-size: 14px;
  padding-bottom: 10px;
}

.employee-management .user-status-filter {
  text-align: left;
  display: grid;
  align-items: center;
  margin: 0px;
  width: 30%;
  text-align: left;
}

.employee-management .user-status-filter p {
  padding-left: 0px !important;
  font-weight: 500;
  font-size: 17px;
}

.employee-management .ag-theme-alpine .ag-row {
  font-size: 14px !important;
  background: #f0f4f8 !important;
  margin-bottom: 13px !important;
}

.employee-management .ag-theme-alpine .ag-root-wrapper {
  border-color: transparent !important;
}

.employee-management .ag-theme-alpine .ag-ltr .ag-cell {
  border-right-width: 1px;
  display: flex !important;
  align-items: center !important;
}

.employee-management .ag-theme-alpine .ag-ltr .ag-cell {
  border-right-width: 1px;
  color: #333333;
  font-size: 18px !important;
  font-weight: 400;
}

.employee-management span.ag-header-cell-text {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.employee-management .ag-theme-alpine .ag-header {
  border-bottom-color: transparent !important;
  background: transparent !important;
}

// .employee-management .ag-center-cols-container {
//   width: 100% !important;
// }
.employee-management .ag-cell-inline-editing {
  height: 85px !important;
}

.employee-management .add-employee-btn input {
  border-radius: 5px;
  border: 1px solid #ededed;
  height: 40px;
  padding: 5px 10px;
  width: 25%;
}

.employee-management .edit-btn {
  padding: 6px 22px;
  margin: 3px;
  color: white;
  border: 1px solid #14cd31;
  background: #14cd31;
  border-radius: 24px;
}

.employee-management .delete-btn {
  padding: 6px 18px;
  margin: 3px;
  color: white;
  border: 1px solid #cd1414;
  background: #cd1414;
  border-radius: 24px;
}

.employee-management .pagination-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.employee-management .pagination-header select {
  margin-left: 10px;
  border-radius: 5px !important;
  padding: 5px 10px;
}

.employee-management .css-1hb7zxy-IndicatorsContainer {
  height: 40px;
}

.employee-management .ag-paging-panel.ag-unselectable {
  margin-top: 15px;
}

.employee-management .ag-body-horizontal-scroll {
  overflow: auto;
}

.employee-management .ag-body-horizontal-scroll::-webkit-scrollbar {
  width: 10px;
}

.employee-management .ag-body-horizontal-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.employee-management .ag-body-horizontal-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

.employee-management span.ag-paging-page-summary-panel {
  margin-right: 0px !important;
}

.employee-management .ag-theme-alpine .ag-paging-panel {
  height: 70px;
}

.user-management.employee-management {
  margin-bottom: 50px;
}

.employee-management .active-button {
  display: flex;
  align-items: center;
  gap: 10px;
}

p.error-msg {
  font-size: 12px !important;
  color: red !important;
  font-weight: 600;
}
.order-meal p.error-msg {
  position: absolute;
  bottom: -40px;
}
.add-employee-btn.disabled-btn button.we-button.active {
  pointer-events: none;
  background: #14cd31;
  opacity: 0.8;
}

.employee-management .active-button button.download-csv {
  border-radius: 29px;
  // margin-right: 10px;
  background: transparent;
  border: 1px solid #14cd31;
  padding: 10px 20px;
  color: #14cd31;
  font-weight: 600;
  font-size: 15px;
}

.close-btn {
  position: relative;
  margin-left: auto;
  text-align: right;
  padding-bottom: 10px;
}

.close-btn img {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 10px;
  filter: brightness(0.5);
  cursor: pointer;
}

.action-btn {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.action-btn.realized-button {
  justify-content: flex-start;
}

.action-btn.realized-button button.edit-btn {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0px 12px !important;
  font-size: 16px !important;
  width: 100%;
  font-size: 13px !important;
  border-radius: 5px;
}

button.resend-btn {
  padding: 0px 14px;
  color: white;
  border: 1px solid #ff940f;
  background: #ff940f;
  border-radius: 24px;
  height: 30px;
}

.employee-management button {
  cursor: pointer;
}

.copy-url {
  margin: 10px 20px;
  text-align: center;
}

.copy-url button {
  padding: 10px 15px;
  max-width: 125px !important;
  width: 100%;
  margin-bottom: 20px;
  font-size: 17px;
  background: #19b630;
  border-radius: 8px;
  border: 1px solid #19b630;
  color: white;
  cursor: pointer;
}

.copy-url p {
  margin: 50px 0px;
  font-size: 18px;
  font-weight: 500;
  color: black;
}

button.copy-btn {
  padding: 0px 7px;
  color: white;
  border: 1px solid #1d94af;
  background: #1d94af;
  border-radius: 24px;
  height: 30px;
  font-size: 11px;
  font-weight: 600;
  margin-left: 3px;
}

.delete-popup button.copy-btn {
  padding: 9px 15px;
  max-width: 90px !important;
  width: 100%;
  margin-bottom: 20px;
  font-size: 17px;
  background: #757575;
  border-radius: 0.25em;
  border: none;
  color: white;
  cursor: pointer;
  margin: 0px 5px;
  height: 100%;
  font-weight: 500;
  text-align: center !important;
}

.delete-popup button.delete-btn {
  background: rgb(20, 205, 49);
  border: none;
  margin: 0px 5px;
  border-radius: 0.25em;
  text-align: center !important;
}
.delete-popup button.delete-btn:hover {
  background: #19b630;
}

.delete-popup button {
  max-width: 90px !important;
  padding: 9px 15px;
}

.cookie-popup {
  position: relative;
}

.cookie-content {
  position: fixed;
  // z-index: 999;
  background: #000000;
  font-size: 18px;
  padding: 28px 35px;
  bottom: 5%;
  width: 100%;
  text-align: left;
  box-shadow: 0px 0px 5px 0px #efefef;
  color: white;
}

.cookie-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookie-content p {
  width: 80%;
}

.cookie-btn {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.cookie-btn button {
  margin: 5px;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.cookie-btn .reject-btn {
  background: #eb2020;
  border: 1px solid #eb2020;
  color: white !important;
  font-size: 16px;
  font-weight: 600;
}

button.accept-btn {
  background: #09bd06;
  border: 1px solid #09bd06;
  color: white !important;
  font-size: 16px;
}

.reservation-management .ag-header-group-cell-label {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.action-btn.action-btn button {
  max-width: 30%;
  width: 50%;
  margin: 3px;
}

.reservation-management .action-btn.action-btn button {
  max-width: fit-content;
  width: 100%;
  margin: 3px;
  font-size: 13px !important;
  max-width: 100%;
}

.reservation-management .button-wrapper .we-button.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.tags-management .icon-wrapper {
  display: flex;
  flex-direction: revert;
}

.tags-management .action-btn {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.tags-management .action-btn.action-btn button {
  max-width: fit-content;
}

.tags-management .button-wrapper .we-button {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.tags-management .icon-wrapper .we-icon,
.tags-management .icon-wrapper .we-icon-text {
  margin-left: -7px !important;
}

.tags-management .icon-wrapper p {
  margin-left: 0px !important;
}

.print-table tr td {
  text-align: left;
  padding: 10px;
  font-size: 16px;
  color: #000;
  font-family: 'OpenSans', sans-serif;
}

.print-table tr td span {
  margin-top: -2px;
  color: #000;
  font-size: 18px;
}

.add-employee-popup.print-popup .MuiBox-root {
  overflow: auto;
  position: absolute;
  max-height: 700px;
  border-radius: 8px;
  border: none;
  height: 100%;
  width: 60%;
}

table.print-table tr th {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: black;
  text-align: center;
  padding: 10px 5px;
}

table.print-table tr td {
  text-align: center;
  text-transform: capitalize;
}

table.print-table tr th:first-child,
table.print-table tr td:first-child {
  text-align: left;
}

table.print-table.print-bottom-table {
  border: 1px solid #d7d3d3;
  padding: 10px 5px !important;
  border-collapse: collapse;
}

table.print-table.print-bottom-table tr:first-child {
  border-collapse: collapse;
  background: #d7d3d3;
  border-collapse: collapse;
  border: 1px solid #d7d3d3 !important;
}

table.print-table.print-bottom-table tr th {
  color: black !important;
}

.add-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.add-slot-popup .addft-btn {
  justify-content: flex-end;
  margin-top: 0px;
  margin-bottom: 20px;
  display: flex;
}

.add-slot-popup .addft-btn .button-wrapper {
  justify-content: flex-end !important;
}

.forget-popup .reset-form .add-employee-btn {
  margin: 20px 0px 0px !important;
  justify-content: center !important;
}

.forget-popup .reset-form .add-employee-btn.disabled-btn {
  pointer-events: none;
}

.forget-popup .reset-form {
  width: 100%;
  margin: 0px auto 0px;
}

@page {
  size: 7in 9.25in;
  margin: 27mm 16mm 27mm 16mm;
}

.box-section p {
  margin: 10px 0px;
  font-weight: 700;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-section span {
  margin-right: 20px;
}

.box-section {
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 20px !important;
  // box-shadow: 0px 0px 5px 0px #e1e1e1;
  min-height: 150px;
}

.add-employee-popup.print-popup .MuiBox-root.MuiBox-root-13:focus-visible {
  outline: none !important;
  box-shadow: none;
}

.print-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.print-btn button {
  font-size: 20px;
  font-weight: 600;
  padding: 12px 20px !important;
}

.add-employee-popup.print-popup button {
  border-radius: 5px;
  border: 1px solid #09bd06;
  padding: 10px 20px;
  background: #09bd06;
  color: white;
  cursor: pointer;
}

.add-employee-popup.print-popup .MuiBox-root::-webkit-scrollbar {
  width: 5px;
}

.add-employee-popup.print-popup .MuiBox-root::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.add-employee-popup.print-popup .MuiBox-root::-webkit-scrollbar-thumb {
  background: #888;
}

.privacy-text a {
  color: #396ee5;
  font-weight: 600;
  margin-right: 15px;
  font-size: 14px;
  text-decoration: none;
}
.version-def-style {
  color: rgba(0, 0, 0, 0.25);
}
.privacy-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter-section {
  display: flex;
  align-items: flex-end;
  width: 100%;
  transition: all 0.3s;
}
.reservation-management .filter-section .from-date {
  display: grid;
  align-items: center;
  margin-left: 20px;
  max-width: 210px;
  width: 100%;
}

.reservation-management .data-filter {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.reservation-management .data-filter .button-wrapper {
  justify-content: flex-end !important;
  margin-top: 20px;
}

.reservation-management .filter-section label,
.reservation-updated-page .search-input label {
  font-size: 13px;
  text-align: left;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
}
.search-input-wrapper {
  display: flex;
}
.xyz {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.new-one {
  display: grid;
}

.reservation-management .filter-section input {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 15px;
  color: black;
  cursor: pointer;
  min-height: 38px;
  background-color: transparent !important;
}

.reservation-management .filter-section input:focus {
  outline: none;
  box-shadow: none;
}

.reservation-management .button-wrapper .we-button {
  font-size: 15px !important;
  padding: 10px 20px;
}

.reservation-management .reservation-csv {
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 20px;
}

// .reservation-management .filter-section {
//   max-width: 600px;
// }
.reset-max-width {
  max-width: unset !important;
}
.reservation-management .add-employee-btn .active-button {
  max-width: 140px;
}
.reservation-management .add-employee-btn .active-button .total-data p {
  font-size: 14px;
  font-weight: 700;
}

.reservation-management .add-employee-btn .active-button img {
  margin: 0;
}

.print-popup .align-table {
  direction: rtl;
}

.print-popup tr.box-list td:first-child {
  padding-right: 0px !important;
  padding-left: 10px !important;
}

.print-popup tr.box-list td:last-child {
  padding-left: 0px !important;
  padding-right: 10px !important;
}

.privacy-policy p,
.terms-condition-page p {
  text-align: left;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
}

.privacy-policy h6,
.terms-condition-page h6 {
  margin: 0px;
  font-size: 18px;
  text-align: left;
  margin-bottom: 8px;
  font-weight: 700;
}

.privacy-policy ul,
.terms-condition-page ul {
  margin: 0px 0px;
  padding-left: 18px;
}

.terms-condition-page ul li,
.privacy-policy ul li {
  list-style: none;
  text-align: left;
  font-size: 16px;
  padding: 5px 0px;
  display: inherit;
  align-items: baseline;
}

.terms-condition-page ul li a,
.privacy-policy ul li a {
  margin: 0px 5px;
}

.privacy-policy ul li span,
.terms-condition-page ul li span {
  margin-right: 10px;
}

.terms-condition-page,
.privacy-policy {
  // direction: rtl;
  padding-bottom: 50px;
  font-size: 14px;
}

.terms-condition-page ul li span,
.privacy-policy ul li span {
  font-weight: 600;
}

.terms-condition-page a,
.privacy-policy a {
  color: blue;
  margin-right: 10px;
}

.tags-selection .MuiFormControl-root {
  width: 100%;
  max-width: 100%;
  margin-top: 25px;
}

.tags-selection label {
  font-size: 17px;
  font-weight: 500;
  padding-bottom: 1px;
  transform: none;
  color: black;
  position: initial;
  text-align: left;
}

.bold-date abbr {
  font-weight: 700;
}

button abbr {
  color: #8e8e8e !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  pointer-events: none;
  cursor: not-allowed;
}

button.react-calendar__tile.react-calendar__month-view__days__day.bold-date {
  pointer-events: initial !important;
  cursor: pointer !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day.bold-date
  abbr {
  color: #494949 !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day abbr {
  color: #a2a2a2 !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day.bold-date:disabled {
  pointer-events: none !important;
  cursor: not-allowed;
}

button.react-calendar__tile.react-calendar__month-view__days__day.bold-date:disabled
  abbr {
  color: #a2a2a2 !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day--weekend
  abbr {
  color: #f53030 !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day--weekend.bold-date
  abbr {
  color: #d10000 !important;
}

.tags-selection label + .MuiInput-formControl {
  margin-top: 5px !important;
}

.tags-selection .MuiChip-root {
  padding: 5px 7px;
  font-size: 16px;
  min-width: 60px;
  background: #f0f4f8;
}

.tags-selection * {
  color: black !important;
  text-align: left !important;
}

.tags-selection label {
  text-align: left;
  margin-bottom: 5px;
}

.tags-selection {
  display: grid;
  margin-top: 15px;
}

.tags-selection .css-1rhbuit-multiValue {
  border-radius: 5px !important;
  background-color: #b1b1b1 !important;
}

.copymeal-popup .MuiTypography-h5 {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: black !important;
}

.copymeal-popup .menu-construction .meal-dish {
  cursor: pointer;
}

.copymeal-popup .meal-management .meals {
  max-height: 450px;
  overflow: auto;
}

.copymeal-popup .meal-management .meals::-webkit-scrollbar {
  width: 5px;
}

.copymeal-popup .meal-management .meals::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.copymeal-popup .meal-management .meals::-webkit-scrollbar-thumb {
  background: #888;
}

.copymeal-popup .meal-management .meals .meal {
  margin-top: 0px;
  margin-bottom: 20px;
  cursor: pointer;
}

.copymeal-popup .meal-added {
  background: #bcddff !important;
  pointer-events: none !important;
}

.copymeal-popup .meal-management .meals .meal .section1 {
  flex: 8 1;
}

.copymeal-popup .meal-management .meals .meal .section2 {
  flex: 2 1;
}

.lang-item .input-field {
  padding: 0px 20px !important;
}

.print-popup .align-table {
  direction: rtl;
}

.print-popup .align-table .print-table * {
  direction: rtl;
  text-align: right !important;
}

.align-table .print-table tr td {
  text-align: right !important;
}

.meal-type-management input {
  background-color: #ffffff !important;
  border: 1px solid #a6bcd0 !important;
  border-radius: 4px !important;
}
.meal-type-management .item.blocked-listing p {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: left;
  color: #6d7287;
}
.meal-type-management .language-input {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 0px 15px 0px 0px;
}
.meal-type-management .language-input {
  &.order-index {
    width: 20% !important;
  }
  &.cancellation-time {
    width: 25% !important;
  }
}
.meal-type-management .language-input.order-index {
  width: 15% !important;
}

.rtl .meal-type-management .language-input {
  text-align: right !important;
}

.meal-type-management .language-input.order-index input {
  width: 100%;
}

.meal-type-management .language-input input {
  width: 100%;
}

.meal-type-management form.add-mealtypes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.meal-type-management section.add-btn .we-icon {
  height: 40px;
  width: 40px;
}

.meal-type-management section.add-btn .we-icon img {
  height: 25px;
  width: 25px;
}

.kitchen-management .actions .we-icon {
  height: 40px;
  width: 40px;
}

.kitchen-management .actions .we-icon img {
  height: 25px;
  width: 25px;
}

.meal-type-management .item.blocked-listing input {
  pointer-events: none;
  cursor: not-allowed;
  background: transparent !important;
  border: none !important;
}

.meal-type-management section.add-btn {
  display: flex;
  align-items: center;
}

.add-mealtype {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.meal-type-management .add-btn button {
  padding: 6px 15px;
  margin: 3px;
  color: white;
  border: 1px solid #14cd31;
  background: #14cd31;
  border-radius: 24px;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
}

.kitchen-management .actions p {
  font-weight: 700;
  font-size: 16px !important;
  text-decoration: none !important;
}

.rtl .meal-type-management .item.blocked-listing input {
  padding-right: 0px !important;
}

.rtl .add-slot-popup .item-list table tr td,
.rtl .add-slot-popup table tr th {
  text-align: right !important;
}

/*********slot popup ***********/
.add-slot-popup .item-list table {
  width: 100%;
}

.add-slot-popup .input-field input {
  width: 100% !important;
  border: 1px solid #e3e3e3;
  min-width: 0;
  font-size: 1em;
  color: #333333;
  text-overflow: ellipsis;
  border-radius: 4px;
  padding: 10px 15px;
  box-shadow: none;
  min-height: 40px;
}

.add-slot-popup .item-list table tr td,
.item-list table tr th {
  width: 20%;
  text-align: left;
}

.add-slot-popup .item-list table tr td {
  padding: 5px 0px;
}

.add-slot-popup .item-list button {
  cursor: pointer;
}

.add-slot-popup .item-list .edit-btn {
  background: #7c7c7c;
  border: 1px solid #7c7c7c;
  color: white;
  padding: 5px;
  min-width: 60px;
  border-radius: 5px;
  margin: 0px 5px;
  font-size: 12px;
  font-weight: 600;
}

.add-slot-popup table tr td,
.add-slot-popup table tr th {
  border-bottom: none;
  white-space: nowrap;
  padding: 5px 10px !important;
  font-weight: 500;
}

.meal-type-management .item {
  background-color: #f0f4f8;
  padding: 10px 20px;
}

.meal-type-management section.add-btn p {
  font-weight: 700;
  font-size: 16px !important;
  text-decoration: none !important;
}

.no-mealtype {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.add-slot-popup .MuiPaper-root {
  box-shadow: none !important;
}

.add-slot-popup .item-list .MuiTableContainer-root::-webkit-scrollbar {
  height: 5px;
}

.add-slot-popup .item-list .MuiTableContainer-root::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.add-slot-popup .item-list .MuiTableContainer-root::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
}

.add-slot-popup table tr th {
  font-weight: 600;
}

.add-slot-popup .item-list .delete-btn {
  background: #d00707;
  border: 1px solid #d00707;
  color: white;
  padding: 5px;
  min-width: 60px;
  border-radius: 5px;
  margin: 0px 5px;
  font-size: 12px;
  font-weight: 600;
}

.add-slot-popup p:first-child {
  font-size: 15px;
  color: black;
  font-weight: 700;
  margin-top: 8px;
}

.payment-check-option {
  align-items: center;
  margin: 10px 0px;
}

.payment-check-option input {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  border-radius: 50% !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  border: 1px solid #e0e0e0;
  position: relative !important;
  padding: 0px !important;
  cursor: pointer;
}

.payment-check-option input[type='radio']:checked {
  border: 1px solid #14cd31;
}

.payment-check-option input[type='radio']:checked:after {
  content: '';
  position: absolute;
  top: 3.5px;
  left: 3.1px;
  padding: 7px;
  border: 1px solid #14cd31;
  border-radius: 50%;
  background: #14cd31;
  height: 8px;
  width: 8px;
}

/********** slot popup **********/
.slot-popup .button-wrapper .we-button.disabled {
  background-color: #a6bcd0;
  font-size: 20px;
  color: white;
}

.slot-popup .slot-section * {
  color: #475058;
}

.slot-popup .button-wrapper .we-button.active {
  font-size: 16px;
  color: white;
}

.slot-popup .MuiBox-root {
  max-width: 550px;
  width: 100%;
}

.slot-popup .bottom {
  display: flex;
  justify-content: center;
  margin: 40px 0px 15px;
}

.slot-popup .slot-section h6 {
  color: #14cd31 !important;
  border-bottom: 1px dashed;
  padding-bottom: 10px;
  margin: 20px 0px !important;
}

.slot-popup .slot-timing-listing {
  text-align: left !important;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.slot-popup .slot-timing-listing label {
  margin-left: 10px;
  font-size: 16px;
}

.slot-popup input {
  height: 20px;
  width: 20px;
}

.slot-popup .slot-timing p {
  font-size: 18px;
  font-weight: 700;
}

.slot-popup .slot-timing p:nth-child(2) {
  margin-bottom: 30px;
}

.slot-popup .slot-timing p {
  font-size: 18px;
  font-weight: 700;
  text-align: initial;
  padding-left: 11px;
  margin-bottom: 5px;
}

/*********** Reservtion popup *************/
.update-reservations {
  max-height: 450px;
  overflow: auto;
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    max-height: 350px;
  }
}

.update-reservations::-webkit-scrollbar {
  width: 5px;
}

.update-reservations::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.update-reservations::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
}

.update-reservations .item .input-field {
  max-width: 30%;
  margin: 0px;
}

.add-slot-popup .add-btn {
  width: fit-content;
}

.update-reservations .item {
  align-items: center;
  text-align: left;
  margin: 0px 10px;
  justify-content: space-between;
}

.update-reservations .item .input-field:first-child,
.update-reservations .item .input-field:nth-child(2) {
  width: 15%;
}

.update-reservations h6 {
  margin: 0px;
  font-size: 14px;
}

.update-reservations p {
  min-width: 0;
  font-size: 1em;
  color: #333333;
  text-overflow: ellipsis;
  border-radius: 4px;
  padding: 8px 0px;
  box-shadow: none;
}

.update-reservations label {
  font-weight: 700;
}

.update-reservations .addft-btn {
  margin-bottom: 0px;
  margin-top: 15px;
}

.update-reservations .item {
  align-items: center;
  text-align: left;
  margin: 15px 10px;
  justify-content: space-between;
  background: #f7f7f7;
  border-radius: 5px;
  padding: 10px 10px;
}

.rtl .add-dish-to-meal .dish .section1 .type {
  flex: initial;
}

.dishes .dish-price {
  width: 15%;
}

.add-slot-popup .item .input-field {
  width: 30%;
  margin: 6px;
  margin-bottom: 20px;
  display: grid;
}

.add-new-slot .item-list {
  padding: 0px 5px 0px;
  overflow: auto;
  height: 30vh;
  @media screen and (max-width: 767px) {
    height: 24vh;
  }
}

.rc-time-picker-panel-combobox {
  display: flex;
}

.rc-time-picker-panel-select li {
  padding-right: 10px;
}

.rc-time-picker-panel-select::-webkit-scrollbar {
  width: 5px;
}

.rc-time-picker-panel-select::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
}

// .log-viewer-page .ag-header-container,
// .log-viewer-page .ag-center-cols-container {
//   width: 100% !important;
// }
.log-json-data {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ededed;
  padding: 20px 30px;
  max-width: 800px;
  word-break: break-all;
  border-radius: 5px;
  height: auto;
  background-color: white;
  width: 100%;
}

.log-json-data .copy-url {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 10px;
}

.log-json-data .updated-data-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.log-json-data .updated-data-heading h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #23283c;
  padding: 0;
}
.log-json-data .json-formatter-row,
.json-formatter-row a,
.json-formatter-row a:hover {
  font-size: 16px;
}
.log-json-data .close-btn img {
  position: initial;
  right: 0;
}
.log-json-data .close-btn {
  position: relative;
  margin-left: auto;
  text-align: right;
  padding-bottom: 0;
}
.bg-shadow:before {
  content: '';
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  background: #00000061;
  z-index: 99;
}

.add-employee-popup.delete-popup.log-json-data {
  display: none;
}

.add-employee-popup.delete-popup.log-json-data.open-json-popup {
  display: block;
}

.log-json-data .copy-url::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.log-json-data .copy-url::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.log-json-data .copy-url::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.log-json-data .confirmation-btn {
  margin-top: 10px;
  margin-bottom: 30px;
}

.rtl .log-viewer-page .ag-cell-normal-height {
  text-align: right;
}

.log-json-data h6 {
  padding: 20px 0px 0px;
  font-size: 18px;
  margin: 0px;
  text-align: center;
}

.log-viewer-page .action-btn {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.rc-time-picker-clear {
  top: 7px;
  pointer-events: none;
}

p.no-meal-available {
  text-align: center;
  padding: 20px 0px;
  font-size: 20px !important;
}

.add-slot-popup .MuiBox-root {
  top: 50%;
  left: 50%;
  border: 1px solid rgb(237, 237, 237);
  padding: 15px;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  max-width: 600px;
  width: 100%;
  min-height: 400px;
}

p.no-meal-available {
  text-align: center;
  padding: 20px 0px;
  font-size: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-new-slot.add-slot-popup .MuiBox-root {
  top: unset;
  left: unset;
  position: unset;
  transform: unset;
}

.add-new-slot.add-employee-popup.add-slot-popup > div {
  display: flex;
  max-width: 860px;
}
/******** review page ************/
.review-page .review-rating,
.user-review-page .review-rating-disable {
  pointer-events: none;
}

.review-details-section {
  display: flex;
  justify-content: space-between;
  flex-direction: revert;
  padding: 15px;
  background-color: #f1f1f187;
}

.new-comment .add-review h6 {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.new-comment .add-review textarea {
  max-height: 70px;
  font-size: 13px;
}

.right-review-section .user-name h6 {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}

.new-comment p {
  cursor: pointer;
}

.review-comment-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.review-comment-btn .review-rating {
  margin-top: 0px;
}

.review-details-name h4 {
  margin-top: 0px;
}

.left-review-section {
  width: 25%;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #e0e0e0;
  border: 1px solid #ededed;
}

.select-add-review .right-review-section {
  width: calc(100% - 25%);
  margin-left: 40px;
}

.select-add-review {
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.right-review-section h6 {
  margin: 0px;
  text-align: left;
}

.right-review-section {
  text-align: left;
}

.right-review-section .user-name {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 9px;
}

.right-review-section .user-name h6 {
  font-size: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-comment p {
  font-size: 14px;
  color: #434343;
}

.user-comment .time {
  font-size: 12px;
  margin-top: 0px;
  color: #a1a1a1;
}

.user-comment {
  padding: 10px 0px;
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 15px;
}

h6.review-heading {
  border-bottom: 1px solid #d7d7d7;
  font-size: 18px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.review-count p {
  font-size: 12px;
  font-weight: 700;
}

.review-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.review-count p span {
  font-weight: 400;
  color: #909090;
  margin-left: 8px;
}

.rating-total {
  margin-bottom: 15px;
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 20px;
}

.review-details-name h4 {
  margin-top: 0px;
}

.add-review textarea {
  width: 100%;
  min-height: 30px !important;
  height: 100%;
  max-height: 100px;
  border: 1px solid gainsboro;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
  font-family: inherit;
  font-weight: 500;
}

.add-review h6 {
  font-size: 15px;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
}

.add-review {
  margin-bottom: 20px;
}

.user-comment.other-comment {
  width: 100%;
  margin-left: auto;
  // padding-left: 50px;
}

.new-comment img {
  height: 35px;
  width: 35px;
  margin-left: 20px;
}

.new-comment.review-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.review-rating > div {
  padding: 0px !important;
  justify-content: flex-start;
}

.right-review-section .user-name span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
}

.add-review .submit-review-btn {
  display: flex;
  justify-content: flex-end;
}

.hebrew-text {
  direction: rtl !important;
  text-align: right !important;
}

.hebrew-text ul li,
.hebrew-text h6,
.hebrew-text p {
  text-align: right !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.bold-date
  abbr {
  color: #f53030 !important;
}

.review-page .button-wrapper button {
  font-weight: 500 !important;
  font-size: 12px !important;
  padding: 8px 15px !important;
  line-height: initial;
  border-radius: 8px;
}
.kitchen-device .review-btn {
  display: flex;
  gap: 15px;
}

/********** header language selection ************/
.languages-selection .lang-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
}

.languages-selection {
  max-width: 115px;
  width: 100%;
  position: absolute;
  right: 0px;
}

.rtl .header .languages-selection {
  left: 0px !important;
  right: revert;
}

.lang-item img:first-child {
  height: 20px;
}

.lang-label img {
  margin-top: 4px;
  margin-left: 10px;
}

.languages .lang-shortcode span {
  font-size: 16px;
}

.lang-item img:first-child {
  height: 25px;
}

.lang-label img {
  margin-top: 8px;
  margin-left: 10px;
}

.lang-label {
  display: flex;
  align-items: center;
}

.login .form .input-group .label-input .input-field,
.reset-password .form .input-group .label-input .input-field {
  font-size: 17px;
}

.lang-dropdown {
  position: absolute;
  background: #ffffff;
  right: 0;
  border-radius: 5px;
  margin-top: 12px;
  box-shadow: 0px 0px 10px 0px #d8d8d8;
  bottom: 50px;
  width: 90%;
  left: 0;
  z-index: 1001;
}

.languages {
  position: relative;
}

.lang-dropdown .lang-item {
  justify-content: flex-start;
  margin: 15px 0px;
}

.lang-dropdown .lang-item span {
  font-size: 18px;
}

.lang-dropdown .lang-item img {
  height: 20px;
}

.lang-shortcode {
  display: flex;
  align-items: center;
}

.lang-shortcode img {
  height: 21px !important;
}

/*********Wallet CSS ************* */
.user-wallet {
  max-width: 200px;
  width: 100%;
  // position: absolute;
  // right: 315px;
}

.user-wallet h6 {
  font-weight: 600;
  color: #14cd31;
  margin: 0;
}

.user-wallet h6 span {
  color: #475058;
}

.rtl .header .user-wallet {
  // right: unset;
  // left: 310px;
  z-index: -1;
  // max-width: fit-content;
}

/******** reveiw ui css**********/
.Review-page .action-btn.action-btn button {
  max-width: fit-content;
  width: 50%;
  margin: 3px;
}

.Review-page .action-btn {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.user-review-page .right-review-section {
  margin-top: 40px;
}

/*******custom dropdown **********/
.custom-dropdown {
  position: relative;
}

.custom-dropdown .dropdwon-list {
  position: absolute;
  right: 0px;
  background: white;
  border-radius: 5px;
  padding: 15px 15px;
  box-shadow: 0px 0px 5px 0px #c8c8c8;
  min-width: 240px;
  z-index: 999;
}

.custom-dropdown .dropdown-item {
  display: flex;
  padding: 10px 0px;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
}

.custom-dropdown .dropdown-item p {
  margin-right: 10px;
}

/******** ag grid css *********/
.ag-theme-alpine .ag-pinned-left-cols-container .ag-row {
  background: #c5ccd354 !important;
}

.ag-theme-alpine .ag-pinned-left-header,
.ag-pinned-left-cols-container,
.ag-theme-alpine
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
    .ag-cell-range-single-cell
  ) {
  border: none !important;
}

.ag-theme-alpine .ag-pinned-left-cols-container .ag-row {
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
}

.ag-row-even,
.ag-row-odd {
  border-bottom-left-radius: 0px !important;
}

.ag-theme-alpine .ag-ltr .ag-cell,
.ag-theme-alpine .ag-row {
  border-top-left-radius: 0px !important;
}

.ag-horizontal-left-spacer,
.ag-horizontal-right-spacer {
  overflow-x: auto !important;
}

.ag-body-horizontal-scroll-viewport {
  max-height: 10px !important;
  min-height: 10px !important;
  height: 10px !important;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.ag-theme-alpine
  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
  .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
  border-right: none !important;
}

.ag-center-cols-viewport::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 0px;
}

.ag-center-cols-viewport::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0px;
}

.ag-center-cols-viewport::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0px;
}

// .ag-center-cols-viewport {
//   width: 100%;
//   overflow-x: inherit !important;
// }
.review-count {
  pointer-events: none;
}

.rating-total.review-one {
  border-bottom: none;
}

.rtl .select-add-review .right-review-section {
  margin-left: 0px;
  margin-right: 40px;
}

.rtl .user-comment p {
  text-align: right;
}

.employee-payment-management .custom-dropdown {
  display: flex;
  justify-content: flex-end;
}

.employee-payment-management .custom-dropdown .dropdown-menu {
  font-size: 16px;
  display: flex;
  align-items: center;
  background: #14cd31;
  padding: 10px 20px;
  border-radius: 23px;
  color: white;
  cursor: pointer;
  font-weight: 600;
}
.download-btn .btn-primary {
  background: #14cd31;
  border: 1px solid #14cd31;
}
.download-btn .btn-primary:active,
.download-btn .btn-primary:focus,
.download-btn .btn-primary:hover {
  background: #14cd31 !important;
  border: 1px solid #14cd31 !important;
}
.employee-payment-management .custom-dropdown .dropdown-menu i {
  margin-left: 13px;
  font-size: 25px;
  color: #ffffff;
}

.employee-payment-management .custom-dropdown .dropdwon-list {
  box-shadow: 0px 0px 2px 0px #c8c8c8;
  top: 48px;
}

.control-panel-payment .custom-dropdown {
  display: flex;
  justify-content: flex-end;
}

.control-panel-payment .custom-dropdown .dropdown-menu {
  font-size: 16px;
  display: flex;
  align-items: center;
  background: #14cd31;
  padding: 10px 20px;
  border-radius: 23px;
  color: white;
  cursor: pointer;
  font-weight: 600;
}

.control-panel-payment .custom-dropdown .dropdown-menu i {
  margin-left: 13px;
  font-size: 25px;
  color: #ffffff;
}

.control-panel-payment .custom-dropdown .dropdwon-list {
  box-shadow: 0px 0px 2px 0px #c8c8c8;
  top: 48px;
}

.rtl .nav .items ul li {
  text-align: right;
}

.chart-wrapper p {
  margin: 50px 0px;
  font-size: 19.2px;
  text-align: center;
}

.panel-chart .chart-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/********** slot popup **********/
.slot-popup .slot-section * {
  color: #475058;
}

.slot-popup .button-wrapper .we-button.active {
  font-size: 16px;
  color: white;
}

.slot-popup .MuiBox-root {
  max-width: 550px;
  width: 100%;
}

.slot-popup .bottom {
  display: flex;
  justify-content: center;
  margin: 40px 0px 15px;
}

.slot-popup .slot-section h6 {
  color: #14cd31 !important;
  border-bottom: 1px dashed;
  padding-bottom: 10px;
  margin: 20px 0px !important;
}

.slot-popup .slot-timing-listing {
  text-align: left !important;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.slot-popup .slot-timing-listing label {
  margin-left: 10px;
  font-size: 16px;
}

.slot-popup input {
  height: 20px;
  width: 20px;
}

.slot-popup .slot-timing p {
  font-size: 18px;
  font-weight: 700;
}

.slot-popup .slot-timing p:nth-child(2) {
  margin-bottom: 30px;
}

.slot-popup .slot-timing p {
  font-size: 18px;
  font-weight: 700;
  text-align: initial;
  padding-left: 11px;
  margin-bottom: 5px;
}

/********new css ************/
.action-btn .menu-container {
  // position: absolute;
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  position: absolute;
  top: 5px;
  right: 9px;
}

.action-btn .menu-item:hover {
  cursor: pointer;
  background: rgb(240, 240, 240);
}

.action-btn .menu-item.active {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}

.action-btn .menu-item {
  display: block;
  padding: 10px 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
}

.action-btn .menu-item button {
  max-width: 200px !important;
  width: 100% !important;
}

.action-btn .edit-btn {
  padding: 6px 22px;
  margin: 3px;
  color: #fff;
  border: 1px solid #14cd31;
  background: #14cd31;
  border-radius: 24px;
}

.action-btn .delete-btn {
  padding: 6px 18px;
  margin: 3px;
  color: #fff;
  border: 1px solid #cd1414;
  background: #cd1414;
  border-radius: 24px;
}

.user-management i.fa.fa-ellipsis-v {
  padding: 10px 20px;
}

.ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none !important;
}

.control-panel .panel-chart {
  color: hsl(0, 0%, 10%);
  max-width: 100%;
  width: 100%;
  margin: 1rem auto;
  padding: 30px 30px !important;
  // box-shadow: 0 2px 10px -6px red;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.control-panel .new-one {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.control-panel .new-one .button-wrapper .we-button.active {
  background-color: #14cd31;
  transition: all 0.3s ease;
  font-size: 13px !important;
  padding: 8px 25px;
}

.control-panel h4.title {
  margin: 0px 0px 30px !important;
  text-align: left;
  font-size: 17px;
  color: #000000;
  font-weight: 600;
}
.rtl {
  .control-panel h4.title,
  .panel-chart-section .from-date label {
    text-align: right;
  }
}

.credit-card-option input {
  height: 20px;
  width: 20px;
  background: transparent !important;
  appearance: none !important;
  border: 1px solid #14cd31;
  border-radius: 5px !important;
  position: relative;
}

.credit-card-option input {
  cursor: not-allowed;
  pointer-events: none;
}

.credit-card-option input:checked:before {
  background: #14cd31 !important;
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: -1px;
  border-radius: 5px;
  background-color: #445768;
  transition: all 0.2s linear;
  top: -1px;
}

.credit-card-option input:checked:after {
  content: '';
  display: block;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 2.5px;
  left: 6px;
}

.form .error-message {
  font-size: 15px;
}

.password-info h6 {
  margin: 0px;
  font-size: 14px;
  color: red;
  font-weight: 400;
  text-align: left;
}

.action-btn.realized-button.payment-indication p {
  max-width: fit-content;
  width: 100%;
  margin: 3px;
  font-size: 16px;
  color: #ba1c06 !important;
  text-align: center;
}

.reservation-popup .copy-url {
  overflow-x: auto;
}

.reservation-popup .copy-url {
  overflow-x: auto;
  padding-bottom: 10px;
}

.reservation-popup .copy-url::-webkit-scrollbar {
  height: 5px;
}

.reservation-popup .copy-url::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.reservation-popup .copy-url::-webkit-scrollbar-thumb {
  background: #888;
}

.form .error-message {
  font-size: 15px;
}

.password-info h6 {
  margin: 0px;
  font-size: 14px;
  color: red;
  font-weight: 400;
  text-align: left;
}

.password-info .input-group.currentPassword {
  padding-bottom: 20px;
  margin-bottom: 0.8rem;
}

.order-voucher.mob-voucher {
  display: none;
}

.scan-voucher p {
  margin: 15px 0px;
}

.scan-voucher iframe {
  min-height: 175px;
  border: none;
  max-width: 170px;
}

.meal-management .top .button-wrapper {
  margin: 0px 10px;
}

.employee-payment-management .form {
  width: 50%;
  margin: 0px auto;
}

.employee-payment-management .form p.form-explanation {
  text-align: center !important;
  width: 100% !important;
}

.employee-payment-management .form .inputs-wrapper {
  width: 100%;
  align-items: initial;
}

.employee-payment-management .form .checkboxes-wrapper {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.employee-payment-management .form .checkboxes-wrapper > div {
  justify-content: flex-start !important;
}

.profile .input-group.card-id,
.profile .input-group.employee-id {
  cursor: not-allowed;
}

.meal-payment-popup .payment-details {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.reservation-title {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.reservation-title .back-link-section {
  position: absolute;
  left: 0px;
  top: 0;
}

.reservation-dish-rating {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.reservation-dish-rating .makeStyles-root-1 {
  align-items: baseline;
  justify-content: flex-start;
}

.dishes-container .dish-cards .dish-single-card .dish-details {
  flex-direction: column;
}

.reservation-dish-rating {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.reservation-dish-rating .makeStyles-root-1,
.reservation-dish-rating .makeStyles-root-6 {
  align-items: baseline;
  justify-content: flex-start;
}

.dishes-container .dish-cards .dish-single-card .dish-details {
  flex-direction: column;
}

.dishes-container .dish-cards {
  justify-content: center;
  margin-top: 20px;
}

.back-button-remove .arrowIcon {
  display: none;
}

.back-button-remove img {
  height: 22px;
  margin-right: 5px;
}

.back-button-remove {
  display: flex;
  align-items: center;
}

.back-link-section img {
  height: 16px;
  margin-right: 0;
}

// .sidebar-languages-selection {
//   display: none;
// }
// .sidebar-kitchen-selection.sub-header-wrapper {
//   display: none;
// }
.order-voucher.mob-voucher {
  display: none;
}

.scan-voucher p {
  margin: 15px 0px;
}

.scan-voucher iframe {
  min-height: 175px;
  border: none;
  max-width: 170px;
}

.meal-management .top .button-wrapper {
  margin: 0px 10px;
}

.employee-payment-management .form {
  width: 50%;
  margin: 0px auto;
}

.employee-payment-management .form p.form-explanation {
  text-align: center !important;
  width: 100% !important;
}

.employee-payment-management .form .inputs-wrapper {
  width: 100%;
}

.employee-payment-management .form .checkboxes-wrapper {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.employee-payment-management .form .checkboxes-wrapper > div {
  justify-content: flex-start !important;
}

.profile .input-group.card-id,
.profile .input-group.employee-id {
  cursor: not-allowed;
}

.meal-payment-popup .payment-details {
  margin: 18px 0px;
}

.profile .input-group.card-id .label-input,
.profile .input-group.employee-id .label-input {
  background: #d8d8d8;
  cursor: not-allowed;
}

.scan-voucher p {
  margin: 15px 0px;
}

.scan-voucher iframe {
  min-height: 175px;
  border: none;
  max-width: 170px;
}

.meal-payment-popup .payment-details {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.meal-payment-popup .extras-meal-management {
  border: 1px solid #14cd31;
  border-radius: 5px;
  background: #ffffff;
  padding: 20px 0px 20px 0px !important;
  box-shadow: inset 0px 0px 5px 0px #14cd31;
  margin-top: 40px !important;
}

.meal-payment-popup .payment-details p,
.meal-payment-popup .payment-details h6 {
  margin: 0px !important;
}

.meal-payment-popup .payment-details h6 {
  line-height: initial !important;
  font-size: 18px;
  margin-left: 15px !important;
  width: 45%;
}

.meal-payment-popup h2 {
  text-align: center;
  color: #14cd31;
}

.meal-payment-popup .payment-details p {
  width: 45%;
  text-align: left !important;
}

.meal-payment-popup .payment-details {
  margin: 18px 0px;
}

.change-password-form {
  padding: 25px 0;
}

.change-password-heading p {
  text-align: center;
  margin-bottom: 25px;
  width: 100%;
  font-weight: 600;
  font-size: 20px;
}

.change-password-form .input-group.password {
  margin-bottom: 15px;
}

.change-password-form .form .upper-placeholder {
  font-size: 14px;
}

.change-password-popup input {
  cursor: inherit !important;
}

.change-password-popup .button-wrapper .we-button {
  padding: 12px 30px;
}

h6.change-password {
  width: 100%;
  text-align: left;
  font-size: 18px;
  cursor: pointer;
  text-decoration: underline;
  color: #4f4f4f;
  font-weight: 500;
  margin: 10px 0;
}

.back-button-remove .arrowIcon {
  display: none;
}

.back-button-remove img {
  height: 22px;
  margin-right: 5px;
}

.back-button-remove {
  display: flex;
  align-items: center;
}

.back-link-section {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  @media screen and (max-width: 480px) {
    gap: 0px;
  }
  p {
    margin-bottom: 0;
    font-size: 16px;
  }
}

.back-link-section i {
  font-size: 24px;
}

// .back-link-section img {
//   height: 22px;
// }

// .sidebar-languages-selection {
//   display: none;
// }
// .sidebar-kitchen-selection.sub-header-wrapper {
//   display: none;
// }

.reservation-management .filter-section select:focus {
  outline: none;
  box-shadow: none !important;
}

button.search-button {
  background: #14cd30;
  border: 1px solid #dff8e3;
  border-radius: 5px;
  height: 40px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  cursor: pointer;
}
button.resize {
  width: 60px;
  margin: 2px 25px 0 5px !important;
}
button.reset-button {
  background: #e60b0b;
  border: 1px solid #dff8e3;
  border-radius: 5px;
  height: 40px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  margin-top: 26px;
  cursor: pointer;
}
.reset-button-user-management {
  background: #e60b0b;
  border: 1px solid #dff8e3;
  border-radius: 5px;
  height: 40px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
}
button.reset-button img {
  width: 17px;
}
button.reset-button-user-management img {
  width: 17px;
}
button.search-button img {
  height: 28px;
  // margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.search-button img {
  height: 22px;
  -webkit-filter: invert(1);
  filter: invert(1);
  position: unset !important;
}
.quantity-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.resize {
  width: 60px;
  margin: 2px 25px 0;
}
.quantity-section .total-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 110px;
  border: 2px solid #14cd31;
  width: 100%;
  border-radius: 50px;
  padding: 5px 12px;
}

.quantity-section .total-quantity p {
  color: #14cd31;
}
.quantity-section .total-quantity img {
  width: 16px;
  cursor: pointer;
}
.quantity-section .total-quantity .remove-quantity img {
  margin-top: -5px;
  position: relative;
}
.quantity-section .remove-quantity {
  line-height: 0;
}
.remove-quantity.disable-value img {
  pointer-events: none;
  cursor: not-allowed !important;
}
.cafeteria-dishes-section .dish-single-card.shadow-card.pointer {
  cursor: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*!
 * Bootstrap Grid v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.flex {
  display: flex;
}
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: flex-start !important;
  -ms-flex-pack: flex-start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: flex-start !important;
  -ms-flex-align: flex-start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: flex-end !important;
  -ms-flex-align: flex-end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: flex-start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: flex-end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: flex-start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.animated {
  -webkit-animation-duration: 1ms;
  animation-duration: 1ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.delay-1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay-2 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay-3 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay-4 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.delay-5 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay-6 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.delay-7 {
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}

.delay-8 {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

.slow {
  -webkit-animation-duration: 0.8s !important;
  animation-duration: 0.8s !important;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSansHebrew-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSansHebrew-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSansHebrew-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSansHebrew-Bold.ttf') format('truetype');
  font-weight: 700;
}

html,
body,
p {
  font-family: 'OpenSans', sans-serif;
  margin: 0;
  padding: 0;
}

h2.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.28;
  letter-spacing: 1.8px;
  text-align: center;
  color: #14cd31;
  margin: 1rem 0;
}

.preloader {
  position: fixed;
  z-index: 1500;
  width: 100vw;
  height: 100vh;
  left: 0px;
  right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: white;

  &.fetching {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.preloader .block_logo {
  margin-top: -5%;
}

.preloader .block_logo .logo {
  width: 62px;
  height: 62px;
  border-radius: 100%;
  margin: auto;
  text-align: center;
  background-color: #14cd31;
}

.preloader .block_logo .logo span {
  font-size: 46px;
  font-weight: bold;
  line-height: 1.35;
  letter-spacing: 2.76px;
  color: #ffffff;
  position: relative;
  left: -7px;
}

.preloader .block_logo .logo__text {
  font-size: 46px;
  font-weight: 300;
  line-height: 1.35;
  letter-spacing: 2.76px;
  color: #14cd31;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 0;
}

.preloader .block_logo .logo__text span {
  font-weight: 600;
}

.preloader .block_logo .logo__description {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #727272;
  line-height: 1.35;
  letter-spacing: 1.2px;
}

#calendar {
  font-family: 'OpenSans', sans-serif;
}

#calendar::-webkit-calendar-picker-indicator {
  color: transparent;
  opacity: 1;
  background: url(./assets/images/calendar.svg) no-repeat center;
  background-size: contain;
}

header {
  background-color: #f0f4f8;
  height: 100%;
  max-height: 90px;
  position: relative;
  z-index: 30;
}

header .burger_menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }
}

header .burger_menu span {
  width: 17px;
  margin-bottom: 5px;
  border: solid 1.2px #000000;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #000;
}

header .burger_menu span:nth-child(2) {
  width: 12px;
}

header .burger_menu span:nth-child(3) {
  width: 7px;
  margin-bottom: 0;
}

header .burger_menu:hover span {
  width: 17px;
}

header .logo__text {
  font-size: 46px;
  font-weight: 300;
  line-height: 1.35;
  letter-spacing: 2.76px;
  color: #14cd31;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

header .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    cursor: pointer;
    margin: 20px;
    height: 60px;
  }
}

header .logo__text span {
  font-weight: 600;
}

header .nav {
  width: 460px;
  min-height: 100vh;
  position: absolute;
  z-index: 100;
  top: 0;
  left: -460px;
  background-color: #475058;
  border-bottom-right-radius: 30px;
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

header .nav.active {
  left: 0;
}

header .nav.orange_menu ul.sub_menu__opened .lang_item.active {
  border-bottom: solid 1px #ff8317;
}

header .nav.orange_menu ul li {
  border-bottom: solid 1px #ff8317;
}

header .nav.orange_menu ul li::after {
  top: 33px;
  background: url(./assets/images/left-arrow__orange.svg) center center
    no-repeat;
  background-size: contain;
}

header .nav.orange_menu ul li.lang_item::after {
  top: 34px;
  background: url(./assets/images/arrow-down__orange.svg) center center
    no-repeat;
  background-size: contain;
}

header .nav.orange_menu ul li a {
  line-height: 3.52;
}

header .nav.blue_menu ul.sub_menu__opened .lang_item.active {
  border-bottom: solid 1px #54a7fb;
}

header .nav.blue_menu ul li {
  border-bottom: solid 1px #54a7fb;
}

header .nav.blue_menu ul li::after {
  top: 33px;
  background: url(./assets/images/left-arrow__blue.svg) center center no-repeat;
  background-size: contain;
}

header .nav.blue_menu ul li.lang_item::after {
  top: 34px;
  background: url(./assets/images/arrow-down__blue.svg) center center no-repeat;
  background-size: contain;
}

header .nav.blue_menu ul li a {
  line-height: 3.52;
}

header .nav .nav_title {
  text-align: center;
  color: #a6bcd0;
  font-size: 23px;
  font-weight: normal;
  line-height: 4.22;
  letter-spacing: 1.38px;
  margin-bottom: -2rem;
  position: relative;
}

header .nav .nav_title span {
  position: absolute;
  left: 50px;
  top: 2px;
}

header .nav .nav_title span img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

header .nav ul {
  list-style-type: none;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

header .nav ul li {
  text-align: center;
  border-bottom: solid 1px #14cd31;
  position: relative;
}

header .nav ul li::after {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 43px;
  background: url(./assets/images/left-arrow__green.svg) center center no-repeat;
  background-size: contain;
}

header .nav ul li a {
  color: #f0f4f8;
  text-decoration: none;
  font-size: 23px;
  line-height: 4.22;
  letter-spacing: 1.38px;
}

header .nav ul li.lang_item {
  display: none;
}

header .nav ul li.lang_item[data-order='2'] {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

header .nav ul li.lang_item[data-order='3'] {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

header .nav ul li.lang_item[data-order='4'] {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

header .nav ul li.lang_item::after {
  width: 12px;
  height: 12px;
  right: 14px;
  top: 44px;
  background: url(./assets/images/arrow-down__green.svg) center center no-repeat;
  background-size: contain;
}

header .nav ul li.lang_item.active {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  display: block;
  border-bottom: none;
}

header .nav ul li.lang_item.active .single_lang a {
  color: #f0f4f8;
}

header .nav ul li.lang_item .single_lang {
  position: relative;
}

header .nav ul li.lang_item .single_lang a {
  color: #a6bcd0;
}

header .nav ul li.lang_item .single_lang span {
  position: absolute;
  left: 3px;
  top: 10px;
}

header .nav ul li.lang_item .single_lang img {
  width: 37px;
  height: 37px;
}

header .nav ul.sub_menu__opened .lang_item {
  margin-right: 4rem;
}

header .nav ul.sub_menu__opened .lang_item.active {
  border-bottom: solid 1px #14cd31;
}

header .nav ul.sub_menu__opened .lang_item::after {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

footer .creator {
  line-height: 1.36;
  letter-spacing: 0.42px;
  color: #a6bcd0;
  font-size: 11px;
  margin: 0 0 10px 20px;
}

footer .creator .logo {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #14cd31;
  position: absolute;
  left: 15px;
  top: -2px;
}

footer .creator .logo span {
  font-size: 11px;
  font-weight: 700;
  color: #fff;
  position: relative;
  left: -0.8px;
  top: 2px;
}

.inp_field {
  background-color: #f0f4f8;
  border-radius: 8px;
  padding: 27px 22px 27px 64px;
  width: 100%;
  border: 0;
  outline: none;
  font-size: 23px;
  position: relative;
  color: #475058;
}

.inp_field::-webkit-input-placeholder {
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
}

.inp_field::-moz-placeholder {
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
}

.inp_field:-ms-input-placeholder {
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
}

.inp_field::-ms-input-placeholder {
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
}

.inp_field::placeholder {
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
}

.btn_submit {
  background-color: #7bed8d;
  border-radius: 36px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0;
  color: #ffffff;
  font-size: 23px;
  line-height: 1.39;
  letter-spacing: 0.92px;
  outline: none;
  cursor: pointer;
  -webkit-transition: background-color 0.4s ease-in-out;
  -o-transition: background-color 0.4s ease-in-out;
  transition: background-color 0.4s ease-in-out;
}

.btn_submit img {
  width: 24px;
  margin: 27px -10px 18px 14px;
}

.btn_submit.short {
  height: 40px;
}

.btn_submit:hover {
  background-color: #14cd31;
}

.label_input_field::before {
  content: '';
  position: absolute;
  width: 26px;
  height: 26px;
  background-size: contain;
  left: 35px;
  top: 26px;
  z-index: 10;
}

.label_input_field.f__name::before {
  background-image: url(./assets/images/user.svg);
}

.label_input_field.f__card::before {
  background-image: url(./assets/images/id-card.svg);
}

.label_input_field.f__phone::before {
  background-image: url(./assets/images/phone-call.svg);
}

.label_input_field.f__email::before {
  background-image: url(./assets/images/mail.svg);
}

.label_input_field.f__password::before {
  background-image: url(./assets/images/padlock.svg);
}

.main_checkbox {
  margin-top: 20px;
  text-align: center;
}

.main_checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin-right: 11px;
}

.main_checkbox input:checked + label::before {
  left: 0;
  top: 3px;
  background: url(./assets/images/check.svg) 9px 55% no-repeat;
  background-size: 10px;
  background-color: #7bed8d;
  width: 28px;
  height: 28px;
}

.main_checkbox label {
  padding-left: 33px;
  font-weight: 600;
  position: relative;
  font-size: 23px;
  cursor: pointer;
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
}

.main_checkbox label::before {
  content: '';
  position: absolute;
  left: 9px;
  top: 0;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #f0f4f8;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.df-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main_inputs .form_group {
  position: relative;
}

.main_inputs .form_group + .form_group {
  margin-top: 30px;
}

.main_inputs .form_label {
  position: absolute;
  left: 18px;
  top: 24px;
  color: #a6bcd0;
  background: #f0f4f8;
  z-index: 10;
  -webkit-transition:
    font-size 150ms ease-out,
    -webkit-transform 150ms ease-out;
  transition:
    font-size 150ms ease-out,
    -webkit-transform 150ms ease-out;
  -o-transition:
    transform 150ms ease-out,
    font-size 150ms ease-out;
  transition:
    transform 150ms ease-out,
    font-size 150ms ease-out;
  transition:
    transform 150ms ease-out,
    font-size 150ms ease-out,
    -webkit-transform 150ms ease-out;
  font-size: 23px;
  line-height: 1.37;
  letter-spacing: 1.14px;
}

.main_inputs .form_label.rtl {
  left: initial;
  right: 18px;
}

.main_inputs .focused .form_label {
  -webkit-transform: translateY(-60%);
  -ms-transform: translateY(-60%);
  transform: translateY(-60%);
  font-size: 19px;
}

.main_inputs .form_input {
  position: relative;
  padding: 36px 18px 14px;
  width: 100%;
  outline: 0;
  border: 0;
  border-radius: 8px;
  background: #f0f4f8;
  -webkit-transition: -webkit-box-shadow 150ms ease-out;
  transition: -webkit-box-shadow 150ms ease-out;
  -o-transition: box-shadow 150ms ease-out;
  transition: box-shadow 150ms ease-out;
  transition:
    box-shadow 150ms ease-out,
    -webkit-box-shadow 150ms ease-out;
  font-size: 23px;
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
  color: #475058;
}

.main_inputs .form_input:focus {
  -webkit-box-shadow: 0 2px 0 0 #14cd31;
  box-shadow: 0 2px 0 0 #14cd31;
}

.main_inputs .form_input.filled {
  -webkit-box-shadow: 0 2px 0 0 #7bed8d;
  box-shadow: 0 2px 0 0 #7bed8d;
}

.main_inputs select {
  -webkit-appearance: none;
}

.main_inputs textarea {
  resize: none;
  height: 300px;
}

.main_inputs .field_upload_photo label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f0f4f8;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 300px;
  font-size: 23px;
  color: #475058;
  border-radius: 8px;
}

.main_inputs .field_upload_photo label span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px 4px;
  background-color: rgba(240, 244, 248, 0.6);
  border-radius: 8px;
  margin-bottom: 10px;
}

.main_inputs .field_upload_photo label span img {
  height: 29px;
  margin-left: 16px;
  margin-top: 3px;
}

.main_inputs .field_upload_photo #upload_photo {
  display: none;
}

.rtl {
  direction: rtl;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(68, 75, 82, 0.9);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.popup .block {
  width: 100%;
  max-width: 600px;
  padding: 0 2rem;
}

.popup .block.block_done .title {
  text-align: center;
  color: #7bed8d;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  letter-spacing: 1.2px;
  padding: 0 0 5rem;
  margin: 0 0 3rem;
  border-bottom: dashed 1px #ffffff;
}

.popup .block.block_done .title img {
  width: 20px;
  margin: 0 10px -3px 0;
}

.popup .block.block_done .description {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  letter-spacing: 1.2px;
  color: #ffffff;
}

.popup .block.block_done .description .order {
  font-weight: 400;
  margin-top: 2rem;
}

.popup .block.block_error {
  text-align: center;
  color: #ff3e3e;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  letter-spacing: 1.2px;
  max-width: 450px;
}

.widget {
  position: fixed;
  z-index: 15;
  top: -1000px;
  left: 0;
  background-color: #fff;
  width: 100vw;
  height: calc(100vh - 180px);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget.active {
  top: 180px;
}

.widget .container-fluid,
.widget .container-sm,
.widget .container-md,
.widget .container-lg,
.widget .container-xl {
  max-width: 1500px;
}

.widget .container-fluid .r-line,
.widget .container-sm .r-line,
.widget .container-md .r-line,
.widget .container-lg .r-line,
.widget .container-xl .r-line {
  padding: 35px;
}

.widget .container-fluid .r-line::before,
.widget .container-sm .r-line::before,
.widget .container-md .r-line::before,
.widget .container-lg .r-line::before,
.widget .container-xl .r-line::before {
  content: '';
}

.widget .container-fluid #date_field,
.widget .container-sm #date_field,
.widget .container-md #date_field,
.widget .container-lg #date_field,
.widget .container-xl #date_field {
  display: none;
}

.widget .container-fluid .ui-datepicker,
.widget .container-sm .ui-datepicker,
.widget .container-md .ui-datepicker,
.widget .container-lg .ui-datepicker,
.widget .container-xl .ui-datepicker {
  padding: 35px;
  cursor: default;
  text-transform: uppercase;
}

.widget .container-fluid .ui-datepicker .ui-datepicker-header,
.widget .container-sm .ui-datepicker .ui-datepicker-header,
.widget .container-md .ui-datepicker .ui-datepicker-header,
.widget .container-lg .ui-datepicker .ui-datepicker-header,
.widget .container-xl .ui-datepicker .ui-datepicker-header {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px dashed #a6bcd0;
  max-width: 360px;
  margin: auto;
}

.widget
  .container-fluid
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-title,
.widget .container-sm .ui-datepicker .ui-datepicker-header .ui-datepicker-title,
.widget .container-md .ui-datepicker .ui-datepicker-header .ui-datepicker-title,
.widget .container-lg .ui-datepicker .ui-datepicker-header .ui-datepicker-title,
.widget
  .container-xl
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-title {
  text-align: center;
  position: relative;
  padding-left: 15px;
  font-size: 23px;
  font-weight: 600;
  color: #14cd31;
}

.widget
  .container-fluid
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev,
.widget .container-sm .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.widget .container-md .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.widget .container-lg .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.widget .container-xl .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.widget
  .container-fluid
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next,
.widget .container-sm .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.widget .container-md .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.widget .container-lg .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.widget .container-xl .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.widget
  .container-fluid
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev
  span,
.widget
  .container-sm
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev
  span,
.widget
  .container-md
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev
  span,
.widget
  .container-lg
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev
  span,
.widget
  .container-xl
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev
  span,
.widget
  .container-fluid
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next
  span,
.widget
  .container-sm
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next
  span,
.widget
  .container-md
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next
  span,
.widget
  .container-lg
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next
  span,
.widget
  .container-xl
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next
  span {
  display: block;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  background: url(./assets/images/left-arrow.svg) center center no-repeat;
  background-size: 16px;
  background-color: #7bed8d;
  border-radius: 100%;
}

.widget
  .container-fluid
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev,
.widget .container-sm .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.widget .container-md .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.widget .container-lg .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.widget .container-xl .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  left: 0;
  padding-left: 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.widget
  .container-fluid
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next,
.widget .container-sm .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.widget .container-md .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.widget .container-lg .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.widget .container-xl .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  right: 0;
  padding-right: 0;
}

.widget .container-fluid .ui-datepicker table,
.widget .container-sm .ui-datepicker table,
.widget .container-md .ui-datepicker table,
.widget .container-lg .ui-datepicker table,
.widget .container-xl .ui-datepicker table {
  margin: auto;
}

.widget .container-fluid .ui-datepicker table th,
.widget .container-sm .ui-datepicker table th,
.widget .container-md .ui-datepicker table th,
.widget .container-lg .ui-datepicker table th,
.widget .container-xl .ui-datepicker table th {
  padding-top: 40px;
  padding-bottom: 10px;
}

.widget .container-fluid .ui-datepicker table td,
.widget .container-sm .ui-datepicker table td,
.widget .container-md .ui-datepicker table td,
.widget .container-lg .ui-datepicker table td,
.widget .container-xl .ui-datepicker table td {
  padding: 0 7px;
  text-align: center;
  line-height: 56px;
  color: #475058;
}

.widget .container-fluid .ui-datepicker table td .ui-state-default,
.widget .container-sm .ui-datepicker table td .ui-state-default,
.widget .container-md .ui-datepicker table td .ui-state-default,
.widget .container-lg .ui-datepicker table td .ui-state-default,
.widget .container-xl .ui-datepicker table td .ui-state-default {
  display: block;
  width: 56px;
  outline: none;
  text-decoration: none;
  color: #475058;
}

.widget
  .container-fluid
  .ui-datepicker
  table
  td.ui-state-disabled
  .ui-state-default,
.widget
  .container-sm
  .ui-datepicker
  table
  td.ui-state-disabled
  .ui-state-default,
.widget
  .container-md
  .ui-datepicker
  table
  td.ui-state-disabled
  .ui-state-default,
.widget
  .container-lg
  .ui-datepicker
  table
  td.ui-state-disabled
  .ui-state-default,
.widget
  .container-xl
  .ui-datepicker
  table
  td.ui-state-disabled
  .ui-state-default {
  color: #999;
}

.widget .container-fluid .ui-datepicker table td .ui-state-active,
.widget .container-sm .ui-datepicker table td .ui-state-active,
.widget .container-md .ui-datepicker table td .ui-state-active,
.widget .container-lg .ui-datepicker table td .ui-state-active,
.widget .container-xl .ui-datepicker table td .ui-state-active {
  color: #475058;
  border-color: #14cd31;
  background: rgba(123, 237, 141, 0.67);
  font-weight: 700;
  border-radius: 7px;
}

.widget .container-fluid .title,
.widget .container-sm .title,
.widget .container-md .title,
.widget .container-lg .title,
.widget .container-xl .title {
  font-size: 23px;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px dashed #a6bcd0;
  padding-bottom: 10px;
  max-width: 360px;
  margin: 0 auto 2rem;
}

.widget .container-fluid .block_types,
.widget .container-sm .block_types,
.widget .container-md .block_types,
.widget .container-lg .block_types,
.widget .container-xl .block_types {
  height: 100%;
  max-height: 350px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.widget .container-fluid .block_types label,
.widget .container-sm .block_types label,
.widget .container-md .block_types label,
.widget .container-lg .block_types label,
.widget .container-xl .block_types label {
  font-size: 20px;
  font-weight: 500;
  color: #475058;
  margin: 1.7rem 0;
  cursor: pointer;
  padding: 0.8rem 1.7rem 0.9rem;
  border-radius: 8px;
}

.widget .container-fluid .block_types label.active,
.widget .container-sm .block_types label.active,
.widget .container-md .block_types label.active,
.widget .container-lg .block_types label.active,
.widget .container-xl .block_types label.active {
  background: rgba(123, 237, 141, 0.67);
  border: 2px solid #14cd31;
}

.widget .container-fluid .block_types input,
.widget .container-sm .block_types input,
.widget .container-md .block_types input,
.widget .container-lg .block_types input,
.widget .container-xl .block_types input {
  display: none;
}

.widget .container-fluid .btn_submit,
.widget .container-sm .btn_submit,
.widget .container-md .btn_submit,
.widget .container-lg .btn_submit,
.widget .container-xl .btn_submit {
  height: 40px;
}

main .top .container-fluid,
main .top .container-sm,
main .top .container-md,
main .top .container-lg,
main .top .container-xl {
  max-width: 1900px;
}

main .top .welcome {
  font-size: 23px;
  font-weight: 600;
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
  color: #475058;
}

main .top .welcome .manager {
  font-weight: bold;
  color: #1563b1;
}

main .top .welcome .kitchen_manager {
  font-weight: bold;
  color: #ff8317;
}

main.main_page {
  min-height: calc(100vh - 112px);
}

main.main_page {
  section.sub-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
    background: #fff;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    position: relative;
    z-index: 20;
    height: 70px;
  }
}

main.main_page section.step {
  display: none;
}

main.main_page section.step.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

main.main_page section .forgot_pass {
  text-align: right;
  margin-top: 10px;
}

main.main_page section .forgot_pass a {
  font-size: 20px;
  font-weight: 600;
  color: #a6bcd0;
  line-height: 1.35;
  letter-spacing: 1.2px;
}

main.main_page section .newsletter {
  font-size: 23px;
  font-weight: 600;
  margin-top: 26px;
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
}

main.lobby_page,
main.choosing-dish_page {
  min-height: calc(100vh - 112px);
}

main.lobby_page .content,
main.choosing-dish_page .content {
  padding-top: 3rem;
  position: relative;
  z-index: 5;
}

main.lobby_page .content .col-md-5,
main.choosing-dish_page .content .col-md-5 {
  margin-bottom: 130px;
}

main.lobby_page .content .single_item,
main.choosing-dish_page .content .single_item {
  background-color: #f0f4f8;
  border-radius: 8px;
  height: calc(100% + 90px);
  position: relative;
}

main.lobby_page .content .single_item .top_photo,
main.choosing-dish_page .content .single_item .top_photo {
  height: 115px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
}

main.lobby_page .content .single_item .top,
main.choosing-dish_page .content .single_item .top {
  padding: 0 20px;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #f0f4f8;
}

main.lobby_page .content .single_item .top h4,
main.choosing-dish_page .content .single_item .top h4 {
  margin-bottom: 0;
  margin-top: 14px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  letter-spacing: 1.2px;
  color: #475058;
}

main.lobby_page .content .single_item .top p,
main.choosing-dish_page .content .single_item .top p {
  font-size: 20px;
  line-height: 1.35;
  letter-spacing: 1.2px;
  color: #475058;
}

main.lobby_page .content .single_item .top p.dish_name,
main.choosing-dish_page .content .single_item .top p.dish_name {
  border-top: 1px dashed #a6bcd0;
  margin-top: 10px;
  padding-top: 10px;
}

main.lobby_page .content .single_item .bottom,
main.choosing-dish_page .content .single_item .bottom {
  text-align: center;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

main.lobby_page .content .single_item .bottom .btn_submit,
main.choosing-dish_page .content .single_item .bottom .btn_submit {
  font-size: 20px;
  white-space: nowrap;
  padding: 4px 30px;
  display: block;
}

main.lobby_page .content .single_item .bottom .btn_submit.message,
main.choosing-dish_page .content .single_item .bottom .btn_submit.message {
  background-color: #a6bcd0;
  position: relative;
}

main.lobby_page .content .single_item .bottom .btn_submit.message::after,
main.choosing-dish_page
  .content
  .single_item
  .bottom
  .btn_submit.message::after {
  content: '';
  position: absolute;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  bottom: 35px;
  border: 6px solid transparent;
  border-bottom: 6px solid #a6bcd0;
}

main.lobby_page .content .single_item .bottom .btn_submit img,
main.choosing-dish_page .content .single_item .bottom .btn_submit img {
  width: 16px;
  margin: 0;
  position: relative;
  top: 1px;
  right: -6px;
}

main.lobby_page_2 {
  min-height: calc(100vh - 112px);
}

main.lobby_page_2.lobby_page_3 .content {
  max-width: 1600px;
  margin: auto;
}

main.lobby_page_2.lobby_page_3 .restaurant img {
  max-width: 148px;
}

main.lobby_page_2 .content {
  max-width: 1600px;
  margin: auto;
  padding-top: 2.5rem;
}

main.lobby_page_2 .content .single_item {
  text-align: center;
  background: url(./assets/images/food_bg.png) top right no-repeat;
  background-color: #f0f4f8;
  background-size: cover;
  padding: 20px;
  height: 100%;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

main.lobby_page_2 .content .single_item h4 {
  font-size: 25px;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
  letter-spacing: 1.2px;
  color: #475058;
}

main.lobby_page_2 .content .single_item p {
  line-height: 1.35;
  letter-spacing: 1.2px;
  font-size: 20px;
  color: #475058;
}

main.lobby_page_2 .content .single_item .stars img {
  width: 40px;
  margin: 20px 5px 30px;
}

main.lobby_page_2 .content .single_item .btn_submit {
  font-size: 20px;
  white-space: nowrap;
  padding: 4px 50px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  text-decoration: none;
}

main.lobby_page_2 .content .single_item .btn_submit img {
  width: 16px;
  margin: 0;
  position: relative;
  top: 1px;
  right: -6px;
}

main.lobby_page_2 .content .block_btns {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

main.lobby_page_2 .content .block_btns .btn {
  width: 100%;
  padding: 64px 10px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  line-height: 0.96;
  letter-spacing: 1.5px;
  border-radius: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

main.lobby_page_2 .content .block_btns .btn.first_btn {
  margin-bottom: 1.5rem;
  background-color: #7bed8d;
}

main.lobby_page_2 .content .block_btns .btn.first_btn:hover {
  background-color: #14cd31;
}

main.lobby_page_2 .content .block_btns .btn.second_btn {
  background-color: #a6bcd0;
}

main.lobby_page_2 .content .block_btns .btn.second_btn:hover {
  background-color: #5f768b;
}

main.meals-i-ate {
  min-height: calc(100vh - 112px);
}

main.meals-i-ate .content {
  min-height: calc(100vh - 400px);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

main.meals-i-ate .content h4 {
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
  color: #475058;
}

main.meals-i-ate .content .btn_submit {
  font-size: 20px;
  white-space: nowrap;
  padding: 4px 50px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  text-decoration: none;
}

main.meals-i-ate .content .btn_submit img {
  width: 16px;
  margin: 0;
  position: relative;
  top: 1px;
  right: -6px;
}

main.control-panel {
  min-height: calc(100vh - 112px);
}

main.control-panel .content {
  margin-top: 2.5rem;
}

main.control-panel .content .single_item {
  text-align: center;
  background: url(./assets/images/food_bg.png) top right no-repeat;
  background-color: #f0f4f8;
  background-size: cover;
  padding: 20px;
  height: 100%;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

main.control-panel .content .single_item h4 {
  font-size: 25px;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
  letter-spacing: 1.2px;
  color: #475058;
}

main.control-panel .content .single_item p {
  line-height: 1.35;
  letter-spacing: 1.2px;
  font-size: 20px;
  color: #475058;
}

main.control-panel .content .single_item canvas {
  padding: 5px;
}

main.control-panel .content .single_item .r-border {
  border-left: 1px dashed #a6bcd0;
}

main.control-panel .content .single_item .stars img {
  width: 25px;
  margin: 10px 5px;
}

main.control-panel .content .single_item .btn_submit {
  font-size: 20px;
  white-space: nowrap;
  padding: 4px 50px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  text-decoration: none;
}

main.control-panel .content .single_item .btn_submit img {
  width: 16px;
  margin: 0;
  position: relative;
  top: 1px;
  right: -6px;
}

main.user-management_page,
main.packet-management_page {
  min-height: calc(100vh - 112px);
}

main.user-management_page .content,
main.packet-management_page .content {
  max-width: 1610px;
  margin: 2.5rem auto 0;
}

main.user-management_page .content .btn_submit,
main.packet-management_page .content .btn_submit {
  max-width: 224px;
  font-size: 20px;
  font-weight: 600;
  padding: 4px 0 8px;
  height: 40px;
  line-height: 1.35;
  letter-spacing: 1.2px;
}

main.user-management_page .content table,
main.packet-management_page .content table {
  width: 100%;
  border-collapse: collapse;
}

main.user-management_page .content table tr,
main.packet-management_page .content table tr {
  background-color: #f0f4f8;
  border-bottom: 22px solid #fff;
}

main.user-management_page .content table tr th,
main.packet-management_page .content table tr th {
  font-size: 20px;
  color: #475058;
  line-height: 1.35;
  letter-spacing: 1.2px;
  background-color: #ffffff;
}

main.user-management_page .content table tr td,
main.packet-management_page .content table tr td {
  padding: 40px 10px;
}

main.user-management_page .content table button,
main.packet-management_page .content table button {
  border: none;
  background-color: #f0f4f8;
  outline: none;
  cursor: pointer;
}

main.user-management_page .content table button img,
main.packet-management_page .content table button img {
  width: 40px;
  height: 40px;
}

main.user-management_page .content table .row_form input,
main.user-management_page .content table .row_form select,
main.packet-management_page .content table .row_form input,
main.packet-management_page .content table .row_form select {
  font-size: 20px;
  color: #475058;
  width: 100%;
  height: 44px;
  padding: 0 6px;
  border: 1px solid #f0f4f8;
  background-color: #f0f4f8;
  line-height: 1.35;
  letter-spacing: 1.2px;
}

main.user-management_page .content table .row_form.active input,
main.user-management_page .content table .row_form.active select,
main.packet-management_page .content table .row_form.active input,
main.packet-management_page .content table .row_form.active select {
  background-color: #ffffff;
}

main.user-management_page .content form.search input,
main.packet-management_page .content form.search input {
  background-color: #f0f4f8;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 20px;
  padding: 9px 15px;
}

main.user-management_page .content form.search button,
main.packet-management_page .content form.search button {
  position: relative;
  top: -6px;
  border-radius: 100%;
  background-color: #7bed8d;
  border: none;
  width: 42px;
  height: 42px;
  cursor: pointer;
  -webkit-transition: background-color 0.4s ease-in-out;
  -o-transition: background-color 0.4s ease-in-out;
  transition: background-color 0.4s ease-in-out;
}

main.user-management_page .content form.search button:hover,
main.packet-management_page .content form.search button:hover {
  background-color: #14cd31;
}

main.user-management_page .content form.search button img,
main.packet-management_page .content form.search button img {
  position: absolute;
  right: 11px;
  top: 11px;
  width: 20px;
  height: 20px;
}

main.meal-management_page,
main.packet-management_page,
main.assembling-meal-menu_page {
  min-height: calc(100vh - 112px);
}

main.meal-management_page .content,
main.packet-management_page .content,
main.assembling-meal-menu_page .content {
  max-width: 1610px;
  margin: 2.5rem auto 0;
}

main.meal-management_page .content table,
main.packet-management_page .content table,
main.assembling-meal-menu_page .content table {
  width: 100%;
  border-collapse: collapse;
}

main.meal-management_page .content table tr,
main.packet-management_page .content table tr,
main.assembling-meal-menu_page .content table tr {
  background-color: #f0f4f8;
  border-bottom: 22px solid #fff;
}

main.meal-management_page .content table tr td,
main.packet-management_page .content table tr td,
main.assembling-meal-menu_page .content table tr td {
  padding: 30px 10px;
  text-align: center;
  font-size: 20px;
  color: #475058;
  line-height: 1.35;
  letter-spacing: 1.2px;
}

main.meal-management_page .content table tr td .more_info,
main.packet-management_page .content table tr td .more_info,
main.assembling-meal-menu_page .content table tr td .more_info {
  padding: 6px 12px;
  border-radius: 100%;
  background-color: #7bed8d;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

main.meal-management_page .content table tr td .more_info:hover,
main.packet-management_page .content table tr td .more_info:hover,
main.assembling-meal-menu_page .content table tr td .more_info:hover {
  background-color: #14cd31;
}

main.meal-management_page .content table tr td .more_info img,
main.packet-management_page .content table tr td .more_info img,
main.assembling-meal-menu_page .content table tr td .more_info img {
  width: 15px;
  height: 15px;
}

main.meal-management_page .content table tr td .stars img,
main.packet-management_page .content table tr td .stars img,
main.assembling-meal-menu_page .content table tr td .stars img {
  width: 20px;
  height: 20px;
  margin: 3px;
}

main.meal-management_page .content table tr td .last_block,
main.packet-management_page .content table tr td .last_block,
main.assembling-meal-menu_page .content table tr td .last_block {
  padding: 10px 0;
  border-left: 1px dashed #a6bcd0;
}

main.packet-management_page .content,
main.assembling-meal-menu_page .content {
  max-width: 1336px;
}

main.packet-management_page .content .block_eat_photo,
main.assembling-meal-menu_page .content .block_eat_photo {
  width: 129px;
  height: 129px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}

main.packet-management_page .content .block_eat_photo img,
main.assembling-meal-menu_page .content .block_eat_photo img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

main.packet-management_page .content table tr td,
main.assembling-meal-menu_page .content table tr td {
  padding: 0;
}

main.packet-management_page .content table tr td:nth-child(1),
main.assembling-meal-menu_page .content table tr td:nth-child(1) {
  width: 170px;
}

main.packet-management_page .content table tr td:nth-child(2),
main.assembling-meal-menu_page .content table tr td:nth-child(2) {
  text-align: left;
  max-width: 140px;
}

main.packet-management_page .content table tr td .stars img,
main.assembling-meal-menu_page .content table tr td .stars img {
  width: 13px;
  height: 14px;
  margin: 13px 3px 3px;
}

main.adding-dish-to-meal_page .content .btn_submit {
  max-width: 188px;
  margin: auto;
  text-decoration: none;
}

main.adding-dose_page,
main.assembling-meal-menu_page {
  min-height: calc(100vh - 112px);
}

main.adding-dose_page .content,
main.assembling-meal-menu_page .content {
  max-width: 1366px;
  margin: 2.5rem auto 0;
}

main.adding-dose_page .content form,
main.assembling-meal-menu_page .content form {
  width: 100%;
}

main.adding-dose_page .content .btn_submit,
main.assembling-meal-menu_page .content .btn_submit {
  height: 80px;
}

main.adding-dose_page .content .link_close,
main.assembling-meal-menu_page .content .link_close {
  font-size: 23px;
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
  color: #1563b1;
}

main.assembling-meal-menu_page .content .h_block {
  text-align: center;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.96px;
  color: #475058;
  margin-top: -1rem;
}

main.assembling-meal-menu_page .content table thead tr {
  background-color: #ffffff;
}

main.assembling-meal-menu_page .content table thead tr th {
  font-size: 23px;
  font-weight: 400;
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
  color: #475058;
}

main.assembling-meal-menu_page .content table thead tr th:nth-child(1) {
  text-align: left;
}

main.assembling-meal-menu_page .content table thead tr th .btn_submit {
  height: 40px;
}

main.assembling-meal-menu_page .content table tbody tr td:nth-child(2) {
  max-width: initial;
}

main.assembling-meal-menu_page .content table tbody tr td button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

main.assembling-meal-menu_page .content table tbody tr td .input_qty {
  height: 40px;
  max-width: 100px;
  font-size: 20px;
  color: #475058;
  text-align: center;
  line-height: 1.35;
  letter-spacing: 1.2px;
}

main.assembling-meal-menu_page .content table tbody tr td .icon {
  width: 40px;
  height: 40px;
  margin-bottom: -5px;
}

main.assembling-meal-menu_page_2 .top .btn_submit {
  text-decoration: none;
  margin-right: 10px;
  padding: 0 10px;
}

main.assembling-meal-menu_page_2 .top .btn_submit span {
  border-radius: 100%;
  background-color: #ffffff;
  color: #475058;
  margin-right: 20px;
  padding: 3px;
  font-size: 16px;
}

main.assembling-meal-menu_page_2 h4 {
  font-size: 20px;
  line-height: 1.35;
  letter-spacing: 1.2px;
  text-align: center;
  color: #475058;
  font-weight: 400;
  margin: 0;
}

main.assembling-meal-menu_page_2 .disable .df-center {
  text-decoration: underline;
  opacity: 0.36;
}

main.assembling-meal-menu_page_2 .disable button {
  display: none;
}

main.assembling-meal-menu_page_2 .disable .input_qty {
  border: none;
}

main.distributes-portions_page {
  min-height: calc(100vh - 112px);
}

main.distributes-portions_page .extra_data {
  font-size: 23px;
  font-weight: 600;
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
}

main.distributes-portions_page .content {
  max-width: 1610px;
  margin: 2.5rem auto 0;
}

main.distributes-portions_page .content .item_block {
  padding: 0 10px;
}

main.distributes-portions_page .content .item_block .item {
  padding: 18px 20px;
  border-radius: 8px;
  border: 2px dashed #14cd31;
}

main.distributes-portions_page .content .item_block .item .bg {
  border-radius: 8px;
  background-color: #f0f4f8;
}

main.distributes-portions_page .content .item_block .item .bg .item_top {
  border-bottom: dashed 1px #a6bcd0;
}

main.distributes-portions_page
  .content
  .item_block
  .item
  .bg
  .item_top
  .photo_eat {
  width: 100%;
  height: 90px;
  overflow: hidden;
  border-radius: 8px;
}

main.distributes-portions_page
  .content
  .item_block
  .item
  .bg
  .item_top
  .photo_eat
  img {
  width: 100%;
}

main.distributes-portions_page .content .item_block .item .bg .item_top .title,
main.distributes-portions_page
  .content
  .item_block
  .item
  .bg
  .item_top
  .order_num {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.38;
  letter-spacing: 1.56px;
  color: #14cd31;
  margin: 0;
  text-align: center;
}

main.distributes-portions_page
  .content
  .item_block
  .item
  .bg
  .item_top
  .order_num {
  font-size: 60px;
  letter-spacing: 3.6px;
}

main.distributes-portions_page .content .item_block .item .bg .item_bottom {
  padding-bottom: 20px;
}

main.distributes-portions_page
  .content
  .item_block
  .item
  .bg
  .item_bottom
  .full_name,
main.distributes-portions_page
  .content
  .item_block
  .item
  .bg
  .item_bottom
  .wait {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  letter-spacing: 1.2px;
  color: #475058;
  margin: 5px;
  text-align: center;
}

main.distributes-portions_page
  .content
  .item_block
  .item
  .bg
  .item_bottom
  .btn_submit {
  height: 40px;
  max-width: 155px;
  background-color: #7bed8d;
  text-decoration: none;
  margin: 20px auto;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

main.distributes-portions_page
  .content
  .item_block
  .item
  .bg
  .item_bottom
  .btn_submit:hover {
  background-color: #14cd31;
}

main.distributes-portions_page
  .content
  .item_block
  .item
  .bg
  .item_bottom
  .wait {
  margin: 0 auto;
  color: #54a7fb;
  display: block;
}

main.order-summary_page {
  min-height: calc(100vh - 112px);
}

main.order-summary_page.order-voucher_page .col-12 {
  border: 2px dashed #14cd31;
}

main.order-summary_page.order-voucher_page .col-12 .order_item {
  margin: 16px 0;
}

main.order-summary_page .content {
  max-width: 1224px;
  margin: 2.5rem auto 0;
}

main.order-summary_page .content .order_item {
  background: #f0f4f8;
  border-radius: 8px;
}

main.order-summary_page .content .order_item img {
  max-width: 358px;
  width: 100%;
  margin-bottom: -4px;
}

main.order-summary_page .content .order_item .description {
  width: 70%;
  padding: 30px 40px;
}

main.order-summary_page .content .order_item .description p {
  font-size: 20px;
  color: #475058;
  line-height: 1.35;
  letter-spacing: 1.2px;
}

main.order-summary_page .content .order_item .description .title {
  margin-bottom: 40px;
}

main.order-summary_page .content .order_item .description .title h4,
main.order-summary_page .content .order_item .description .title p {
  font-size: 20px;
  font-weight: bold;
  color: #475058;
  line-height: 1.35;
  letter-spacing: 1.2px;
  margin: 0;
}

main.order-summary_page .content .order_item .description .title p {
  border-top: 1px dashed #a6bcd0;
  font-weight: 400;
  padding-top: 16px;
  margin-top: 20px;
}

main.order-summary_page .content .order_item .description .stars img {
  width: 20px;
  margin: 0 2px;
}

main.order-summary_page .content .order_item .description .order_voucher_num {
  text-align: center;
}

main.order-summary_page .content .order_item .description .order_voucher_num p {
  font-size: 50px;
  font-weight: 600;
  line-height: 1.36;
  letter-spacing: 3px;
  color: #14cd31;
}

main.order-summary_page
  .content
  .order_item
  .description
  .order_voucher_num
  p:nth-child(2) {
  font-size: 65px;
  letter-spacing: 3.9px;
}

main.order-summary_page .content .change_order {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  color: #475058;
  text-decoration: none;
  line-height: 1.35;
  letter-spacing: 1.2px;
}

main.order-summary_page .content .change_order span {
  background: #7bed8d;
  border-radius: 100%;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

main.order-summary_page .content .change_order span img {
  width: 18px;
  margin-top: 5px;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

main.order-summary_page .content .change_order:hover span {
  background: #14cd31;
}

main.order-summary_page .content .btn_submit {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 50px;
  right: 0;
  z-index: 10;
  border-radius: 0;
}

main.add-opinion_page {
  min-height: calc(100vh - 112px);
}

main.add-opinion_page .content {
  max-width: 1366px;
  margin: 2.5rem auto 0;
  font-size: 20px;
  color: #475058;
  line-height: 1.35;
  letter-spacing: 1.2px;
}

main.add-opinion_page .content .opinion_item {
  padding: 25px 30px;
  background: #f0f4f8;
}

main.add-opinion_page .content .opinion_item .description {
  width: 100%;
}

main.add-opinion_page .content .opinion_item .description .opinion_stars label {
  width: 48px;
  height: 45px;
  margin-left: 20px;
  background: url(./assets/images/star.svg) center center no-repeat;
  background-size: contain;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

main.add-opinion_page
  .content
  .opinion_item
  .description
  .opinion_stars
  label:hover,
main.add-opinion_page
  .content
  .opinion_item
  .description
  .opinion_stars
  label.active {
  background: url(./assets/images/star_green.svg) center center no-repeat;
  background-size: contain;
}

main.add-opinion_page
  .content
  .opinion_item
  .description
  .opinion_stars
  label:hover
  ~ label,
main.add-opinion_page
  .content
  .opinion_item
  .description
  .opinion_stars
  label.active
  ~ label {
  background: url(./assets/images/star_green.svg) center center no-repeat;
  background-size: contain;
}

main.add-opinion_page .content .opinion_item .description .opinion_stars input {
  display: none;
}

main.add-opinion_page .content .opinion_item .description textarea {
  width: 100%;
  padding: 14px 28px;
  font-size: 20px;
  font-weight: 600;
  resize: none;
  font-family: inherit;
  border: none;
  outline: none;
  line-height: 1.35;
  letter-spacing: 1.2px;
}

main.add-opinion_page
  .content
  .opinion_item
  .description
  textarea::-webkit-input-placeholder {
  color: #a6bcd0;
}

main.add-opinion_page
  .content
  .opinion_item
  .description
  textarea::-moz-placeholder {
  color: #a6bcd0;
}

main.add-opinion_page
  .content
  .opinion_item
  .description
  textarea:-ms-input-placeholder {
  color: #a6bcd0;
}

main.add-opinion_page
  .content
  .opinion_item
  .description
  textarea::-ms-input-placeholder {
  color: #a6bcd0;
}

main.add-opinion_page
  .content
  .opinion_item
  .description
  textarea::placeholder {
  color: #a6bcd0;
}

main.add-opinion_page .content .opinion_item .description button {
  max-width: 212px;
  margin-left: calc(100% - 212px);
  margin-top: 20px;
}

main.add-opinion_page .content .popup .btn_submit {
  background: transparent;
  border: 2px solid #14cd31;
  text-decoration: none;
  font-weight: 400;
  max-width: 212px;
  margin: 40px auto 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

main.add-opinion_page .content .popup .btn_submit:hover {
  background: #14cd31;
}

main.choosing-dish_page .calendar_top_btn {
  color: #14cd31;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.35;
  letter-spacing: 1.02px;
  text-decoration: underline;
  margin-right: 30px;
  margin-bottom: -8px;
  cursor: pointer;
}

main.choosing-dish_page .calendar_top_btn img {
  width: 28px;
  margin-left: 10px;
}

main.choosing-dish_page .single_item .stars img {
  width: 18px;
  margin-left: 4px;
}

main.choosing-dish_page .single_item .dish_description {
  margin-top: 1rem;
  padding: 1rem;
  border-top: 1px dashed #a6bcd0;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.96px;
  color: #475058;
}

main.choosing-dish_page .single_item .btn_error {
  background: #ff3e3e;
}

main.choosing-dish_page .single_item .btn_error img {
  display: none;
}

main.page_404 {
  min-height: calc(100vh - 156px);
}

main.page_404 .content {
  max-width: 1366px;
  margin: auto;
}

main.page_404 .content .col-12 {
  text-align: center;
}

main.page_404 .content .col-12 h3 {
  font-size: 50px;
  font-weight: 600;
  line-height: 1.36;
  letter-spacing: 3px;
  color: #14cd31;
  margin: 0;
}

main.page_404 .content .col-12 p {
  font-size: 23px;
  font-weight: 600;
  line-height: 1.39;
  letter-spacing: 1.38px;
  color: #a6bcd0;
  color: #475058;
  margin-bottom: 2rem;
}

main.page_404 .content .col-12 img {
  max-width: 416px;
  width: 100%;
}

html[lang='en'] header .nav.blue_menu ul li::after,
html[lang='ru'] header .nav.blue_menu ul li::after {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

html[lang='en'] .btn_submit img,
html[lang='ru'] .btn_submit img {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.payment-management-btn {
  margin-top: 20px;
}
.user-details-block p.payment-method.without-link {
  color: #14cd31;
  width: fit-content;
  text-decoration: none;
}
.user-details-block .payment-method,
.user-details-block .payment-method p {
  color: #14cd31;
  text-decoration: none;
  width: fit-content;
}

.copy-previous-meal {
  .ag-root-wrapper {
    .ag-header {
      height: 38px !important;
      min-height: 38px !important;
      .ag-header-row {
        height: 100% !important;
        min-height: 100% !important;
      }
    }
    // .ag-body-viewport {
    //   .ag-row {

    //   .ag-cell {
    //     &:last-child {
    //       justify-content: flex-end !important;
    //     }
    //     .percent-data {

    //     }
    //   }
    //   }
    // }
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }

  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: flex-start !important;
    -ms-flex-pack: flex-start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: flex-end !important;
    -ms-flex-pack: flex-end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: flex-start !important;
    -ms-flex-align: flex-start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: flex-end !important;
    -ms-flex-align: flex-end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: flex-start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: flex-end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: flex-start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }

  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: flex-start !important;
    -ms-flex-pack: flex-start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-box-align: flex-start !important;
    -ms-flex-align: flex-start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: flex-end !important;
    -ms-flex-align: flex-end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: flex-start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: flex-end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: flex-start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }

  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: flex-start !important;
    -ms-flex-pack: flex-start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-box-align: flex-start !important;
    -ms-flex-align: flex-start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: flex-end !important;
    -ms-flex-align: flex-end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: flex-start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: flex-end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: flex-start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
  .from-date-small {
    width: 100% !important;
  }
}

// @media (min-width: 1650px) {
//  .employee-management .ag-center-cols-container {
//     width: 100% !important;
// }
// }
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }

  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: flex-start !important;
    -ms-flex-pack: flex-start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -webkit-box-align: flex-start !important;
    -ms-flex-align: flex-start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: flex-end !important;
    -ms-flex-align: flex-end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: flex-start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: flex-end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: flex-start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

main {
  .single_item {
    background-color: #f0f4f8;
    border-radius: 8px;
    height: calc(100% + 90px);
    position: relative;
  }
}

.ag-root-wrapper.ag-ltr.ag-layout-auto-height {
  width: 100%;
}

.ag-header-container,
.ag-center-cols-container {
  // margin: 0px auto;
  width: 100%;
}

// @media print{

//   .print-table{
//     margin-top: 0px !important;
//   }

//  }
// .preview-print {
//   margin-left: 5px;
// }
.print-page-popup {
  width: 90%;
  margin: 0px auto;
}

.print-btn button {
  font-size: 20px;
  font-weight: 600;
  padding: 12px 20px !important;
  background: #14cd31;
  border-radius: 40px !important;
  padding: 10px 25px !important;
  min-height: 45px !important;
  color: white;
  text-align: left !important;
  border: 1px solid #14cd31;
  cursor: pointer;
}

.languages span {
  text-transform: uppercase;
}

header.header.before-login {
  justify-content: space-between;
  padding: 20px;
}
header.header.display-none {
  display: none;
}
// .meal-slot-new-popup {
//   opacity: 0;
//   visibility: hidden;
// }

// .meal-slot-new-popup.open-new-popup {
//   opacity: 1;
//   visibility: visible;
//   z-index: 999999;
// }
.meal-slot-new-popup.open-new-popup .add-employee-popup.add-slot-popup {
  display: block;
}

.meal-slot-new-popup .add-employee-popup.add-slot-popup {
  display: none;
}

@media print {
  .print-page-popup {
    width: 100%;
    margin: 0px auto;
  }

  .print-page-popup table {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  // body  {
  //   visibility: hidden;
  // }
  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  @page {
    size: a4;
  }

  // #printpreview,
  // #printpreview * {
  //   visibility: visible;
  //   overflow: auto;
  // }
  .add-employee-popup.print-popup .MuiBox-root {
    width: 100% !important;
  }

  #printpreview {
    width: 100% !important;
    margin-top: 0px !important;
  }

  table.print-table tr th {
    font-size: 12px !important;
  }

  table.print-table tr td,
  .print-table tr td span {
    font-size: 12px !important;
  }

  table.print-table tr .mealtype,
  table.print-table tr .mealtype span {
    font-size: 20px !important;
  }

  header.header {
    display: none;
  }

  .sub-header-wrapper {
    display: none;
  }

  .we-footer,
  .print-btn {
    display: none;
  }

  .content-container {
    padding: 0px !important;
  }
}

/********new css ************/
.action-btn .menu-container {
  // position: absolute;
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  position: absolute;
  top: 5px;
  right: 9px;
}

.action-btn .menu-item:hover {
  cursor: pointer;
  background: rgb(240, 240, 240);
}

.action-btn .menu-item.active {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}

.action-btn .menu-item {
  display: block;
  padding: 10px 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
}

.action-btn .menu-item button {
  max-width: 200px !important;
  width: 100% !important;
}

.action-btn .edit-btn {
  padding: 6px 22px;
  margin: 3px;
  color: #fff;
  border: 1px solid #14cd31;
  background: #14cd31;
  border-radius: 24px;
}

.action-btn .delete-btn {
  padding: 6px 18px;
  margin: 3px;
  color: #fff;
  border: 1px solid #cd1414;
  background: #cd1414;
  border-radius: 24px;
}

.user-management i.fa.fa-ellipsis-v {
  padding: 10px 20px;
}

.ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none !important;
}

.copymeal-popup {
  z-index: 99 !important;
}

.hover-popup {
  background-color: white;
  padding: 15px;
  z-index: 99999;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgb(209, 209, 209);
}

.hover-popup p {
  font-size: 13px;
  margin: 6px 0px;
}

.swal2-container {
  z-index: 99999;
}

.desktop .sub-header .title {
  font-size: 23px !important;
}

/****** ahow extra popup **************/
.show-extra-popup .item-card.extras-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  margin-bottom: 15px;
}

.show-extra-popup .item-card.extras-list .extras-dishname img {
  height: 80px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  margin-right: 8px;
}

.show-extra-popup .item-card.extras-list .extras-dishname {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
}

.show-extra-popup .extras-button button {
  padding: 12px 25px;
  margin: 5px;
  border-radius: 5px;
  min-width: 115px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.show-extra-popup .extras-button button:last-child {
  background: #14cd31;
  border: 1px solid #14cd31;
  color: white;
  border-radius: 40px;
}

// .show-extra-popup .extras-button button:first-child {
//   background: #e60505;
//   border: 1px solid #dd1010;
//   color: white;
//   border-radius: 40px;
// }
.show-extra-popup .extras-button.other-button {
  position: absolute;
  background: white;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  right: 0px;
  padding-top: 10px;
}

.show-extra-popup .extras-listing-section {
  position: relative;
  height: 100%;
}

.show-extra-popup .item-card.extras-list {
  border-radius: 5px;
}

.show-extra-popup .extras-meal-management {
  height: 440px;
  overflow: auto;
  padding-right: 10px;
}

.show-extra-popup .extras-meal-management::-webkit-scrollbar {
  width: 5px;
}

.show-extra-popup .extras-meal-management::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.show-extra-popup .extras-meal-management::-webkit-scrollbar-thumb {
  background: #888;
}

.show-extra-popup .extras-button .inventory {
  display: flex;
}

.show-extra-popup input {
  width: 70px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  border: 0;
  border-radius: 3px;
  outline: none;
  background-color: white;
}

.show-extra-popup .ltr .icon-wrapper .we-icon,
.show-extra-popup .ltr .icon-wrapper .we-icon-text {
  margin-left: 0;
}

.show-extra-popup .extras-button p {
  min-width: 70px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  border: 0;
  border-radius: 3px;
  outline: none;
  color: rgb(43, 43, 43);
  margin-bottom: 0px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.show-extra-popup .extras-listing-section h4 {
  text-align: center;
  font-size: 20px;
}

.swal2-container {
  z-index: 99999999 !important;
}

.show-extra-popup .inventory .icon-wrapper.disabled .we-icon {
  opacity: 0.4;
}

.rtl .ag-cell {
  text-align: right !important;
}

// .ag-center-cols-container, .ag-header-container, .ag-header-row.ag-header-row-column{
//   width: 100% !important;
// }
.rtl .show-extra-popup .item-card.extras-list .extras-dishname img {
  margin-right: 0;
  margin-left: 15px;
}

.rtl .show-extra-popup .item-card.extras-list {
  margin-bottom: 15px;
  padding-left: 15px;
}

.order-popup .popup-block .order-details h4 {
  margin-bottom: 8px;
}

.order-popup .popup-block .order-details h6 {
  margin: 0px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 400;
}

.check-payment-popup .extras-meal-management {
  height: auto;
  overflow: auto;
  padding-right: 10px;
}

.check-payment-popup .extras-meal-management p {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.check-payment-popup .extras-button button:first-child {
  background: #14cd31 !important;
  border: 1px solid #14cd31 !important;
}

.check-payment-popup .extras-button {
  display: flex;
  justify-content: center !important;
  margin-top: 45px;
}

.check-payment-popup .extras-listing-section h4 {
  text-align: center;
  font-size: 20px;
}

.check-payment-popup .extras-button button {
  padding: 12px 25px;
  margin: 5px;
  border-radius: 5px;
  min-width: 115px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: white;
  border-radius: 40px;
}

.rtl .ag-rtl .ag-body,
.rtl .ag-rtl .ag-floating-top,
.rtl .ag-rtl .ag-floating-bottom,
.rtl .ag-rtl .ag-header,
.rtl .ag-rtl .ag-body-viewport,
.rtl .ag-rtl .ag-body-horizontal-scroll {
  flex-direction: initial !important;
}

.rtl .ag-theme-alpine .ag-rtl .ag-pinned-left-header .ag-header-row::before,
.rtl .ag-theme-alpine .ag-ltr .ag-pinned-right-header .ag-header-row::after {
  content: '';
  position: absolute;
  height: calc(100% - 20px);
  top: 10px;
  width: 1px;
  background-color: #babfc7;
  background-color: transparent;
}

.forget-popup .reset-form {
  padding: 25px 20px;
}

.forget-popup .reset-form .input-field {
  margin-bottom: 20px;
}

.forget-popup .reset-form .button-wrapper button {
  width: 100%;
  max-width: 100% !important;
}

.MuiBox-root {
  outline: none;
  box-shadow: none;
}

.desktop .sub-header .user-name {
  flex: 1.2 1;
  max-width: 250px;
}
.employee-management .data-filter {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.employee-management .xyz .button-wrapper {
  justify-content: flex-end !important;
  margin-top: 20px;
}

.employee-management .xyz label {
  font-size: 17px;
  text-align: left;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
}

.employee-management .xyz input {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 15px;
  color: black;
  cursor: pointer;
  min-height: 38px;
  background-color: transparent !important;
}

.employee-management .xyz .from-date {
  display: grid;
  align-items: center;
  margin-left: 20px;
  max-width: 210px;
  width: 100%;
}

.employee-management .xyz .from-date:first-child {
  margin-left: 0;
}

.employee-management .reservation-csv {
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 20px;
}

@media screen and (max-width: 991px) {
  .cancelleSection {
    // background: rgb(242 73 83 / 27%) !important;
    position: relative;
  }
  // .cancelleSection::before {
  //   content: '';
  //   width: 100%;
  //   position: absolute;
  //   height: 1px;
  //   background: rgba(242, 73, 83, 1);
  //   left: 0;
  // }

  .cancelleSection p {
    text-decoration: line-through;
  }
  .from-date-small {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .order-voucher.mob-voucher
    .green-border
    .order-container
    .order-details
    .order-time {
    margin-top: 0px;
    font-weight: 600;
    font-size: 14px;
  }

  .order-voucher.mob-voucher
    .green-border
    .order-container
    .order-details
    .price
    span {
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
  }

  .order-voucher.mob-voucher
    .green-border
    .order-container
    .order-details
    .order-description
    span {
    font-weight: 600;
    color: #475058;
    font-size: 14px;
    margin-right: 10px;
  }

  .order-voucher.mob-voucher
    .green-border
    .order-container
    .order-details
    .order-description {
    margin-top: 0px;
    color: #14cd31;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }

  .order-voucher.mob-voucher .makeStyles-root-6 {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    min-width: 150px;
    align-items: center;
    flex-direction: inherit;
    justify-content: flex-start;
    margin-top: 10px;
  }

  .order-voucher.web-voucher {
    display: none;
  }

  .order-voucher.mob-voucher {
    display: block;
  }

  .order-voucher.mob-voucher .green-border .order-container .qr-code {
    margin-left: 5px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .reservation-title .icon-wrapper.back-button {
    position: absolute;
    left: 0px;
  }

  .total-data p {
    font-size: 15px;
  }

  .total-data p span {
    font-weight: 600;
  }
  .sidebar-kitchen-selection.sub-header-wrapper {
    display: block;
  }

  section.sub-header .kitchens-list.show-kitchen.pointer {
    display: none;
  }

  .sidebar-kitchen-selection .sub-header {
    background: transparent;
    box-shadow: none;
  }

  .sidebar-kitchen-selection .kitchens-list.show-kitchen {
    width: 100%;
  }

  .sidebar-kitchen-selection .kitchens-list .list-item {
    justify-content: flex-start !important;
  }

  .sidebar-kitchen-selection .list-item {
    padding: 0px 15px !important;
  }

  .sidebar-kitchen-selection .list-item img.kitchen-arrow {
    margin-left: 14px;
  }

  .sidebar-kitchen-selection .content {
    background: #475058 !important;
    bottom: 25px !important;
    padding: 10px 0px;
    top: auto !important;
  }

  .sidebar-kitchen-selection .content .list-item {
    margin: 5px 0px;
  }

  .sidebar-languages-selection {
    width: 100%;
    display: block;
  }

  .sidebar-languages-selection .lang-dropdown {
    position: absolute;
    background: #475058;
    width: 100%;
    right: 0px;
    border-radius: 5px;
    margin-top: 12px;
    box-shadow: none;
    bottom: 25px;
    z-index: 9999;
  }

  .nav .nav_title .user_name {
    flex: 1 1;
    line-height: initial;
    font-size: 16px;
  }

  .sidebar-languages-selection .languages-selection {
    width: 100%;
    max-width: 100%;
    color: white;
    margin-top: 20px;
  }

  .lang-dropdown .lang-item img {
    height: 16px;
  }

  .lang-shortcode img {
    height: 16px !important;
  }

  .lang-dropdown .lang-item span {
    font-size: 16px;
  }

  .languages .lang-shortcode span {
    font-size: 16px;
  }

  .lang-label img {
    margin-top: 4px;
    margin-left: 10px;
  }

  // .languages-selection .lang-item {
  //   justify-content: flex-start;
  // }

  .lang-dropdown .lang-item {
    justify-content: flex-start;
  }

  .header .languages-selection {
    max-width: 90px;
    right: 15px;
    display: none;
  }

  .languages-selection .lang-item {
    padding-right: 0;
  }

  .rtl .lang-label img {
    margin-left: 0px;
    margin-right: 10px;
  }

  .rtl .back-link-section img {
    margin-right: 0px;
    margin-left: 8px;
  }

  .back-link-section img {
    height: 16px;
    margin-right: 8px;
  }

  .reservation-title .title-date {
    font-weight: 700;
    font-size: 14px;
  }

  // .sub-header-wrapper .kitchens-list.show-kitchen.pointer {
  //   display: none;
  // }
  .reservation-title .back-link-section {
    position: initial;
    left: 0px;
  }

  .dishes-container .dish-cards .dish-single-card {
    margin: 1.2rem 0px;
    max-width: 100%;
  }

  .reservation-dish-rating {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .reservation-dish-rating .makeStyles-root-1 {
    align-items: baseline;
    justify-content: flex-start;
  }

  .dishes-container .dish-cards .dish-single-card .dish-details {
    flex-direction: column;
  }

  .reservation-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .order-voucher.mob-voucher
    .green-border
    .order-container
    .order-details
    .order-time {
    margin-top: 0px;
    font-weight: 600;
    font-size: 14px;
  }

  .order-voucher.mob-voucher
    .green-border
    .order-container
    .order-details
    .price
    span {
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
  }

  .order-voucher.mob-voucher
    .green-border
    .order-container
    .order-details
    .order-description
    span {
    font-weight: 600;
    color: #475058;
    font-size: 14px;
    margin-right: 10px;
  }

  .order-voucher.mob-voucher
    .green-border
    .order-container
    .order-details
    .order-description {
    margin-top: 0px;
    color: #14cd31;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }

  .order-voucher.mob-voucher .makeStyles-root-6 {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    min-width: 150px;
    align-items: center;
    flex-direction: inherit;
    justify-content: flex-start;
    margin-top: 10px;
  }

  .order-voucher.web-voucher {
    display: none;
  }

  .order-voucher.mob-voucher {
    display: block;
  }

  .order-voucher .green-border .order-container .voucher-realized {
    margin: 0 0 0 6px;
  }

  .order-voucher.mob-voucher .green-border .order-container .qr-code {
    margin-left: 5px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .employee-payment-management .form {
    width: 100% !important;
  }

  .meal-payment-popup .MuiBox-root {
    padding: 14px 15px;
  }

  .meal-payment-popup .payment-details h6 {
    font-size: 13px;
  }

  .check-payment-popup .extras-meal-management p {
    font-size: 12px !important;
  }

  .container {
    padding: 0px !important;
  }

  .panel-chart-section {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 10px;
  }

  .control-panel .grid .panel-card .content {
    flex-direction: column;
    overflow: hidden;
  }

  .control-panel .panel-chart {
    padding: 15px !important;
    margin: 0px auto;
    overflow: hidden;
  }

  .control-panel .new-one {
    flex-direction: column !important;
  }

  .panel-chart-section .xyz {
    margin-bottom: 10px;
  }

  .order-voucher .green-border .order-container .qr-code {
    margin: 5px auto !important;
  }

  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 5px;
    padding-right: 5px;
  }

  .action-btn .menu-container {
    right: 28px;
  }

  .user-management .ag-pinned-left-cols-container,
  .user-management .ag-pinned-left-header {
    min-width: 135px !important;
  }

  .user-management .ag-row-even,
  .user-management .ag-row-odd {
    height: 60px !important;
    margin-bottom: 0px !important;
    border-bottom: none !important;
  }

  .user-management .ag-theme-alpine .ag-row {
    margin-bottom: 0px !important;
  }

  .user-management span.ag-header-cell-text {
    font-size: 17px !important;
  }

  .user-management .ag-theme-alpine .ag-paging-panel > * {
    margin: 0 5px;
  }

  .user-management .ag-theme-alpine .ag-ltr .ag-cell {
    font-size: 16px !important;
  }

  /************reviews*************/
  .select-add-review {
    margin-top: 40px;
    display: block;
    width: 100%;
  }

  .left-review-section {
    width: 100%;
  }

  .select-add-review .right-review-section {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .review-details-section .review-details-name {
    width: 100%;
  }

  .review-details-section {
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .review-details-name h4 {
    margin-bottom: 0px;
  }

  .login .form .input-group .label-input .input-field,
  .reset-password .form .input-group .label-input .input-field {
    font-size: 14px;
  }

  /***** add meal*********/
  .add-dish-to-meal .dish .section1,
  .add-dish-to-meal .dish .section2 {
    padding: 15px 15px;
  }

  .rtl .add-dish-to-meal .dish .section2 .rating-wrapper p {
    text-align: right;
  }

  .add-dish-to-meal .dish .section2 .rating-wrapper > div {
    display: grid;
    flex-wrap: wrap;
    font-size: 20px;
    min-width: 150px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  }

  /********Menu construction**********/
  .meal-dish.mealdish-mob section.section2 {
    display: none;
  }

  .dropdwon-list-mob .MuiPaper-root {
    outline: 0;
    position: relative !important;
    max-width: 60%;
    min-width: 43px;
    max-height: auto;
    min-height: 16px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .dropdwon-list-mob li .we-toggle {
    display: flex;
    flex-direction: revert;
  }

  .dropdwon-list-mob li p {
    margin-right: 10px !important;
  }
  .rtl .custom-dropdown .dropdwon-list {
    left: 0px;
    right: auto;
  }
  .rtl .custom-dropdown .dropdown-item p {
    margin-left: 10px;
    margin-right: 0px;
  }

  .dropdwon-list .MuiPaper-root {
    top: 215px !important;
  }

  .dropdwon-list-mob .MuiPaper-root {
    top: 66% !important;
  }

  .header .logo-header {
    height: 40px;
    width: 40px;
  }

  // .add-dish-to-meal .show-on-mobile {
  //   display: none;
  // }
  .add-dish-to-meal .top .search {
    width: 100% !important;
  }

  .add-dish-to-meal .top .search .input {
    width: 80%;
  }

  .mobile .sub-header.mobile {
    padding-top: 0px;
  }

  .header {
    max-height: 70px;
  }

  /******reservation management page ************/
  .add-slot-popup .item-list table tr td,
  .item-list table tr th {
    font-size: 14px;
  }

  .meal-type-management .language-input label {
    font-size: 12px;
  }

  .meal-type-management section.add-btn .we-icon img {
    height: 18px;
    width: 18px;
  }

  .meal-type-management section.add-btn p {
    font-size: 13px !important;
  }

  .addmeal-listing form.add-mealtypes {
    display: grid;
  }

  .meal-type-management .language-input {
    width: 100%;
  }

  .meal-type-management .listing-section {
    width: 100%;
  }

  .meal-type-management section.add-btn {
    margin-left: 50%;
  }

  .meal-type-management .language-input {
    width: fit-content;
    white-space: nowrap;
  }

  .reservation-management .xyz .from-date {
    width: 48% !important;
  }

  .reservation-management .xyz .from-date input {
    width: 100% !important;
  }

  .reservation-management .data-filter {
    flex-wrap: wrap;
  }

  .content-container {
    padding: 30px 40px;
  }

  .sub-header-wrapper .sub-header .kitchens-list .list-item {
    padding: 10px 10px;
    font-size: 16px;
    color: #f0f4f8;
    font-weight: 400;
  }

  .mobile .sub-header .user-name {
    padding: 0 5px;
  }

  .sub-header-wrapper .sub-header .kitchens-list .kitchen-arrow {
    width: 15px;
    height: 15px;
  }

  .mobile .sub-header .title {
    font-size: 16px;
  }

  .reservation-management .data-filter {
    display: block;
  }

  .reservation-management .filter-section .from-date {
    max-width: 100%;
  }

  .reservation-management .filter-section .from-date {
    margin-left: 0px;
  }

  .filter-section {
    margin-bottom: 10px;
  }

  .reservation-management .filter-section label {
    font-size: 13px;
  }

  .xyz {
    justify-content: space-between;
  }

  .xyz .from-date {
    max-width: 48% !important;
  }
  .employee-management .active-button {
    justify-content: space-between;
  }

  .cookie-btn button {
    font-size: 14px !important;
  }

  .cookie-btn {
    margin-top: 10px;
  }

  .cookie-content {
    display: grid;
    justify-content: space-between;
    align-items: center;
  }

  .cookie-content {
    font-size: 14px;
    padding: 20px 15px 2px;
    bottom: 6%;
  }

  .add-employee-btn {
    flex-wrap: wrap;
  }

  .cookie-content {
    display: grid;
  }

  .cookie-content p,
  .cookie-btn {
    width: 100%;
  }

  .add-employee-popup .MuiBox-root {
    width: 100% !important;
    padding: 15px;
    border-radius: 5px;
  }

  .add-employee-popup .item .input-field,
  .add-employee-popup.update-lang .item .input-field {
    width: 100%;
    margin-bottom: 0px;
  }
  .add-employee-popup.update-lang .item {
    justify-content: flex-end;
  }
  .add-employee-popup.update-lang .add-employee-btn {
    margin-bottom: 0;
  }

  .employee-management .active-button {
    margin-top: 10px;
  }

  .employee-management .add-employee-btn input {
    width: 100%;
  }

  .employee-management .button-wrapper .we-button.active {
    padding: 8px 20px;
  }

  .employee-management .ag-paging-panel.ag-unselectable {
    flex-wrap: wrap;
  }

  .employee-management .user-status-filter {
    display: none;
  }

  .employee-management .add-employee-btn {
    justify-content: flex-end !important;
  }

  /********** User Walletr responsive CSS ***********/

  .user-wallet h6 {
    font-size: 12px;
  }

  .rtl .user-wallet h6 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /********** header responsive issue ***********/
  .nav {
    border-radius: 0px;
  }

  .nav .items ul li {
    text-align: left;
    padding: 10px 0px;
  }

  .rtl .nav .items ul li {
    text-align: right;
  }

  .nav .sidebar-bottom {
    padding: 0px;
  }

  .nav {
    width: 85vw !important;
  }

  .nav .sidebar-bottom img {
    margin: 0 20px;
  }

  .nav .items ul li a,
  .nav .items ul li span {
    font-size: 16px;
  }

  .nav .items ul li:after {
    top: 20px;
  }

  .header {
    position: sticky;
    top: 0px;
  }

  .sub-header-wrapper .sub-header {
    position: sticky;
    top: 74.8px;
  }

  h2.title {
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.35;
    letter-spacing: 1.2px;
  }

  header .nav {
    width: 100%;
    border-bottom-left-radius: 30px;
  }

  header .nav.orange_menu ul li a {
    line-height: 4.56;
  }

  header .nav.blue_menu ul li a {
    line-height: 5.44;
  }

  header .nav .nav_title {
    font-size: 16px;
  }

  header .nav ul li a {
    font-size: 16px;
    line-height: 5.44;
  }

  .inp_field {
    padding: 17px 22px 17px 48px;
    font-size: 16px;
  }

  .btn_submit {
    font-size: 16px;
    height: 34px;
  }

  .btn_submit img {
    width: 18px;
    margin: 22px -10px 18px 8px;
  }

  .label_input_field::before {
    width: 20px;
    height: 20px;
    left: 30px;
    top: 16px;
  }

  .main_checkbox input:checked + label::before {
    top: 0;
    width: 24px;
    height: 24px;
    background: url(./assets/images/check.svg) 7px 54% no-repeat;
    background-size: 10px;
    background-color: #7bed8d;
  }

  .main_checkbox label {
    font-size: 16px;
  }

  .main_inputs .form_label {
    left: 14px;
    top: 20px;
    font-size: 16px;
    letter-spacing: 0.96px;
  }

  .main_inputs .form_label.rtl {
    right: 14px;
  }

  .main_inputs .focused .form_label {
    font-size: 14px;
  }

  .main_inputs .form_input {
    padding: 30px 14px 8px;
    font-size: 16px;
    letter-spacing: 0.96px;
  }

  .widget {
    position: absolute;
  }

  .widget.active {
    top: 220px;
  }

  .widget .container-fluid .ui-datepicker table th,
  .widget .container-sm .ui-datepicker table th,
  .widget .container-md .ui-datepicker table th,
  .widget .container-lg .ui-datepicker table th,
  .widget .container-xl .ui-datepicker table th {
    padding-top: 20px;
  }

  .widget .container-fluid .ui-datepicker table td,
  .widget .container-sm .ui-datepicker table td,
  .widget .container-md .ui-datepicker table td,
  .widget .container-lg .ui-datepicker table td,
  .widget .container-xl .ui-datepicker table td {
    padding: 0 4px;
    line-height: 30px;
  }

  .widget .container-fluid .ui-datepicker table td .ui-state-default,
  .widget .container-sm .ui-datepicker table td .ui-state-default,
  .widget .container-md .ui-datepicker table td .ui-state-default,
  .widget .container-lg .ui-datepicker table td .ui-state-default,
  .widget .container-xl .ui-datepicker table td .ui-state-default {
    width: 26px;
  }

  .widget .container-fluid .title,
  .widget .container-sm .title,
  .widget .container-md .title,
  .widget .container-lg .title,
  .widget .container-xl .title {
    margin: 0 auto;
  }

  .widget .container-fluid .block_types label,
  .widget .container-sm .block_types label,
  .widget .container-md .block_types label,
  .widget .container-lg .block_types label,
  .widget .container-xl .block_types label {
    font-size: 16px;
    margin: 0;
  }

  main .top {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 10px 0;
  }

  main .top .welcome {
    font-size: 16px;
    letter-spacing: 0.96px;
  }

  main .top .title_block {
    position: absolute;
    top: 80px;
  }

  main.main_page section.step.step_3 [class^='col-']:nth-child(6) {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  main.main_page section.step.step_3 [class^='col-']:nth-child(7) {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  main.main_page section.step.step_3 [class^='col-']:nth-child(8) {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  main.main_page section.step.step_3 [class^='col-']:nth-child(9) {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  main.main_page section .forgot_pass {
    text-align: center;
  }

  main.main_page section .forgot_pass a {
    font-size: 16px;
  }

  main.main_page section .newsletter {
    font-size: 16px;
  }

  main.main_page section .btn_submit {
    height: 60px;
  }

  main.lobby_page .content,
  main.choosing-dish_page .content {
    padding-top: 1rem;
  }

  main.lobby_page .content .single_item .top h4,
  main.choosing-dish_page .content .single_item .top h4 {
    font-size: 16px;
  }

  main.lobby_page .content .single_item .top p,
  main.choosing-dish_page .content .single_item .top p {
    font-size: 16px;
  }

  main.lobby_page .content .single_item .bottom .btn_submit,
  main.choosing-dish_page .content .single_item .bottom .btn_submit {
    font-size: 16px;
  }

  main.lobby_page .content .single_item .bottom .btn_submit img,
  main.choosing-dish_page .content .single_item .bottom .btn_submit img {
    top: 4px;
  }

  main.lobby_page_2 .content {
    padding-top: 0;
  }

  main.lobby_page_2 .content .single_item h4 {
    font-size: 16px;
  }

  main.lobby_page_2 .content .single_item p {
    font-size: 16px;
  }

  main.lobby_page_2 .content .single_item .btn_submit {
    font-size: 16px;
  }

  main.lobby_page_2 .content .single_item .btn_submit img {
    top: 4px;
  }

  main.lobby_page_2 .content .block_btns .btn {
    padding: 32px 10px;
    font-size: 16px;
  }

  main.meals-i-ate {
    min-height: calc(100vh - 126px);
  }

  main.meals-i-ate .content .btn_submit {
    font-size: 16px;
  }

  main.meals-i-ate .content .btn_submit img {
    top: 4px;
  }

  main.control-panel .content .single_item .btn_submit {
    font-size: 16px;
  }

  main.control-panel .content .single_item .btn_submit img {
    top: 4px;
  }

  main.user-management_page h2.title,
  main.packet-management_page h2.title {
    margin: 0;
  }

  main.user-management_page .content .btn_submit,
  main.packet-management_page .content .btn_submit {
    font-size: 16px;
    padding: 4px 0 6px;
    height: 34px;
    max-width: 166px;
    margin-left: calc(100% - 166px);
  }

  main.user-management_page .content form.search input,
  main.packet-management_page .content form.search input {
    font-size: 16px;
    padding: 7px 14px;
    width: calc(100% - 36px);
  }

  main.user-management_page .content form.search button,
  main.packet-management_page .content form.search button {
    top: -5px;
    width: 30px;
    height: 30px;
  }

  main.user-management_page .content form.search button img,
  main.packet-management_page .content form.search button img {
    left: 8px;
    top: 8px;
    width: 15px;
    height: 15px;
  }

  main.meal-management_page .content table tr,
  main.packet-management_page .content table tr,
  main.assembling-meal-menu_page .content table tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    border-bottom: none;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  main.meal-management_page .content table tr td,
  main.packet-management_page .content table tr td,
  main.assembling-meal-menu_page .content table tr td {
    font-size: 16px;
    letter-spacing: 0.96px;
  }

  main.meal-management_page .content table tr td .more_info,
  main.packet-management_page .content table tr td .more_info,
  main.assembling-meal-menu_page .content table tr td .more_info {
    padding: 2px 7px;
  }

  main.meal-management_page .content table tr td .more_info img,
  main.packet-management_page .content table tr td .more_info img,
  main.assembling-meal-menu_page .content table tr td .more_info img {
    width: 12px;
    height: 12px;
  }

  main.meal-management_page .content table tr td .last_block,
  main.packet-management_page .content table tr td .last_block,
  main.assembling-meal-menu_page .content table tr td .last_block {
    padding: 0;
    border: none;
  }

  main.packet-management_page .content table tr td,
  main.assembling-meal-menu_page .content table tr td {
    padding: 8px 10px;
  }

  main.packet-management_page .content table tr td:nth-child(1),
  main.assembling-meal-menu_page .content table tr td:nth-child(1) {
    display: none;
  }

  main.packet-management_page .content table tr td:nth-child(2),
  main.assembling-meal-menu_page .content table tr td:nth-child(2) {
    max-width: initial;
    width: calc(100% - 70px);
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  main.packet-management_page .content table tr td:nth-child(3),
  main.assembling-meal-menu_page .content table tr td:nth-child(3) {
    width: 115px;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px dashed #a6bcd0;
    padding-top: 12px;
  }

  main.packet-management_page .content table tr td:nth-child(4),
  main.assembling-meal-menu_page .content table tr td:nth-child(4) {
    width: 70px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    text-align: right;
  }

  main.packet-management_page .content table tr td:nth-child(5),
  main.assembling-meal-menu_page .content table tr td:nth-child(5) {
    width: calc(100% - 115px);
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    border-top: 1px dashed #a6bcd0;
    padding-top: 12px;
    text-align: right;
    font-weight: 600;
  }

  main.packet-management_page .content table tr td .stars img,
  main.assembling-meal-menu_page .content table tr td .stars img {
    height: 13px;
    margin: 2px;
  }

  main.adding-dose_page .content .btn_submit,
  main.assembling-meal-menu_page .content .btn_submit {
    height: 56px;
  }

  main.adding-dose_page .content .link_close,
  main.assembling-meal-menu_page .content .link_close {
    font-size: 16px;
  }

  main.assembling-meal-menu_page .content table thead tr th {
    font-size: 16px;
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  main.assembling-meal-menu_page .content table thead tr th .btn_submit {
    height: 30px;
    padding: 0 20px;
  }

  main.assembling-meal-menu_page .content table tbody tr td .input_qty {
    height: 30px;
    font-size: 16px;
    max-width: 48px;
  }

  main.assembling-meal-menu_page .content table tbody tr td .icon {
    width: 29px;
    height: 29px;
  }

  main.assembling-meal-menu_page .content table tbody tr td:nth-child(1) {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: initial;
  }

  main.assembling-meal-menu_page .content table tbody tr td:nth-child(2) {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: calc(100% - 106px);
  }

  main.assembling-meal-menu_page .content table tbody tr td:nth-child(3) {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: 106px;
    border-top: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
  }

  main.assembling-meal-menu_page .content table tbody tr td:nth-child(4) {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: calc(100% - 130px);
    border-top: 1px dashed #a6bcd0;
  }

  main.assembling-meal-menu_page .content table tbody tr td:nth-child(5) {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    width: 130px;
  }

  main.assembling-meal-menu_page_2 h4 {
    font-size: 16px;
    letter-spacing: 0.96px;
  }

  main.assembling-meal-menu_page_2 .content table tr td:nth-child(1) {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: initial;
  }

  main.assembling-meal-menu_page_2 .content table tr td:nth-child(2) {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: 50%;
  }

  main.assembling-meal-menu_page_2 .content table tr td:nth-child(3) {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: 50%;
    border-top: none;
    padding-top: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
  }

  main.assembling-meal-menu_page_2 .content table tr td:nth-child(4) {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: calc(100% - 130px);
    border-top: 1px dashed #a6bcd0;
  }

  main.assembling-meal-menu_page_2 .content table tr td:nth-child(5) {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    width: 130px;
  }

  main.assembling-meal-menu_page_2 .content table tr td .input_qty {
    height: 30px !important;
    font-size: 16px !important;
    max-width: 48px !important;
  }

  main.assembling-meal-menu_page_2 .content table tr td .icon {
    width: 29px !important;
    height: 29px !important;
    margin-bottom: -4px !important;
  }

  main.distributes-portions_page h2.title {
    margin: 0;
  }

  main.distributes-portions_page .extra_data {
    font-size: 16px;
    position: absolute;
    right: 15px;
    top: 66px;
  }

  main.distributes-portions_page .extra_data:nth-child(2) {
    top: 86px;
  }

  main.distributes-portions_page .content .item_block .item {
    padding: 8px 10px;
  }

  main.distributes-portions_page
    .content
    .item_block
    .item
    .bg
    .item_top
    .title,
  main.distributes-portions_page
    .content
    .item_block
    .item
    .bg
    .item_top
    .order_num {
    font-size: 16px;
  }

  main.distributes-portions_page
    .content
    .item_block
    .item
    .bg
    .item_top
    .order_num {
    font-size: 30px;
  }

  main.distributes-portions_page .content .item_block .item .bg .item_bottom {
    padding-bottom: 10px;
  }

  main.distributes-portions_page
    .content
    .item_block
    .item
    .bg
    .item_bottom
    .full_name,
  main.distributes-portions_page
    .content
    .item_block
    .item
    .bg
    .item_bottom
    .wait {
    font-size: 16px;
  }

  main.distributes-portions_page
    .content
    .item_block
    .item
    .bg
    .item_bottom
    .btn_submit {
    height: 24px;
    margin: 10px auto;
  }

  main.order-summary_page .content .order_item .description {
    width: 100%;
    padding: 16px;
  }

  main.order-summary_page .content .order_item .description p {
    font-size: 16px;
  }

  main.order-summary_page .content .order_item .description .title {
    width: 100%;
  }

  main.order-summary_page .content .order_item .description .title h4 {
    max-width: 190px;
  }

  main.order-summary_page .content .order_item .description .title h4,
  main.order-summary_page .content .order_item .description .title p {
    font-size: 16px;
  }

  main.order-summary_page .content .order_item .description .stars {
    position: absolute;
    right: 30px;
  }

  main.order-summary_page .content .order_item .description .stars img {
    width: 14px;
  }

  main.order-summary_page
    .content
    .order_item
    .description
    .order_voucher_num
    p {
    font-size: 40px;
    letter-spacing: 2.4px;
  }

  main.order-summary_page
    .content
    .order_item
    .description
    .order_voucher_num
    p:nth-child(2) {
    font-size: 55px;
    letter-spacing: 3.3px;
  }

  main.order-summary_page .content .change_order {
    font-size: 16px;
  }

  main.order-summary_page .content .change_order span {
    width: 24px;
    height: 24px;
  }

  main.order-summary_page .content .change_order span img {
    width: 14px;
  }

  main.add-opinion_page .content {
    font-size: 16px;
  }

  main.add-opinion_page
    .content
    .opinion_item
    .description
    .opinion_stars
    label {
    width: 38px;
    height: 35px;
    margin-left: 0;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  main.add-opinion_page .content .opinion_item .description textarea {
    font-size: 16px;
    padding: 8px 10px;
  }

  main.add-opinion_page .content .opinion_item .description button {
    max-width: 200px;
    margin-left: calc(50% - 100px);
  }

  main.choosing-dish_page h2.title {
    margin: 0;
    max-width: 150px;
    text-align: left;
  }

  main.choosing-dish_page .calendar_top_btn {
    position: absolute;
    top: 69px;
    right: -15px;
  }

  main.main .content {
    margin-top: 5rem;
  }

  main.main.adding-dose_page .col-md-4:nth-child(1) {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  main.main.adding-dose_page .col-md-4:nth-child(2) {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  main.main.adding-dose_page .col-md-4:nth-child(3) {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  main.main.adding-dose_page .col-md-4:nth-child(4) {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  main.main.adding-dose_page .col-md-4:nth-child(5) {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  main.main.adding-dose_page .col-md-4:nth-child(6) {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  main.main.adding-dose_page .col-md-4:nth-child(7) {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  main.main.adding-dose_page .col-md-4:nth-child(8) {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  main.main.meal-management_page table tr td {
    padding: 10px;
  }

  main.main.meal-management_page table tr td:nth-child(1) {
    width: 35%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
  }

  main.main.meal-management_page table tr td:nth-child(2) {
    width: 20%;
  }

  main.main.meal-management_page table tr td:nth-child(3) {
    width: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
  }

  main.main.meal-management_page table tr td:nth-child(4),
  main.main.meal-management_page table tr td:nth-child(5) {
    width: 50%;
    border-top: 1px dashed #a6bcd0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  main.main.meal-management_page table tr td:nth-child(5) {
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
  }

  main.main.meal-management_page table tr td .more_info {
    padding: 3px 7px;
  }

  main.main.meal-management_page table tr td .more_info img {
    height: 13px;
  }

  main.main.meal-management_page table tr td .stars img {
    width: 14px;
    height: 14px;
    margin: 4px 3px 0px;
  }

  main.main.meal-management_page_2 .content {
    margin-top: 1.6rem;
  }

  main.main.meal-management_page_2 h2.title {
    margin: 1rem 0 0;
  }

  main.main.user-management_page .btn_submit,
  main.main.packet-management_page .btn_submit {
    position: absolute;
    top: -104px;
  }

  main.main.user-management_page table thead {
    display: none;
  }

  main.main.user-management_page table tbody tr td {
    padding: 10px;
    display: block;
  }

  main.main.assembling-meal-menu_page_2 table tbody tr td:nth-child(2) {
    width: calc(100% - 160px);
  }

  main.main.assembling-meal-menu_page_2 table tbody tr td:nth-child(3) {
    width: 160px;
  }

  .nav .items ul li:after {
    display: none;
  }

  .order-voucher .green-border .order-container {
    background: transparent;
  }

  .meal-management .dishes-header h6:nth-child(2),
  .meal-management .add-dishes-table .dish.dish-content section:nth-child(2),
  .meal-management .dishes-header h6:nth-child(7),
  .meal-management .add-dishes-table .dish.dish-content section:nth-child(7) {
    display: none;
  }

  .meal-management .add-dishes-table .dishes-header {
    display: flex;
  }

  .meal-management .add-dishes-table .dishes-header h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2b3147;
  }

  .meal-management .add-dishes-table .dish.dish-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #6d7287;
  }

  .box-layout span img {
    width: 25px;
    height: 25px;
  }

  .control-panel-payment .box-section-details {
    width: 100%;
  }

  .control-panel-payment .order-summary {
    margin: 0;
  }

  .order-summary-section {
    margin-bottom: 15px;
  }

  .control-panel-payment .new-orders-section {
    margin-bottom: 15px;
    padding: 5px;
    height: 54px;
  }
  .control-panel-payment .new-orders-section .new-orders,
  .control-panel-payment .manage-orders a {
    font-size: 14px;
  }

  .order-status-section .order-status-box {
    padding: 10px;
    align-items: center;
  }

  .control-panel .new-one {
    align-items: center;
  }

  .control-panel-payment .total-items h6 {
    font-size: 32px;
    font-weight: 500;
  }
  .control-panel-payment .total-items p {
    line-height: 1.2;
  }

  .control-panel-payment .active-month {
    width: 100%;
    justify-content: space-between;
  }

  .control-panel-payment .grid-layout {
    padding: 0;
  }

  .order-status-section .order-status-box {
    width: calc(35% - 10px);
  }
  .control-panel-payment .dishes-section {
    margin-top: 0;
  }
  .control-panel-payment .income-section {
    justify-content: space-between;
    margin-bottom: 0px;
  }
  .daily-target-head::after {
    content: unset;
  }
  .daily-target-detail a {
    border-radius: 6px;
  }

  .control-panel-payment .pregress-bar-section {
    width: 100%;
  }
  .payment-method.add-link p,
  .payment-method.add-link,
  p.payment-method.without-link {
    color: #14cd31;
    width: fit-content;
    text-decoration: none;
  }
  .swal2-title {
    font-size: 22px !important;
  }
}
.center {
  margin: 0 auto !important;
  padding-left: 60px !important;
}
.text-center {
  text-align: center;
}
@media screen and (max-width: 360px) {
  .nav .items ul li a,
  .nav .items ul li span {
    font-size: 12px;
  }

  .xyz {
    display: block;
  }

  .xyz .from-date {
    max-width: 100% !important;
  }

  .reservation-management .data-filter {
    display: block;
    width: 100%;
  }

  .filter-section {
    width: 100%;
  }

  .employee-management .active-button {
    width: 100%;
  }

  .add-employee-popup .item .input-field {
    width: 100%;
  }

  .MuiBox-root-7 {
    padding: 15px 5px;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    -o-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }

  .animated[class*='Out'] {
    opacity: 0;
  }
}

main {
  .single_item {
    background-color: #f0f4f8;
    border-radius: 8px;
    height: calc(100% + 90px);
    position: relative;
  }
}

.ag-root-wrapper.ag-ltr.ag-layout-auto-height {
  width: 100%;
}

.ag-header-container,
.ag-center-cols-container {
  // margin: 0px auto;
  width: 100%;
}

// @media print{

//   .print-table{
//     margin-top: 0px !important;
//   }

//  }
// .preview-print {
//   margin-left: 5px;
// }
.print-btn button {
  font-size: 20px;
  font-weight: 600;
  padding: 12px 20px !important;
  background: #14cd31;
  border-radius: 40px !important;
  padding: 10px 25px !important;
  min-height: 45px !important;
  color: white;
  text-align: left !important;
  border: 1px solid #14cd31;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    font-size: 14px;
    padding: 5px 16px !important;
  }
}

.languages span {
  text-transform: uppercase;
}

header.header.before-login {
  justify-content: space-between;
  padding: 20px;
}

// .meal-slot-new-popup {
//   opacity: 0;
//   visibility: hidden;
// }

// .meal-slot-new-popup.open-new-popup {
//   opacity: 1;
//   visibility: visible;
//   z-index: 999999;
// }
.meal-slot-new-popup.open-new-popup .add-employee-popup.add-slot-popup {
  display: block;
}

.meal-slot-new-popup .add-employee-popup.add-slot-popup {
  display: none;
}

.bg-modal:before {
  content: '';
  position: fixed;
  background-color: rgba(19, 19, 19, 0.6588235294117647);
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99 !important;
}

.add-slot-popup .MuiBox-root {
  z-index: 99 !important;
}

@media print {
  .print-page-popup {
    width: 100%;
    margin: 0px auto;
  }

  .print-page-popup table {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .print-page-popup .gloal-back-btn {
    display: none !important;
  }

  // body  {
  //   visibility: hidden;
  // }
  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  @page {
    size: a4;
  }

  // #printpreview,
  // #printpreview * {
  //   visibility: visible;
  //   overflow: auto;
  // }
  .add-employee-popup.print-popup .MuiBox-root {
    width: 100% !important;
  }

  #printpreview {
    width: 100% !important;
    margin-top: 0px !important;
  }

  table.print-table tr th {
    font-size: 12px !important;
  }

  table.print-table tr td,
  .print-table tr td span {
    font-size: 12px !important;
  }

  table.print-table tr .mealtype,
  table.print-table tr .mealtype span {
    font-size: 20px !important;
  }

  header.header {
    display: none;
  }

  .sub-header-wrapper {
    display: none;
  }

  .we-footer,
  .print-btn {
    display: none;
  }

  .content-container {
    padding: 0px !important;
  }
}

/********new css ************/
.action-btn .menu-container {
  // position: absolute;
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  position: absolute;
  top: 5px;
  right: 9px;
}

.action-btn .menu-item:hover {
  cursor: pointer;
  background: rgb(240, 240, 240);
}

.action-btn .menu-item.active {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}

.action-btn .menu-item {
  display: block;
  padding: 10px 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
}

.action-btn .menu-item button {
  max-width: 200px !important;
  width: 100% !important;
}

.action-btn .edit-btn {
  padding: 6px 22px;
  margin: 3px;
  color: #fff;
  border: 1px solid #14cd31;
  background: #14cd31;
  border-radius: 24px;
}

.action-btn .delete-btn {
  padding: 6px 18px;
  margin: 3px;
  color: #fff;
  border: 1px solid #cd1414;
  background: #cd1414;
  border-radius: 24px;
}

.user-management i.fa.fa-ellipsis-v {
  padding: 10px 20px;
}

.ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none !important;
}

.copymeal-popup {
  z-index: 99 !important;
}

.hover-popup {
  background-color: white;
  padding: 15px;
  z-index: 99999;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgb(209, 209, 209);
}

.hover-popup p {
  font-size: 13px;
  margin: 6px 0px;
}

.swal2-container {
  z-index: 99999;
}

.desktop .sub-header .title {
  font-size: 20px !important;
}

/****** ahow extra popup **************/
.show-extra-popup .item-card.extras-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  margin-bottom: 15px;
}

.show-extra-popup .item-card.extras-list .extras-dishname img {
  height: 80px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  margin-right: 8px;
}

.show-extra-popup .item-card.extras-list .extras-dishname {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
}

.show-extra-popup .extras-button button {
  padding: 12px 25px;
  margin: 5px;
  border-radius: 5px;
  min-width: 115px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.show-extra-popup .extras-button button:last-child {
  background: #14cd31;
  border: 1px solid #14cd31;
  color: white;
  border-radius: 40px;
}

// .show-extra-popup .extras-button button:first-child {
//   background: #e60505;
//   border: 1px solid #dd1010;
//   color: white;
//   border-radius: 40px;
// }
.show-extra-popup .extras-button.other-button {
  position: absolute;
  background: white;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  right: 0px;
  padding-top: 10px;
}

.show-extra-popup .extras-listing-section {
  position: relative;
  height: 100%;
}

.show-extra-popup .item-card.extras-list {
  border-radius: 5px;
}

.show-extra-popup .extras-meal-management {
  height: 440px;
  overflow: auto;
  padding-right: 10px;
}

.show-extra-popup .extras-meal-management::-webkit-scrollbar {
  width: 5px;
}

.show-extra-popup .extras-meal-management::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.show-extra-popup .extras-meal-management::-webkit-scrollbar-thumb {
  background: #888;
}

.show-extra-popup .extras-button .inventory {
  display: flex;
}

.show-extra-popup input {
  width: 70px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  border: 0;
  border-radius: 3px;
  outline: none;
  background-color: white;
}

.show-extra-popup .ltr .icon-wrapper .we-icon,
.show-extra-popup .ltr .icon-wrapper .we-icon-text {
  margin-left: 0;
}

.show-extra-popup .extras-button p {
  min-width: 70px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  border: 0;
  border-radius: 3px;
  outline: none;
  color: rgb(43, 43, 43);
  margin-bottom: 0px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.show-extra-popup .extras-listing-section h4 {
  text-align: center;
  font-size: 20px;
}

.swal2-container {
  z-index: 99999999 !important;
}

.show-extra-popup .inventory .icon-wrapper.disabled .we-icon {
  opacity: 0.4;
}

.rtl .ag-cell {
  text-align: right !important;
}

// .ag-center-cols-container, .ag-header-container, .ag-header-row.ag-header-row-column{
//   width: 100% !important;
// }
.rtl .show-extra-popup .item-card.extras-list .extras-dishname img {
  margin-right: 0;
  margin-left: 15px;
}

.rtl .show-extra-popup .item-card.extras-list {
  margin-bottom: 15px;
  padding-left: 15px;
}

.order-popup .popup-block .order-details h4 {
  margin-bottom: 8px;
}

.order-popup .popup-block .order-details h6 {
  margin: 0px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 400;
}

.check-payment-popup .extras-meal-management {
  height: auto;
  overflow: auto;
  padding-right: 10px;
}

.check-payment-popup .extras-meal-management p {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.check-payment-popup .extras-button button:first-child {
  background: #14cd31 !important;
  border: 1px solid #14cd31 !important;
}

.check-payment-popup .extras-button {
  display: flex;
  justify-content: center !important;
  margin-top: 45px;
}

.check-payment-popup .extras-listing-section h4 {
  text-align: center;
  font-size: 20px;
}

.check-payment-popup .extras-button button {
  padding: 12px 25px;
  margin: 5px;
  border-radius: 5px;
  min-width: 115px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: white;
  border-radius: 40px;
}

.rtl .ag-rtl .ag-body,
.rtl .ag-rtl .ag-floating-top,
.rtl .ag-rtl .ag-floating-bottom,
.rtl .ag-rtl .ag-header,
.rtl .ag-rtl .ag-body-viewport,
.rtl .ag-rtl .ag-body-horizontal-scroll {
  flex-direction: initial !important;
}

.rtl .ag-theme-alpine .ag-rtl .ag-pinned-left-header .ag-header-row::before,
.rtl .ag-theme-alpine .ag-ltr .ag-pinned-right-header .ag-header-row::after {
  content: '';
  position: absolute;
  height: calc(100% - 20px);
  top: 10px;
  width: 1px;
  background-color: #babfc7;
  background-color: transparent;
}

.chart-wrapper p {
  margin: 50px 0px;
  font-size: 19.2px;
  text-align: center;
}

.panel-chart .chart-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forget-popup .reset-form {
  padding: 25px 20px;
}

.forget-popup .reset-form .input-field {
  margin-bottom: 20px;
}

.forget-popup .reset-form .button-wrapper button {
  width: 100%;
  max-width: 100% !important;
}

.MuiBox-root {
  outline: none;
  box-shadow: none;
}

.desktop .sub-header .user-name {
  flex: 1.2 1;
  max-width: 250px;
}

.desktop .sub-header .kitchens-list {
  max-width: 250px;
}

.sub-header-wrapper .sub-header .kitchens-list .kitchen-arrow {
  margin-left: 15px;
}

.sub-header-wrapper .sub-header .kitchens-list .list-item {
  padding: 10px;
}

.reservation-management .filter-section .from-date {
  display: grid;
  align-items: center;
  margin-left: 0;
  min-width: 100px;
  max-width: 210px;
  width: 100%;
}

.employee-management .data-filter {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.employee-management .xyz .button-wrapper {
  justify-content: flex-end !important;
  margin-top: 20px;
}

.employee-management .xyz label {
  font-size: 17px;
  text-align: left;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
}

.employee-management .xyz input {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 15px;
  color: black;
  cursor: pointer;
  min-height: 38px;
  background-color: transparent !important;
}

.employee-management .xyz .from-date {
  display: grid;
  align-items: center;
  margin-left: 20px;
  max-width: 210px;
  width: 100%;
}

.employee-management .xyz .from-date:first-child {
  margin-left: 0;
}

.employee-management .reservation-csv {
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 20px;
  // display: flex;
  //   justify-content: center;
  //   align-items: center;
}

.card-checkbox {
  top: 10px;
  right: 10px;
  position: absolute;
  height: 19px;
  width: 18px;
  border-radius: 4px;
  margin: 0;
  appearance: none;
  border: 1px solid #14cd30;
  background: #fff;
  cursor: pointer;
}

.card-checkbox:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  font-family: FontAwesome;
  color: #fff;
  font-size: 15px;
}

.card-checkbox:checked:before {
  background: #14cd30;
  content: '\f00c';
  border: 1px solid #14cd30;
}

/*********** cafeteria section css *********/
.cafeteria-dishes-section .button-wrapper {
  margin-top: 40px;
}

.cafeteria-dishes-section .card-checkbox {
  height: 28px;
  width: 28px;
}

.cafeteria-dishes-section .card-checkbox:before {
  font-size: 24px;
}

.cafeteria-order-card .dishImage {
  height: 100px;
  width: 150px;
}

.cafeteria-order-card {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
}

.cafeteria-order-card .order-details {
  width: calc(100% - 200px);
  margin-left: 50px;
}

.cafeteria-order-card section.section1.dash-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: navajowhite;
}

section.section1.dash-bottom h4 {
  margin: 0;
}

.cafeteria-order-card .dishname p {
  text-align: left;
}

.cafeteria-total-section {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  border-top: 1px dashed #b5b5b5;
  padding-top: 30px;
}

.cafeteria-total-section span {
  font-weight: 600;
  margin-right: 10px;
  font-size: 20px;
}

// .cafeteria-dishes-list {
//   width: 30%;
//   max-width: 350px;
// }
// .cafeteria-dishes-list .dish-single-card {
//   width: 100% !important;
//   max-width: 100% !important;
// }
.total-ordered-dishes h4 {
  margin: 0px;
}

.total-ordered-dishes {
  margin: 25px 0px;
}

.cafeteria-order-wrapper .cafeteria-title {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  text-align: left;
  margin-bottom: 50px;
}

.cafeteria-order-wrapper .cafeteria-title p {
  font-weight: 600;
}

.cafeteria-ordered-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cafeteria-ordered-list .cafeteria-order-card {
  width: 48%;
  background: #ffffff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #dedede;
  align-items: flex-start;
}

.cafeteria-ordered-list .cafeteria-order-card .order-details {
  margin-left: 20px;
}

.cafeteria-ordered-list .cafeteria-order-card .order-details h4 {
  font-weight: 600;
  font-size: 21px;
  margin-bottom: 10px;
}

.terminal-no {
  margin-left: 20px;
}

.item.blocked-listing.mealtype-cafeteria {
  background: #f5dfbf;
  position: relative;
}

.cafe-img {
  position: absolute;
  right: 0px;
  z-index: 9999999999;
  top: 3px;
}

.cafe-img img {
  height: 36px;
}

.payment-method {
  color: #14cd31;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  display: flex;
}
.payment-method {
  color: #14cd31;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.icon-wrapper.disabled {
  opacity: 0.5 !important;
}
.card-checkbox {
  top: 10px;
  right: 10px;
  position: absolute;
  height: 19px;
  width: 18px;
  border-radius: 4px;
  margin: 0;
  appearance: none;
  border: 1px solid #14cd30;
  background: #fff;
  cursor: pointer;
}
.card-checkbox:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  font-family: FontAwesome;
  color: #fff;
  font-size: 15px;
}
.card-checkbox:checked:before {
  background: #14cd30;
  content: '\f00c';
  border: 1px solid #14cd30;
}

/*********** cafeteria section css *********/
.cafeteria-dishes-section .button-wrapper {
  margin-top: 40px;
}
.cafeteria-dishes-section .card-checkbox {
  height: 28px;
  width: 28px;
}
.cafeteria-dishes-section .card-checkbox:before {
  font-size: 24px;
}

.cafeteria-order-card .dishImage {
  height: 100px;
  width: 150px;
}

.cafeteria-order-card {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
}

.cafeteria-order-card .order-details {
  width: calc(100% - 200px);
  margin-left: 50px;
}

.cafeteria-order-card section.section1.dash-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: navajowhite;
}

section.section1.dash-bottom h4 {
  margin: 0;
}
.cafeteria-order-card .dishname p {
  text-align: left;
}
.cafeteria-total-section {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  border-top: 1px dashed #b5b5b5;
  padding-top: 30px;
}
.cafeteria-total-section span {
  font-weight: 600;
  margin-right: 10px;
  font-size: 20px;
}
// .cafeteria-dishes-list {
//   width: 30%;
//   max-width: 350px;
// }
// .cafeteria-dishes-list .dish-single-card {
//   width: 100% !important;
//   max-width: 100% !important;
// }
.total-ordered-dishes h4 {
  margin: 0px;
}

.total-ordered-dishes {
  margin: 25px 0px;
}
.cafeteria-order-wrapper .cafeteria-title {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  text-align: left;
  margin-bottom: 50px;
}

.cafeteria-order-wrapper .cafeteria-title p {
  font-weight: 600;
}
.cafeteria-ordered-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cafeteria-ordered-list .cafeteria-order-card {
  width: 48%;
  background: #ffffff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #dedede;
  align-items: flex-start;
}

.cafeteria-ordered-list .cafeteria-order-card .order-details {
  margin-left: 20px;
}

.cafeteria-ordered-list .cafeteria-order-card .order-details h4 {
  font-weight: 600;
  font-size: 21px;
  margin-bottom: 10px;
}
.item.blocked-listing.mealtype-cafeteria {
  background: #f5dfbf;
  position: relative;
}
.cafe-img {
  position: absolute;
  right: 0px;
  z-index: 9999999999;
  top: 3px;
}

.cafe-img img {
  height: 36px;
}
.icon-wrapper.disabled {
  opacity: 0.5 !important;
}
.terminal-no {
  margin-left: 20px;
}
.swal2-styled:focus {
  box-shadow: none !important;
}
.toggle-mealtype {
  span {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .toggle-btn {
    min-width: 45px !important;
    min-height: 24px;
    border-radius: 14px !important;
    border: 0 !important;
    background: #fff !important;
    border: 2px solid #c9e8ce !important;
    position: relative;
    transition: all ease-in 0.1s;

    span {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 2.2px;
      border-radius: 50%;
    }
  }
}

.icon-wrapper.disabled {
  opacity: 0.5 !important;
}

.toggle-mealtype {
  align-items: flex-start;
  justify-content: center;
}

.toggle-mealtype span {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}

.toggle-btn {
  min-width: 60px !important;
  min-height: 30px;
  border-radius: 14px !important;
  border: 0 !important;
  background: #fff !important;
  border: 2px solid #c9e8ce !important;
  position: relative;
  transition: all ease-in 0.1s;
}

.toggle-btn span {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 1px;
  border-radius: 50%;
}

.toggle-btn.toggle-btn-off span {
  left: 1px;
  background: #c9e8ce;
}

.toggle-btn-on {
  background: #fff !important;
  border: 2px solid #14cd31 !important;
  cursor: pointer;
}

.toggle-btn.toggle-btn-on span {
  left: unset;
  right: 2px;
  background: #14cd31;
}
.rtl .close-btn img {
  left: 0px;
  right: inherit;
}

// Wallet page NEw CSS

.wallet-page {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #6d7287;
    text-transform: capitalize;
    margin-bottom: 30px;
  }
  .icon-wrapper {
    .we-icon {
      &.arrowIcon {
        background: transparent;
        width: fit-content !important;
        height: fit-content !important;
        margin: 0 !important;
      }
    }
    .we-icon-text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #23283c;
      margin: 0px !important;
    }
  }
}

.rtl {
  .wallet-page {
    .icon-wrapper {
      .we-icon-text {
        margin: 0 !important;
      }
    }

    .ag-root-wrapper {
      .ag-paging-panel {
        justify-content: flex-start;
        // flex-direction: row-reverse;
      }
    }
  }
}

.reservation-management .filter-section .select-section {
  margin-left: 20px;
}
.select-section-label {
  font-size: 13px;
  text-align: left;
}
.reservation-management .filter-section .select-section button.search-button {
  margin: 0;
}

// .reservation-management .searchBtn.search-input {
//   display: flex;
//   gap: 10px;
//   max-width: 347px;
//   align-items: center;
// }
.reservation-management.global-data-table.reservation-updated-page
  button.search-button {
  margin: 0;
  margin-top: 26px;
}

.employee-management-page .payment-method,
.user-management-table .payment-method {
  text-decoration: none;
}

.rtl {
  header {
    .lang-login-dropdown .dropdown {
      .dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }
      .dropdown-menu {
        min-width: 100px;
      }
    }
  }
  header {
    .lang-login-dropdown .dropdown {
      .dropdown-menu {
        min-width: 100px;
        padding: 2px 0px;
      }
    }
  }

  .rememmber-me .we-checkbox img {
    margin-right: 0;
    margin-left: 10px;
  }
  .privacy-text a {
    margin-left: 15px;
    margin-right: 0;
  }
}

.orderRealise-check-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #14cd30;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  background: transparent;
  font-size: 16px;
  color: #14cd30;
  &:hover,
  &:focus,
  &:focus-within {
    opacity: 0.9;
  }
  &:disabled {
    pointer-events: none;
    &:hover,
    &:focus,
    &:focus-within {
      opacity: 1;
    }
  }
  &.realized {
    background: #14cd30;
    i {
      color: #fff;
    }
  }
}

.paymentStatus-section {
  display: flex;
  flex-direction: column;
}
.order-status {
  display: flex;
  gap: 5px;
  align-items: center;
  i {
    font-size: 6px;
    border-radius: 50%;
  }
  &.success {
    color: #38bb94;
    i {
      color: #38bb94;
      background: #38bb94;
    }
  }
  &.pending {
    color: #ffa030;
    i {
      color: #ffa030;
      background: #ffa030;
    }
  }
  &.failed {
    color: #f13c47;
    i {
      color: #f13c47;
      background: #f13c47;
    }
  }
}
.copy-previous-meal.global-data-table .add-employee-btn {
  margin: 20px 0;
}
.ag-body-horizontal-scroll.ag-scrollbar-invisible {
  display: none;
}
.copy-previous-meal-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 630px;
  width: 100%;
}
.copy-previous-meal .filter-section {
  width: fit-content;
}
.copy-previous-meal.global-data-table .filter-section select {
  padding-left: 12px !important;
}
.copy-previous-meal.global-data-table .search-input img {
  top: 12px;
}
.review-btn button span.wallet-icon {
  display: none;
}

.view-more-popup .MuiBox-root {
  max-height: fit-content;
  height: fit-content;
  // rtl CSS
}
.view-more-popup-dishes .MuiBox-root {
  min-height: 900px;
  height: fit-content;
}
.rtl {
  .back-link-section img {
    transform: rotate(180deg);
  }
  .copy-url p {
    text-align: center !important;
  }
  .confirmation-btn {
    text-align: center !important;
  }
  .preloader .block_logo {
    text-align: center !important;
  }
  .preloader .block_logo .logo__description,
  .preloader .block_logo .logo__text {
    text-align: center !important;
  }
  .privacy-text a {
    margin-right: 0px;
    margin-left: 15px;
  }
  button.search-button img {
    transform: rotate(90deg);
  }

  .log-json-data .close-btn {
    margin-right: auto;
    margin-left: unset;
  }
  .action-btn .menu-container {
    right: 0;
  }
  .reservation-management .filter-section .select-section {
    margin-right: 20px;
  }
  .reservation-management.global-data-table
    .add-employee-btn
    .active-button
    .actions
    img {
    margin-right: 0;
  }
  .review-page .select-add-review .right-review-section {
    margin-right: 0;
  }
  @media screen and (max-width: 480px) {
    .reservation-management .filter-section .select-section {
      margin-right: 0;
    }
  }
}
table.print-table tr td span {
  padding: 0px !important;
}
table.print-table.print-first.table,
table.print-table.print-first.table tr td {
  border: none !important;
}
table.print-table.print-first.table td {
  text-align: center !important;
}
@media screen and (max-width: 767px) {
  table.print-table tr th,
  .print-table tr td {
    font-size: 12px !important;
  }
  .print-table tr td span:first-child {
    font-weight: 800;
  }
  .print-table tr td span:last-child {
    font-size: 11px;
    line-height: initial;
  }
  table.print-table tr td:first-child {
    width: 50% !important;
    white-space: nowrap;
  }
  .box-section {
    white-space: nowrap;
  }
  .box-section p,
  .box-section p span {
    font-size: 12px !important;
  }
  .add-new-slot .MuiBox-root {
    padding: 12px;
  }
  .add-new-slot .item {
    margin-top: 10px !important;
  }
  .add-new-slot .item .input-field label {
    margin-bottom: 0px !important;
  }
  .add-new-slot .add-btn {
    margin-top: 10px;
  }
  .add-new-slot table thead {
    height: 35px;
  }
  .add-new-slot .addft-btn {
    margin-bottom: 10px;
  }
  .add-new-slot p.error-msg {
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .review-btn button span.wallet-icon {
    display: block;
  }
  .review-btn button span.wallet-icon i {
    font-size: 16px;
  }
  .review-btn button span.wallet-text {
    display: none;
  }
}
