@import 'global_variables';

/* Dish Component Styling */
.meal-time {
  font-size: 20px;
  @media (max-width: $tablet-max) {
    font-size: 16px;
  }

  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .wrapper {
      padding-top: 20px;
      display: flex;
      justify-content: start;
      flex-direction: column;
      flex-wrap: wrap;

      @media (max-width: $tablet-max) {
        &:last-child {
          margin-top: 1rem;
        }
        align-items: center;
      }

      .title {
        font-size: 23px;
        font-weight: 600;
        text-align: center;
        border-bottom: 1px dashed #a6bcd0;
        padding-bottom: 10px;

        @media (max-width: $tablet-max) {
          width: 80%;
          cursor: pointer;
        }

        .show-on-mobile {
          display: none;
          @media (max-width: $tablet-max) {
            display: inline;
          }
        }

        .meal-type {
          min-height: 30px;
        }
      }

      .meal-type {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;

        .option-wrapper {
          font-size: 22px;
          width: 100%;
          flex: 1;

          padding: 30px 20px;
          @media (max-width: $tablet-max) {
            padding: 15px 20px;
          }

          &.disabled {
            color: #a9a9a9;
            pointer-events: none;
          }

          .option {
            border-style: none;
            border-radius: 7px;
            cursor: pointer;
          }
          .active {
            color: #475058;
            border-color: rgb(20, 205, 49);
            border-width: 1px;
            border-style: solid;
            padding: 15px 20px;

            background: rgba(123, 237, 141, 0.67);
            font-weight: 700;
          }
        }
      }
    }
  }

  .bottom {
    padding-top: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .we-button.active {
      font-size: 24px;
    }
  }

  .placeholder {
    font-size: 0.8em;
    margin: 10px 20px;
    color: $lighter-black;
  }
}

.mobile {
  .meal-time {
    display: flex;
    flex-direction: column;

    .content {
      flex-direction: column;
    }
  }
}
