@import 'global_variables';

/* Dish Component Styling */
.dishes-container {
  display: flex;
  justify-content: center;

  font-size: 20px;
  @media (max-width: $dish-medium-max-screen) {
    font-size: 16px;
  }

  .dish-cards {
    margin: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    width: 100%;

    .dish-single-card {
      background-color: $cards-background-color;

      &.current-selected {
      }

      border-radius: 8px;
      margin: 1.2rem;

      display: flex;
      flex-direction: column;

      &.pointer {
        cursor: pointer;
        position: relative;
      }

      /* Media queries */
      max-width: 350px;
      // On large screens show three dishes
      @media (min-width: $dish-large-min-screen) {
        width: 30%;
      }

      // On medium screens show two dishes
      @media (max-width: $dish-medium-max-screen) {
        width: 45%;
      }

      // On small screens show one dish
      @media (max-width: $dish-small-max-screen) {
        width: 100%;
      }

      .dish-img {
        height: 115px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 6px;

        .chef-mealDish {
          width: 60px;
          border: 3px solid black;
          border-radius: 80px;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }

      .dish-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1em;
        flex: 1;

        .section1 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1;

          .dish-name {
            font-size: 20px;
            font-weight: bold;
            word-wrap: break-word;
          }

          .dish-category {
            font-size: 14px;
          }
        }

        .rating-wrapper {
          max-width: 40%;
          justify-self: flex-end;

          @media (max-width: $tablet-max) {
            max-width: 60%;
          }
        }
      }

      .dish-text {
        text-align: start;
      }

      .dish-mealType {
        font-size: 14px;
        font-weight: lighter;
      }

      /* Dish Buttons */
      .button-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 20px;

        .text {
          font-size: 0.8em;
        }
      }
    }
  }
}
