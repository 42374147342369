@import 'global_variables';

.control-panel {
  margin-top: 20px;

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-gap: 20px;

    @media (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      margin-bottom: 10px;
    }

    .panel-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 991px) {
        width: 100%;
      }

      @media (max-width: $tablet-max) {
        grid-column: span 2;
      }

      background-color: $light-gray;
      opacity: 0.8;
      background-size: cover;

      border-radius: 8px;
      padding: 20px 0;

      .title {
        margin: 0;
      }

      .content {
        display: flex;
        flex: 1;
        width: 100%;
        justify-content: space-around;
        align-items: center;

        p {
          font-size: 0.8em;

          &.chart-content {
            position: relative;

            &.up {
              bottom: -30px;
            }

            &.down {
              top: -30px;
            }
          }
        }

        .part1,
        .part2 {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .action-btn {
        margin-top: 20px;
      }
    }

    .panel-chart {
      background-color: $light-gray;
      padding: 20px;
      border-radius: 8px;
      grid-column: span 2;

      h4 {
        margin: 0;
      }
    }
  }
}

.panel-chart-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;

  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}

.panel-chart-section .from-date label {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.panel-chart-section .from-date {
  display: grid;
  justify-content: flex-start;
  margin-right: 15px;
}

.panel-chart-section .from-date input {
  height: 38px;
  border-radius: 5px;
  border: 1px solid #e4e1e1;
  color: #464646;
  padding: 10px;
  font-family: 'OpenSans';
}

.panel-chart-section .filter-section {
  margin-bottom: 0px;
}

.panel-chart-section .xyz {
  justify-content: flex-end;
}

.control-panel .grid .panel-card .content {
  flex-direction: column;
}

.control-panel .button-wrapper .we-button {
  font-size: 15px !important;
}

.control-panel-payment .grid-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
  gap: 10px 0;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
}

.control-panel-payment .box-layout span {
  width: 85px;
  height: 85px;
  background: #f4fdf5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right: 15px;
  @media (max-width: 1220px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    padding: 16px 12px;
  }
}

.control-panel-payment .box-layout {
  max-width: 245px;
  width: 100%;
  height: 146px;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(82, 63, 105, 0.05);
  border-radius: 8px;
  padding: 13px 20px;

  @media (max-width: 1220px) {
    height: fit-content;
    max-width: calc(25% - 20px);
  }
  @media (max-width: 768px) {
    max-width: calc(50% - 14px);
  }
}
.box-layout-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 10px;
}

.control-panel-payment .total-items h6 {
  font-weight: 400;
  font-size: 39px;
  line-height: 48px;
  color: #000000;
  margin: 0;
}

.control-panel-payment .total-items p,
.control-panel-payment .box-layout p {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-transform: uppercase;
  color: #7e7e7e;
  margin: 0;
}

.control-panel-payment .box-section-details {
  // width: 48%;
  max-width: 570px;
  width: 100%;
  padding: 20px 10px;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(82, 63, 105, 0.05);
  border-radius: 8px;
  padding-top: 0;

  @media (max-width: 991px) {
    width: 100%;
  }
}
.control-panel-payment .grid-revenue-layout-left {
  max-width: 570px;
  width: 100%;
  padding: 20px 10px;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(82, 63, 105, 0.05);
  border-radius: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 10px;
  }
}
.control-panel-payment .grid-revenue-layout-right {
  max-width: calc(100% - 586px);
  width: 100%;
  padding: 20px 10px;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(82, 63, 105, 0.05);
  border-radius: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
}
.control-panel-payment .grid-revenue-layout-right .active-month {
  max-width: 102px;
  width: 100%;
  height: 39px;
}
.control-panel-payment .grid-revenue-layout-right .active-month button {
  background: #f4fdf5;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #2bc155;
}

.order-summary-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 60px;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }

  .order-summary {
    @media (max-width: 768px) {
      width: 55%;
    }
  }
  .active-month {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.control-panel-payment .grid-revenue-alyout {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 10px;
  }
}

.control-panel-payment .active-month {
  display: flex;
  align-items: center;
  height: 46px;
  background: #f4fdf5;
  border-radius: 6px;
}

.control-panel-payment .active-month button {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  color: #000000;
  background: #ffffff;
  border-radius: 6px;
  border: none;
}
.control-panel-payment .active-month button:hover {
  background: #ffffff;
  color: #000000;
}

.control-panel-payment .order-summary h6 {
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  text-align: left;
  margin-bottom: 8px;
  @media (max-width: 991px) {
    font-weight: 500;
    margin: 0;
    padding-bottom: 8px;
  }
}

.control-panel-payment .order-summary p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7e7e7e;
  margin: 0;

  @media (max-width: 768px) {
    text-align: left;
    display: none;
  }
}
.rtl {
  .control-panel-payment .order-summary p {
    @media (max-width: 768px) {
      text-align: right;
    }
  }
  .control-panel .new-one {
    @media (max-width: 768px) {
      align-items: flex-start;
    }
  }
}

// .control-panel-payment .order-summary {
//   margin-right: 24px;
// }

.control-panel-payment .new-orders-section {
  display: flex;
  padding: 16px 17px;
  gap: 15px;
  height: 75px;
  background: #e7faec;
  border-radius: 12px;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #3d4465;
  margin-bottom: 60px;
}

.control-panel-payment .new-orders-section .new-orders span {
  width: 80px;
  height: 43px;
  background: #2bc155;
  border: 1px solid #2bc155;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 21px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  // margin-right: 15px;
}

.control-panel-payment .new-orders-section .new-orders {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.control-panel-payment .manage-orders a {
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2f4cdd;
}
.dish-progressbar,
.order-progressbar,
.other-progressbar {
  max-width: 115px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.dish-progressbar span,
.order-progressbar span,
.other-progressbar span {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #7e7e7e;
}
.dish-progressbar .bg-success {
  background: #ff6d4d !important;
}
.order-progressbar .bg-success {
  background: #2bc156 !important;
}
.other-progressbar .bg-success {
  background: #404a56 !important;
}
.control-panel-review-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.control-panel-review-chart {
  max-width: calc(100% - 438px);
  width: 100%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
}
.daily-target-section {
  max-width: 420px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(82, 63, 105, 0.05);
  border-radius: 8px;
  padding: 20px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.daily-target-head {
  display: flex;
  gap: 10px;
  padding-bottom: 15px;
  position: relative;
  justify-content: center;
}
.daily-target-head::after {
  content: '';
  position: absolute;
  width: 206px;
  height: 1px;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
  background: #b6b6b6;
}
.daily-target-head span {
  background: #20c997;
  border-radius: 2px;
  width: 9px;
}
.daily-target-heading h6 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  margin: 0;
  padding-bottom: 9px;
}
.daily-target-heading p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7e7e7e;
  margin: 0;
  text-align: left;
}
.daily-target-progress {
  max-width: 150px;
  width: 100%;
  margin: 30px auto;
}
.daily-target-progress .CircularProgressbar-path {
  stroke: #ff5c5a !important;
  stroke-width: 10px;
}
.daily-target-progress .CircularProgressbar-trail {
  stroke: #f8f8f8 !important;
  stroke-width: 10px;
}
.daily-target-progress .CircularProgressbar-text {
  fill: #000000 !important;
}
.daily-target-footer {
  max-width: 240px;
  width: 100%;
  margin: 0px auto;
  margin-top: 20px;
}
.today-amnt p {
  font-weight: 400;
  font-size: 28px;
  line-height: 39px;
  text-align: center;
  color: #000000;
  margin: 0;
  padding-bottom: 15px;
}
.target-amnt p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #7e7e7e;
  margin: 0;
  padding-bottom: 15px;
}
.daily-target-status p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #7e7e7e;
  margin: 0;
  padding-bottom: 15px;
}
.daily-target-detail a {
  border: 1px solid #20c997;
  border-radius: 2px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #20c997;
  padding: 9px 18px;
  text-decoration: none;
  display: inline-block;
}
.order-status-section {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.order-status-section .order-status-box {
  width: 138.8px;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  padding: 14px 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: calc(33% - 10px);
  }
}

.order-status-section .order-status-box h6 {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #3d4465;
}

.order-status-section .order-status-box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7e7e7e;
  margin: 0;
  @media (max-width: 768px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}

.control-panel-payment .chart-list {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7e7e7e;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.control-panel-payment .chart-section {
  width: 45%;
  position: relative;
}
.control-panel-payment .pregress-bar-section {
  width: 55%;
}

.control-panel-payment .dishes-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.control-panel-payment .chart-list .progress {
  width: 100%;
  height: 10px;
}

.control-panel-payment .panel-chart-section .from-date input {
  font-size: 14px;
}

.control-panel-payment .income-section {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
  gap: 54px;
}

// .control-panel-payment .income-section .income-box {
//   margin-right: 54px;
// }

.control-panel-payment .income-section p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6e6e6e;
  margin: 0;
  text-align: left;
}

.control-panel-payment .income-section h6 {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #3d4465;
}

.rtl {
  .control-panel-payment .order-summary h6 {
    text-align: right;
  }
}
