/********** profile wrapper css **************/
.profile-wrapper {
  padding-bottom: 50px;
}
.profile-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 48px;
}
.employee-payment-management .form .upper-placeholder {
  font-size: 14px;
}
.employee-payment-management .icon-wrapper .we-icon {
  margin: 0 12px;
}
.profile-sidebar {
  padding: 50px 5px;
  min-height: 600px;
  height: 100%;
  width: 250px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.profile-sidebar .nav {
  height: 100%;
  min-height: 100%;
  width: 100% !important;
}
.profile-content {
  width: calc(100% - 298px);
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  /* margin-left: 48px; */
  padding: 50px 20px;
  max-width: 800px;
}
.profile-menu .nav a {
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  color: #6d7287;
  margin-bottom: 5px;
  display: flex;
  width: 100%;
  padding: 18px 10px;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
}

.profile-menu .nav a:hover {
  background: #e5ffe9;
  color: #14cd30;
  text-decoration: none;
  cursor: pointer;
}

.profile-menu .nav a.active {
  background: #e5ffe9;
  color: #14cd30;
  text-decoration: none;
}

.profile-menu .nav a img {
  margin-right: 10px;
}

.profile-page-content {
  padding: 30px 40px;
  border-radius: 0px 0px 3px 3px;
  height: calc(100% - 58px);
  max-width: 550px;
  width: 100%;
  margin: 0px auto;
}

.profile-page {
  height: 100%;
}
.profile-menu .nav a i {
  margin-right: 12px;
  font-size: 17px;
}

/********** profile page ********/
.profile-wrapper .employee-payment-management .form {
  width: 100%;
}
.profile-wrapper p.form-explanation {
  display: flex;
  margin-top: 0 !important;
  font-weight: 300;
  font-size: 14px;
  line-height: 34px;
  color: #8289a3;
  margin-bottom: 25px;
}
.profile-wrapper h6.heading {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: #23283c;
}
.profile-wrapper .employee-payment-management .form .input-group .label-input {
  width: 100%;
}
.profile-wrapper .employee-payment-management .checkbox-full {
  width: fit-content;
}
.profile-wrapper .employee-payment-management .we-checkbox.keep-logged p {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #23283c;
}

.profile-wrapper .employee-payment-management .we-checkbox img {
  background-color: #14cd30;
}
.employee-payment-management .form .input-group .label-input .input-section p {
  margin: 0;
  line-height: initial;
}
.profile-wrapper .form .input-group .label-input {
  padding: 5px 0px;
}
/******* settigns page ************/
/********* setting csss **********/
.settings-btn {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 25px;
  gap: 10px;
}
.settings-content {
  max-width: 440px;
  width: 100%;
}
.settings-content .form-group {
  margin-bottom: 25px !important;
}
.settings-content .form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
  width: 100%;
  text-align: left;
  margin-bottom: 0;
}
.rtl .settings-content .form-label {
  text-align: right;
}

.settings-page .form-input {
  position: relative;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.settings-page .form-input img {
  position: absolute;
  top: 14px;
  left: 20px;
}

.settings-page .form-input .form-control::placeholder {
  color: #495766;
}
.profile-wrapper .settings-btn button:last-child,
.payment-management-page .settings-btn button:last-child {
  padding: 12px 10px;
  min-width: 82px;
  height: 48px;
  background: #f8f9fa !important;
  border: 1px solid #f8f9fa;
  border-radius: 6px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #212529;
}
.profile-wrapper .settings-btn button:first-child,
.payment-management-page .settings-btn button:first-child {
  padding: 12px 24px;
  min-width: 82px;
  height: 48px;
  background: #14cd30 !important;
  border-radius: 6px;
  border: 1px solid #14cd30;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 24px; */
  text-align: center;
  color: #ffffff;
  /* margin-right: 10px; */
}
.profile-wrapper .page-heading h3 {
  text-align: left;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: #212529;
}
.rtl .profile-wrapper .page-heading h3 {
  text-align: right;
}
.settings-content input,
.settings-content input:hover,
.settings-content input:focus {
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0px;
  padding-left: 0px;
  outline: none;
  box-shadow: none;
}
.settings-content input::placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
}
.settings-content .form-group p {
  margin: 0;
  text-align: left;
  margin-top: 5px;
}
/*********** payment management ***********/
.payment-management-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.payment-management-form-section {
  max-width: 310px;
  width: 100%;
}
.cibus-error-table {
  max-width: calc(100% - 350px);
  width: 100%;
}
.payment-management-page form.form h6 {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  line-height: 21px;
  color: #212529;
}

.payment-management-page form.form {
  margin-top: 25px;
}
.payment-management-page .payment-check-option label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.payment-management-page .payment-check-option input {
  height: 15px;
  width: 15px;
}
.payment-management-page
  .payment-check-option
  input[type='radio']:checked:after {
  top: 2px;
  left: 2px;
  padding: 3.5px;
}
.profile-wrapper .page-heading p {
  font-weight: 400;
  font-size: 14px;
  color: #7e7e7e;
  text-align: left;
}

/*********** security-page *********/
.security-page .form .input-group .label-input input::placeholder,
.security-page .form .input-group .label-input input {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
}
.security-page .form .input-group .label-input .icon {
  display: none;
}
.profile-wrapper .form .input-group .label-input .input-section p {
  margin: 0;
  line-height: initial;
  color: #f13c47;
  font-size: 16px;
}
.profile-wrapper .form .input-group .label-input .icon {
  height: 16px;
  width: 16px;
}
.profile-wrapper .form .input-group .label-input .input-section {
  padding: 0px;
  border-bottom: 1px solid #9e9e9e;
  align-items: center;
}
.profile-wrapper .form .input-group .label-input {
  min-height: 44px;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.profile-wrapper .form .upper-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
  padding: 0;
}
.profile-wrapper .form .input-group .label-input > div {
  width: 100%;
  text-align: left;
  justify-content: flex-start;
}
.profile-wrapper .form .input-group .label-input .input-field {
  font-size: 14px !important;
  padding-left: 10px;
}
.profile-wrapper .we-checkbox .unchecked,
.profile-wrapper .we-checkbox img {
  margin-left: 0px !important;
}
.rtl .profile-wrapper .we-checkbox .unchecked,
.profile-wrapper .we-checkbox img {
  margin: 0 0 0 10px !important;
}
@media screen and (max-width: 991px) {
  .profile-content {
    width: 100%;
    margin-left: 0;
    max-width: 100%;
    padding: 15px 20px;
  }
  .profile-grid {
    gap: 0;
  }
  .profile-sidebar {
    padding: 10px 0px;
    min-height: auto;
    width: 100%;
    background: transparent;
    box-shadow: none;
  }
  .profile-sidebar .nav {
    width: 100% !important;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .profile-menu .nav a {
    font-size: 13px;
    padding: 12px 5px;
    justify-content: center;
    width: 30%;
  }
  .profile-wrapper .form .input-group .label-input .input-field::placeholder {
    font-size: 12px;
  }
  .profile-wrapper .signup .form .inputs-wrapper .input-field,
  .profile-wrapper .profile .form .inputs-wrapper .input-field {
    font-size: 12px;
  }
  .employee-payment-management .form p.form-explanation {
    margin: 0;
    line-height: initial;
    font-size: 12px;
    margin-bottom: 24px;
  }
  .profile-wrapper .employee-payment-management .we-checkbox.keep-logged p {
    text-align: left;
  }

  .profile-wrapper .settings-btn button,
  .payment-management-page .settings-btn button {
    height: 40px !important;
    line-height: 14px !important;
  }
  .profile-wrapper .page-heading h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .payment-management-form-section {
    max-width: 100%;
  }
  .cibus-error-table {
    max-width: 100%;
  }
}
.rtl .profile-wrapper .settings-btn button,
.rtl .payment-management-page .settings-btn button {
  text-align: center !important;
}
.rtl .payment-management-page .payment-check-option input {
  margin-right: 0px;
  margin-left: 10px;
}
