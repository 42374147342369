@import '../global_variables';

.add-dish-to-meal {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 20px;

  @media (max-width: $tablet-max) {
    font-size: 16px;
  }

  .top {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .search {
      display: flex;
      flex: 1;
      @media (max-width: $mobile-max) {
        flex: unset;
      }

      .input {
        width: 40%;

        background-color: $light-gray;
        border: 0;
        border-radius: 8px;
        outline: none;

        font-size: 1em;
        text-align: start;
        padding: 10px 20px;
      }
    }
  }

  .dish {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 20px 0;

    @media (max-width: $tablet-max) {
      flex-direction: column;
    }

    background-color: $light-gray;
    border-radius: 8px;

    .image {
      flex: 1;
      min-width: 100px;

      @media (max-width: $tablet-max) {
        display: none;
      }
    }

    .section1 {
      display: flex;
      flex: 3;
      width: 100%;
      justify-content: space-between;
      padding: 20px 30px;

      @media (max-width: $tablet-max) {
        border-bottom: 1px dashed $darker-gray;
      }

      .name {
        text-align: right;
        flex: 5;
      }

      .type {
        text-align: right;
        @media (max-width: $tablet-max) {
          text-align: end;
        }
        flex: 2;
      }
    }

    .section2 {
      display: flex;
      flex: 3;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px;

      .rating {
        display: flex;
        flex: 5;
        justify-content: center;

        @media (max-width: $tablet-max) {
          flex: 3;
          justify-content: flex-start;
        }
      }

      .include-add {
        display: flex;
        flex: 3;

        justify-content: center;
        @media (max-width: $tablet-max) {
          justify-content: flex-end;
        }

        p {
          text-align: center;
          @media (max-width: $tablet-max) {
            text-align: left;
          }
        }
      }
    }
  }
}
.actions-btns.include-add img {
  cursor: pointer;
}
.add-dish-to-meal .include-add img {
  cursor: pointer;
}
.add-dish-to-meal.global-data-table .ag-row {
  display: flex;
  align-items: center;
}
