@import '../global_variables';

.guest-screen {
  display: flex;
  flex-direction: column;
  flex: 1;

  /* Languages */
  .languages-selection {
    display: flex;
    justify-content: center;

    .languages {
      display: flex;
      flex-direction: column;

      z-index: 1;
      position: absolute;

      @media (min-width: $tablet-max) {
        left: 30px;
      }

      .lang-item {
        display: flex;
        align-items: center;
        cursor: pointer;

        background-color: $white-color;
        width: 100%;
        height: 70px;

        .lang-label {
          display: flex;
          justify-content: space-between;

          width: 10rem;
          height: 35px;
          @media (max-width: $tablet-max) {
            height: 30px;
          }
          border-bottom: 2px solid $darker-gray;
        }

        img {
          margin: 5px 15px;
        }
      }
    }
  }

  .guest-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    flex: 1;

    @media (max-width: $tablet-max) {
      margin-top: 0px;
    }
  }
}
