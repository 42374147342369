.my-orders-page {
  h1.page-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 34px;
    color: #23283c;
    margin: 0;
    text-align: left;
    padding-bottom: 20px;
  }
}

/***** My Orders Table Section ******/
.my-orders-table {
  .my-orders-table-head {
    background: #eff0f7;
    border-radius: 6px 6px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 100%;
  }
  .table-text-section {
    width: 100%;
    max-width: calc(100% - 164px);
    display: flex;
    justify-content: space-between;
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #2b3147;
      margin-bottom: 0;
      width: 100%;
      text-align: center !important;
      &:nth-child(1) {
        max-width: 250px;
        text-align: left !important;
      }
      &:nth-child(2) {
        max-width: 220px;
        text-align: left !important;
      }
      &:nth-child(3) {
        max-width: 200px;
      }
      &:nth-child(4),
      &:nth-child(5) {
        max-width: 120px;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #6d7287;
        text-align: left;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #6d7287;
      margin-bottom: 0;
      width: 100%;
      text-align: left;
      align-items: center;
      display: flex;
      justify-content: center;
      &:nth-child(1) {
        max-width: 250px;
        justify-content: flex-start;
      }
      &:nth-child(2) {
        max-width: 220px;
        justify-content: flex-start;
      }
      &:nth-child(3) {
        max-width: 200px;
      }
      &:nth-child(4) {
        max-width: 120px;
      }
      &:nth-child(5) {
        max-width: 80px;
      }
    }
  }
  .mobile-popup-btn {
    display: none;
  }
  .my-orders-table-body {
    width: 100%;
    .my-orders-table-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      gap: 10px;
      background: #ffffff;
      border-bottom: 0.5px solid rgba(130, 137, 163, 0.3);
      min-height: 56px;
    }
  }
}
.remove-back-border {
  background-color: transparent !important;
  border-bottom: 0 !important;
}
.rtl {
  .my-orders-table {
    .table-text-section {
      h5 {
        &:nth-child(1) {
          text-align: right !important;
        }
        &:nth-child(2) {
          max-width: 200px;
          text-align: right !important;
        }
      }

      ul {
        li {
          text-align: right;
        }
      }
    }
  }
  .voucher-page {
    .voucher-heading-section {
      .back-section {
        flex-direction: row-reverse;
      }
    }
  }
}

/***** Table Buttons Style ******/
.table-btn-section {
  width: 100%;
  max-width: 154px;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding: 0px;
  gap: 5px;

  .icon-wrapper {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 0px !important;
    gap: 5px;
    width: 44px;
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    outline: 0 !important;
    color: #6d7287 !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 15px !important;
    letter-spacing: 0.02em;
    &:hover,
    &:focus,
    &:focus-within {
      background: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
      outline: 0 !important;
    }
    &:hover {
      opacity: 0.85;
    }
    img {
      width: 20px;
      height: 20px;
    }

    .we-icon {
      margin: 0 !important;
      width: fit-content !important;
      img {
        width: 20px;
        height: 20px;
      }
    }
    p {
      color: #6d7287 !important;
      font-weight: 400 !important;
      font-size: 10px !important;
      line-height: 15px !important;
      letter-spacing: 0.02em;
      text-decoration: none;
      margin: 0 !important;
    }
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #f24953;
    margin-bottom: 0;
    width: 100%;
    text-align: right;
  }
  .rating-wrapper > div {
    align-items: flex-end !important;
  }
  .rating {
    // margin: 0 0 0 auto;
    .table-btn {
      width: 50px !important;
    }
  }
}
.table-btn {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 0px !important;
  gap: 5px;
  width: 44px;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
  color: #6d7287 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  letter-spacing: 0.02em;
  &:hover,
  &:focus,
  &:focus-within {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    outline: 0 !important;
  }
  &:hover {
    opacity: 0.85;
  }
  img {
    width: 20px;
    height: 20px;
  }

  .we-icon {
    margin: 0 !important;
    width: fit-content !important;
    img {
      width: 20px;
      height: 20px;
    }
  }
  p {
    color: #6d7287 !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 15px !important;
    letter-spacing: 0.02em;
    text-decoration: none;
    margin: 0 !important;
  }
}

/***** My Orders Modal In Mobiles ****/
.myOrdersMobile-modal {
  .modal-dialog {
    max-width: 343px;
    margin: auto;
    .modal-content {
      padding: 20px 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 20px;
      height: 385px;
    }
    .modal-header {
      padding: 0;
      border: 0;
      position: relative;
      color: #23283c;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      justify-content: center;
      .btn-close {
        padding: 0;
        background-image: url(../assets/icons/eva_arrow-back-fill.svg);
        opacity: 1;
        width: 20px;
        height: 20px;
        background-size: 100%;
        position: absolute;
        left: 0;
        top: 5px;
        font-size: 14px;
        line-height: 1;
        box-shadow: none !important;
        outline: 0 !important;
      }
    }
    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: calc(100% - 60px);
      .meal-details {
        height: calc(100% - 60px);
        overflow-y: auto;
      }
    }

    .table-btn-section {
      margin: 0 0 -20px;
      padding-top: 0;
      justify-content: space-between;
      border-top: 0.5px solid rgba(35, 40, 60, 0.2);
      max-width: calc(100% + 20px);
      width: calc(100% + 20px);
      .table-btn {
        height: 59px;
        width: 100% !important;
        max-width: 100% !important;
        border-radius: 0 !important;
        padding: 8px 0 8px !important;
        justify-content: space-between;
        border-right: 0.5px solid rgba(35, 40, 60, 0.2) !important;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #8289a3;
        &:last-child {
          border-right: 0 !important;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .rating {
        width: 50%;
        .table-btn {
          width: calc(100% - 5px) !important;
        }
      }
    }
  }
}

/******* Meal Details In My ORders and Voucher Popup *****/

.meal-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  .meal-details-field {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.05em;
      color: #2b3147;
      margin: 0;
      width: calc(100% - 196px);
      text-align: left;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #6d7287;
      margin: 0;
      width: 196px;
      text-align: left;
    }
    a {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #6d7287;
      margin: 0;
      width: 196px;
      text-align: left;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      width: 196px;
      li {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #6d7287;
        margin: 0;
        text-align: left;
      }
    }
  }
}
.rtl
  .myOrdersMobile-modal
  .modal-dialog
  .table-btn-section
  .table-btn:last-child {
  border-right: 0.5px solid rgba(35, 40, 60, 0.2) !important ;
}
.rtl
  .myOrdersMobile-modal
  .modal-dialog
  .table-btn-section
  .table-btn:first-child {
  border-right: none !important ;
}
.rtl .my-orders-page .my-orders-table .mobile-popup-btn .table-btn img {
  transform: rotate(180deg);
}

/******************************* Voucher Modal *************************/
.voucher-modal {
  .modal-dialog {
    max-width: 375px;
    margin: auto;
    .modal-content {
      padding: 8px 10px;
      height: 535px;
    }
    .modal-body,
    .modal-header {
      padding: 0;
    }
    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      .qr-section {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 252px;
          height: auto;
        }
      }
      .voucher-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.02em;
          color: #6d7287;
        }
      }
      .meal-details {
        .meal-details-field {
          p {
            max-width: 105px;
            text-align: right;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}

/******************************* Voucher Page *************************/
.voucher-page {
  .voucher-heading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 10px;
    h2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #23283c;
      gap: 4px;
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #6d7287;
      }
    }
    .back-section {
      background: transparent !important;
      box-shadow: none !important;
      outline: 0 !important;
      border: 0 !important;
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      position: absolute;
      left: 0;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: #23283c;
      }
    }
  }
  .voucher-section {
    .order-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 20px;
      gap: 50px;
    }

    .userName {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #6d7287;
      margin: 0;
      width: 100%;
    }
    .qr-code {
      padding: 0;
      width: fit-content;
      img {
        width: 252px;
        height: 257.61px;
      }
    }
    .voucher-section-right {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex-direction: column;
      max-width: 700px;
      width: 100%;
      padding: 25px 0;
    }
    .order-details {
      width: 100%;
      min-height: 207px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .my-orders-table {
        min-height: calc(100% - 74px);
        .table-text-section {
          max-width: 100%;
          h5,
          p,
          ul {
            width: 100%;
            max-width: 25% !important;
            margin: 0;
          }
          h5,
          p {
            &:nth-child(1) {
              max-width: 40% !important;
            }
            &:nth-child(3) {
              max-width: 20% !important;
            }
            &:nth-child(4) {
              max-width: 20% !important;
            }
          }
        }
      }
      .total-price-section .remove-gap {
        gap: 0;
        direction: ltr !important;
      }
      .total-price-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 15px 20px 0px;
        width: 100%;

        h5 {
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          color: #14cd31;
          margin: 0;
          font-weight: 600;
        }
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #23283c;
          margin: 0;
          display: flex;
          gap: 2px;
          max-width: 20%;
          width: 100%;
          padding: 0px 10px;
          justify-content: center;
        }
      }
    }
  }
}

.rtl {
  .voucher-page {
    .voucher-section {
      .order-details {
        .total-price-section {
          p {
            display: flex;
          }
        }
      }
    }
  }
}

.ltr {
  .table-btn-section {
    max-width: 200px;
    .table-btn {
      max-width: 85px;
      width: 100%;
      &:nth-child(1) {
        // width: 75px;
        white-space: break-spaces;
      }
    }
  }
}

.mobSection {
  display: none !important;
}

.meal-details {
  .meal-details-field {
    p.rating {
      .rating-wrapper > div {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
}
.my-orders-table .my-orders-table-body h3 {
  margin: 40px auto !important;
  font-size: 15px;
  font-weight: 500;
}
.voucher-page .voucher-section .order-details .total-price-section.extra-div {
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 0.5px solid rgba(130, 137, 163, 0.3);
}
.voucher-page
  .voucher-section
  .order-details
  .total-price-section.extra-div
  h5 {
  display: flex;
  width: fit-content;
  gap: 10px;
  color: #2b3147;
}
.voucher-page
  .voucher-section
  .order-details
  .total-price-section.extra-div
  h5
  p {
  max-width: 100%;
  color: #6d7287;
  font-weight: 500;
}
.voucher-page .voucher-section .order-details .total-price-section.extra-div p {
  color: #6d7287;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
/******************************* Media Query *************************/

$breakpoint-tablet: 992px;
$breakpoint-mobile: 576px;
@media (max-width: 1100px) {
  .ltr {
    .my-orders-table {
      .table-text-section {
        h5,
        p {
          &:nth-child(1) {
            max-width: calc(100% - 520px);
          }
          &:nth-child(3) {
            max-width: 130px;
          }
          &:nth-child(2) {
            max-width: 130px;
          }
          &:nth-child(5) {
            max-width: 60px;
          }
        }
      }
    }
    //   .table-btn-section {
    //     max-width: 175px;
    //     .table-btn {
    //       &:nth-child(1) {
    //         width: 77px;
    //       }
    //     }
    // }
  }
}
@media (max-width: $breakpoint-tablet) {
  .desktopSection {
    display: none !important;
  }
  .mobSection {
    display: block !important;
  }
  .my-orders-page {
    h1.page-heading {
      font-size: 20px;
      line-height: 30px;
      span {
        font-weight: 500;
      }
    }
    .my-orders-table {
      .my-orders-table-body {
        .my-orders-table-item {
          gap: 0;
        }
      }
      .table-text-section {
        max-width: calc(100% - 20px);
        h5 {
          font-size: 14px;
          line-height: 16px;
          &:nth-child(1) {
            max-width: 40%;
          }
          &:nth-child(2),
          &:nth-child(4) {
            max-width: 30%;
          }
          &:nth-child(3),
          &:nth-child(5) {
            display: none;
          }
        }
        p {
          &:nth-child(1) {
            max-width: 40%;
          }
          &:nth-child(2),
          &:nth-child(4) {
            max-width: 30%;
          }
          &:nth-child(3),
          &:nth-child(5) {
            display: none;
          }
        }
      }

      .table-btn-section {
        display: none;
      }
      .mobile-popup-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        .table-btn {
          gap: 5px;
          width: fit-content;
        }
      }
    }
  }

  .voucher-page {
    .voucher-heading-section {
      .back-section {
        span {
          display: none;
        }
      }
    }
    .voucher-section {
      .order-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
        gap: 0;
        .voucher-section-right {
          width: 100%;
          padding: 15px 0;
          gap: 15px;
          .order-details {
            min-height: fit-content;
            .my-orders-table {
              min-height: fit-content;

              .my-orders-table-head,
              .my-orders-table-item {
                padding: 10px;
              }
            }
          }
          .table-text-section {
            h5 {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
            }
            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              align-items: flex-start;
            }
            ul {
              li {
                display: none;
                &:first-child {
                  display: inline-block;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  max-width: 100%;
                  align-items: center;
                  justify-content: center;
                  line-height: 1;
                }
              }
            }
          }
        }
      }
    }
  }
  .voucher-page .voucher-section .order-details .total-price-section.extra-div {
    padding: 10px;
  }
  .voucher-page .voucher-section .order-details .total-price-section {
    padding: 15px 10px 0px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .my-orders-page {
    .my-orders-table-head {
      padding: 10px;
    }
    .my-orders-table {
      .my-orders-table-body {
        .my-orders-table-item {
          gap: 0;
          padding: 10px;
        }
      }
      .table-text-section {
        h5 {
          &:nth-child(1) {
            max-width: calc(100% - 200px);
          }
          &:nth-child(2),
          &:nth-child(4) {
            max-width: 100px;
          }
          &:nth-child(3),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8) {
            display: none !important;
          }
        }
        p {
          &:nth-child(1) {
            max-width: calc(100% - 200px);
          }
          &:nth-child(2),
          &:nth-child(4) {
            max-width: 100px;
          }
          &:nth-child(3),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8) {
            display: none !important;
          }
        }
      }
    }
  }
}
.text-section-dishes {
  display: flex;
  flex-direction: column;
}
.text-section-dishes span {
  display: inline-block;
}
a.btn.btn-primary.order-meal-btn {
  background: #14cd30;
  border: 1px solid #14cd30;
  color: white;
  font-size: 14px;
  padding: 5px 20px;
  width: fit-content;
  margin: initial;
}
