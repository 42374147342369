@import 'global_variables';

.nav {
  display: inline-table;
  flex-direction: column;
  // width: 400px;
  min-height: 100vh;
  // background-color: #475058;
  border-bottom-left-radius: 0px;
  //-webkit-box-shadow: 0 5px 30px 0 rgb(0, 0, 0 / 5%);
  //box-shadow: 0 5px 30px 0 rgb(0, 0, 0 / 5%);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 100vw;
    border-bottom-right-radius: 30px;
  }

  .nav_title {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: center;
    color: #a6bcd0;
    font-size: 21px;
    font-weight: 400;
    letter-spacing: 1.2px;
    line-height: 4;
    margin-bottom: 1rem;
    padding: 20px 0px;

    @media (max-width: $tablet-max) {
      line-height: 3;
      margin: 0;
    }

    .user_name {
      flex: 1;
      line-height: initial;
    }

    .logout {
      margin: 0 20px;

      .icon-wrapper {
        //flex-direction: column;
        //align-items: center;

        .we-icon-text {
          line-height: 1em;
          font-size: 0.5em;
          display: inline;
          width: unset;
        }
      }
    }

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
    }
  }

  .items {
    flex: 1;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      li {
        position: relative;
        text-align: center;
        align-items: center;
        border-bottom: solid 1px $darker-green;

        // &:after {
        //   content: '';
        //   width: 20px;
        //   height: 20px;
        //   position: absolute;
        //   left: 10px;
        //   top: 33px;
        //   background: url(../assets/images/left-arrow__green.svg) center center
        //     no-repeat;
        //   background-size: contain;
        // }
        a,
        span {
          flex: 1;
          color: #f0f4f8;
          text-decoration: none;
          font-size: 18px;
          line-height: 4.22;

          @media (max-width: $tablet-max) {
            line-height: 3;
          }

          letter-spacing: 1.38px;
        }

        cursor: pointer;
      }
    }
  }

  .sidebar-bottom {
    justify-self: flex-end;
    //position: absolute;
    //bottom:30px;
    padding: 20px;

    .languages {
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      height: auto;

      &.hidden {
        max-height: 0;
      }

      &.visible {
        max-height: 100vh;
      }

      .menu_item:after {
        //background: none;
      }
    }

    .menu_item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 5px;
      color: #f0f4f8;
      text-decoration: none;
      font-size: 23px;
      line-height: 4;
      letter-spacing: 1.2px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      img {
        margin: 0 20px;
      }

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        margin: 0 10px;
        background: url(../assets/images/left-arrow__green.svg) center center
          no-repeat;
        background-size: contain;
      }

      &.selectable {
        &:after {
          content: '';
          width: 12px;
          height: 12px;
          margin: 0 10px;
          background: url(../assets/images/arrow-down__green.svg) center center
            no-repeat;
          background-size: contain;
          //transform: rotate(90deg);
          transition: all 0.2s ease-in-out;
        }

        &.open {
          &:after {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

.nav .items ul li {
  text-align: left;
  padding-left: 30px;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.sidebar::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: E5FFE9;
  border-radius: 5px;
}

section.sidebar-bottom span {
  text-transform: uppercase;
}

.sidebar-menu .dropdown-menu.show {
  display: block;
  max-height: 310px !important;
  overflow: auto;
}

.sidebar-menu .items .dropdown-menu {
  position: relative !important;
  transform: none !important;
  inset: inherit !important;
  border: none;
  padding: 0 28px;
  background: transparent;
  transition: all 0.5s ease;
  max-height: 0;
  overflow-y: hidden;
  display: block;
}

.sidebar-menu a.dropdown-toggle {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6d7287;
  padding: 10px;
  width: 100%;
  display: flex;
  gap: 18px;
  border-radius: 6px;
}

.sidebar-menu a.topbar-link {
  display: list-item;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6d7287;
  padding: 10px 20px;
  width: 100%;
  text-decoration: none;
  border-radius: 6px;
}
.sidebar-menu a.topbar-link:focus,
.sidebar-menu a.dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}

.sidebar-menu a.topbar-link:hover,
.sidebar-menu a.dropdown-toggle:hover {
  background: #eff0f7;
}

.sidebar-menu .dropdown-toggle::after {
  position: absolute;
  background: url(../assets/icons/arrowIcon.svg);
  border: none;
  right: 30px;
  width: 12px;
  height: 6px;
  top: 17px;
}

.sidebar-menu .nav-item.show.dropdown .dropdown-toggle::after {
  transform: rotate(180deg);
}
.single-lang-btn.dropdown-toggle {
  cursor: default !important;
}
.single-lang-btn.dropdown-toggle::after {
  display: none;
}

// section.logo-section img:last-child {
//   transform: rotate(180deg);
// }

.sidebar-menu .dropdown-menu::-webkit-scrollbar {
  width: 0px;
}

.sidebar .dropdown-menu::-webkit-scrollbar-track {
  border-radius: 0px;
}

.sidebar .dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 0px;
}

@media screen and (max-width: 768px) {
  .sidebar-menu a.dropdown-toggle {
    font-size: 14px;
    line-height: initial;
    padding: 10px;
  }

  .sidebar-menu a.topbar-link {
    font-size: 14px;
    line-height: initial;
    padding: 10px;
  }
  .sidebar {
    max-width: 280px;
  }
}

.sidebar .languages .lang-shortcode span,
.sidebar .languages span {
  text-transform: initial !important;
}

.sidebar-menu .sidebar-footer .dropdown-menu {
  min-width: 98%;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: 0;
  padding: 5px 0;
}

.sidebar-menu .sidebar-footer .dropdown-menu .dropdown-item {
  padding: 10px;
  color: #23283c;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.sidebar-menu .sidebar-footer .dropdown-menu .dropdown-item:active {
  background: #eff0f7;
}

// rtl CSS

.rtl {
  .sidebar-menu .dropdown-toggle::after {
    left: 30px;
    right: unset;
  }
}
