@import 'global_variables';

.order-wrapper {
  margin: 1rem auto;
  width: 100%;

  display: flex;
  flex-direction: column;

  .icon-wrapper {
    .we-icon {
      transform: scaleX(-1);
    }

    .we-icon-text {
      @media (max-width: $tablet-max) {
        display: inline-block;
        width: max-content;
      }

      text-decoration: none;
    }
  }

  .order-card {
    width: 100%;
    max-width: 1300px;
    align-self: center;

    display: flex;
    margin: 20px 10px;
    border-radius: 8px;
    background-color: $cards-background-color;

    color: $lighter-black;
    text-align: start;
    font-size: 20px;

    @media (max-width: $tablet-max) {
      flex-direction: column;
      font-size: 16px;
    }

    .dishImage {
      flex: 2;
      max-width: 300px;
      min-height: 150px;

      @media (max-width: $tablet-max) {
        height: 150px;
        width: 100%;
        max-width: 100%;
      }

      .dish-img {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
      }
    }

    .order-details {
      display: flex;
      flex-direction: column;
      flex: 5;
      padding: 30px 40px;

      @media (max-width: $tablet-max) {
        padding: 20px;
      }

      .section1 {
        display: flex;
        @media (max-width: $tablet-max) {
          flex-direction: column;
        }
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        h4 {
          font-size: 1.2em;
          margin: 0;
        }
      }

      .section2 {
        .order-time {
          margin-top: 20px;
        }

        .order-description {
          margin-top: 20px;
        }
      }
    }

    .button-wrapper {
      margin-top: 20px;
      justify-content: flex-end;

      @media (max-width: $tablet-max) {
        justify-content: center;
      }
    }
  }
}

/* popup */

.order-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  background-color: $popup-dark-gray-background;
  z-index: 99999;

  font-size: 20px;
  @media (max-width: $tablet-max) {
    font-size: 16px;
  }

  .popup-block {
    padding: 0 2rem;

    .popup-title {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 5em;
      @media (max-width: $tablet-max) {
        padding: 0 1em;
      }

      color: $light-green;
      border-bottom: dashed 1px $white-color;

      font-size: 1em;
      letter-spacing: 1.2px;

      img {
        width: 1em;
        height: 1em;
        margin: 1.2em;
      }
    }

    .order-details {
      align-self: flex-start;

      color: $white-color;
      font-size: 1em;
      text-align: start;
    }
  }

  .button-wrapper {
    margin-top: 5em;
    .we-button {
      width: 10em;
    }
  }
}

.add-extras-order-details {
  display: flex;
  justify-content: flex-end;
}
