.rs-input-group-addon svg {
  color: #00d100;
  fill: #00d100;
}
.rs-input-group:hover {
  cursor: pointer !important;
}
.rs-input-group,
.rs-input-group:hover,
.rs-input-group-focus,
.rs-input-group:focus {
  border-color: #9dc79d !important;
  background-color: #fafefa !important;
  height: 45px;
  text-align: center;
  align-items: center;
  padding: 10px;
}
.rs-input-group-sm > .rs-input {
  //padding-left: 14px !important;
  padding: 0 !important;
}
.rs-input-group input {
  background-color: #fafefa !important;
}
.rs-btn-link {
  color: #00d100 !important;
}
.rs-btn-primary {
  background-color: #00d100 !important;
}
.fade.modal.show {
  padding: 0 !important;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-appearance: none !important;
  appearance: none !important;
  box-shadow: inset 0 0 0 1px #00d100 !important;
}
.rs-calendar-table-cell-content:hover {
  color: #00d100 !important;
  background-color: rgba(157, 199, 157, 0.35) !important;
}
.rs-calendar-table-cell-in-range::before {
  background-color: rgba(157, 199, 157, 0.3) !important;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
  color: #fff !important;
}
.rs-date-range-input,
.rs-date-range-input::placeholder {
  color: #4b4b4b;
}
.rs-calendar-table-cell-selected-start,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #00d100 !important;
  border-radius: 6px !important;
}
.rs-picker-daterange-menu,
.rs-picker-popup-daterange {
  z-index: 9999 !important;
}
.rs-picker-toolbar-ranges,
.rs-picker-daterange-header {
  display: none !important;
}
.lobby-user-font {
  font-family: 'OpenSans', sans-serif;
}
.your-dish-swal-content {
  z-index: 150 !important;
}
.your-dish-swal-actions {
  flex-direction: row-reverse;
}
.client-lobby-title-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: left;
  font-family: 'OpenSans', sans-serif;
  min-width: 200px;
}
.client-lobby-header-container {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 40px auto;
  max-width: 2000px;
}
.client-lobby-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  display: flex;
  align-items: center;
}
.client-lobby-subtitle {
  color: #656565;
  font-size: 18px;
  font-weight: 400;
  // white-space: nowrap;
}
.client-lobby-filter-block-datepicker {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
}
.client-lobby-filter-block-label {
  color: #656565;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 175%;
}
.client-lobby-filter-block {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.client-lobby-desktop-filter {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.client-lobby-overflow-hidden {
  overflow: hidden;
}
.client-lobby-filter-block-dropdown {
  width: 190px;
  height: 45px;
  color: #9f9f9f !important;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
}
.client-lobby-btn-section {
  display: flex;
  gap: 65px;
  justify-content: end;
  align-items: center;
  min-width: 300px;
}
.client-lobby-btn-section-view-more {
  color: #00d100;
  align-items: center;
  cursor: pointer;
  min-width: 10px;
}
.client-lobby-btn-section-view-more-rotated {
  color: #00d100;
  align-items: center;
  cursor: pointer;
  transform: rotate(180deg);
  min-width: 10px;
}
.client-lobby-btn-block {
  display: flex;
  gap: 10px;
}
.client-lobby-order-btn-outlined {
  width: 125px !important;
  height: 45px;
  color: #00d100 !important;
  border: 2px solid #00d100 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 175% !important;
  text-align: center !important;
}
.client-lobby-order-btn-outlined:hover {
  color: #31a331 !important;
  border: 2px solid #31a331 !important;
}
.client-lobby-order-btn {
  width: 125px !important;
  height: 45px;
  background-color: #00d100 !important;
  border: 2px solid #00d100 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 175% !important;
  text-align: center !important;
}
.client-lobby-order-btn:hover {
  background-color: #31a331 !important;
  border: 2px solid #31a331 !important;
}
.green-dropdown {
  color: #00d100 !important;
}
.green-border-and-green-background {
  border: 1px solid #9dc79d !important;
  padding: 10px !important;
  min-width: 100px;
  width: max-content !important;
  background: #fafefa !important;
}
.green-background {
  background: #fafefa !important;
}
.green-border-layout {
  border-radius: 8px !important;
  border: 1px solid #9dc79d !important;
}
.remove-border {
  border: 0 !important;
}
.client-lobby-collect-btn {
  width: 125px !important;
  height: 45px;
  color: #00d100 !important;
  background-color: #fff !important;
  border: 2px solid #00d100 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 175% !important;
  text-align: center !important;
}
.client-lobby-collect-btn:hover {
  color: #31a331 !important;
  border: 2px solid #31a331 !important;
}
.dropdown-toggle::after {
  color: #00d100 !important;
}
.remove-arrow::after {
  color: transparent !important;
}
.bottom-popup-container {
  // display: none;
}
.padding-left-right-25 {
  padding: 0 25px;
}
.client-lobby-reset {
  display: flex;
  margin: 0;
  margin-top: 47px;
  align-items: start;
  height: fit-content;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #9f9f9f;
}
.client-lobby-reset-mob {
  display: flex;
  margin: 0;
  align-items: start;
  height: fit-content;
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  color: #9f9f9f;
}
.client-lobby-reset-mob.active {
  display: flex;
  margin: 0;
  align-items: start;
  height: fit-content;
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  color: #00d100;
}
.client-lobby-reset-mob:hover,
.client-lobby-reset:hover {
  cursor: default;
}
.client-lobby-reset.active {
  display: flex;
  margin: 0;
  margin-top: 47px;
  align-items: start;
  height: fit-content;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #00d100;
}
.client-lobby-reset-mob.active:hover,
.client-lobby-reset.active:hover {
  cursor: pointer;
}
.bottom-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s ease-out;
}

.popup-content {
  z-index: 999;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  height: 550px;
  border-radius: 15px 15px 0px 0px;
  animation: slideIn 0.3s ease-out;
}
.lobby-mobile-filter-header {
  padding: 13px 22px;
  display: flex;
  justify-content: space-between;
}
.lobby-mobile-filter-header-text {
  margin: 0;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 175%;
}
.lobby-mobile-filter-header-close-btn {
  color: #00d100;
  font-size: 18px;
  font-weight: 600;
  line-height: 175%;
  cursor: pointer;
}
.lobby-mobile-filter-filters-block {
  margin-top: 15px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
}
.lobby-mobile-filter-button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.client-lobby-dish-column {
  display: flex;
  flex-direction: column;
}
@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
    display: none;
  }
}
.meal-details-popup-heading {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  margin-bottom: 10px;
}
.meal-details-popup-heading-pb {
  margin-bottom: 30px;
}
.meal-details-popup-meal-desc {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #6d7287;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 18px;
  max-height: 50px;
}
.bottom-popup.closed {
  background-color: transparent;
  animation: slideOut 0.3s ease-in;
}

.meal-details-popup-heading-close {
  height: 15px;
  filter: brightness(0.5);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: end;
}

.meal-details-popup-heading-h6 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  justify-content: end;
  text-align: right;
  margin: 0;
}
.meal-details-popup-heading-close-container {
  display: flex;
  justify-content: end;
}
.client-lobby-mob-elem {
  display: none;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-lobby-order-green {
  color: #00d100;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 175%;
}
.client-lobby-first-column,
.meal-details-popup-content-wrapper {
  padding: 30px 35px;
}
.meal-details-modal {
  height: calc(100% + 35px);
}
.client-lobby-dish-cell {
  min-width: 150px !important;
}
.meal-details-btn-section {
  width: 100%;
  max-width: 154px;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding: 0px;
  gap: 5px;

  .icon-wrapper {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 0px !important;
    gap: 5px;
    width: 44px;
    background: transparent !important;
    border: 0 !important;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none !important;
    outline: 0 !important;
    color: #6d7287 !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 15px !important;
    letter-spacing: 0.02em;
    &:hover,
    &:focus,
    &:focus-within {
      background: transparent !important;
      border: 0 !important;
      -webkit-appearance: none;
      appearance: none;
      box-shadow: none !important;
      outline: 0 !important;
    }
    &:hover {
      opacity: 0.85;
    }
    img {
      width: 20px;
      height: 20px;
    }

    .we-icon {
      margin: 0 !important;
      width: fit-content !important;
      img {
        width: 20px;
        height: 20px;
      }
    }
    p {
      color: #6d7287 !important;
      font-weight: 400 !important;
      font-size: 10px !important;
      line-height: 15px !important;
      letter-spacing: 0.02em;
      text-decoration: none;
      margin: 0 !important;
    }
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #f24953;
    margin-bottom: 0;
    width: 100%;
    text-align: right;
  }
  .rating-wrapper > div {
    align-items: flex-end !important;
  }
  .rating {
    // margin: 0 0 0 auto;
    .table-btn {
      width: 50px !important;
    }
  }
}
.meal-details-btn-section {
  margin: 0;
  padding-top: 0;
  justify-content: space-between;
  border-top: 0.5px solid rgba(35, 40, 60, 0.2);
  max-width: 100%;
  width: 100%;
  .table-btn {
    height: 59px;
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 0 !important;
    padding: 8px 0 8px !important;
    justify-content: space-between;
    border-right: 0.5px solid rgba(35, 40, 60, 0.2) !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #8289a3;
    &:hover {
      opacity: 1;
      background-color: #fff !important;
      border-right: 0.5px solid rgba(35, 40, 60, 0.2) !important;
    }
  }
  .rating {
    width: 50%;
    .table-btn {
      width: calc(100% - 5px) !important;
    }
  }
}

.meal-details-popup-font-title {
  color: #9f9f9f;
  font-family: 'OpenSans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  width: calc(100% - 196px);
  text-align: left;
  white-space: nowrap;
}
.meal-details-popup-font-content {
  color: #595962;
  font-family: 'OpenSans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 175%;
  margin: 0;
  width: calc(100% - 196px);
  text-align: left;
}
.meal-details-popup-font-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.meal-details-popup-text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.client-lobby-pagination-container {
  display: flex;
  justify-content: center;
}
.client-lobby-voucher-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.client-lobby-voucher-text {
  color: #201f3d;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  white-space: nowrap;
}
.client-lobby-voucher-icon {
  cursor: pointer;
}
.client-lobby-point-container {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 0;
}
.client-lobby-point-text {
  color: #201f3d;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  white-space: nowrap;
}
.meal-details-popup-first-tab {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 16px;
}
.width-for-popup {
  width: 525px !important;
  max-width: 600px !important;
}
.padding-left-right {
  padding: 0 35px;
}
.multidish-text {
  margin: 0;
  white-space: nowrap;
  text-transform: lowercase;
}
.multidish-text-wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
}
.text-for-lobby-user-in-subheader {
  color: #656565;
  font-size: 16px;
  font-weight: 600;
}
.client-lobby-filter-block-datepicker-datepickercomp {
  width: 240px !important;
  height: 45px !important;
  color: #656565 !important;
}
.background-kitchen-select-lobby {
  background-color: #fff !important;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  width: max-content !important;
  // max-width: 125px !important;
}
.kitchen-dropdown-lobby-mob {
  width: max-content !important;
}
.client-lobby-mob-filter {
  display: none;
}
.client-lobby-first-column {
  padding-left: 50px !important;
}
.client-lobby-last-column {
  padding-right: 50px !important;
}
.sub-header-wrapper-lobby-mobile {
  display: none;
}
.lobby-mobile-filter-content-wrapper {
  padding: 25px;
  padding-top: 5px;
  max-height: calc(100vh - 394px);
  gap: 30px;
  display: flex;
  flex-direction: column;
}
.client-lobby-empty-table {
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-lobby-empty-table-text {
  text-align: center;
  margin: 0;
  width: 100%;
}

.tooltip-custom-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.tooltip-custom-content::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.tooltip-custom-content::-webkit-scrollbar-track {
  background: #cacaca;
  border-radius: 10px;
}
.tooltip-custom-content::-webkit-scrollbar-thumb {
  background: #00d100;
  border-radius: 10px;
}
.tooltip-custom-wrapper {
  // display: none;
  position: absolute;
  transform: translate(-50%, -3.5%);
  z-index: 10;
}
.tooltip-custom-wrapper-he {
  // display: none;
  position: absolute;
  transform: translate(50%, -3.5%);
  z-index: 10;
}

.tooltip-custom {
  display: none;
  position: absolute;
  bottom: 0%;
  left: 4%;
  background-color: #fff;
  color: #000;
  padding: 20px;
  -webkit-appearance: none !important;
  appearance: none !important;
  box-shadow: 0 5px 20px 0 rgba(32, 31, 61, 0.19);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 175%;
  width: 250px;
  max-height: fit-content;
  border-radius: 20px;
  z-index: 10;
  opacity: 0;
}
.cursor-pointer-tooltip-icon {
  cursor: pointer;
  z-index: 2;
}
.tooltip-custom-content {
  position: relative;
  overflow-y: auto;
  max-width: 100%;
  height: fit-content;
  max-height: fit-content;
  z-index: 10;
}
.tooltip-custom ol {
  margin: 0;
  padding-left: 20px;
}
@keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hideBlock {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.tooltip-custom-show {
  display: block;
  z-index: 999;
}
.tooltip-custom-show-anim {
  animation: showBlock 0.3s linear forwards;
}
.tooltip-custom-hide-anim {
  animation: hideBlock 0.3s linear forwards;
}

.client-lobby-table-styles {
  font-size: 18px;
}
.client-lobby-mob-elem-flex {
  display: none;
}
.tableRowOpenSans .openSansStyle {
  font-family: 'OpenSans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #595962 !important;
}
.meal-details-dish-cell {
  min-width: 150px !important;
}
.client-lobby-filter-block-datepicker-datepickercomp-mob {
  width: 400px !important;
  height: 60px !important;
}
.client-lobby-filter-block-datepicker-datepickercomp-mob .rs-input-group {
  height: 60px !important;
}
.client-lobby-filter-block-dropdown-mob {
  width: 400px !important;
  height: 60px !important;
  color: #9f9f9f !important;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
}
.client-lobby-russian-headers-style {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 100% !important;
}
.client-lobby-table-row-shadow {
  -moz-box-shadow: 0px 5px 20px 0px rgba(32, 31, 61, 0.15) !important;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(32, 31, 61, 0.15) !important;
  box-shadow: 0px 5px 20px 0px rgba(32, 31, 61, 0.15) !important;
}
.tableRowOpenSans .onlyOpenSansFont {
  font-family: 'OpenSans', sans-serif !important;
}
.meal-details-popup-credit-points {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}
.active > .page-link {
  background-color: #00d100 !important;
  border-color: #00d100 !important;
  color: #fff !important;
}
.page-link {
  color: #00d100 !important;
  text-decoration: none !important;
  outline: none !important;
}
.custom-width-for-kitchen-dropdown {
  width: 100% !important;
}
.client-lobby-custom-table-header {
  display: block !important;
}
.client-lobby-custom-table-body {
  display: flex !important;
  flex-direction: column !important;
  gap: 15px !important;
}

.client-lobby-custom-table-row {
  display: grid !important;
  grid-template-columns:
    minmax(150px, 250px) minmax(150px, 300px) minmax(150px, 400px)
    minmax(125px, 225px) 410px !important;
  justify-content: space-between !important;
  width: calc(100vw - 100px) !important;
  height: fit-content !important;
  min-height: 130px;
}
.client-lobby-custom-table-row-for-header {
  height: 70px !important;
  max-height: 70px !important;
  min-height: unset !important;
}
.client-lobby-custom-table-cell {
  display: block;
}
.client-lobby-custom-table {
  margin: 0 !important;
}
@media (min-width: 1600px) {
  .client-lobby-custom-table-row {
    grid-template-columns: 1fr 1fr 1fr 1fr minmax(410px, 510px) !important;
    max-width: 2000px;
  }
  .client-lobby-first-column {
    padding-left: 70px !important;
  }
  .client-lobby-last-column {
    padding-right: 70px !important;
  }
}
@media (max-width: 1290px) {
  .client-lobby-btn-section {
    gap: 55px;
  }
  .client-lobby-custom-table-row {
    grid-template-columns:
      minmax(160px, 250px) minmax(125px, 300px) minmax(100px, 400px)
      minmax(125px, 150px) 400px !important;
  }
  .multidish-text-wrapper {
    gap: 1px;
  }
}
@media (max-width: 1150px) {
  .client-lobby-dish-cell {
    min-width: 100px !important;
  }
  .client-lobby-btn-section {
    gap: 45px;
  }
  .client-lobby-custom-table-row {
    grid-template-columns:
      minmax(155px, 250px) minmax(125px, 300px) minmax(100px, 400px)
      minmax(125px, 225px) 390px !important;
  }
}
@media (max-width: 1060px) {
  .client-lobby-reset {
    margin-top: 47px;
    align-items: start;
    height: fit-content;
  }
  .client-lobby-desktop-filter,
  .client-lobby-title-container {
    width: 50%;
    max-width: 400px;
  }
  .client-lobby-header-container {
    width: 100%;
    gap: 25px;
    align-items: start;
  }
  .client-lobby-filter-block-datepicker-datepickercomp {
    width: 400px !important;
  }
  .client-lobby-desktop-filter {
    flex-wrap: wrap;
    width: 100% !important;
  }
}
@media (max-width: 1050px) {
  .client-lobby-btn-block {
    flex-direction: column;
  }
  .client-lobby-btn-section {
    min-width: 125px;
    gap: 40px;
  }
  .client-lobby-custom-table-row {
    grid-template-columns:
      minmax(155px, 250px) minmax(125px, 300px) minmax(100px, 400px)
      minmax(75px, 225px) 300px !important;
  }
}

@media (max-width: 975px) {
  .client-lobby-custom-table-row {
    grid-template-columns:
      155px minmax(125px, 300px) minmax(100px, 400px)
      minmax(75px, 225px) 250px !important;
  }
  .client-lobby-last-column {
    padding-right: 25px !important;
  }
  .client-lobby-first-column {
    padding-left: 25px !important;
  }
  .client-lobby-collect-btn,
  .client-lobby-order-btn {
    width: 100px !important;
  }
  .client-lobby-btn-section {
    min-width: 100px;
    gap: 40px;
  }
  .client-lobby-apply-btn {
    width: unset !important;
    height: unset !important;
    padding: 10px 40px !important;
    text-align: center !important;
  }
  .client-lobby-custom-table-header {
    height: 90px;
  }
}
.client-lobby-filter-block-datepicker-datepickercomp-mob
  .rs-input-group-sm
  > .rs-input {
  padding-left: 13px !important;
  padding-right: 13px !important;
  font-size: 14px !important;
}
.client-lobby-filter-block-datepicker-datepickercomp-mob .rs-input-group,
.client-lobby-filter-block-datepicker-datepickercomp-mob .rs-input-group:hover,
.client-lobby-filter-block-datepicker-datepickercomp-mob .rs-input-group-focus,
.client-lobby-filter-block-datepicker-datepickercomp-mob .rs-input-group:focus {
  padding: 7px;
}
@media (max-width: 900px) {
  .rs-input-group,
  .rs-input-group:hover,
  .rs-input-group-focus,
  .rs-input-group:focus {
    padding: 7px;
  }
  .client-lobby-order-btn {
    width: 75px;
  }
  .client-lobby-collect-btn {
    width: 75px;
  }
  .client-lobby-desktop-filter,
  .client-lobby-title-container {
    width: 50%;
    max-width: 340px;
  }
  .client-lobby-filter-block-datepicker-datepickercomp {
    width: 340px !important;
  }
  .client-lobby-filter-block-dropdown {
    width: 160px;
  }
  .client-lobby-header-container {
    width: 100%;
    gap: 10px;
  }
  .rs-input-group-sm > .rs-input,
  .client-lobby-filter-block-dropdown {
    font-size: 11.5px !important;
  }
  .client-lobby-custom-table-row {
    grid-template-columns:
      150px minmax(120px, 300px) minmax(100px, 400px)
      minmax(75px, 225px) 250px !important;
  }
}
.container-styles-margin-max-width {
  max-width: unset !important;
  margin: 0;
}
.page-item {
  outline: none !important;
  box-shadow: 0;
  --bs-pagination-focus-box-shadow: 0;
  --bs-pagination-focus-bg: #fff;
}
.page-item:hover {
  text-decoration: none !important;
}
.page-item:focus {
  outline: none !important;
  box-shadow: 0;
  --bs-pagination-focus-box-shadow: 0;
  --bs-pagination-focus-bg: #fff;
}
.def-font-style-size,
.def-font-style-size.MuiTableCell-root {
  font-family: 'Popping', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #000 !important;
}
.def-font-style-size,
.def-font-style-size-color.MuiTableCell-root {
  color: #212529 !important;
}
.meal-details-btn-section .font-for-buttons-in-card {
  font-family: 'OpenSans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #000 !important;
  padding: 12px 0 !important;
  justify-content: start !important;
  gap: 5px !important;
  height: unset !important;
}
.meal-details-btn-section .img-resize {
  min-width: 25px;
  min-height: 25px;
}
.meal-details-btn-section .img-resize-for-voucher {
  min-width: 25px;
  min-height: 25px;
}
.meal-details-btn-section .font-for-buttons-in-card-voucher {
  font-family: 'OpenSans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #000 !important;
  padding: 12px 0 !important;
  justify-content: start !important;
  gap: 5px !important;
  height: unset !important;
}
@media (max-width: 850px) {
  .client-lobby-btn-section {
    gap: 30px;
  }
  .client-lobby-custom-table-row {
    grid-template-columns:
      125px minmax(120px, 300px) minmax(105px, 400px)
      minmax(85px, 225px) 240px !important;
  }
}
@media (max-width: 800px) {
  .client-lobby-reset.active,
  .client-lobby-reset {
    display: none;
  }
  .def-font-style-size,
  .def-font-style-size.MuiTableCell-root {
    font-size: 14px !important;
  }
  .client-lobby-custom-table-body {
    gap: 10px;
  }
  .client-lobby-custom-table-row {
    grid-template-columns: 200px 300px auto !important;
    min-height: 120px !important;
  }
  .rs-input-group-sm > .rs-input {
    font-size: 14px !important;
  }
  .client-lobby-mob-font-styles {
    font-size: 16px !important;
  }
  .client-lobby-mob-elem {
    display: block;
  }
  .client-lobby-title-container {
    min-width: 100% !important;
  }
  .client-lobby-title-filter-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .client-lobby-mob-elem-flex {
    display: flex;
    font-size: 14px;
  }
  .client-lobby-mob-elem-font {
    font-size: 14px !important;
  }
  .client-lobby-hideable-column {
    display: none !important;
  }
  .client-lobby-btn-block {
    display: none !important;
  }
  .client-lobby-btn-section {
    gap: 0;
    min-width: unset;
  }
  .width-for-popup {
    width: unset !important;
  }
  .client-lobby-desktop-filter {
    display: none;
  }
  .client-lobby-mob-filter {
    display: block;
    cursor: pointer;
    position: relative;
  }

  .client-lobby-mob-filter-count-container {
    position: absolute;
    top: 0;
    right: 0;
  }
  .client-lobby-mob-filter-counter {
    position: absolute;
    top: 1.5px;
    right: 5.5px;
    color: #fff;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
  }
  .client-lobby-mob-filter-counter-big {
    position: absolute;
    top: 4.5px;
    right: 4.9px;
    color: #fff;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
  }
  .client-lobby-header-container {
    margin-bottom: 5px;
  }
  .client-lobby-title {
    font-size: 22px;
  }
  .client-lobby-subtitle {
    font-size: 16px;
  }
  .bottom-popup-container {
    display: block;
    position: relative;
  }
  .meal-details-popup-font-content {
    font-size: 16px;
  }
  .tableRowOpenSans .openSansStyle {
    font-family: 'OpenSans', sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #595962 !important;
  }
}
.tab-fonts {
  color: #000 !important;
  font-family: 'OpenSans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
}
.tab-fonts[aria-selected='false'] {
  color: #9f9f9f !important;
  font-family: 'OpenSans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
}
@media (max-width: 768px) {
  .sub-header-wrapper-lobby-mobile {
    display: block;
  }

  .client-lobby-subtitle {
    white-space: initial;
  }
  .client-lobby-desktop-filter {
    max-width: 300px;
  }
  .client-lobby-custom-table-row {
    grid-template-columns: 125px 225px auto !important;
  }
}
@media (max-width: 525px) {
  .client-lobby-custom-table-row {
    grid-template-columns: 125px 200px auto !important;
  }
  .client-lobby-filter-block-dropdown {
    width: 275px;
  }

  .client-lobby-first-column {
    padding-left: 20px !important;
  }
  .client-lobby-last-column {
    padding-right: 20px !important;
  }
  .client-lobby-mob-elem {
    width: 120px;
  }
}
@media (max-width: 500px) {
  .client-lobby-custom-table-row {
    grid-template-columns: 100px 150px auto !important;
  }
}
@media (max-width: 480px) {
  .client-lobby-custom-table-row {
    width: calc(100vw - 30px) !important;
  }
  .meal-details-modal {
    min-height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .client-lobby-pagination-container {
    max-width: 350px;
  }
  .meal-details-btn-section .font-for-buttons-in-card-voucher,
  .meal-details-btn-section .font-for-buttons-in-card {
    font-size: 14px !important;
  }
  .meal-details-custom-popup-headers-fonts {
    font-size: 14px !important;
  }
  .sub-header-wrapper .sub-header-lobby-mobile {
    display: flex !important;
    flex-direction: row !important;
  }
  .meal-details-popup-font-content {
    width: calc(100% - 196px) !important;
  }
  .meal-details-popup-font-title {
    width: unset !important;
  }
  .sub-header-wrapper-lobby-mobile {
    padding: 5px 10px;
  }

  .client-lobby-subtitle {
    white-space: initial;
  }
  .client-lobby-title-container {
    min-width: unset;
  }
  .client-lobby-title-container {
    margin-top: 20px;
  }
  .client-lobby-btn-section {
    min-width: unset;
  }
  .client-lobby-filter-block-datepicker-datepickercomp-mob {
    width: calc(100vw - 50px) !important;
  }
  .client-lobby-filter-block-dropdown-mob {
    width: calc(100vw - 50px) !important;
  }
  .popup-content-meal-details {
    height: calc(100vh - 116px);
  }
  .lobby-mobile-content-wrapper {
    width: 100%;
    height: calc(100vh - 173.5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (max-height: 600px) {
    .lobby-mobile-filter-content-wrapper {
      padding: 12.5px;
      padding-left: 25px;
      padding-top: 5px;
    }
  }
}
@media (max-width: 400px) {
  .meal-details-popup-font-content {
    width: calc(100% - 160px) !important;
  }
  .client-lobby-custom-table-row {
    grid-template-columns: 100px 150px auto !important;
  }
  .meal-details-modal {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .view-more-popup-lobby-iphone-fix {
    min-height: 350px;
  }
  .meal-details-btn-section-iphone-fix {
    max-height: 70px;
  }
}
@media (max-width: 380px) {
  .pagination-elem-ellipsis .page-link {
    padding: 6px 2px !important;
  }
  .pagination-elem-btns .page-link {
    padding: 6px 14px !important;
  }
}
@media (max-width: 350px) {
  .client-lobby-custom-table-row {
    grid-template-columns: 100px 140px auto !important;
  }
  .mobile .content-container {
    padding: 5px;
  }
  .client-lobby-custom-table-row {
    width: calc(100vw - 10px) !important;
  }
}
@media (max-width: 300px) {
  .client-lobby-custom-table-row {
    grid-template-columns: 100px 100px auto !important;
  }
}
@media (max-height: 550px) {
  .popup-content-filter {
    height: 95vh !important;
  }
  .lobby-mobile-filter-content-wrapper-for-filter {
    overflow: auto;
    height: 80vh !important;
    max-height: 80vh !important;
    min-height: 80vh !important;
  }
  .meal-details-popup-credit-points {
    max-height: calc(100vh - 270px);
  }
}
@media (max-height: 650px) {
  .meal-details-popup-first-tab-max-height {
    max-height: calc(100vh - 390px);
    overflow: auto;
  }
  .lobby-mobile-filter-content-wrapper {
    padding-top: 5px;
  }
}
