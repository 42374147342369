@import '../global_variables';

.dish-form {
  font-size: 20px;
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: $tablet-max) {
    flex-direction: column;
    font-size: 16px;
  }

  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: $tablet-max) {
    margin: 10px 0;
  }

  .item {
    padding: 10px;
  }

  .section1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 3;
    margin: 20px;

    @media (max-width: $laptop-max) {
      flex: 2;
    }

    .lang-item {
      min-width: 220px;
      max-width: 80%;
      //max-width: 50%;
      flex: 1;

      @media (max-width: $laptop-max) {
        max-width: 100%;
      }

      .lang-title {
        display: flex;
        align-items: center;
        margin-top: 30px;
        //margin: 0 30px;

        &.right {
          text-align: right;
        }

        &.left {
          text-align: left;
          flex-direction: row-reverse;

          input {
            text-align: left;
          }
        }

        span {
          padding: 0 20px;
        }
      }

      .text-area {
        .input-field {
          min-height: 250px;
          font: 400 1em Arial;
        }
      }
    }
  }

  .section2 {
    flex: 1;
    margin: 95px 20px;
    //margin: 65px 20px;

    @media (max-width: $laptop-max) {
      margin: 30px;
    }

    .price {
      background-color: $light-gray;
      border-radius: 8px;
    }

    .category-select {
      background-color: $light-gray;
      padding: 0 15px;
      border-radius: 8px;
      margin-top: 20px;

      select {
        width: 100%;
        padding: 18px 0;
        font: 400 1em Arial;

        border: 0;
        outline: none;

        background-color: $light-gray;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .upload-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin-top: 20px;
      background-color: $light-gray;
      border-radius: 8px;
      height: 280px;
      font-size: 1em;

      .add-image {
        display: flex;
        align-items: center;

        cursor: pointer;
        margin: 10px;

        img {
          height: 40px;
          width: 40px;
        }

        p {
          margin: 0 10px;
        }
      }
    }
  }
}
.actions .include-add p {
  padding: 0;
  margin: 0;
}
.actions .include-add img {
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.submit-section {
  display: flex;

  @media (max-width: $tablet-max) {
    flex-direction: column-reverse;
  }

  align-items: center;
  margin: 50px;

  .cancel {
    display: flex;
    justify-content: flex-start;

    h4 {
      color: $dark-blue;
      border-bottom: 1px solid $dark-blue;
      font-weight: normal;
      cursor: pointer;
      justify-self: flex-start;
    }
  }

  .submit-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;

    .btn {
      padding: 1em 6em;

      @media (max-width: $tablet-max) {
        padding: 0.8em 2em;
      }
    }
  }
}

/* Form */
.add-dishes-table {
  margin-top: 20px;
}

.add-dishes-table .dishes-header {
  display: flex;
  justify-content: space-between;
  height: 44px;
  background: #eff0f7;
  border-bottom: 0.5px solid #8289a3;
  border-radius: 6px 6px 0px 0px;
  align-items: center;
}

.add-dishes-table .dishes-header h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #2b3147;
  margin: 0;
  text-align: left;
  padding: 8px 20px;
}

.add-dishes-table .dishes-header h6:nth-child(1) {
  width: 20%;
}

.add-dishes-table .dishes-header h6:nth-child(2) {
  width: 35%;
}

.add-dishes-table .dishes-header h6:nth-child(3) {
  width: 10%;
}

.add-dishes-table .dishes-header h6:nth-child(4) {
  width: 15%;
}

.add-dishes-table .dishes-header h6:nth-child(5) {
  width: 10%;
}

.add-dishes-table .dishes-header h6:nth-child(6) {
  width: 10%;
  text-align: right;
}

.add-dishes-table .dish.dish-content section {
  padding: 8px 20px;
}

.add-dishes-table .dish.dish-content section:nth-child(1) {
  width: 20%;
}

.add-dishes-table .dish.dish-content section:nth-child(2) {
  width: 35%;
}

.add-dishes-table .dish.dish-content section:nth-child(3) {
  width: 10%;
}

.add-dishes-table .dish.dish-content section:nth-child(4) {
  width: 15%;
}

.add-dishes-table .dish.dish-content section:nth-child(5) {
  width: 10%;
}

.add-dishes-table .dish.dish-content section:nth-child(6) {
  width: 10%;
}

.add-dishes-table .dish.dish-content section.dish-image .dish-img {
  width: 75px;
  height: 60px !important;
}

.add-dishes-table .dish.dish-content section.dish-image {
  display: flex;
  align-items: center;
}

.add-dishes-table .dish.dish-content section.dish-image p {
  text-align: left;
  margin: 0;
  margin-left: 5px;
}
.dish-name-with-tag {
  width: calc(100% - 80px);
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding-left: 5px;
  margin: 5px 0;
}

.add-dishes-table .dish.dish-content p {
  font-weight: 400;
  // font-size: 14px;
  // line-height: 18px;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #6d7287;
  margin-bottom: 0;
}

.add-dishes-table .dish.dish-content {
  background: #ffffff;
  border-bottom: 0.5px solid #8289a3;
  border-radius: 0;
}

.add-dishes-table .include-add img {
  height: 20px;
  cursor: pointer;
}

.add-dishes-table .rating-wrapper > div {
  align-items: flex-start;
  justify-content: flex-start;
}

.add-dishes-table .include-add,
.add-dishes-table .include-add p {
  text-align: right !important;
}

.add-dish-to-meal .search-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.add-dish-to-meal .dish-name-with-tag {
  width: calc(100% - 75px);
}
.add-dish-to-meal .dish-name-with-tag p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin: 0;
}
.dish-name-with-tag p {
  margin: 5px 0;
}
.add-dish-to-meal .makeStylesRating {
  min-width: 90px;
  display: unset;
}
.rtl .dish-name-with-tag {
  padding-left: 0;
  padding-right: 5px;
}
.add-dish-to-meal section.image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 5px;
}
.add-dish-to-meal .dish-img {
  width: 75px;
  height: 60px !important;
}

.add-dish-to-meal .search-section p.back-link-text {
  margin: 0;
}

.add-dish-to-meal .search-input-section {
  margin-left: 20px;
  max-width: 392px;
  width: 100%;
  position: relative;
}
.autocomplete {
  position: relative;
}

#suggestions {
  position: absolute;
  max-height: 200px;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  color: #6d7287;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
#suggestions:empty {
  border: 0;
}
#suggestions::-webkit-scrollbar {
  width: 4px;
  height: 3px;
}
#suggestions::-webkit-scrollbar-track {
  background: #cacaca;
  border-radius: 10px;
}
#suggestions::-webkit-scrollbar-thumb {
  background: #00d100;
  border-radius: 10px;
}
#suggestions li {
  padding: 7.5px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

#suggestions li:hover {
  background-color: rgba(39, 232, 69, 0.2);
}
.search-input-section {
  margin-left: 20px;
  max-width: 392px;
  width: 100%;
  position: relative;
}
.dish-management-page .search-section.add-dish-to-meal-section {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  max-width: 100vw;
  align-items: start;
}
.back-link-additional {
  position: relative;
  width: 120px;
  height: 50px;
  display: block !important;
}
.back-link-additional p {
  padding-top: 7.5px;
}
.dish-management-search-block {
  display: flex;
  min-width: 500px;
}
.add-dish-to-meal .search-input-section input {
  padding: 11px 10px 11px 40px;
  gap: 10px;
  height: 43px;
  background: #eff0f7;
  border-radius: 6px;
  border: 1px solid #eff0f7;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6d7287;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.add-dishes-table .dish.dish-content {
  overflow: auto;
}

.add-extra .dishes-header h6:nth-child(2) {
  width: 10%;
}

.add-extra .dish.dish-content section:nth-child(2) {
  width: 10%;
}

.add-extra .dishes .dish-price {
  width: unset;
}

.add-extra .dish .section2 {
  flex: unset !important;
}

.meal-management .dishes-header h6:nth-child(n + 1),
.meal-management .add-dishes-table .dish.dish-content section:nth-child(n + 1) {
  width: 12%;
  text-align: left;
}

.meal-management .dishes-header h6:last-child,
.meal-management .add-dishes-table .dish.dish-content section:last-child {
  width: 14%;
}

.meal-management .actions {
  display: flex;
  justify-content: flex-end;
}

.meal-management .add-dishes-table .dish.dish-content {
  padding: 0;
  align-items: center;
}

.add-dish-to-meal .search-input-section img {
  position: absolute;
  top: 10px;
  left: 10px;
}
.search-input-section-reset {
  cursor: pointer;
  color: red;
  font-size: 16px;
  margin: 0;
}
.add-dish-to-meal-search {
  height: 42px !important;
}
.meal.dish.dish-content:first-child {
  margin-top: 0;
}
@media screen and (max-width: 640px) {
  .dish-management-search-block {
    display: flex;
    gap: 15px;
    min-width: 150px;
    flex-direction: column;
  }
  .dish-management-search-block .from-date {
    margin-left: 10px;
  }
  .search-input-section {
    margin-left: 7.5px;
  }
  .search-input-section input {
    max-width: 140px !important;
  }
}
@media screen and (max-width: 480px) {
  .dish-management-search-block .flex .search-input-section {
    margin-left: 5px;
  }
}
@media screen and (max-width: 991px) {
  .add-dishes-table .dishes-header {
    display: none;
  }
  .flex-add-dish {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .dish-name-with-tag {
    width: 100%;
    padding-left: 0;
  }
  .dish-management-page .search-section .dish-management-search-block img {
    left: 12px;
  }
  .add-dishes-table .dish.dish-content section {
    padding: 10px 0px;
  }

  .add-dishes-table .dish.dish-content section.dish-description {
    display: none;
  }

  .add-dishes-table .dish.dish-content section.dish-image .dish-img {
    display: none;
  }

  .add-dishes-table .dish.dish-content {
    flex-direction: row;
  }

  .add-dishes-table .dish.dish-content .section2 {
    display: none;
  }

  .add-dishes-table .dish.dish-content section:nth-child(6) {
    width: 5% !important;
  }

  .add-dishes-table .dish.dish-content section:nth-child(1) {
    width: 50%;
  }

  .add-dishes-table .dish.dish-content section:nth-child(3) {
    width: 20%;
    overflow: hidden;
  }

  .add-dishes-table .dish.dish-content section:nth-child(4) {
    width: 20%;
  }
  .add-dishes-table.add-extra .dish.dish-content section:nth-child(4) {
    width: 30%;
  }

  .add-dishes-table .dish.dish-content section:nth-child(6) {
    width: 5% !important;
  }

  .add-dishes-table .dish.dish-content {
    overflow: auto;
  }

  .add-dish-to-meal .search-input-section {
    margin-left: 0;
    margin-top: 10px;
  }

  .add-dish-to-meal .search-section {
    align-items: flex-start;
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .add-dish-to-meal .dish-img {
    display: none;
  }
  .add-dish-to-meal .dish-name-with-tag {
    width: 100%;
    padding-left: 0;
  }
  .add-dishes-table .rating-wrapper > div svg,
  .lobby-manager .rating-wrapper > div svg {
    width: 16px;
    height: 16px;
  }
  .add-dishes-table .rating-wrapper > div p,
  .lobby-manager .rating-wrapper > div p {
    font-size: 12px !important;
  }
}

// rtl CSS
.rtl .add-dish-to-meal .search-input-section {
  margin-left: 0;
}
.rtl .add-dish-to-meal .search-input-section input {
  padding: 11px 40px 11px 10px;
}
.rtl .add-dish-to-meal .search-input-section img {
  left: unset;
  right: 10px;
  transform: rotate(90deg);
}
.flex-add-dish {
  display: flex;
  align-items: center;
  gap: 10px;
}
