@import 'global_variables';
.rtl {
  .form-explanation {
    width: 34% !important;
  }
}
.employee-payment-management {
  .form {
    .upper-placeholder {
      font-size: 0.8em;
    }
    .input-group {
      .label-input {
        .icon {
          height: 24px;
          width: 24px;
        }
        .input-section {
          padding-left: 15px;
        }
      }
    }
  }
}
.signup,
.profile {
  font-size: 20px;
  @media (max-width: $tablet-max) {
    font-size: 16px;
  }

  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;

  @media (max-width: $laptop-max) {
    width: 80%;
  }
  @media (max-width: $tablet-max) {
    width: 100%;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-explanation {
      align-self: center !important;
      margin: 10px;
      width: 100%;

      font-size: 0.8em;
      text-align: right;
    }

    .inputs-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;

      .input-field {
        font-size: 1em;
      }

      .one-column {
        grid-column: span 1;
        @media (max-width: $tablet-max) {
          grid-column: span 2;
        }
      }

      .two-columns {
        grid-column: span 2;
        @media (max-width: $tablet-max) {
          order: 5;
        }
      }

      .card-id,
      .employee-id {
        grid-column: span 1;
        @media (max-width: $tablet-max) {
          grid-column: span 2;
        }

        order: 10;
      }
    }

    .checkboxes-wrapper {
      margin-top: 50px;

      p {
        color: $lighter-black;
        @media (max-width: $mobile-max) {
          font-size: 0.8em;
        }
      }
    }

    .submit-btn {
      padding: 10px 100px;
      margin-top: 20px;
    }
  }
}
