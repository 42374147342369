.meal-time-content {
}
.meal-time-content-box {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 40px;
  max-width: 1074px;
  width: 100%;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif !important;
}

.order-meal-box h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.18em;
  color: #23283c;
  margin: 0;
  padding-bottom: 30px;
  font-family: 'Poppins', sans-serif !important;
}

.order-meal {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 20px;
}

.order-meal-date {
  position: relative;
  max-width: calc(50% - 77px);
  width: 100%;
  font-family: 'Poppins', sans-serif !important;
}

.order-meal-date p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #23283c;
  text-align: start;
  margin: 0;
  padding-bottom: 15px;
  font-family: 'Poppins', sans-serif !important;
}

.order-meal select.form-select {
  height: 48px;
  border: 1px solid rgba(35, 40, 60, 0.3) !important;
  border-radius: 6px;
  background: #fff !important;
  padding: 8px 10px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #23283c;
  max-width: 100%;
}
.order-meal .form-select:focus {
  box-shadow: none;
}
.order-meal-btn button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  width: 154px;
  height: 48px;
  gap: 8px;
  background: #14cd30;
  border-radius: 6px;
  border: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  box-shadow: none;
  font-family: 'Poppins', sans-serif !important;
}
.order-meal-btn button:hover {
  background: #19b630 !important;
}
.rtl .order-meal-btn button img {
  transform: rotate(180deg);
}
/* .order-meal-btn button img {
  padding-left: 8px;
} */
.disable-btn button {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.7;
}

/******************************* Media Query *************************/

@media (max-width: 768px) {
  .order-meal {
    flex-direction: column;
    gap: 35px;
  }
  .order-meal-box h1 {
    font-size: 30px;
    line-height: 45px;
  }
  .order-meal-date {
    margin: auto;
    max-width: 100%;
  }
  .order-meal-btn {
    margin: auto;
    max-width: 400px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 576px) {
  .meal-time-content-box {
    box-shadow: none;
    padding: 0;
  }
}
