@import 'global_variables';

.order-voucher {
  display: flex;
  flex-direction: column;

  .green-border {
    width: 100%;
    max-width: 1300px;
    align-self: center;

    border: 2px solid $light-green;
    padding: 20px;

    margin: 0px 0;
    border-radius: 8px;

    .order-container {
      display: flex;
      border-radius: 8px;
      // background-color: $cards-background-color;

      color: $lighter-black;
      text-align: start;
      font-size: 20px;

      @media (max-width: $laptop-max) {
        flex-direction: column;
        font-size: 16px;
      }

      .dish-img {
        flex: 3;
        max-width: 350px;
      }

      .order-details {
        display: flex;
        flex-direction: column;
        flex: 5;
        padding: 30px 40px;

        @media (max-width: $tablet-max) {
          padding: 20px 0px;
        }

        .order-title {
          display: flex;
          @media (max-width: $tablet-max) {
            flex-direction: column;
          }
          justify-content: space-between;
          align-items: center;

          h4 {
            margin: 0;
          }
        }

        .order-time {
          border-top: 1px dashed #a6bcd0;
          font-weight: 400;
          padding-top: 16px;
          margin-top: 20px;
        }

        .order-description {
          margin-top: 40px;
          color: $darker-green;
          font-weight: 600;
          font-size: 30px;
          text-align: center;
        }
      }
      .qr-code {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.order-voucher .green-border .order-container .qr-code {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: fit-content !important;
  width: fit-content !important;
  margin: 5px auto !important;
  margin-right: 5px !important;
  // mix-blend-mode: difference;
}
.order-voucher .green-border .order-container .voucher-realized {
  margin: 0 0 15px 6px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}
.qr-code img {
  height: 200px;
}
@media screen and (max-width: 768px) {
  .mob-right-section p {
    margin: 5px 0px;
    display: flex;
    justify-content: center;
  }

  .mob-right-section .order-details p {
    margin: 15px 0px;
  }

  .order-details p {
    margin: 5px 0px;
  }

  .mob-right-section p span {
    font-weight: 600;
    color: #475058;
    margin-right: 10px;
    font-size: 14px;
  }
  .order-voucher.mob-voucher
    .green-border
    .order-container
    .order-details
    .order-description,
  .order-details p {
    display: flex;
    justify-content: center;
  }
  .order-voucher .green-border .order-container .qr-code {
    width: 100% !important;
    margin: 0px auto;
    justify-content: center !important;
  }
  .qr-code img {
    height: 175px;
  }
  .order-voucher .green-border .order-container .qr-code {
    padding: 0px;
  }
}

.order-container.multidish-voucher {
  flex-direction: column;
}

.full-width .order-details {
  width: calc(100% - 350px);
}

.order-container.multidish-voucher img {
  width: 200px;
  object-fit: cover;
  border-radius: 5px !important;
  height: 150px;
}

.full-width {
  display: flex;
  margin-bottom: 20px;
}
