@import 'global_variables';

.search-line-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $mobile-max) {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .search {
    display: flex;
    flex: 1;
    @media (max-width: $mobile-max) {
      flex: unset;
      position: relative;
      z-index: 1;
    }

    &.toggle {
      max-width: 100%;
    }

    .input {
      width: 40%;
      @media (max-width: $mobile-max) {
        position: absolute;
        left: 60px;
        height: 100%;
        width: 0;
        padding: 0;
        opacity: 0.8;
        transition: all 0.2s ease-in-out;
        &.toggle {
          opacity: 1;
          width: calc(100vw - 30px - 60px - 20px);
          padding: 10px 20px;
        }
      }

      background-color: $light-gray;
      border: 0;
      border-radius: 8px;
      outline: none;

      font-size: 1em;
      text-align: start;
      padding: 10px 20px;
    }

    button {
      background-color: transparent;
      border: 0;
      outline: none;

      .we-icon {
        &:hover {
          cursor: auto;
          @media (max-width: $mobile-max) {
            cursor: pointer;
          }
        }
      }
    }
  }

  .add-btn {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    transition: opacity 0.2s ease-in-out;

    &.toggle {
      opacity: 0;
    }
  }
}
