.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 30;
  height: 80px;
  background: #ffffff;
  // box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  z-index: 999;
  position: sticky;
  top: 0px;
  border-bottom: 1px solid #eff0f7;

  section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 50px;

    @media screen and (max-width: 768px) {
      padding: 0px 15px;
    }

    .left-section {
      display: flex;
      align-items: center;
      max-width: 300px;
      width: 100%;
      @media screen and (max-width: 768px) {
        max-width: unset;
        width: 50px;
      }

      p {
        font-weight: 700;
        font-size: 20px;
        color: #23283c;
        margin-left: 55px;
      }

      @media screen and (max-width: 768px) {
        p {
          display: none;
        }
      }
    }

    .user-name {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      color: #878787;
      @media screen and (max-width: 768px) {
        position: relative;
      }
    }

    @media screen and (max-width: 768px) {
      .user-name > div {
        display: none !important;
      }

      .user-name .user-wallet {
        display: block !important;
        position: absolute;
        right: 62px;
      }
      // .user-name .user-wallet h6 {
      //   display: flex;
      // }
      .user-name .lang-login-dropdown {
        display: unset !important;
        margin-left: 10px;
      }
    }
  }

  .no-hamburger {
    flex: unset;
    max-width: unset;
  }

  .burger_menu {
    @media (max-width: $tablet-max) {
      right: 10px;
    }
  }

  .logo-header {
    width: 40px;
    height: 38px;
    margin: 10px;
    cursor: pointer;
    object-fit: contain;
  }
}

.sidebar-languages-selection .languages-selection {
  width: 100%;
  max-width: 100%;
  color: white;
  margin-top: 0px;
}

.sidebar-languages-selection {
  width: 100%;
  display: block;
}

.sidebar-languages-selection .lang-dropdown {
  position: absolute;
  background: #475058;
  width: 100%;
  right: 0px;
  border-radius: 5px;
  margin-top: 12px;
  box-shadow: none;
  bottom: 25px;
  z-index: 9999;
}
.flex-file-upload {
  display: flex;
  gap: 10px;
}
.flex-file-upload-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 10px;
}
section.fixed-sidebar-section {
  height: 110px;
  background: #475058;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.sidebar section.items {
  height: 100%;
  min-height: 250px;
  overflow: auto;
  margin: 0 12px;
}
@media screen and (min-height: 300px) {
  .main-wrapper {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.sidebar section.items::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sidebar section.items::-webkit-scrollbar-track {
  background: #e9e9e9;
}

/* Handle */
.sidebar section.items::-webkit-scrollbar-thumb {
  background: #e5ffe9;
}

.sidebar-kitchen-selection.sub-header-wrapper {
  display: block !important;
}

.sidebar-kitchen-selection .sub-header {
  background: transparent !important;
  box-shadow: none !important;
}

.sub-header-wrapper .sub-header {
  position: sticky !important;
  top: 74.8px !important;
}

.sidebar-kitchen-selection .kitchens-list.show-kitchen {
  width: 100% !important;
}

.sub-header-wrapper .sub-header .kitchens-list .list-item {
  padding: 10px 45px 10px 45px !important;
  font-size: 16px !important;
  color: #f0f4f8 !important;
  font-weight: 400 !important;
}

.sidebar-kitchen-selection .content {
  background: #475058 !important;
  bottom: 25px !important;
  padding: 10px 0px;
  top: auto !important;
}

.languages-selection .lang-item {
  padding: 10px 45px 10px 32px !important;
}

/********** sidebar css ***********/
.sidebar .list-item img {
  height: 18px;
}
.language-section-version {
  margin-top: 10px;
  text-align: right;
}

.sidebar-upload-button-wrapper {
  display: flex;
  justify-content: center;
}
.sidebar-upload-filename {
  font-size: 9px;
  max-width: 50px;
  overflow: hidden;
  max-height: 15px;
  text-overflow: ellipsis;
  margin: 0;
}
.upload-file-block {
  font-size: 14px;
  max-width: 100px;
  overflow: hidden;
  max-height: 64px;
  text-overflow: ellipsis;
  margin: 0;
}
.file-actions-block {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.sidebar-upload-title {
  font-size: 12px;
  max-width: 75px;
  overflow: hidden;
  max-height: 15px;
  text-overflow: ellipsis;
  margin: 0;
  text-align: center;
}
.delete-file-block {
  height: 30px;
  margin: 0 auto;
  cursor: default;
  display: flex;
  gap: 10px;
}
.delete-file-block-column {
  display: flex;
  flex-direction: column;
}
i.las {
  font-size: 22px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.sidebar-edit-block {
  display: flex;
  flex-direction: column;
}
.sidebar-edit-block-control {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cursor-pointer-datepicker {
  cursor: pointer;
}
.upload-file-button {
  display: flex;
  margin: auto;
  cursor: pointer;
}
.errorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.errors {
  font-size: 10px;
  margin: 0 15px;
  color: red;
}
.sidebar-upload-input {
  max-width: 175px;
}
.sidebar-upload-input-rtl {
  max-width: 175px;
}
.sidebar-upload-input-rtl label {
  left: inherit !important;
  right: 1.75rem !important;
  transform-origin: right !important;
}
.language-section .languages-selection {
  position: initial;
  // max-width: 305px;
  max-width: 100%;
  width: 100%;
  // margin: 0 auto;
  margin: 0;
  border-radius: 6px;
}

.sidebar-menu {
  background: #ffffff !important;
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.05);
  border-radius: 0px 8px 8px 0px;
}

.MuiDrawer-paper.sidebar {
  background: #ffffff !important;
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.05);
  border-radius: 0px 8px 8px 0px;
  bottom: 0;
}

.sidebar .sidebar-menu .items ul li {
  width: 100%;
  padding: 0;
  border-bottom: none;
  margin: 0;
  border-radius: 6px;
}

.sidebar .sidebar-menu .items ul li:hover {
  background: #eff0f7;
}

.sidebar .sidebar-menu .items ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6d7287;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
}
.file-download-elem {
  margin: 0;
  margin-left: -3px;
}
.file-download-icon {
  margin-left: -1px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6d7287;
}
.sidebar .sidebar-menu .items ul li a.active svg circle,
.sidebar .sidebar-menu .items ul li a.active svg path,
.sidebar .sidebar-menu .items ul li a.active svg rect,
.sidebar .sidebar-menu .items ul li a.topbar-link.active-topbar-link svg circle,
.sidebar .sidebar-menu .items ul li a.topbar-link.active-topbar-link svg path,
.sidebar .sidebar-menu .items ul li a.topbar-link.active-topbar-link svg rect,
.nav-item.dropdown a.active svg circle,
.nav-item.dropdown a.active svg path,
.nav-item.dropdown a.active svg rect {
  stroke: #14cd30;
}
.sidebar
  .sidebar-menu
  .items
  ul
  li
  a.topbar-link.active-topbar-link
  svg
  path:nth-child(2) {
  fill: #14cd30;
}

.sidebar .sidebar-menu .items ul li a.topbar-link.active-topbar-link,
.sidebar-menu a.dropdown-toggle.nav-link.active {
  background: #e5ffe9;
  border-radius: 8px;
  color: #14cd30;
  font-weight: 600;
}

section.logo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px 0px;
}

section.logo-section img {
  cursor: pointer;
}
section.logo-section a.mobile-logo {
  display: none;
}

section.user-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 15px 45px;
  width: 100%;
  max-width: 320px;
  max-height: 150px;
}

section.user-profile .user-name img {
  height: 36px;
  width: 36px;
  margin-right: 10px;
}

.sidebar .kitchens-list.show-kitchen.pointer {
  // padding: 0px 15px;
  padding: 0;
  cursor: pointer;
  // margin: 15px 0px;
  position: relative;
}
.sidebar .kitchens-list.show-kitchen.pointer .btn-success {
  width: 100%;
  background: #fff;
  border: 1px solid #333951;
  border-radius: 8px;
  padding: 0 10px;
  height: 44px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar .kitchens-list.show-kitchen.pointer .btn-success {
  display: none;
}
.desk-kitchen-selector.sidebar
  .kitchens-list.show-kitchen.pointer
  .btn-success {
  display: flex;
  gap: 15px;
}
.sidebar .kitchens-list.show-kitchen.pointer .dropdown-toggle::after {
  display: none;
}

.sidebar .kitchens-list.show-kitchen.pointer .content {
  background: #ffffff;
  // box-shadow: 0px 0px 25px rgb(0 0 0 / 15%);
  border-radius: 8px;
  padding: 0px 0px;
  display: none;
  transition: all ease 0.5s;
  position: absolute;
  width: calc(100% - 27px);
  bottom: 50px;
  z-index: 9999;
  left: 15px;
  max-height: 136px;
  overflow-y: auto;
  scroll-behavior: smooth;
  box-shadow: 0px 0px 10px 0px #d8d8d8;
}

.sidebar .kitchens-list.show-kitchen.pointer .content::-webkit-scrollbar {
  width: 3px;
  border-radius: 3px;
}

.sidebar .kitchens-list.show-kitchen.pointer .content::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 3px;
}

.sidebar .kitchens-list.show-kitchen.pointer .content::-webkit-scrollbar-thumb {
  background: #e5ffe9;
  border-radius: 3px;
}

.sidebar .kitchens-list.show-kitchen.pointer .content.active {
  display: block;
}

.sidebar .kitchens-list.show-kitchen.pointer .content .list-item {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  justify-content: space-between;
}

.sidebar .kitchens-list.show-kitchen.pointer .content .list-item:hover {
  background: #eff0f7;
}
.sidebar .kitchens-list.show-kitchen.pointer .list-item:active {
  background: #dfdfe2;
  color: #212529;
}

.sidebar .kitchens-list.show-kitchen.pointer .list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar .list-item.main-kitchen {
  background: #ffffff;
  border: 1px solid #333951;
  border-radius: 8px;
  padding: 0px 10px;
  height: 44px;
}

.sidebar .list-item.main-kitchen p {
  margin: 0;
}

// .sidebar .language-section {
//   margin-bottom: 25px;
// }

.sidebar .languages-selection .lang-item {
  padding: 10px 10px 10px 0 !important;
  cursor: pointer;
  margin: 0;
}
.language-section .languages-selection .languages {
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #333951;
  border-radius: 8px;
  padding: 0px 10px;
  height: 44px;
}
// .sidebar .languages-selection .lang-item:hover {
//   background: #eff0f7;
// }

.sidebar {
  max-width: 335px;
  width: 100%;
}

.lang-login-dropdown .dropdown button {
  background: #14cd30;
  border: none;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 500;
  border: 1px solid #14cd30;
  text-transform: uppercase;
}

.lang-login-dropdown .dropdown a {
  text-transform: uppercase;
}

.lang-login-dropdown .dropdown button:focus {
  box-shadow: none;
  outline: none;
}

.lang-login-dropdown .dropdown-item:active {
  background: #e9ecef !important;
}
.sidebar .sidebar-menu {
  width: 100% !important;
  min-height: 100%;
  height: 100%;
  scroll-behavior: auto;
}
.sub-header-wrapper .sub-header .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #23283c;
  // margin: 15px 0px 0px;
  font-family: 'Poppins';
  text-align: left;
}
.language-section .languages-selection .languages .lang-item {
  padding: 0px !important;
  height: 100%;
}
.sidebar .language-section .dropdown button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  width: 100%;
  background: #fff;
  border: 1px solid #333951;
  border-radius: 8px;
  padding: 0 10px;
  height: 44px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
}

.sidebar .language-section .dropdown button::after {
  display: none;
}

.sidebar .language-section .dropdown button .language-left-section img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.language-section {
  // padding: 0px 15px;
  padding: 0;
  cursor: pointer;
  // margin: 15px 0px;
  margin: 0;
  position: relative;
}

.sidebar .language-section a.dropdown-item {
  padding: 12px 10px;
}
.sidebar .language-section a.dropdown-item img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.sidebar .language-section .dropdown {
  position: relative;
}
.sidebar .language-section .dropdown-menu.show {
  position: absolute;
}
@media screen and (max-width: 768px) {
  .language-section .languages-selection {
    padding: 0px 0;
  }
  .language-section .languages-selection .languages {
    height: 38px;
  }
  .language-section-version {
    margin-bottom: 0;
    text-align: right;
  }
  .main-wrapper {
    height: calc(100vh - 250px) !important;
  }
  @media screen and (max-height: 765px) {
    .main-wrapper {
      height: calc(100vh - 200px);
    }
  }
  .sub-header-wrapper .sub-header .title {
    padding: 0;
    text-align: center;
  }
  .languages-selection .lang-item {
    padding: 10px 10px 10px 0px !important;
  }

  .lang-dropdown .lang-item {
    justify-content: flex-start;
    margin: 0px 0px !important;
  }

  .sidebar-languages-selection .lang-dropdown {
    bottom: 38px !important;
  }

  .sub-header-wrapper .sub-header .kitchens-list .list-item {
    padding: 10px 14px !important;
  }

  .sidebar-languages-selection .languages-selection {
    margin-top: 0px !important;
  }
  .sidebar {
    height: 100vh;
    background-color: #475058 !important;
  }

  section.fixed-sidebar-section {
    height: 110px;
    background: #475058;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .sidebar section.items {
    // height: calc(100vh - 247px);
    height: calc(100% - 270px);
    overflow: auto;
  }

  .sidebar-languages-selection .languages-selection {
    padding-left: 5px;
  }

  .sidebar .sidebar-menu .items ul li a {
    font-size: 14px;
    line-height: initial;
    width: 100%;
    padding: 10px;
  }

  section.user-profile {
    padding: 25px 15px 25px;
  }

  .sidebar .list-item.main-kitchen p {
    font-size: 13px;
  }

  .sidebar .list-item.main-kitchen {
    height: 38px;
  }

  // .sidebar .languages-selection .lang-item {
  //   padding: 0px 10px !important;
  // }

  section.user-profile .user-name span {
    font-size: 12px;
  }

  // .logout .icon-wrapper p.we-icon-text.small {
  //   display: none;
  // }

  section.logo-section img:first-child {
    height: 25px;
  }
  section.user-profile .logout p {
    margin: 0;
  }
  .sub-header-wrapper .sub-header .title {
    padding: 0;
    text-align: center;
    margin: 0 !important;
    font-size: 16px !important;
  }
  .header .logo-header {
    // display: none;
  }
  .header .user-name img {
    margin: 0 !important;
  }
  .lang-shortcode img {
    height: 16px !important;
    width: 17px;
    margin-right: 10px;
  }
  .language-section .languages-selection .languages .lang-dropdown .lang-item {
    padding: 8px 0px !important;
  }
  .languages .lang-shortcode span {
    font-size: 13px !important;
  }
  header.header.before-login img.logo-header {
    display: block;
    margin: 0px !important;
  }

  header.header.before-login .user-name img {
    margin: 0px !important;
  }
  section.logo-section img.desk-logo {
    display: none;
  }
  .logo-size {
    max-width: 15px;
    max-height: 15px;
  }
  section.logo-section a.mobile-logo {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  section.logo-section a.mobile-logo img:first-child {
    height: 25px;
  }
  section.logo-section .mobile-logo img {
    height: 15px;
  }
  .lang-login-dropdown .dropdown button {
    font-size: 12px !important;
    padding: 5px;
  }
  .rtl .user-name .lang-login-dropdown {
    margin-left: 0 !important;
    margin-right: 10px;
  }
  .lang-login-dropdown .dropdown a {
    font-size: 14px;
  }
  .sidebar .kitchens-list.show-kitchen.pointer .btn-success {
    display: flex;
  }
  .desk-kitchen-selector.sidebar
    .kitchens-list.show-kitchen.pointer
    .btn-success {
    display: none;
  }
}

.sidebar .sidebar-footer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}
.logged-in-name {
  text-align: center !important;
}
.header section .left-section .header-notification-text,
.header-notification-text {
  color: #fff;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  padding: 0 5px;
  display: block;
}
.notification-round {
  position: absolute;
  top: 0;
  left: 20px;
  min-width: 20px;
  width: auto;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-round-he {
  position: absolute;
  top: 0;
  right: 20px;
  min-width: 20px;
  width: auto;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-notification-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.sidebar-notification-round {
  top: unset;
  left: unset;
}
.capsule {
  border-radius: 10px;
}
.burger-menu-wrapper {
  position: relative;
}
.toast-notification-style {
  background-color: #ffe67d !important;
  color: #000 !important;
  font-size: 16px;
  padding: 30px 20px;
}
.toast-notification-style button svg {
  color: #000;
  width: 25px;
  height: 25px;
}
.toast-notification-style .Toastify__toast-body {
  align-items: start;
}
.toast-notification-style .Toastify__toast-body .Toastify__toast-icon {
  margin-top: 2px;
}
.toast-content-wrapper {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 5px;
}
.toast-content-wrapper p,
.toast-content-wrapper h5 {
  margin: 0;
}
.toast-content-wrapper h5 {
  font-weight: 600;
}
.rtl {
  section.user-profile .user-name img {
    margin-right: 0px;
    margin-left: 10px;
  }
  section.logo-section img:last-child {
    transform: rotate(180deg);
  }
  section.logo-section .mobile-logo img {
    transform: rotate(0deg);
  }
  .logged-in-name {
    text-align: center !important;
  }
  @media screen and (max-width: 768px) {
    .user-name .user-wallet {
      right: unset !important;
      left: 45px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.user-name-span {
  max-height: 150px;
  max-width: 180px;
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis;
  margin: auto;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .user-name-span {
    max-width: 155px;
  }
}
@media screen and (max-width: 480px) {
  .sub-header {
    display: none !important;
  }
  .sub-header.mobile {
    display: block !important;
  }
}
.user-name {
  display: flex;
}
