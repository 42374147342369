@import 'global_variables';

/* Buttons */

.button-wrapper {
  display: flex;

  .we-button {
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 36px;
    padding: 5px 30px;

    @media (max-width: $tablet-max) {
      padding: 5px 15px;
    }

    font-weight: bold;

    color: $white-color;
    font-size: 1em;
    text-align: center;

    &.active {
      background-color: $enabled-button-background;

      &:hover {
        background-color: $enabled-button-hover-background;
        cursor: pointer;
      }

      transition: all 0.3s ease;
    }

    &.disabled {
      background-color: $darker-gray;

      &.finished {
        background-color: $dark-red;
      }
    }
  }

  /* Hide the Icon (arrow) in button */
  button > div {
    @media (max-width: $tablet-max) {
      display: none;
    }
  }
}

.alert-dialog {
  width: 300px;
}
.button-alert-green {
  background-color: rgb(20, 205, 49) !important;
  margin-bottom: 25px !important;
}
.alert-text p {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #595959 !important;
  text-align: center !important;
}
.alert-title h2 {
  color: #595959 !important;
  font-size: 35px !important;
  font-weight: 600 !important;
  text-align: center !important;
  text-transform: none !important;
  word-wrap: break-word !important;
  font-family: 'OpenSans', sans-serif !important;
}
.alert-dialog-big {
  width: 600px;
}
.icon-wrapper {
  display: flex;
  flex-direction: column;

  &.back-button {
    flex-direction: row;
  }

  align-items: center;

  width: fit-content;

  &:hover {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .we-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px 0 0;

    @media (max-width: $tablet-max) {
      margin: 0 6px 0 0;
    }

    border-radius: 50px;
    padding: 0;

    &.back-button {
      transform: scaleX(-1);
    }

    img {
      height: 20px;
      width: 20px;
    }

    &.green {
      background-color: $darker-green;
    }

    &.red {
      background-color: $dark-red;
    }
  }
}

.we-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rtl .we-toggle * {
  text-align: left !important;
}
.we-icon-text {
  font-weight: bold;
  text-decoration: underline;
  margin-right: 10px;

  @media (max-width: $tablet-max) {
    margin-right: 6px;
  }

  width: max-content;

  &.xSmall {
    font-size: 0.7em;
  }

  &.small {
    font-size: 0.8em;
  }

  &.medium {
    font-size: 1em;
  }

  &.large {
    font-size: 1.2em;
  }

  &.back-button {
    text-decoration: unset;
    font-weight: normal;
  }

  &.toggle-text {
    margin: 4px 0 0 0;
  }

  &.show-on-mobile {
    @media (max-width: $tablet-max) {
      display: inline-block;
      width: max-content;
    }
  }
}

.item-card {
  background-color: $cards-background-color;
}

// .item-card {
//   &.future-card {
//     background-color: $future-item-background;
//   }
// }

.dish-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
}

/* no-dishes , no-meals */

.no-meals,
.no-dishes,
.no-users {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #2b3147;
  font-weight: 500;
  padding: 20px 0;

  p {
    font-size: 1.2em;
  }
}

/* Alignment */

.align-text {
  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}

.dash-left {
  @media (min-width: $tablet-max + 2px) {
    border-left: 1px dashed $darker-gray;
  }
}

.dash-bottom {
  border-bottom: 1px dashed $darker-gray;
}

.dish-name {
  text-align: right;
}

/* Rating */

.rating-wrapper {
}

/* Show previous */
.show-previous {
  display: flex;

  align-items: center;
  justify-content: center;

  .in-front {
    position: absolute;
    z-index: 2;
    margin-top: 100px;
  }

  .fake-item {
    width: 100%;
    transform: translateY(20px);
    opacity: 0.4;
  }
}

/* Swal classes */

.swal-alert {
  font-size: 20px;
}

.shadow-card {
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.voucherIcon {
  transform: rotate(90deg);
}

/* Animation */

.fadeInClass {
  animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.input-error-message {
  margin: 5px;
  font-size: 0.8em;
  color: $dark-red;
  opacity: 0.7;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Alignment */
.align-right {
  text-align: right;
  justify-content: flex-start;

  input,
  textarea {
    text-align: right;
  }
}

.align-left {
  text-align: left;
  justify-content: flex-start;
  flex-direction: row-reverse;

  input,
  textarea {
    text-align: left;
  }
}
