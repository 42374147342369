@import '../global_variables';

.menu-construction {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 20px;

  @media (max-width: $tablet-max) {
    font-size: 14px;
    padding-top: 55px;
    .custom-dropdown .dropdown-item {
      font-size: 12px;
    }
  }

  .filter {
    display: flex;
    margin: 0px 0px 30px 0;
    justify-content: space-between;

    @media (max-width: $tablet-max) {
      flex-direction: column;
      margin: 0px 0px 10px 0;
    }

    .item {
      padding: 10px 15px;
      margin-left: 20px;
      flex: 1;
      max-width: fit-content;

      @media (max-width: $tablet-max) {
        align-items: center;
        max-width: 100%;
        margin: 5px;
      }

      background-color: $light-gray;
      border: 0;
      border-radius: 8px;

      .placeholder {
        font-size: 0.8em;
        color: $darker-gray;
        text-align: start;
      }

      &.date {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .date-picker {
          cursor: pointer;
        }

        img {
          width: 30px;
          height: 30px;
          cursor: auto;
        }
      }

      &.type {
        select {
          width: 90%;
        }

        select,
        option {
          flex: 1;

          background-color: transparent;
          border: 0;
          outline: none;

          font-size: 1.2em;
          cursor: pointer;
        }
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    text-align: start;
    flex-wrap: wrap;

    @media (max-width: $tablet-max) {
      justify-content: space-between;
    }

    .titleLabel {
      @media (min-width: $tablet-max) {
        flex: 5;
      }
    }

    .summaryLabel {
      display: flex;
      justify-content: center;
      margin: 0 20px;

      @media (max-width: $tablet-max) {
        order: -1;
        width: 100%;
        margin: 0px 0px 20px;
        flex-direction: row;
      }

      .separator {
        padding: 0 20px;
      }
    }
  }

  .meal-dish {
    display: flex;
    align-items: center;

    &.deleting {
      opacity: 0.3;
      transition: all 0.5s ease-in-out;
    }

    @media (max-width: $tablet-max) {
      flex-direction: column;
    }

    margin: 20px 0;

    background-color: $light-gray;
    border-radius: 8px;
    overflow: auto;

    .image {
      //flex: 1;
      //min-width: 100px;

      @media (max-width: $tablet-max) {
        // display: none;
      }
    }

    section {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px 20px;

      .we-icon {
        padding: 0;
        margin: 0;
        //background-color: transparent;

        img {
          width: 1.5em;
          height: 1.5em;
        }
      }
    }

    .section1 {
      display: flex;
      flex: 4;
      text-align: start;
      align-items: flex-start;

      //border-left: 1px dashed $darker-gray;
      @media (max-width: $tablet-max) {
        border-left: 0;
        border-bottom: 1px dashed $darker-gray;
      }

      .name {
        flex: 2;
        margin-right: 10px;

        @media (max-width: $tablet-max) {
          margin-right: 0;
          font-size: 14px;
          font-weight: bold;
          flex: 3;
        }
      }

      .rating {
        flex: 1;
      }
    }

    .section2 {
      flex: 3;
      justify-content: space-between;
      //margin-right: 50px;

      p {
        margin: 0 10px;

        @media (max-width: $tablet-max) {
          margin: 0;
        }
      }

      .toggle-text {
        margin-top: 3px;
      }

      .inventory {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;

        @media (max-width: $tablet-max) {
          justify-content: flex-start;

          div,
          img {
            display: none;
          }
        }

        input {
          width: 70px;
          height: 30px;
          text-align: center;
          cursor: pointer;

          margin: 0 10px;

          border: 0;
          border-radius: 3px;
          outline: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $tablet-max) {
          flex: 1;
        }

        div {
          margin: 2px;
        }
      }
    }
  }
}

.menu-construction .button-wrapper button {
  padding: 10px 25px !important;
  min-height: 50px !important;
}

.menu-construction .open-calendar {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  height: 68px;
}
.menu-construction-week-view-view-more-btn img {
  width: 12.5px;
  height: 15px;
  cursor: pointer;
}
.form-group-week-view {
  width: 100%;
  margin-bottom: 40px !important;
}
.form-group-week-view label {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #23283c;
}
.form-group-week-view .form-check-input:checked {
  background-color: #00d100;
  border-color: #00d100;
}
.form-group-week-view .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 209, 0, 0.25);
}
.menu-construction-week-view-no-meals-wrapper {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-construction-week-view-overflow {
  overflow-x: auto;
}
.menu-construction-week-view-row-7 {
  display: grid;
  grid-template-columns: 120px repeat(7, minmax(250px, 1000px));
}
.menu-construction-week-view-row-6 {
  display: grid;
  grid-template-columns: 120px repeat(6, minmax(250px, 1000px));
}
.menu-construction-week-view-row-5 {
  display: grid;
  grid-template-columns: 120px repeat(5, minmax(250px, 1000px));
}

.menu-construction-week-view-row-he-7 {
  display: grid;
  grid-template-columns: repeat(7, minmax(250px, 1000px)) 120px;
}
.menu-construction-week-view-row-he-6 {
  display: grid;
  grid-template-columns: repeat(6, minmax(250px, 1000px)) 120px;
}
.menu-construction-week-view-row-he-5 {
  display: grid;
  grid-template-columns: repeat(5, minmax(250px, 1000px)) 120px;
}

.menu-construction-week-view-hover-effect {
  transition: all 0.3s ease;
  cursor: pointer;
}
.menu-construction-week-view-inv {
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menu-construction-page-minus-top-margin {
  margin-top: -25px;
}
.menu-construction-week-view-hover-effect:hover {
  transform: scale(1.02);
  background-color: rgba(39, 232, 69, 0.2);
}
.menu-construction-week-view-header-day-week {
  font-family: 'OpenSans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24.5px;
  color: #626262;
}
.menu-construction-week-view-table-elem-header-wrapper {
  display: flex;
  justify-content: space-between;
  font-family: 'OpenSans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000;
  border-collapse: collapse;
}
.padding-5px {
  padding: 5px;
}
.menu-construction-week-view-table-elem-header {
  display: flex;
  gap: 25px;
}
.menu-construction-week-view-table {
  margin: 0 auto;
  direction: ltr !important;
}
.menu-construction-week-view-dish-type {
  width: 120px;
  background-color: #fff;
  z-index: 8;
}
.menu-construction-week-view-dish-type-he {
  justify-content: end;
}
.menu-construction-week-view-table th:first-of-type {
  border-top-left-radius: 15px;
}
.menu-construction-week-view-table th:last-of-type {
  border-top-right-radius: 15px;
}
.menu-construction-week-view-table tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}
.menu-construction-week-view-table tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}
.menu-construction-week-view-cell-list {
  list-style-type: none;
  padding: 0;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.menu-construction-week-view-border {
  border: 1px solid #e5e7f3;
}
.menu-construction-week-view-cell-font-light {
  font-family: 'OpenSans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  border-bottom: 2px solid #e5e7f3;
  min-height: 31px;
  width: 100%;
  display: flex;
}
.menu-construction-week-view-cell-dishname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 160px;
  padding: 5px;
  flex-grow: 1;
}
.menu-construction-week-view-cell-font-light:last-of-type {
  border: 0;
}
.menu-construction-week-view-cell-ordered {
  font-weight: 700;
  color: #00d100;
}
.menu-construction-week-view-cell-not-ordered {
  font-weight: 700;
  color: #ff2a2a;
}
.mw-172px {
  text-align: left;
  min-width: 172.5px;
  max-width: 172.5px;
}
.mw-155px {
  text-align: left;
  min-width: 155px;
  max-width: 155px;
}
.view-details-week-view-header {
  white-space: nowrap;
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.reservation-title-desktop {
  margin-left: 22px;
  font-size: 16px !important;
}
.reservation-title-desktop p {
  font-size: 16px !important;
}
.view-details-week-view-header-desktop {
  margin: 0;
  font-weight: bolder;
  font-size: 25px;
}
.user-details-block .week-view-card-ordered-link {
  font-weight: 700;
  color: #00d100;
  cursor: pointer;
}
.view-detail-head-ai-start {
  align-items: start !important;
}
.view-detail-head-desc {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #6d7287;
  text-align: left;
  word-break: break-word;
}
.view-detail-table {
  text-align: center;
  font-weight: 600;
}
.view-detail-table,
.view-detail-table-body-th,
.view-detail-table-body-td {
  border-collapse: collapse;
  border: 1px solid black;
}
.view-detail-table-head {
  background-color: black;
  color: #fff;
  text-align: center;
}
.view-detail-table-head-th {
  padding: 5px 30px;
  font-family: 'OpenSans', sans-serif !important;
}
.view-detail-table-body-td {
  padding: 5px 5px;
}
.menu-construction-week-view-filter-wrapper {
  display: flex;
  justify-content: space-between;
}
.user-details-row-mob,
.view-detail-table-mob {
  display: none !important;
}
.week-view-more-details-content-wrapper {
  margin: 25px 0;
  display: flex;
  gap: 15px;
}
.view-details-mob {
  display: none !important;
}
.view-details-desktop {
  display: flex !important;
}
.week-view-more-details-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 5px 0;
}
.week-view-more-details-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.week-view-card-image {
  max-width: 250px;
  max-height: 250px;
}
.border-right {
  border-right: 2px solid #e5e7f3;
}
.menu-construction-week-view-cell-bold {
  font-weight: 700;
}
.menu-construction-week-view-add-dish-text {
  font-family: 'OpenSans', sans-serif;
  font-weight: 600;
  color: #4cbe39;
  font-size: 14px;
  line-height: 19px;
}
.menu-construction-week-view-add-dish-button {
  cursor: pointer;
}
.menu-construction-week-view-header-date {
  font-family: 'OpenSans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #000;
}
.menu-construction-daterange-font-style {
  font-family: 'OpenSans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24.5px;
}
.menu-construction-week-view-header-cell {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
}
.menu-construction-week-view-cell-font {
  font-family: 'OpenSans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
.menu-construction-week-view-cell-margin-position {
  display: flex;
  justify-content: start;
}
.menu-construction .filter .item.date {
  position: relative;
}

.menu-construction .date-picker p,
.menu-construction .filter .item.type select {
  font-size: 18px;
  color: #383838;
}
.menu-construction .no-data .item {
  width: 100% !important;
  margin-left: 0px !important;
  max-width: 100% !important;
}
.datepicker-container {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  max-width: 275px;
}
.menu-construction .no-data .error-msg {
  color: red !important;
  font-size: 14px !important;
  margin-left: 5px;
  margin-top: 5px;
}

.menu-construction .filter .item.date .date-picker,
.menu-construction .filter .item.date .date-picker .react-datepicker-wrapper {
  width: 100%;
}

.menu-construction .filter .filter-left-section {
  align-items: flex-start !important;
}

.rtl .menu-construction .date-picker p,
.rtl .menu-construction .filter .item.type select {
  text-align: right !important;
  width: 100%;
}
.menu-construction .no-data {
  text-align: left;
  margin-right: 10px;
}
.menu-construction .meal-dish.mealdish-mob .mob-action {
  display: none;
}

.menu-construction .filter .filter-left-section {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-construction .filter button.MuiButtonBase-root,
.meal-action-dropdown .btn-primary {
  background: #14cd31;
  border-radius: 5px !important;
  padding: 10px 25px !important;
  min-height: 45px !important;
  color: white;
  text-align: left !important;
  font-size: 12px;
}
.meal-action-dropdown .btn-primary {
  border: none;
}
.meal-action-dropdown .btn-primary:hover {
  background: #14cd31 !important;
}
.meal-action-dropdown .dropdown-menu.show {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
  border: none;
}

.meal-action-dropdown .dropdown-item:active {
  background: transparent;
  color: #212529;
}

.menu-construction .meal-dish::-webkit-scrollbar {
  height: 5px;
}

.menu-construction .meal-dish::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.menu-construction .meal-dish::-webkit-scrollbar-thumb {
  background: #dee3e9;
}

.menu-construction span.MuiIconButton-label img {
  width: 18px;
  margin-left: 10px;
  margin-top: 2px;
}

.dropdwon-list .MuiPaper-root {
  top: 310px !important;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
}

.menu-construction .add-extras {
  margin: 0px 10px;
}

.menu-construction .add-extras .we-icon {
  margin-right: 0px !important;
  height: auto !important;
}

.menu-construction .add-extras button {
  font-weight: 500 !important;
  display: flex;
}

.extras-dish-section section.section2 {
  justify-content: flex-end !important;
}

.menu-construction .select-meal-type.disable-mealtype select {
  padding: 5px 10px !important;
  height: 45px;
}

.menu-construction .date-picker p,
.menu-construction .filter .item.type select {
  margin: 0;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px;
  color: #6d7287 !important;
}

.menu-construction .filter-left-section .open-calendar {
  height: 45px;
}

.menu-construction .filter .item {
  padding: 5px 10px;
  margin-left: 0px;
  height: 45px;
}

.menu-construction .no-data,
.menu-construction .no-meal-data {
  width: 250px;
}

.menu-construction .filter-left-section .icon-wrapper .we-icon {
  margin: 0px !important;
}

.menu-construction .filter-left-section .icon-wrapper .we-icon img {
  height: auto;
  width: 22px;
}

.menu-construction-page section.menuconstruction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
}

.menu-construction-page section.menuconstruction-header button,
.menu-construction-page section.menuconstruction-header button:hover,
.menu-construction-page section.menuconstruction-header button:focus {
  padding: 3px 10px;
  min-width: 113px;
  height: 30px;
  border: 1px solid #6d7287;
  border-radius: 3px;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
  display: flex;
  align-items: center;
}

.menu-construction-page section.menuconstruction-header p {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
}

.menu-construction-page .no-dishes {
  margin: 23px 0px;
  background: #eff0f7;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.menu-construction-page .no-dishes p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.menu-construction .filter .filter-left-section {
  width: fit-content;
}

.menu-construction .header-listing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  background: #eff0f7;
  // border-bottom: 0.5px solid #8289a3;
  border-bottom: 1px solid #e0e3ef;
  border-radius: 6px 6px 0px 0px;
}

.menu-construction .header-listing h6:first-child {
  width: 30%;
}

.menu-construction .header-listing h6 {
  margin: 0;
  padding: 0px 10px;
  width: 12.5%;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.menu-construction .header-listing h6:last-child {
  text-align: right;
}

.menu-construction .dishes {
  margin-top: 20px;
}

.menu-construction section.inventroy .inventory,
.user-details-block .inventory {
  padding: 5px 8px;
  width: 115px;
  height: 35px;
  border: 1px solid #14cd30;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #14cd30;
}
.user-details-block .inventory {
  width: 90px;
  height: 30px;
}
.menu-construction section.inventroy .inventory input.inv-input,
.user-details-block .inventory input.inv-input {
  border: none;
  max-width: 100%;
  width: 100%;
  text-align: center;
  color: #14cd30;
  font-weight: 500;
}
.user-details-block .we-toggle {
  align-items: unset;
}

.menu-construction section.inventroy .inventory i {
  cursor: pointer;
}

.menu-construction .meal-dish section.dish-name:first-child {
  width: 30%;
}

.menu-construction .meal-dish section {
  width: 12.5%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6d7287;
  display: flex;
  align-items: center;
  padding: 10px;
}

.menu-construction .meal-dish section:last-child {
  display: flex;
  justify-content: flex-end;
}

.menu-construction .meal-dish {
  margin: 0px 0;
  border-radius: 0;
  background: #ffffff;
  // border-bottom: 0.5px solid #8289a3;
  border-bottom: 1px solid #e0e3ef;
}

.menu-construction .meal-dish p {
  // font-weight: 400;
  // font-size: 14px;
  // line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #6d7287;
  margin: 0;
}
.menu-construction .meal-dish section.dish-name:first-child p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.menu-construction .actions i {
  color: #6d7287;
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.menu-construction .extras-section {
  margin: 30px 0px;
}

.menu-construction .extras-section .header-listing h6 {
  width: 13.33%;
  padding: 10px;
}

.menu-construction .extras-section .header-listing h6:first-child {
  width: 30%;
}

.menu-construction .extras-section .header-listing h6:last-child {
  width: 30%;
}

.menu-construction .extras-section .dishes section {
  width: 13.33%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6d7287;
}

.menu-construction .extras-section .dishes section:first-child {
  width: 30%;
}

.menu-construction .extras-section .dishes section:last-child {
  width: 30%;
}

.menu-construction-page select {
  padding: 0px 12px !important;
  height: 45px;
}

.menu-construction-page .menu-construction .no-data .item {
  max-width: 392px !important;
}

.menu-construction .filter p.back-link-text {
  margin: 0;
}

.menu-construction .custom-dropdown .dropdown-item p {
  display: flex;
}

.menu-construction .dishes .meal-dish section .mob-icon {
  display: none;
}

.menu-construction-page .summaryLabel .meal-summary .percent-data {
  margin: 0px 10px;
}
.menu-construction-page
  .summaryLabel
  .meal-summary
  .percent-data.order-percent
  p {
  color: #14cd30;
  cursor: pointer;
}

.menu-construction .filter .item.date i {
  color: #6d7287;
  font-size: 25px;
}
.menu-construction .meal-dish {
  overflow-x: initial;
  overflow-y: initial;
}

.menu-construction .meal-dish.mealdish-mob .mob-action {
  display: block;
}
.menu-construction .meal-dish {
  flex-direction: row;
}

.menu-construction .meal-summary .inventory {
  text-align: right !important;
}
.menu-construction .filter button.MuiButtonBase-root,
.meal-action-dropdown .btn-primary {
  padding: 5px 20px !important;
  min-height: 40px !important;
}

.menu-construction .button-wrapper button {
  min-height: 40px !important;
}
.menu-construction .meal-summary .percent-data {
  justify-content: flex-end;
}

.menu-construction .date-picker p,
.menu-construction .filter .item.type select {
  font-size: 12px;
}

.menu-construction .filter .item.type select {
  width: 100%;
}
.menu-construction .show-on-mobile.medium {
  display: none;
}

.menu-construction .filter {
  position: relative;
}

.menu-construction .filter .MuiBox-root {
  position: absolute;
  right: 0px;
  top: -25px;
}

.rtl .menu-construction .filter .MuiBox-root {
  left: 0px;
  right: auto;
}
.menu-construction .meal-summary {
  flex-direction: row;
}

.menu-construction .meal-dish.mealdish-mob .meal-summary {
  flex-direction: column;
  flex: 1 1;
}

.menu-construction .meal-dish.mealdish-mob .mob-action {
  padding-right: 10px;
}

.rtl .menu-construction .meal-dish.mealdish-mob .mob-action {
  padding-left: 10px;
}

// .mobile .sub-header .title .title-date {
//   display: none;
// }
.add-edit-dish-grid {
  display: grid !important;
  gap: 3rem;
  grid-template-columns: auto auto auto;
}
.add-edit-dish-grid-2 {
  width: calc(100% - 110px);
  display: grid !important;
  gap: 3rem;
  grid-template-columns: 33.3% 33.3% 33.3%;
}
.week-view-edit-first-section {
  gap: 3rem;
}

@media screen and (max-width: 991px) {
  .add-edit-popup-week-view .modal-dialog {
    width: 90vw;
    max-width: unset;
  }
}
@media screen and (max-width: 800px) {
  .add-edit-dish-grid-2 {
    width: calc(100% - 50px);
    display: grid !important;
    gap: 3rem;
    grid-template-columns: 50% 50%;
  }
}
@media screen and (max-width: 550px) {
  .add-edit-popup-week-view .modal-dialog {
    width: unset;
    max-width: unset;
  }
  .week-view-edit-first-section {
    flex-wrap: wrap;
    gap: 0 !important;
  }
  .add-edit-popup .week-view-edit-first-section .form-group {
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 475px) {
  .add-edit-dish-grid-2 {
    width: 100%;
    display: grid !important;
    gap: 3rem;
    grid-template-columns: 100%;
  }
}
.menu-construction .meal-dish .image {
  display: none;
}
.menu-construction .dishes .meal-dish section .mob-icon i {
  color: #6d7287;
  font-size: 16px;
}
.meal-action-dropdown .btn-primary img {
  width: 16px;
}
.meal-action-dropdown .dropdown-toggle::after {
  display: none;
}
.chef-img-week-view {
  width: 33px;
  height: 33px;
  color: green;
}
@media screen and (max-width: 1366px) {
  .menu-construction .no-meal-data {
    width: 50%;
  }

  .menu-construction .no-data {
    width: 50%;
    text-align: left;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .menu-construction .no-meal-data {
    width: 50%;
  }

  .menu-construction .no-data {
    width: 50%;
    text-align: left;
    margin-right: 10px;
  }
}

@media screen and (max-width: 991px) {
  .menu-construction .dishes .meal-dish section .mob-icon {
    display: flex;
  }

  .menu-construction-page section.menuconstruction-header p.titleLabel {
    display: none;
  }
  .view-detail-table-head-th {
    padding: 5px 15px;
    font-family: 'OpenSans', sans-serif !important;
  }
  .menu-construction-page section.menuconstruction-header {
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }

  .menu-construction .summaryLabel {
    width: 100%;
    margin-bottom: 10px;
  }

  .menu-construction-page section.menuconstruction-header p {
    margin-left: 5px;
  }

  .menu-construction-page section.menuconstruction-header .meal-summary {
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .menu-construction .header-listing h6,
  .menu-construction .dishes .meal-dish section {
    display: none;
  }

  // .menu-construction .header-listing h6:first-child,
  // .menu-construction .header-listing h6:nth-child(6),
  // .menu-construction .dishes .meal-dish section:first-child,
  // .menu-construction .dishes .meal-dish section:nth-child(6),
  // .menu-construction .dishes .meal-dish section:nth-child(8),
  // .menu-construction .extras-section .header-listing h6:first-child,
  // .menu-construction .extras-section .header-listing h6:nth-child(4),
  // .menu-construction .extras-section .dishes section:nth-child(4) {
  //   display: flex;
  //   width: 55%;
  // }

  // .menu-construction .dishes .meal-dish section:nth-child(6),
  // .menu-construction .header-listing h6:nth-child(6),
  // .menu-construction .extras-section .header-listing h6:nth-child(4),
  // .menu-construction .extras-section .dishes section:nth-child(4) {
  //   justify-content: center;
  //   width: 35%;
  // }
  .menu-construction .header-listing h6:nth-child(1),
  .menu-construction .header-listing h6:nth-child(2),
  .menu-construction .header-listing h6:nth-child(3),
  .menu-construction .header-listing h6:nth-child(4),
  .menu-construction .meal-dish section:nth-child(1),
  .menu-construction .meal-dish section:nth-child(2),
  .menu-construction .meal-dish section:nth-child(3),
  .menu-construction .meal-dish section:nth-child(4),
  .menu-construction .dishes .meal-dish section:nth-child(8) {
    display: flex;
  }

  .menu-construction .dishes .meal-dish section:nth-child(8) .actions {
    display: none !important;
  }

  .menu-construction .dishes .meal-dish section:nth-child(8) .mob-icon i {
    color: #6d7287;
    font-size: 16px;
  }

  .menu-construction .dishes .meal-dish section:nth-child(8) {
    width: fit-content !important;
  }

  .extras-section .meal-dish section:last-child {
    display: flex !important;
    width: 36px !important;
    flex: unset;
  }

  // .extras-section .meal-dish section:last-child .actions {
  //   display: none;
  // }

  .menu-construction .meal-dish .section2 .inventory .label .percent-data {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .menu-construction .no-data {
    width: 100%;
    margin-right: 0px;
  }
  .view-detail-table-head-th {
    padding: 5px 5px;
    font-family: 'OpenSans', sans-serif !important;
  }
  .menu-construction-week-view-filter-wrapper {
    flex-direction: column;
    gap: 10px;
  }
  .week-view-more-details-content-wrapper {
    flex-direction: column;
  }
  .menu-construction .filter .filter-left-section {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  .menu-construction .filter .MuiBox-root {
    top: -50px;
  }
  .meal-action-dropdown {
    position: absolute;
    right: 0;
    top: -50px;
  }
  .menu-construction-page section.menuconstruction-header button,
  .menu-construction-page section.menuconstruction-header button:hover,
  .menu-construction-page section.menuconstruction-header button:focus {
    font-size: 14px;
    min-width: fit-content;
  }
  .menu-construction .add-extras button {
    font-size: 14px !important;
  }
  .menu-construction .header-listing h6:last-child {
    width: 36px !important  ;
    display: flex;
  }
  .menu-construction-page section.menuconstruction-header p {
    margin-left: 0px;
    font-weight: 700;
    font-size: 14px;
  }
  .menu-construction-page section.menuconstruction-header .meal-summary {
    justify-content: center;
  }
  .menu-construction-page .summaryLabel .meal-summary .percent-data {
    margin: 0px 5px;
  }
  .menu-construction .meal-summary .inventory {
    margin-right: 5px;
  }
  .menu-construction-page .menu-construction .no-data .item {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 480px) {
  .user-details-row-mob {
    display: flex !important;
    flex-direction: column;
    gap: 5px;
  }
  .view-detail-table-mob {
    display: table !important;
  }
  .view-detail-table {
    display: none;
  }
  .menu-construction-week-view-filter-wrapper {
    margin-top: 25px;
  }
  .week-view-more-details-info-wrapper {
    gap: 10px;
  }
  .week-view-more-details-content-wrapper {
    margin: 0 5px;
  }
  .menu-construction section.inventroy .inventory {
    border: none;
    padding: 0;
  }
  .menu-construction section.inventroy .inventory i {
    display: none;
  }
  .menu-construction .header-listing h6:nth-child(2),
  .menu-construction .header-listing h6:nth-child(3),
  .menu-construction .header-listing h6:nth-child(4),
  .menu-construction .meal-dish section:nth-child(1),
  .menu-construction .meal-dish section:nth-child(2),
  .menu-construction .meal-dish section:nth-child(3),
  .menu-construction .meal-dish section:nth-child(4) {
    width: 20%;
  }
  .menu-construction .meal-dish section.dish-name:first-child,
  .menu-construction .header-listing h6:nth-child(1) {
    width: 35%;
  }
  .menu-construction .meal-dish section {
    padding: 10px 5px;
  }
  .menu-construction .header-listing h6 {
    padding: 0px 5px;
    font-size: 12px;
  }
  .menu-construction .actions i {
    margin-left: 0;
    font-size: 18px;
  }
}

@media screen and (max-width: 360px) {
  .menu-construction .meal-summary {
    flex-direction: row;
    font-size: 10px;
  }

  .menu-construction .meal-dish .section1 .name {
    font-weight: 600;
    font-size: 12px;
  }
  .view-detail-table {
    margin: 0 -20px;
  }
  .menu-construction .filter button.MuiButtonBase-root {
    padding: 5px 15px !important;
    min-height: 35px !important;
    font-size: 12px;
  }

  .menu-construction .button-wrapper button {
    min-height: 40px !important;
    font-size: 12px;
  }
}

.ltr
  .menu-construction
  .filter
  .item.date
  .date-picker
  .react-datepicker-popper {
  transform: translate3d(3px, 40px, 0px) !important;
}

// rtl CSS
.rtl {
  .menu-construction span.MuiIconButton-label img {
    margin-right: 10px;
    margin-left: 0px;
  }
  .menu-construction .dishes .meal-dish section .mob-icon i {
    transform: rotate(180deg);
  }
  .menu-construction .no-data .item {
    margin-right: 0px !important;
  }
  .user-details-block .we-toggle {
    align-items: flex-start;
  }
}
