@import 'global_variables';

@mixin Text-Style-Text-Area {
  font-family: Assistant;
  font-size: 18px;
  color: black;
}

@mixin Label-text-style {
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.form {
  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .label-wrapper {
    display: flex;

    align-items: center;
    padding: 0;
    @include Text-Style-Text-Area;

    label {
      @include Label-text-style;
    }
  }

  .input-group {
    margin-bottom: 5px;

    .label-input {
      display: flex;
      justify-items: center;
      align-items: flex-start;
      background-color: #f0f4f8;
      border-radius: 8px;
      background-color: $cards-background-color;
      padding: 10px 0px;
      height: 100%;
      min-height: 60px;

      .input-section {
        display: flex;
        justify-items: flex-start;
        width: 100%;
      }

      .input-field {
        background-color: inherit;
        padding-right: 20px;

        width: 100%;
        border: 0;
        outline: 0;
        font-size: 0.9em;
        position: relative;
        color: #475058;
      }
      .icon {
        background-size: 28px 28px;
        height: 28px;
        width: 28px;
        display: block;
      }

      &.required-field {
        border: 1px solid $dark-red;
        padding-bottom: 5px;
        padding-top: 10px;
      }
    }
  }

  .field-wrapper {
    .icon {
      background-image: url(./icons/phone-call.svg);

      background-size: 28px 28px;
      height: 28px;
      width: 28px;
      display: block;
    }
    .input-group {
      .label-input {
        display: flex;
        justify-items: center;
        align-items: center;
        background-color: #f0f4f8;
        border-radius: 8px;
        background-color: $cards-background-color;
        padding: 27px;
        .input-field {
          background-color: inherit;
          padding-right: 20px;

          width: 100%;
          border: 0;
          outline: 0;
          font-size: 23px;
          position: relative;
          color: #475058;
        }
      }
    }
    width: 300px;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .upper-placeholder {
    font-size: 0.6em;
    text-align: start;
    margin-bottom: 10px;
    padding: 0px 20px;
  }

  .error-message {
    font-size: 0.8em;
    color: $dark-red;
    opacity: 0.7;
    width: 100%;
    text-align: end;
  }

  .inputs-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: $tablet-max) {
      flex-direction: column;
    }
  }
}

/* Forgot password */
.forgot-pass {
  align-self: flex-end;
  text-decoration: underline;
  color: $darker-gray;
}

/* Checkbox */

.we-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: $darker-gray;

  img {
    background-color: $light-green;
    border-radius: 50px;
    padding: 5px;
    margin: 0 10px;

    width: 24px;
    height: 24px;

    &:hover {
      background-color: $darker-green;
    }
  }

  .unchecked {
    background-color: $light-gray;
    border-radius: 50px;
    margin: 0 10px;
    width: 24px;
    height: 24px;

    font-size: 24px;

    &:hover {
      background-color: $light-green;
    }
  }
}
