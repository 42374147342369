@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Exo+2:wght@200;300;400;500&family=Lobster&family=Poppins:wght@100;200;300;400&display=swap');
/***wrapper css **********/
.content-container {
  padding: 40px 50px;
}
.la,
.las {
  font-family: 'Line Awesome Free' !important;
}
body * {
  font-family: 'OpenSans', sans-serif;
}
.fa {
  font-family: FontAwesome !important;
}
.global-data-table .ag-header-cell:last-child i {
  color: #6d7287 !important;
}
.global-data-table .ag-theme-alpine .ag-header {
  background: transparent !important;
  border: none;
}
.flex-default {
  display: flex;
}
.global-data-table .ag-theme-alpine .ag-header .ag-header-container {
  background: #eff0f7 !important;
  border: none !important;
  width: 100% !important;
}
.my-orders-dropdown {
  width: 200px;
  margin: 20px;
  margin-top: 0;
}
/* .global-data-table .ag-center-cols-viewport .ag-center-cols-container {
  width: 100% !important;
} */
.global-data-table .ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}
.reservation-management-fail-cancel-cell-size-30-percents {
  width: 30%;
  min-width: 100px !important;
  max-width: 30%;
}
.reservation-management-fail-cancel-cell-size-id {
  width: 100px;
  min-width: 50px !important;
  max-width: 100px;
}
.z-index-1500 {
  z-index: 1500;
}
.global-data-table .ag-theme-alpine .ag-ltr .ag-cell {
  text-align: left !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.global-data-table .ag-theme-alpine .ag-rtl .ag-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* .copy-previous-meal .ag-body-viewport .ag-row .ag-cell:last-child {
  justify-content: flex-end !important;
}
.copy-previous-meal .ag-body-viewport .ag-row .ag-cell:last-child .percent-data {
  margin: 0 0 0 auto;
} */
.global-data-table span.ag-header-cell-text {
  font-size: 14px !important;
  color: #2b3147;
  font-weight: 600 !important;
}
.global-data-table .ag-cell-value {
  color: #6d7287 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.global-data-table .name-section h6 {
  color: #2b3147 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  margin: 0 !important;
  line-height: initial !important;
}
.global-data-table .name-section p {
  color: #6d7287 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 0 !important;
  line-height: initial !important;
}
.global-data-table .ag-row {
  border-bottom: 1px solid #e0e3ef !important;
}
.global-data-table i.fa.fa-ellipsis-v {
  font-size: 20px !important;
}
.global-data-table .name-section .status-deleted {
  color: #ff4242 !important;
}
.global-data-table .name-section .status-active {
  color: #38bb94 !important;
}
.global-data-table .name-section h6.user-status i {
  font-size: 10px !important;
  position: relative;
  margin-right: 3px;
}
.global-data-table .ag-row {
  background-color: white !important;
}
.global-data-table .name-section p,
.global-data-table .name-section h6 {
  font-size: 14px !important;
}
.global-data-table .ag-theme-alpine .ag-header-cell,
.global-data-table .ag-theme-alpine .ag-header-group-cell,
.global-data-table .ag-theme-alpine .ag-cell {
  padding: 0px 6px !important;
}
.more-info {
  height: 15px;
  cursor: pointer;
}
.global-data-table .ag-header-cell img {
  height: 15px;
}
.global-data-table .add-employee-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  gap: 10px;
}
.add-employee-btn .active-button .download-csv {
  border: 1px solid #e0e3ef;
  border-radius: 6px;
  background: #ffffff;
  padding: 5px 10px;
  color: #25213b;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.add-employee-btn .active-button .download-csv img {
  width: 20px;
  height: 20px;
}
.green-radio {
  width: fit-content;
}
.green-radio-elem {
  width: fit-content;
}
.green-radio .form-check-input:checked {
  background-color: green;
  border-color: green;
}

.green-radio .form-check-input {
  margin: 1px;
  border-color: green;
  outline: none;
  box-shadow: none;
}

.green-radio .form-check-label {
  font-size: 18px;
  font-weight: 500;
}

.global-data-table .add-employee-btn .active-button {
  display: flex;
  align-items: center;
  gap: 10px;
}
.margin-left-10 {
  margin-left: 10px;
}
.global-data-table .add-employee-btn .active-button .actions {
  display: flex;
  gap: 10px;
  align-items: center;
}
.total-data p {
  font-size: 17px;
  margin: 0;
  line-height: initial;
  /* margin-right: 10px; */
}
.page-info {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}
.reservation-management
  .filter-section
  .search-filter
  .flex-default
  .search-input
  input {
  padding-left: 35px !important;
}
.reservation-management
  .filter-section
  .search-input-wrapper
  .search-input
  input {
  padding-left: 35px !important;
}
.global-data-table .active-button img {
  height: 20px;
  /* margin-left: 10px; */
  cursor: pointer;
}
.global-data-table .add-employee-btn input,
select {
  max-width: 392px;
  height: 40px;
  background: #eff0f7 !important;
  border-radius: 6px;
  width: 100%;
  border: none !important;
  padding: 0px 12px !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6e6893;
  box-shadow: none;
  padding-left: 40px !important;
  outline: none;
}
.global-data-table .add-employee-btn input:focus,
.global-data-table .add-employee-btn input:hover {
  outline: none;
  box-shadow: none;
}
/* select {
  -webkit-appearance: none !important;
} */

.rtl .global-data-table .add-employee-btn input::placeholder {
  direction: rtl;
  text-align: center !important;
  padding-right: 40px !important;
}
.success-btn-payment-change {
  background-color: #14cd31 !important;
  border: 1px solid #14cd31 !important;
}
.success-btn-payment-cancel {
  background-color: rgba(0, 0, 0, 0.6) !important;
  border: 1px solid #4a494999 !important;
}
.Mui-focused.text-color-for-payment-change {
  color: rgba(0, 0, 0, 0.6) !important;
}
.Mui-checked.radio-button-color-for-payment-change {
  color: #14cd31 !important;
}
.global-data-table .add-employee-btn input::placeholder {
  max-width: 392px;
  height: 40px;
  background: transparent !important;
  border-radius: 6px;
  width: 100%;
  border: none !important;
  padding: 0px 12px !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 40px;
  color: #6e6893;
  box-shadow: none;
  padding-left: 40px !important;
  outline: none;
}
.add-factory-btn {
  background: transparent;
  border: 1px solid #6d7287;
  border-radius: 3px;
  color: #23283c;
  font-size: 16px;
  font-weight: 500;
  height: 30px;
  justify-content: center;
  letter-spacing: 0.05em;
  line-height: 24px;
  padding: 3px 5px;
  text-align: center !important;
  min-width: 150px;
}
.ru-min-width {
  min-width: 200px;
}
.add-factory-btn:hover {
  background: transparent;
  border: 1px solid #6d7287;
  border-radius: 3px;
  color: #23283c;
  font-size: 16px;
  font-weight: 500;
  height: 30px;
  justify-content: center;
  letter-spacing: 0.05em;
  line-height: 24px;
  padding: 3px 5px;
  text-align: center !important;
}
.global-data-table .add-employee-btn .search-factory-input::placeholder {
  padding-left: 0 !important;
}
.pointer {
  cursor: pointer;
}
.global-data-table .search-input img {
  position: absolute;
  left: 12px;
  top: 15px;
  height: 17px;
}
.search-input-custom {
  max-width: 392px;
  min-width: 200px;
  width: 100%;
  position: relative;
  /* background: #eff0f7 !important; */
  /* border-radius: 6px; */
}
.mw-125px {
  min-width: 125px;
}
.qr-profile-position {
  margin-top: 13px;
  margin-bottom: auto;
}
.mw-155px {
  min-width: 155px;
}
.actions-factory-desktop {
  display: block;
}
.actions-factory-mob {
  display: none;
}
@media (max-width: 600px) {
  .actions-factory-desktop {
    display: none;
  }
  .actions-factory-mob {
    display: block;
  }
}
.search-input-custom img {
  position: absolute;
  left: 12px;
  top: 12px;
  height: 17px;
}
.search-input {
  max-width: 392px;
  min-width: 200px;
  width: 100%;
  position: relative;
  /* background: #eff0f7 !important; */
  /* border-radius: 6px; */
}
@media (max-width: 400px) {
  .search-input {
    min-width: 125px;
  }
}
/* .ag-header-row, .ag-center-cols-container{
    width: 100% !important;
} */
.global-data-table .ag-header-cell.resizable-header:last-child,
.global-data-table .ag-theme-alpine .ag-ltr .ag-cell:last-child {
  display: flex;
  /* justify-content: flex-end; */
  padding-right: 0px !important;
}
/* .global-data-table .ag-header-viewport {
  flex: inherit;
} */
.global-data-table .ag-theme-alpine .ag-paging-panel {
  height: 38px;
  background: #eff0f7;
  border-radius: 0px 0px 8px 8px;
  border: none;
}
.global-data-table .ag-theme-alpine .ag-paging-panel span {
  font-size: 12px !important;
  font-weight: 600;
  color: #6e6893 !important;
}
.global-data-table .ag-theme-alpine .ag-paging-panel .ag-icon {
  font-size: 18px !important;
  color: #6e6893;
}
.global-data-table .pagination-header {
  font-size: 12px;
  color: #6e6893;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  margin-top: -29px;
  left: 58%;
  gap: 8px;
  width: fit-content;
  z-index: 0;
}
.line {
  border-bottom: 1px solid lightgray;
}
.copy-previous-meal .pagination-header {
  margin: -26px 0 0 20px;
  position: unset;
}

.global-data-table .pagination-header select {
  border: none;
  color: #6e6893;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background: transparent;
  height: auto;
  padding-left: 6px !important;
}
.global-data-table .ag-center-cols-clipper {
  background: #ffffff;
}

.global-data-table .view-more-btn img {
  height: 16px;
  text-align: center;
}

.global-data-table .view-more-btn {
  width: 100%;
  text-align: center;
}
.global-data-table .search-input input::placeholder {
  text-align: left !important;
  display: flex !important;
  justify-content: flex-start !important;
  position: absolute;
  left: 0px;
  top: 12px;
}
.employee-management-page.global-data-table .pagination-header,
.employee-management-page span.ag-paging-row-summary-panel {
  display: none;
}
.employee-management-page .payment-method.without-link {
  pointer-events: none;
  cursor: not-allowed;
  color: #969696;
  text-decoration: none;
}
.employee-management-page .payment-method.add-link p {
  margin: 0;
}
/********* reservation management ************/
.global-data-table.reservation-management span.ag-header-cell-text {
  white-space: pre;
  text-align: left;
}
.global-data-table .ag-row-hover,
.menu-construction .meal-dish:hover,
.kitchen-management .item:hover {
  background: #27e84533 !important;
}
.menu-construction
  .meal-dish:hover
  section.inventroy
  .inventory
  input.inv-input {
  background: #ececec33;
}
.kitchen-management .item.kitchen-header:hover {
  background: #eff0f7 !important;
}
.user-create-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 10px;
}
.user-create-block {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  justify-items: center;
}
.width-add-user {
  min-width: 800px;
}
.width-unset .MuiBox-root.MuiBox-root-7 {
  width: unset !important;
}
.add-user-form {
  overflow-y: auto;
  max-height: 70vh;
}
.dropdown-toggle.nav-link,
.dropdown-toggle.nav-link:active,
.dropdown-toggle.nav-link:hover,
.dropdown-item:hover,
.dropdown-item {
  text-decoration: none;
}

@media (max-width: 950px) {
  .add-user-form {
    max-height: 65vh;
  }
}
.role-input-field {
  margin-left: 25px;
}
.create-cont-emp-title {
  font-size: 30px !important;
  font-weight: 600 !important;
  margin: 0 !important;
}
.user-create-success-wrapper {
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-create-success-text-block {
  display: flex;
  margin: 50px 50px 30px;
  gap: 7.5px;
  font-size: 24px;
  text-align: center;
}
.user-create-success-text-block-name {
  display: flex;
  gap: 7.5px;
}
.user-create-success-copy-block {
  display: flex;
  gap: 5px;
}
.user-create-success-copy-block-pointer {
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.user-create-success-username {
  font-weight: 700;
}
.user-create-success-username-highlight {
  color: #3886b2;
  font-weight: 700;
}
.user-create-success-copy-icon {
  font-size: 32px !important;
  color: #3886b2;
}
.user-create-success-card {
  padding: 6px 22px;
  margin: 3px;
  color: #fff;
  border: 1px solid #14cd31;
  background: #14cd31;
  border-radius: 10px;
  width: 200px;
  min-height: 45px;
}
.user-create-success-add {
  padding: 6px 22px;
  margin: 3px;
  color: #fff;
  border: 1px solid #0060ca;
  background: #0060ca;
  border-radius: 10px;
  width: 200px;
  min-height: 45px;
}

.user-create-success-btn-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
@media (max-width: 950px) {
  .width-add-user {
    min-width: 85vw;
  }
}
.user-create-block .input-field {
  min-width: 200px !important;
}
@media (max-width: 640px) {
  .add-user-roles-block {
    flex-direction: column;
    max-width: 100%;
  }
  .input-field.role-input-field {
    max-width: 250px;
    min-width: 250px;
  }
}
@media (max-width: 850px) {
  .add-employee-popup .item .input-field.type-checkbox.add-user-checkbox {
    margin: 16px auto;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }
  .add-employee-popup .type-checkbox input {
    width: unset !important;
  }
  .user-create-block {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
  }
  .role-input-field {
    max-width: 75%;
  }
  .add-employee-btn.add-employee-btn {
    margin-top: 50px;
  }
  .add-employee-popup .item .input-field.type-checkbox {
    margin: 16px 0;
  }

  .width-add-user {
    min-width: 100%;
  }
  .add-employee-popup-mob .item .input-field {
    width: unset;
    min-width: 200px;
  }
  .user-create-block .input-field {
    min-width: 175px !important;
  }
}
@media (max-width: 768px) {
  .width-add-user {
    min-width: 100%;
  }
  .add-user-form {
    overflow-y: unset;
    max-height: unset;
  }
}
@media (max-width: 450px) {
  .role-input-field {
    margin: 0 auto;
  }
  .user-create-block {
    display: grid;
    grid-template-columns: auto;
  }
}

.user-create-block .input-field.type-checkbox {
  min-width: unset !important;
}
.user-create-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.user-create-title {
  font-weight: 600;
  font-size: 16px;
  color: #23283c;
  margin: 0;
}
.reservation-management .ag-cell[col-id='mealDish[0].mealDish.dish.name'] span {
  max-height: 18.4px;
  overflow: hidden;
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.reservation-management .action-btn.realized-button button {
  background: transparent !important;
  border: none !important;
  padding: 0px !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #05b820;
  text-align: left !important;
  margin: 0;
}
.reservation-management .action-btn.realized-button span {
  color: #f13c47;
}
.reservation-management .order-with-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 100%;
}
.reservation-management .orderId p {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #6d7287;
  margin: 0;
  line-height: initial;
  font-weight: 700;
}
.reservation-management .action-btn.realized-button button.we-button.disabled {
  font-weight: 400;
  color: #2b3147;
}
.reservation-management
  .global-data-table
  .ag-theme-alpine
  .ag-ltr
  .ag-cell
  span {
  line-height: initial;
}
.reservation-management
  .global-data-table
  .ag-theme-alpine
  .ag-ltr
  .ag-cell
  span {
  line-height: initial;
}

.reservation-management .payment-status p {
  line-height: initial;
  margin: 0 !important;
}

.reservation-management .payment-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.reservation-management .payment-status p:first-child {
  /* font-weight: 400;
  font-size: 12px; */
  line-height: 18px;
  color: #6d7287;
}

.reservation-management .add-employee-btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12px;
  flex-wrap: wrap;
}
.reservation-updated-page .add-employee-btn {
  margin-bottom: 30px;
}
.reservation-management .filter-section input,
.reservation-management .filter-section input:hover,
.reservation-management .filter-section input:focus {
  padding-left: 12px !important;
}
.reservation-management select,
.reservation-management select:hover,
.reservation-management select:focus {
  padding-left: 12px !important;
  cursor: pointer;
}
.reservation-management .search-filter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
}
.reservation-management .left-filter {
  display: flex;
  width: 90%;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 20px;
}
.view-detail-head .reservation-title {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.view-detail-head .reservation-title h6 {
  font-weight: 600;
  font-size: 15px;
}
.history-text-styles {
  color: #6d7287;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  line-height: initial;
}
.view-detail-head .reservation-title h5 {
  font-weight: 600;
}
.global-data-table .name-section .exit-action {
  color: red !important;
}
.global-data-table .name-section .entry-action {
  color: #14cd31 !important;
}
/***** container *******/
@media (min-width: 990px) {
  .container {
    max-width: 900px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1550px;
  }
}

/************************************** View detail popup ****************************************/
.include-add-img {
  cursor: pointer;
}
.view-more-popup * {
  font-family: 'Courgette', cursive;
  font-family: 'Exo 2', sans-serif;
  font-family: 'Lobster', cursive;
  font-family: 'Poppins', sans-serif;
}
.view-detail-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 20px 0px;
}
.close-btn-view {
  width: 15px;
  height: 15px;
  filter: brightness(0.5);
  cursor: pointer;
  display: inline-grid;
}
.view-detail-head h2#modal-modal-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #23283c;
}
.user-details-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 10px;
  grid-gap: 5px 0;
}
.user-details-block {
  width: 100%;
  display: flex;
  border-radius: 4px;
  padding: 5px 5px;
  margin-bottom: 0px;
  flex-wrap: wrap;
  word-break: break-all;
  align-items: center;
}

.audit-details-block {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 37.5% 37.5%;
  border-radius: 4px;
  padding: 5px 0;
  margin-bottom: 0px;
  word-break: break-all;
  align-items: center;
}
.audit-details-block h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2b3147;
  margin: 0;
  text-align: left;
}
.mw-75vw .modal-dialog {
  margin: 0 auto;
  width: 75vw;
  max-width: 75vw;
}
.history-column-fieldname {
  min-width: 170px;
  width: 20%;
  max-width: 170px;
}
.history-column-values {
  min-width: 200px;
  width: 40%;
}
.audit-table-container {
  max-height: 60vh;
  overflow: auto;
}
.border-for-print-view {
  border-bottom: 1px solid #d7d3d3;
}
.audit-details-text {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8289a3;
  text-align: left;
  word-wrap: break-word;
}
.audit-details-block p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8289a3;
  text-align: left;
  word-break: break-word;
}
.audit-details-block h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #2b3147;
  margin: 0;
  width: 35%;
  text-align: left;
}

.user-details-block-file-download-text {
  color: #14cd31 !important;
  cursor: pointer;
}
.add-edit-popup-week-view .flex-column .form-group {
  margin-bottom: 0 !important;
}
.user-details-block h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2b3147;
  margin: 0;
  width: 50%;
  text-align: left;
}
.user-details-block p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8289a3;
  width: 50%;
  text-align: left;
  word-break: break-word;
}
.user-details-block a {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8289a3;
  width: 50%;
  text-align: left;
  word-break: break-word;
}
.view-more-popup button.close-modal-btn {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.52px;
  color: #ffffff;
  background: #14cd30;
  box-shadow: 0px 4px 3.8px rgb(0 0 0 / 15%);
  border-radius: 5px;
  padding: 10px 28px;
  margin-top: 55px;
  border-style: none;
}
.view-popup-btn {
  display: flex;
  justify-content: center;
}

.view-btn {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.52px;
  color: #ffffff;
  background: #14cd30;
  box-shadow: 0px 4px 3.8px rgb(0 0 0 / 15%);
  border-radius: 5px;
  padding: 10px 12px;
  border-style: none;
}
.view-more-popup .back img {
  height: 24px;
  cursor: pointer;
  position: absolute;
  left: 16px;
  margin-top: -43px;
  z-index: 99;
}
.view-more-popup .close-btn-view img {
  width: 22px;
  height: 16px;
  filter: brightness(0.5);
  cursor: pointer;
}

.view-more-popup .view-detail-head .close-btn-view {
  width: inherit;
  height: inherit;
}
.user-action-grid {
  display: none;
}
.view-more-popup .actions-btns i {
  font-family: 'Line Awesome Free' !important;
  font-size: 28px;
  margin: 0px 5px;
  cursor: pointer;
}

/******** view reservation popup ***********/
.view-reservation-page .edit-btn {
  padding: 6px 22px;
  margin: 0px;
  color: #fff;
  border: 1px solid #14cd31;
  background: #14cd31;
  border-radius: 8px;
}
.view-reservation-page .button-wrapper {
  justify-content: flex-start !important;
}
.invitations-content-wrapper {
  height: 60vh;
  max-height: 500px;
}
.invitations-content-flex-column-between {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
}
.view-more-popup.view-reservation-page,
.user-detail-container.reservation-content,
.user-detail-container.scrollable-popup {
  height: 60vh;
  overflow-y: auto;
}
.view-more-popup.view-reservation-page::-webkit-scrollbar,
.user-detail-container.reservation-content::-webkit-scrollbar,
.add-new-slot .item-list::-webkit-scrollbar,
.user-detail-container.scrollable-popup::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.view-more-popup.view-reservation-page::-webkit-scrollbar-track,
.user-detail-container.reservation-content::-webkit-scrollbar-track,
.add-new-slot .item-list::-webkit-scrollbar-track,
.user-detail-container.scrollable-popup::-webkit-scrollbar-track {
  background: transparent;
}

.view-more-popup.view-reservation-page::-webkit-scrollbar-thumb,
.user-detail-container.reservation-content::-webkit-scrollbar-thumb,
.add-new-slot .item-list::-webkit-scrollbar-thumb,
.user-detail-container.scrollable-popup::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #56b362;
}
/********* kitchen management ***********/
.kitchen-management.global-data-table .actions button {
  padding: 3px 5px;
  width: 141px;
  height: 30px;
  border: 1px solid #6d7287;
  border-radius: 3px;
  width: fit-content;
}
.kitchen-management.global-data-table .actions button span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
  text-transform: initial;
}
.kitchen-management.global-data-table .add-employee-btn {
  margin-bottom: 20px;
}
.kitchen-management .item .kitchen-form .actions img {
  margin: 0px 8px;
  cursor: pointer;
}
.kitchen-management .left-listing {
  display: flex;
  justify-content: space-between;
  width: 75%;
}
.kitchen-management .item .kitchen-form h6 {
  padding: 5px 15px;
  margin: 0;
  width: 25%;
  text-align: left;
}
.kitchen-management .right-listing {
  display: flex;
  justify-content: flex-end;
  width: 20%;
}
.kitchen-management .item.kitchen-header .right-listing h6 {
  width: 100%;
  text-align: right;
}
.kitchen-management .kitchen-header {
  background: #eff0f7;
  border-bottom: 0.5px solid #8289a3;
  border-radius: 0px 0px 0px 0px;
  padding: 14px 20px 20px;
  /* border-radius: 6px 6px 0px 0px; */
}
.kitchen-form section.actions section:last-child {
  display: none;
}

/************* tag management page *********/
.tag-management-page button {
  height: 30px;
  border: 1px solid #6d7287;
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-width: 105px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
}

.tag-management-page button img {
  margin-right: 5px;
}
.tag-management-page .add-employee-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.tag-management-page .checked-item .icon-wrapper {
  display: flex;
  flex-direction: revert;
  justify-content: flex-start;
}
.tag-management-page .checked-item .icon-wrapper .we-icon {
  margin: 0 !important;
}
.add-kitchen-popup .add-employee-btn button {
  padding: 12px 24px;
  gap: 10px;
  min-width: 83px;
  height: 48px;
  background: #14cd30;
  border-radius: 6px;
  border: 1px solid #14cd30;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  width: 100%;
}
.add-kitchen-popup input {
  padding: 10px 0px;
  height: 38px;
  background: #ffffff;
  border: none !important;
  border-bottom: 1px solid #e3e3e3 !important;
  border-radius: 0px !important;
}
.payment-management-page .form .inputs-wrapper {
  justify-content: flex-start;
  align-items: flex-start;
}
.reservation-updated-page .left-filter {
  width: 90%;
}

.reservation-updated-page .filter-section {
  width: fit-content;
}

.reservation-updated-page .search-input,
.reservation-updated-page .search-input input {
  max-width: 300px;
  position: relative;
}
.reservation-updated-page .search-input img {
  top: 38px;
}

.rtl .reservation-updated-page .search-input input,
.rtl .reservation-updated-page .search-input input {
  padding-right: 40px !important;
  padding-left: 12px !important;
}

@media (max-width: 1200px) {
  .reservation-management .left-filter {
    gap: 10px;
  }
}

input:focus {
  outline: none;
  box-shadow: none;
}
.icon-wrapper.back-button p {
  margin: 0 !important;
  margin-left: 10px !important;
}
.rtl .icon-wrapper.back-button p {
  margin-right: 10px !important;
  margin-left: 0px !important;
}
.rtl .icon-wrapper .we-icon,
.rtl .icon-wrapper .we-icon-text {
  margin-left: 12px;
}
.global-data-table .ag-theme-alpine .ag-ltr .ag-cell span {
  line-height: initial;
}
.log-btn button {
  background: #14cd30 !important;
  border: 1px solid #14cd30 !important;
  font-size: 12px;
  line-height: initial;
}
.log-btn button:hover,
.log-btn button:focus {
  color: #fff;
}
.reset-password .form {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  background: #fff;
  padding: 12px 25px 35px;
  border-radius: 10px;
}
.employee-payment-management .add-apyment-btn {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.employee-payment-management .add-apyment-btn button {
  background: #14cd30;
  border: 1px solid #14cd30;
  font-size: 14px;
}

.employee-payment-management .payment-details span {
  background: #ebebeb;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
}

.employee-payment-management .payment-details span i {
  font-size: 22px;
  margin-left: 10px;
  color: #6d6a6a;
  cursor: pointer;
}
.employee-payment-management .page-heading button,
.employee-payment-management .page-heading button:hover,
.employee-payment-management .page-heading button:focus {
  background: #14cd30;
  border: 1px solid #14cd30;
  font-size: 14px;
}
.employee-payment-management .page-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contractor-management-page .show-reservation h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #38bb94;
}
.reservation-management .payment-indication p.pending {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #ffa030 !important;
}

.forget-password-page .form-group i {
  position: absolute;
  left: 0px;
  bottom: 7px;
  font-size: 26px;
}

.forget-password-page .form-group {
  position: relative;
  margin: 0;
  text-align: left;
}

.forget-password-page .form-group input {
  padding-left: 35px;
  border: none;
  border-bottom: 2px solid #000842;
  border-radius: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000842;
}
.forget-password-page .form-group input:focus {
  outline: none;
  box-shadow: none;
}
.forget-password-page .form-group label {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
  margin: 0;
}
.forget-password-page .form .inputs-wrapper p {
  text-align: left;
  text-transform: capitalize;
}
.wallet-page p.wallet-user-details {
  font-size: 16px;
  font-weight: 600;
}

.wallet-page p.wallet-user-details span {
  margin: 0px 10px;
}
.tag-management-page .add-employee-btn .active-button button,
.kitchen-management .add-employee-btn .active-button button,
.kitchen-device .add-employee-btn .active-button button {
  padding: 3px 5px;
  height: 30px;
  border: 1px solid #6d7287;
  border-radius: 3px;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
  text-align: center !important;
  justify-content: center;
}

.tag-management-page .check-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6d7287;
}

.tag-management-page .check-item p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6d7287;
}

.tag-management-page .check-item i {
  font-size: 14px;
  color: #6d7287;
  margin-right: 10px;
}
.tag-management-page i.las.la-angle-right.tag-actions {
  font-size: 22px;
  cursor: pointer;
}

/********** rtl *************/
.rtl .global-data-table .add-employee-btn .active-button .actions img {
  margin-left: 0px;
  margin-right: 20px;
}
.rtl .settings-content .form-label {
  text-align: right;
}
.rtl * {
  text-align: right !important;
}
.kitchen-detail-container .right-listing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.kitchen-detail-container section.actions {
  display: flex;
  align-items: center;
  gap: 10px;
}
.we-toggle svg path {
  fill: transparent;
}
/************ add employee popup *************/
.add-employee-popup .user-popup-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.add-employee-popup .user-popup-heading img {
  height: 15px;
  filter: brightness(0.5);
  cursor: pointer;
}

.add-employee-popup .user-popup-heading h6 {
  font-weight: 600;
  font-size: 30px;
  color: #23283c;
  margin: 0;
}
.global-data-table .ag-header-viewport {
  background: #eff0f7;
}
.global-data-table .ag-cell {
  white-space: initial;
  line-height: initial !important;
}

.copy-previous-meal .action-btn-add .edit-btn {
  border: 1px solid #e0e3ef;
  border-radius: 6px;
  background: #ffffff;
  padding: 5px 10px;
  color: #25213b;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.modal-footer-btn {
  margin: 30px -20px -35px;
  padding: 0;
  justify-content: space-between;
  border-top: 0.5px solid rgba(35, 40, 60, 0.2);
  width: 100%;
  width: calc(100% + 40px);
  display: flex;
  align-self: center;
  gap: 5px;
}
.kitchen-detail-container .modal-footer-btn {
  margin: 30px -20px -15px;
  width: calc(100% + 25px);
}
.add-mealtypes .modal-footer-btn {
  margin: 30px -14px -14px;
  width: calc(100% + 25px);
}
.invitation-management-select {
  min-width: 200px;
  font-size: 12px;
  font-weight: 400;
}
.invitation-management-filter-block {
  margin: 20px 0;
}
.active-button-styles {
  align-items: baseline !important;
}
.invitation-management-custom-wrapper {
  max-width: unset !important;
}
.invitation-management-datepicker-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.invitation-management-datepicker-elem {
  display: grid;
  align-items: center;
  margin-left: 0;
  min-width: 100px;
  max-width: 210px;
  width: 100%;
}
.invitation-management-search-block {
  margin-top: 24px;
}
@media (max-width: 990px) {
  .invitation-management-search-block {
    margin-top: 0;
  }
}
.invitation-management-datepicker-wrapper
  .invitation-management-datepicker-elem
  input {
  padding: 0 12px !important;
}
.invitation-management-datepicker-elem label {
  font-size: 13px;
  text-align: left;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-size: 600;
  display: block;
}
.user-details-block-list-elem {
  list-style-type: none;
}
.user-details-block-list-wrapper {
  padding-left: 0;
}
.statusId-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}
.statusId-text {
  margin: 0;
}
.user-details-block-custom-flex-settings {
  flex-direction: row;
  gap: 5px;
  flex-wrap: nowrap;
}
.h-60px {
  height: 60px;
}
.view-detail-content {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: nowrap;
}
.view-detail-content-with-buttons {
  max-height: calc(60vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: nowrap;
}
.copy-meal-label {
  text-transform: none !important;
}
.copy-meal-label.Mui-focused {
  text-transform: none !important;
  right: 25;
  left: auto;
  direction: rtl;
  text-align: right;
}
@media (max-width: 950px) {
  .invitation-management-filter-block {
    display: grid;
    grid-template-columns: auto auto auto;
    max-width: 200px;
  }
}
@media (max-height: 850px) {
  .modal-footer-btn {
    margin: 0 -20px -35px;
  }
}

@media (max-width: 768px) {
  .add-user-form {
    overflow: hidden;
    max-height: unset;
  }
  .invitation-management-filter-block {
    grid-template-columns: auto auto;
  }
  .user-create-form-adaptivity {
    max-height: 60vh;
    overflow-y: auto;
    flex-wrap: nowrap !important;
    overflow-x: hidden;
  }
  .active-button.active-button-styles {
    gap: 25px !important;
  }
}
@media (max-width: 550px) {
  .invitation-management-filter-block {
    grid-template-columns: auto;
  }
  .invitations-content-wrapper {
    max-height: unset;
  }
}
@media (max-height: 1000px) {
  .add-user-form {
    overflow: hidden;
    max-height: unset;
  }
  .user-create-form-adaptivity {
    max-height: 60vh;
    overflow-y: auto;
    flex-wrap: nowrap !important;
    overflow-x: hidden;
  }
  .user-create-form-adaptivity-without-overflow {
    max-height: 60vh;
    min-height: 160px;
    flex-wrap: nowrap !important;
  }
}
@media (max-height: 650px) {
  .user-create-form-adaptivity {
    max-height: 50vh;
  }
}
@media (max-width: 550px) {
  .user-create-form-adaptivity {
    max-height: 100%;
    flex-wrap: nowrap !important;
    overflow-y: auto;
  }
  .user-create-form {
    margin-bottom: 75px;
  }
  .add-user-max-height {
    max-height: calc(100vh - 80px) !important;
    overflow: auto;
  }
  .add-cont-emp-btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    margin: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 15px 25px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    margin-left: -30px;
  }
  .add-employee-btn.add-employee-btn-in-popup {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    width: 100%;
    padding: 25px;
    background-color: #fff;
    margin: 0;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  }
}
@media (max-height: 460px) {
  .modal-footer-btn {
    margin: 0 -20px;
  }
  .invitations-content-wrapper-without-buttons {
    height: unset;
  }
  .view-detail-content {
    max-height: 45vh;
  }
}

@media (max-height: 360px) {
  .view-detail-content-with-buttons {
    max-height: calc(55vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    flex-wrap: nowrap;
  }
}
.modal-footer-btn button {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: #ffffff;
  border-radius: 0;
  border: none;
  border-right: 0.5px solid rgba(35, 40, 60, 0.2) !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center !important;
  letter-spacing: 0.02em;
  color: #8289a3;
  padding: 6px;
}
.modal-footer-btn button:last-child,
.modal-footer-btn button:last-child:focus,
.modal-footer-btn button:last-child:hover {
  border-right: none !important;
}

.modal-footer-btn button:hover,
.modal-footer-btn button:focus,
.modal-footer-btn button:active {
  border: none !important;
  border-right: 0.5px solid rgba(35, 40, 60, 0.2) !important;
  background: #ffffff !important;
  box-shadow: none !important;
  color: #8289a3 !important;
}
.modal-footer-btn i {
  font-family: 'Line Awesome Free' !important;
  font-size: 24px;
}
.modal-footer-btn p {
  width: 100%;
  text-align: center !important;
  margin: 0;
  color: #f13c47;
  padding: 14px 0px;
}
.rtl .modal-footer-btn button {
  border-left: 0.5px solid rgba(35, 40, 60, 0.2) !important;
  border-right: none !important;
}

.rtl .modal-footer-btn button:last-child {
  border-left: none !important;
}
.search-button.filter-btn {
  display: none;
}

/* **** RTL CSS ** */
.rtl .react-datepicker__current-month,
.rtl .react-datepicker__day {
  text-align: center !important;
}
.rtl .global-data-table .view-more-btn {
  text-align: center !important;
}
.rtl .global-data-table .view-more-btn img {
  text-align: center !important;
  grid-template: 1241px;
  transform: rotate(180deg);
}
.rtl .tag-management-page i.las.la-angle-right.tag-actions {
  transform: rotate(180deg);
}
.rtl .tag-management-page .check-item i {
  margin-right: 0;
  margin-left: 10px;
}
.rtl .global-data-table .add-employee-btn input {
  padding-right: 40px !important;
  padding-left: 12px !important;
}
.rtl .global-data-table .search-input img {
  left: unset;
  right: 12px;
  transform: rotate(90deg);
}
.rtl
  .global-data-table.contractor-management-page
  .ag-header-cell.resizable-header:last-child {
  padding-right: 12px !important;
}
.rtl .log-viewer-page .filter-section input,
.rtl .log-viewer-page .filter-section input:hover,
.rtl .log-viewer-page .filter-section input:focus,
.rtl .reservation-management .filter-section input,
.rtl .reservation-management .filter-section input:hover,
.rtl .reservation-management .filter-section input:focus {
  padding-right: 12px !important;
}
.rtl
  .global-data-table.log-viewer-page
  .add-employee-btn
  .active-button
  .actions
  img {
  margin: 0;
}
.rtl .wallet-page p.wallet-user-details {
  text-align: center !important;
}
.rtl .gloal-back-btn p {
  margin-right: 4px !important;
  margin-left: 0 !important;
}
.rtl .gloal-back-btn img {
  transform: rotate(180deg);
}
.modal-backdrop {
  z-index: 9999;
}
.modal.show {
  z-index: 999999;
}

@supports (-webkit-touch-callout: none) {
  .global-data-table .add-employee-btn input {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .rtl .global-data-table .add-employee-btn input {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ::i-block-chrome,
  .rtl .global-data-table .add-employee-btn input {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@supports (-webkit-hyphens: none) {
  .rtl .global-data-table .add-employee-btn input {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

/***************** Copy Meal Popup *******************/
.copyMealpopup-inner {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 10px 0;
  min-height: 250px;
  position: relative;
}
.copyMealpopup-inner .closeBtn,
.copyMealpopup-inner .closeBtn:hover,
.copyMealpopup-inner .closeBtn:focus,
.copyMealpopup-inner .closeBtn:focus-within {
  background: transparent;
  border-color: transparent;
  padding: 0;
  position: absolute;
  top: -10px;
  right: -10px;
}
.copyMealpopup-inner .closeBtn:hover {
  opacity: 0.8;
}
.copyMealTxt {
  margin: 0;
  padding: 20px 0;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px !important;
  line-height: 36px;
  color: #3d3d3d;
  font-weight: 700;
  letter-spacing: -0.03em;
}
.copyMeal-modal-button-section {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
.copyMeal-modal-button-section .approvebtn {
  background: #14cd30;
  border-color: #14cd30;
  min-width: 130px;
  font-family: 'Poppins', sans-serif !important;
}

.copyMeal-modal-button-section .approvebtn:hover,
.copyMeal-modal-button-section .approvebtn:focus,
.copyMeal-modal-button-section .approvebtn:focus-within {
  background: #14cd30d9;
  border-color: #14cd30d9;
}

.copyMeal-modal-button-section .selectbtn:hover,
.copyMeal-modal-button-section .selectbtn:focus,
.copyMeal-modal-button-section .selectbtn:focus-within {
  background: #535151e6;
  border-color: #535151e6;
}

.copyMeal-modal-button-section .cancelbtn:hover,
.copyMeal-modal-button-section .cancelbtn:focus,
.copyMeal-modal-button-section .cancelbtn:focus-within {
  background: #ea6060e6;
  border-color: #ea6060e6;
}
.copyMeal-modal-button-section .selectbtn {
  background: #535151;
  border-color: #535151;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  min-width: 130px;
  font-family: 'Poppins', sans-serif !important;
}
.copyMeal-modal-button-section .cancelbtn {
  background: #f13c47;
  border-color: #f13c47;
  min-width: 130px;
  font-family: 'Poppins', sans-serif !important;
}
.selectDish-inner-popup {
  min-height: 250px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectDish-inner-popup .hover-popup {
  box-shadow: none;
  border: 1px solid #cecece;
  width: 100%;
}
.logo-size {
  max-width: 15px;
  max-height: 15px;
}
.add-mealtypes-popup .input-field .fs-15 {
  font-size: 15px;
  font-weight: 600;
}
.fs-15 {
  font-size: 15px;
  font-weight: 600;
}
.selectDish-popup-title {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 10px;
}
.select-dish-name {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #3d3d3d;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 2px;
}
.ai-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-dish-name input[type='checkbox'] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  position: relative;
  cursor: pointer;
  padding: 0 !important;
  margin-right: 5px;
  border: 1px solid #14cd30;
  border-radius: 3px;
}
.select-dish-name input[type='checkbox']:checked {
  position: relative;
  background: #14cd30;
}
.select-dish-name input[type='checkbox']:checked:before {
  content: '\f00c';
  font-family: 'Line Awesome Free' !important;
  font-weight: 900;
  position: absolute;
  top: 1px;
  left: 1px;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
}

.noMealCopy {
  min-width: 121px;
  height: 48px;
  background: #14cd30;
  border-color: #14cd30;
  border-radius: 6px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.noMealCopy:hover,
.noMealCopy .btn:first-child:hover,
.noMealCopy:not(.btn-check) + .btn:hover {
  background: #09b223 !important;
  border-color: #09b223 !important;
}
.percent-data.copy-meal-back-link {
  display: flex;
}

.percent-data.copy-meal-back-link .back-link-section {
  margin-right: 20px;
}
.realized-btn {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  width: 100%;

  background: #fff;
  border-top: 1px solid rgb(51 51 51 / 20%);
}

.sw_payment_popup .swal2-content div {
  text-align: center !important;
}
.sw_font_size_24 {
  font-size: 24px !important;
}
.sw_font_size_20 {
  font-size: 20px !important;
}
.myOrdersMobile-modal
  .modal-dialog
  .table-btn-section.table-single-btn
  .rating
  button.table-btn {
  width: 100% !important;
  max-width: 100% !important;
}
.myOrdersMobile-modal
  .modal-dialog
  .table-btn-section.table-single-btn
  .rating {
  width: 100%;
}
.myOrdersMobile-modal .modal-dialog .table-btn-section .rating button {
  width: 100% !important;
  max-width: 100% !important;
}
.we-pagination-view {
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: 38px;
  background: #eff0f7;
  border-radius: 0px 0px 8px 8px;
  border: none;
  padding: 0px 18px;
}
.we_show_records_main {
  font-size: 14px;
  display: flex;
  align-items: baseline;
  margin-left: 10px;
  margin: 0px 18px;
  align-items: center;
}
.we_show_records_main p {
  margin: 0;
  color: #6e6893 !important;
  font-size: 12px;
}
.rtl-container {
  direction: rtl;
}
.ltr-rule {
  direction: ltr !important;
}
.ltr-container {
  direction: rtl;
}
.user-management-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin: 20px 0;
}
.customList {
  padding: '2px';
}
.customList::-webkit-scrollbar {
  width: 5px;
}
.default-flex-class-for-checkbox {
  display: flex !important;
  font-family: 'OpenSans' !important;
}
.default-flex-class-for-checkbox span {
  font-family: 'OpenSans' !important;
}
.default-flex-class-for-checkbox .Mui-checked {
  color: #14cd30 !important;
}
.customList::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

/* Style the scrollbar thumb */
.customList::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

/* Hover effect for scrollbar thumb */
.customList::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.notifications-page-action-block {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
}
.notifications-page-read-all,
.notifications-page-read-all:hover {
  background-color: rgb(20, 205, 49) !important;
  border-color: rgb(20, 205, 49) !important;
}
.confirm-button,
.confirm-button:hover {
  background-color: rgb(20, 205, 49) !important;
  border-color: rgb(20, 205, 49) !important;
  color: #ffffff;
  border-radius: 0.25em;
  text-align: center !important;
  font-size: 1em;
  margin: 0.3125em;
  min-width: 100px;
  padding: 0.625em 1.1em;
  box-shadow: none;
  font-weight: 500;
}
.overflow {
  overflow: visible;
}
.cancel-button,
.cancel-button:disabled,
.cancel-button:hover {
  background-color: #757575 !important;
  border-color: #757575 !important;
  color: #ffffff !important;
  border-radius: 0.25em;
  font-size: 1em;
  margin: 0.3125em;
  text-align: center !important;
  padding: 0.625em 1.1em;
  box-shadow: none;
  font-weight: 500;
}
.custom-font-weight {
  font-weight: 600;
}
.flex-column {
  margin: 10px 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.custom-active-select {
  border-color: rgb(20, 205, 49) !important;
}
.we-pagination-view .page-item.active > span.page-link {
  background: transparent !important;
  border: none !important;
  color: #6e6893 !important;
  font-size: 12px;
  padding: 10px 0px;
}
.we-pagination-view .page-link {
  color: #6e6893 !important;
  border: none !important;
}
.we-pagination-view .pagination {
  margin: 0;
  background: transparent;
  padding: 0;
}
.we-pagination-view .page-link {
  background: transparent;
}
.we-pagination-view .page-link:focus {
  box-shadow: 0 0 0 0.35rem transparent !important;
}
select#we_change_record_count {
  width: 70px;
  padding: 0 10px !important;
  background: transparent !important;
  border: 1px solid #14cd30 !important;
  color: #14cd30 !important;
}

.past-meals.global-data-table .left-filter {
  display: flex;
  width: 90%;
  align-items: flex-end;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}
.past-meals.global-data-table .filter-section {
  display: flex;
  align-items: flex-end;
  width: 100%;
  transition: all 0.3s;
}
.past-meals.global-data-table .filter-section {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
}
.past-meals.global-data-table .search-filter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.xyz {
  display: flex;
  align-items: flex-end;
  grid-gap: 20px;
  gap: 20px;
}
.error-datepicker {
  font-size: 12px;
  color: red;
  font-weight: 600;
  margin: 0 auto;
  text-align: center !important;
}
.max-width-450 {
  max-width: 450px;
}
.reservation-updated-page .margin-bottom-10 {
  margin-bottom: 10px;
}
.past-meals.global-data-table .filter-section .from-date {
  display: grid;
  align-items: center;
  margin-left: 0;
  max-width: 210px;
  width: 100%;
}
.past-meal .filter-section label {
  font-size: 13px;
  text-align: left;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
}
.past-meals.global-data-table .search-input {
  max-width: 300px;
}
.past-meals.global-data-table .search-input input {
  max-width: 300px;
  position: relative;
  width: 100%;
}
.past-meals.global-data-table .search-input label {
  font-size: 13px;
  text-align: left;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
}
.past-meals.global-data-table .search-input img {
  top: 36px !important;
}
.react-datepicker-popper {
  z-index: 1000;
}
.past-meals.global-data-table .filter-section label,
.past-meals.global-data-table .search-input label,
.past-meals .from-date label {
  font-size: 13px;
  text-align: left;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
}
.global-multi-select label {
  font-size: 13px;
  text-align: left;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
}

.global-multi-select .react-select__value-container .react-select__multi-value {
  background: #eff0f7 !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6e6893;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}
.global-multi-select
  .react-select__value-container
  .react-select__multi-value
  .react-select__multi-value__remove:hover {
  background: transparent !important;
  color: #6e6893;
  opacity: 0.8;
  cursor: pointer;
}
.global-multi-select .react-select__value-container .react-select__placeholder {
  background: transparent !important;
  font-size: 14px;
  line-height: 18px;
  color: #6e6893;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 10px;
}
.global-multi-select .react-select__value-container .react-select__input input {
  min-width: 70px;
  padding-left: 10px !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #6e6893 !important;
  height: 30px;
}
.global-multi-select
  .react-select-container
  .react-select__menu
  .react-select__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: #212529;
  padding: 4px 16px;
  cursor: pointer;
}
.global-multi-select
  .react-select-container
  .react-select__menu
  .react-select__option.react-select__option--is-focused {
  background: #e9ecef;
}
.global-multi-select .react-select__control {
  min-height: 40px;
  border-color: hsl(0, 0%, 80%) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.global-multi-select .react-select__control .react-select__value-container {
  padding-top: 0;
  padding-bottom: 0;
}

.past-meals .global-multi-select {
  max-width: 300px;
  width: 100%;
  padding: 0 12px !important;
}
.past-meals.global-data-table .filter-section input,
.past-meals.global-data-table .filter-section input:hover,
.past-meals.global-data-table .filter-section input:focus {
  padding-left: 12px !important;
}
.past-meals.global-data-table .total-data p {
  width: 145px;
}
.past-meals.global-data-table .filter-section input {
  width: auto !important;
}
.loading_excel {
  font-size: 14px;
}
.actions.hidden {
  display: none !important;
}
.review-management-search-input {
  display: flex;
}
.reservation-management .width-100 {
  width: 100%;
}
.global-data-table .from-date-dropdown input {
  border-radius: 4px !important;
  box-shadow: none !important;
  color: #333 !important;
  font-size: 1em !important;
  min-width: 0 !important;
  padding: 10px 15px !important;
  text-overflow: ellipsis !important;
  background: transparent !important;
}
.global-data-table .from-date-dropdown ::after {
  background: transparent !important;
  color: #333 !important;
}
@media screen and (max-width: 1024px) {
  .kitchen-device .search-user-wrapper {
    max-width: 500px;
  }
}

@media screen and (max-width: 991px) {
  .search-button.filter-btn img {
    filter: unset;
  }
  .salary-search-field {
    margin-bottom: 12px;
    max-width: 200px !important;
  }
  .salary-generate-field {
    margin: 0 !important;
    margin-bottom: 12px !important;
  }
  .search-button.filter-btn {
    display: flex;
    background: #eff0f7;
    border-color: #eff0f7;
  }
  .search-input-wrapper {
    margin: auto;
  }
  .reservation-management .select-section {
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
  }
  .reservation-management .select-section.show {
    height: auto;
  }
  .reservation-management .left-filter {
    width: 100%;
  }
  .reservation-management .left-filter-mob {
    width: 90%;
  }
  .reservation-management .filter-section {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .reservation-management .search-filter {
    width: 100%;
    margin-bottom: 12px;
    grid-gap: 10px;
    gap: 10px;
    align-items: flex-start;
    flex-direction: column;
  }
  .reservation-management .xyz {
    width: 100%;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    margin-left: 0 !important;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .reservation-management .xyz .from-date {
    width: 48% !important;
    margin-left: 0px;
    max-width: 100%;
  }
  .reservation-management .filter-section label {
    font-size: 13px;
  }
  .reservation-management .xyz .from-date input {
    width: 100% !important;
    max-width: 100% !important;
  }
  .reservation-management .xyz .from-date-small {
    min-width: 100% !important;
    margin: 0 auto;
  }
  .reservation-management .left-filter-salary-small {
    min-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .user-action-grid {
    display: flex;
    padding-top: 20px;
    border-top: 1px solid rgba(87, 97, 145, 0.08);
    margin-top: 15px;
    padding-top: 15px;
    justify-content: space-between;
  }
  .user-action-grid-heading h6 {
    margin: 0;
    font-size: 16px;
    text-align: left;
  }
  .user-action-grid-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    width: 50%;
  }
  .user-action-grid-box {
    padding: 5px;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
    background: #14cd31;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 7px 11px;
    width: 45%;
    font-size: 12px;
    text-align: center;
  }
  .user-details-block button {
    background: #14cd31;
    border: 1px solid #14cd31;
    border-radius: 5px;
    padding: 8px 10px;
    color: white;
  }

  .user-action-grid .user-action-grid-heading {
    width: 50%;
  }
  .reservation-management .filter-section {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .reservation-management select,
  .reservation-management select:hover,
  .reservation-management select:focus {
    max-width: 100%;
  }
  .reservation-management .search-filter {
    width: 100%;
    margin-bottom: 12px;
    gap: 10px;
  }
  .reservation-management .xyz {
    width: 100%;
    display: flex;
    gap: 10px;
    margin-left: 0 !important;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .reservation-management .add-employee-btn .active-button {
    justify-content: flex-end !important;
    margin: 0;
    max-width: 100%;
  }
  .global-data-table .pagination-header {
    left: 25%;
  }
  .reservation-management button.search-button {
    margin-right: 0px;
    margin: 0;
  }
  .reservation-management .filter-section .from-date {
    margin-left: 0;
  }
  .reservation-management .left-filter {
    width: 100%;
    font-size: 24px;
  }
  .reservation-management .filter-section .from-date {
    margin-left: 0px;
    max-width: 100%;
    width: 100%;
  }
  .kitchen-management .item .kitchen-form {
    flex-direction: row;
  }
  .sub-header-wrapper .sub-header .title {
    padding: 0;
    text-align: center;
  }
  .left-listing h6:not(:first-child),
  .kitchen-management .item .kitchen-form .language-input:not(:first-child) {
    display: none;
  }
  .left-listing h6:last-child,
  .kitchen-management .item .kitchen-form .language-input:last-child {
    display: block;
  }
  .kitchen-management .left-listing {
    justify-content: unset;
    width: 85%;
  }
  .kitchen-management .right-listing {
    justify-content: unset;
    width: fit-content !important;
  }
  .kitchen-management .item .kitchen-form .actions section:not(:last-child),
  .kitchen-management .item .kitchen-form .actions img:not(:last-child) {
    display: none;
  }
  .kitchen-management.global-data-table p {
    display: none;
  }
  .kitchen-management .active-button {
    justify-content: flex-end !important;
  }
  .kitchen-form section.actions section:last-child {
    display: block !important;
  }

  .reservation-updated-page .search-input,
  .reservation-updated-page .search-input input {
    max-width: 285px;
  }
  .global-data-table .ag-theme-alpine .ag-header-cell,
  .global-data-table .ag-theme-alpine .ag-header-group-cell,
  .global-data-table .ag-theme-alpine .ag-cell {
    padding: 0px 6px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .rtl .sub-header-wrapper .sub-header .title {
    text-align: center !important;
  }
  .search-input {
    max-width: 325px;
  }
  .add-employee-popup .user-popup-heading {
    margin-bottom: 20px;
  }

  /* Choose Meal Print Preview */
  .print-page-popup {
    width: 100%;
  }
  .reservation-management .xyz .from-date-small {
    min-width: 100% !important;
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .wallet-page p.wallet-user-details {
    margin: 15px 0px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .wallet-page p.wallet-user-details span {
    width: fit-content;
  }
  .rtl
    .global-data-table.contractor-management-page
    .ag-header-cell.resizable-header:last-child {
    justify-content: flex-start;
  }
  .user-management.employee-management-page.global-data-table
    .ag-theme-alpine
    .ag-rtl
    .ag-cell:last-child {
    justify-content: flex-start;
  }
  .global-data-table .ag-theme-alpine .ag-paging-panel > * {
    margin: 0 5px;
  }
  .global-data-table .view-more-btn img {
    height: 14px;
  }
  .global-data-table .ag-cell-value,
  .global-data-table span.ag-header-cell-text,
  .global-data-table .name-section p,
  .global-data-table .name-section h6 {
    font-size: 12px !important;
  }
  .forget-password-page .form-group input {
    margin-bottom: 10px;
  }
  .forget-password-page .form-group i {
    bottom: 15px;
  }
  .past-meals.global-data-table .left-filter {
    width: 100%;
  }
  .past-meals .global-multi-select {
    max-width: 100%;
    padding: 0 !important;
  }
  .past-meals.global-data-table .search-input {
    max-width: 275px;
  }
  .past-meals.global-data-table button.search-button {
    margin: 0;
  }
  .past-meals.global-data-table .total-data p {
    width: unset;
  }
}

@media screen and (max-width: 480px) {
  .global-data-table .add-employee-btn .active-button {
    /* margin-top: 15px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .notifications-page-action-block {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 25px 0 40px 0;
  }
  .global-data-table .add-employee-btn .active-button-styles {
    justify-content: start;
  }
  button.inv-manage-size {
    margin-right: 5px !important;
  }
  .global-data-table.review-management .active-button,
  .global-data-table.copy-previous-meal .active-button {
    width: unset;
  }
  .global-data-table.review-management .search-input,
  .global-data-table.copy-previous-meal .search-input {
    max-width: 260px;
  }
  .global-data-table.review-management .search-input img {
    top: 12px;
  }
  .copy-previous-meal.global-data-table .add-employee-btn {
    flex-wrap: unset;
    align-items: flex-end;
  }
  .user-details-block h6,
  .user-details-block p {
    font-size: 14px;
  }
  .copy-previous-meal.global-data-table .active-button {
    justify-content: flex-end;
  }
  .search-input {
    max-width: 100%;
  }
  .search-user-wrapper .from-date {
    max-width: 100%;
  }
  .global-data-table .pagination-header {
    position: relative;
    margin-top: 12px;
    left: 5px;
  }
  .global-data-table .ag-header-cell.resizable-header:last-child,
  .global-data-table .ag-theme-alpine .ag-ltr .ag-cell:last-child {
    justify-content: flex-start;
  }
  .global-data-table .ag-header-cell.resizable-header:last-child,
  .global-data-table .ag-theme-alpine .ag-rtl .ag-cell:last-child {
    justify-content: center;
  }
  /* .global-data-table .ag-center-cols-container,
  .global-data-table .ag-header-container {
    width: 100% !important;
  } */
  .reservation-management .ag-paging-panel {
    justify-content: space-between;
    padding: 0px 10px;
  }
  .reservation-management .ag-theme-alpine .ag-paging-panel > * {
    margin: 0;
  }
  .reservation-management .pagination-header {
    width: 40%;
    justify-content: space-between;
  }

  .reservation-updated-page .search-input input {
    max-width: 100%;
  }
  .reservation-updated-page .search-input {
    max-width: 225px;
  }
  .reservation-management.global-data-table.reservation-updated-page
    .add-employee-btn
    .active-button {
    /* position: absolute; */
    right: 15px;
  }
  .rtl
    .reservation-management.global-data-table.reservation-updated-page
    .add-employee-btn
    .active-button {
    right: unset;
    left: 15px;
  }

  /* Choose Meal Print Preview */
  .print-page-popup {
    min-height: calc(100vh - 150px);
    position: relative;
    width: 100%;
  }
  /* .print-page-popup #printpreview {
    transform: scale(0.5) translate(-43%, -50%);
  } */
  /* .print-page-popup .print-btn {
    bottom: 10%;
    position: absolute;
    left: 24%;
    transform: scale(0.58);
  } */
}
.user-create-success-text-block p {
  white-space: nowrap;
}
.dropdown-toggle,
.topbar-link,
.td-none {
  text-decoration: none;
}
.dropdown-toggle:hover,
.topbar-link:hover,
.td-none:hover {
  text-decoration: none;
}
.meal-details-popup-meal-desc,
.client-lobby-empty-table-text,
.client-lobby-order-btn,
.client-lobby-collect-btn,
.client-lobby-apply-btn {
  text-align: center !important;
}
@media (max-width: 375px) {
  .view-employee-popup .user-details-row .user-details-block h6,
  .view-employee-popup .user-details-row .user-details-block p {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 350px) {
  .xyz {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .flex-default-mob {
    flex-direction: column;
  }
}
.my-meals-flex {
  display: flex;
}
@media (max-width: 480px) {
  .my-meals-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1500px) {
  .user-create-success-text-block {
    margin: 50px 0px 20px;
  }
}
@media (max-width: 1280px) {
  .user-create-success-text-block {
    flex-direction: column;
    gap: 0;
  }
  .user-create-success-text-block-name p {
    margin-bottom: 5px;
  }
}
@media (max-width: 520px) {
  .user-create-success-text-block-name {
    flex-direction: column;
    align-items: center;
  }
  .user-create-success-text-block-name {
    gap: 0;
  }
  .user-create-success-btn-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
.global-data-table .add-employee-btn .dish-dropdown input {
  color: #000 !important;
  padding: 0 !important;
  background: transparent !important;
}
.reservation-management-dropdown-label {
  white-space: nowrap;
}
