.log-viewer-page {
  .log-btn button {
    padding: 0px;
    background: transparent !important;
    border: none !important;
    color: #14cd30 !important;
  }
  .filter-section {
    width: fit-content;
  }
}
@media screen and (max-width: 768px) {
  .log-viewer-page.reservation-management .left-filter {
    gap: 20px;
  }
  .log-viewer-page.reservation-management .search-filter {
    margin: 0;
  }
  .log-viewer-page.reservation-management .xyz .from-date {
    width: 47% !important;
  }
  .log-viewer-page.reservation-management .add-employee-btn {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .log-viewer-page .filter-section {
    width: 100%;
  }
  .log-viewer-page .search-input {
    max-width: 270px;
  }
}
