@import 'global_variables';

/* Dish Component Styling */
.tags-container {
  display: flex;
  justify-content: center;

  font-size: 20px;
  @media (max-width: $dish-medium-max-screen) {
    font-size: 16px;
  }

  .tag-cards {
    margin: 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    flex-direction: column;

    .tag-single-card {
      background-color: $cards-background-color;
      &.current-selected {
      }

      border-radius: 8px;
      margin: 1.2rem;

      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .tag-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > * {
          margin-right: 10px;
        }
      }

      &.pointer {
        cursor: pointer;
      }

      /* Media queries */
      // On large screens show three dishes
      @media (min-width: $dish-large-min-screen) {
        width: 30%;
      }

      // On medium screens show two dishes
      @media (max-width: $dish-medium-max-screen) {
        width: 45%;
      }

      // On small screens show one dish
      @media (max-width: $dish-small-max-screen) {
        width: 100%;
      }

      /* Dish Buttons */
      .button-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 20px;

        .text {
          font-size: 0.8em;
        }
      }
    }
  }
}

.add-tag-popup .toggle-section input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.add-tag-popup .toggle-section label {
  cursor: pointer;
  text-indent: -9999px;
  width: 44px;
  height: 24px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.add-tag-popup .toggle-section label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 3px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.add-tag-popup .toggle-section input:checked + label {
  background: #14cd30;
}

.add-tag-popup .toggle-section input:checked + label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

.add-tag-popup .toggle-section label:active:after {
  width: 25px;
}
.add-tag-popup .toggle-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.add-tag-popup .toggle-section h6 {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-left: 10px;
}
.add-tag-popup .modal-header {
  padding: 20px 30px;
}
.add-tag-popup .modal-body {
  padding: 20px 30px;
}
.add-tag-popup .add-btn {
  margin-top: 40px;
}
@media screen and (max-width: 480px) {
  .add-tag-popup .add-mealtype-section .input-field {
    width: 100%;
  }
  .add-tag-popup .modal-body {
    padding: 20px 20px;
  }
  .add-tag-popup .modal-header {
    padding: 20px 20px 0px;
  }
  .add-mealtypes-popup .modal-header .h4 {
    font-size: 16px;
  }
  .add-mealtypes-popup .input-field label {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .tag-management-page .add-employee-btn .active-button {
    justify-content: flex-end !important;
    width: unset !important;
  }
  .tag-management-page.global-data-table .search-input img {
    top: 12px;
  }
}
// rtl CSS
.rtl {
  .add-tag-popup .toggle-section h6 {
    margin-left: 1px;
    margin-right: 10px;
  }
  .add-tag-popup .modal-header .btn-close {
    margin: unset;
  }
}
