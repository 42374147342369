/* Media Queries width values */
// From link: https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/

$mobile-max: 480px;
$tablet-max: 768px;
$laptop-max: 1024px;
$large-screen-min: 1025px;
$very-large-screen-min: 1600px;

/* Dish screen sizes */
$dish-small-max-screen: $tablet-max;
$dish-medium-max-screen: 1200px;
$dish-large-min-screen: 1200px;

/* Colors */

$white-color: #ffffff;
$light-gray: #f0f4f8;
$darker-gray: #a6bcd0;

$light-sage: #e5f5e8;
$light-green: #7bed8d;
$darker-green: #14cd31;
$dark-green: #19b630;
$dark-red: #ff3e3e;
$dark-blue: #1563b1;
$lighter-black: #475058;

// Backgrounds
$cards-background-color: $light-gray;
$enabled-button-background: $darker-green;
$enabled-button-hover-background: $dark-green;
$future-item-background: $light-sage;
$popup-dark-gray-background: rgba(68, 75, 82, 0.9);

//  Buttons
$active-light-color: $light-green;
$active-dark-color: $darker-green;
$finished-color: $dark-red;
$btn-text-color: $white-color;

// Dish description
$description-dashed-border: $darker-gray;

//ltr-rtl vars
$default-text-align: right;

@mixin global-variable-overriding {
  $default-text-align: left !global;
}
