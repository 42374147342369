body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100%;
  width: 100%;
}
.gloal-back-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.gloal-back-btn p {
  margin: 0 !important;
  margin-left: 4px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.05em !important;
  color: #23283c !important;
}

.gloal-back-btn i {
  color: #23283c;
  font-size: 24px;
}

@media (max-width: 576px) {
  .gloal-back-btn p {
    font-size: 14px !important;
  }
}

/****************** IFRAME FIX *************/

iframe {
  /* display: none;
  pointer-events: none; */
  position: unset !important;
  z-index: 0;
}

.recaptcha iframe,
.iframe-fix iframe,
.iframeFix {
  pointer-events: all;
}
