@import 'global_variables';
.ltr {
  $default-text-align: left !global;
  @include global-variable-overriding;

  .header .burger_menu {
    // position: absolute;
    // top: 20px;
    // left: 20px;
    // right: unset;

    @media (max-width: $tablet-max) {
      left: 10px;
    }
  }

  .my-meals .order-card .action {
    border-right: unset;
    border-left: 1px dashed #a6bcd0;
  }

  .nav {
    .menu_item:after {
      transform: scaleX(-1);
      margin-right: 10px;
      right: 10px;
      left: unset;
    }
    .icon {
      right: 0;
    }
  }
  .login {
    .inputs-wrapper {
      .icon {
        transform: scaleX(-1);
      }
    }
  }

  .languages-login {
    @media (min-width: $tablet-max) {
      left: unset;
      right: 30px;
    }
  }

  .form {
    .input-field {
      padding-right: unset;
      padding-left: 20px;
    }
  }
  .order-go-back-wrapper {
    .go-back-icon {
      transform: scaleX(-1);
      margin-right: 12px;
      margin-left: unset;
    }
  }

  .icon-wrapper {
    .we-icon,
    .we-icon-text {
      margin-left: 12px;
      @media (max-width: $tablet-max) {
        margin-left: 6px;
      }
    }

    .arrowIcon img {
      transform: scaleX(-1);
      left: unset;
    }
  }

  .my-meals .order-card .section1 .dish-name {
    text-align: left;
  }

  .dash-left {
    @media (min-width: $tablet-max + 2px) {
      border-left: unset;
      border-right: 1px dashed $darker-gray;
    }
  }

  .login-action {
    &.forgot-password {
      text-align: right;
    }
  }

  .dish-name {
    text-align: left;
  }

  .sub-header-wrapper .sub-header .user-name {
    text-align: left;
  }

  .signup,
  .profile {
    .form {
      .form-explanation {
        align-self: flex-start;
        text-align: center;
      }
    }
  }

  .search-line-with-button {
    @media (max-width: $tablet-max) {
      flex-direction: row;
    }

    .search {
      flex: 1;
    }
  }

  .dishes .dish .section1 .name {
    text-align: left;
  }

  .menu-construction .meal-dish .section2 .inventory {
    @media (max-width: $tablet-max) {
      justify-content: flex-end;
    }

    .label {
      order: 1;
    }
    flex-direction: row-reverse;
  }

  .menu-construction .dishes .meal-dish .section1 .meal-summary .inventory {
    text-align: left;
  }

  .meal-report .meal-dish .section2 .inventory .inventory-input {
    flex-direction: row-reverse;
  }

  .meal-management .meals .meal .section1 .meal-summary .inventory {
    text-align: left;
  }

  .section1 .name-and-kitchen {
    p {
      text-align: left;
    }
  }

  .select-login-by .login-identifier-option {
    transform: skew(5deg);
    p {
      transform: skew(-5deg);
    }
  }

  //.user-management .users .item .user-form{
  //  text-align: left;
  //}

  .sub-header-wrapper
    .sub-header
    .kitchens-list
    .list-item:hover
    .kitchen-arrow {
    transform: translateX(0px);
  }

  .sub-header-wrapper .sub-header .kitchens-list .content .list-item {
    .kitchen-arrow {
      transform: rotate(-90deg);
    }
    &:hover {
      .kitchen-arrow {
        transform: rotate(-90deg) translateY(0px);
      }
    }
  }
}
