@import '../global_variables';

.kitchen-management {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 20px;
  @media (max-width: $tablet-max) {
    font-size: 16px;
  }

  .search-line-with-button {
    margin: 40px 0;
  }

  .add-kitchen {
    margin-bottom: 50px;

    .add-label {
      text-align: start;
    }
  }

  .table-labels {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    grid-gap: 10px 20px;
    background: #eff0f7;
    border-radius: 6px 6px 0px 0px;
    padding: 10px 20px 0 20px;
    @media (max-width: $tablet-max) {
      display: none;
    }

    p {
      grid-column: span 2;
      font-size: 1.2em;
      text-align: left;
      // padding: 0px 15px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #2b3147;
      // margin-bottom: 14px;
      margin-bottom: 0;
      &.h-email {
        grid-column: span 3;
      }
      &.h-kitchen {
        grid-column: span 4;
      }
    }
  }

  .kitchens {
    flex: 1;
  }

  .item {
    display: flex;
    width: 100%;
    overflow: auto;
    // background-color: $light-gray;
    border-bottom: 0.5px solid #8289a3;
    border-radius: 0;
    &.blocked {
      //opacity: 0.5;
    }

    margin: 0px 0;
    padding: 10px 20px;
    // &.kitchen-header {
    //   padding: 0 0 20px 20px;
    // }
    .kitchen-form {
      h6 {
        padding: 0 !important;
      }
    }

    // @media (max-width: $tablet-max) {
    //   padding: 0px 0px;
    // }

    .kitchen-form {
      display: flex;
      justify-content: space-between;
      // grid-template-columns: repeat(15, 1fr);
      // grid-gap: 10px 0px;
      width: 100%;
      align-items: center;

      @media (max-width: $tablet-max) {
        display: flex;
        // flex-direction: column;
      }

      .language-input,
      .terminal-no {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 25%;

        input {
          grid-column: span 2;
          min-width: 0;
          text-overflow: ellipsis;
          padding: 10px 15px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #6d7287;
        }

        input {
          background-color: transparent;
          border: none;
          outline: none;
          padding: 0;

          &.edit-mode {
            background-color: $white-color;
            border: 1px solid $darker-gray;
            border-radius: 4px;
            &:focus-within {
              border: 1.5px solid $light-green;
            }
          }
        }
      }

      .actions {
        grid-column-start: 15;
        //grid-column: span 2;
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media (max-width: $tablet-max) {
          border-top: 1px dashed $darker-gray;
          margin: 10px 20px 0 20px;
          padding-top: 20px;
        }

        button {
          background-color: transparent;
          border: none;
          outline: none;
          min-width: 0;
          padding: 0;
          width: auto;
          height: auto;
          display: flex;
        }
      }
    }
  }

  .no-kitchens {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }
}

.kitchen-management .item::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.kitchen-management .item::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.kitchen-management .item::-webkit-scrollbar-thumb {
  background: rgb(173, 173, 173);
}
.kitchen-management .we-button.active {
  background-color: #14cd31 !important;
  border-radius: 36px !important;
  padding: 5px 30px !important;
  font-weight: bold !important;
  color: #ffffff !important;
  font-size: 1em !important;
  white-space: nowrap;
}
.kitchen-management .icon-wrapper .we-icon {
  margin: 0px 0px !important;
}
.meal-type-management form.add-mealtypes {
  justify-content: space-between !important;
  align-items: flex-end !important;
}
.meal-type-management .listing-section {
  display: flex;
  justify-content: flex-start;
  width: 80%;
}
.meal-type-management .language-input label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}
.meal-type-management .language-input input {
  background-color: #ffffff !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 5px !important;
  height: 40px;
  padding: 10px;
  font-size: 15px;
  font-family: 'OpenSans';
}
.meal-type-management .language-input input:focus {
  outline: none;
}

/******** popup **********/
.add-kitchen-popup .close-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-kitchen-popup .close-btn h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #23283c;
  margin: 0;
}
.add-kitchen-popup .add-employee-btn {
  justify-content: flex-start !important;
  margin: 0px 10px 0px;
}

.rtl {
  .kitchen-management {
    .kitchen-header {
      &.item {
        .kitchen-form {
          .right-listing {
            h6 {
              text-align: left !important;
            }
          }
        }
      }
    }
    .table-labels {
      p {
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .kitchen-management .kitchen-header {
    border-radius: 6px 6px 0px 0px !important;
  }
}

@media screen and (max-width: 991px) {
  .kitchen-management .item .kitchen-form .actions {
    border-top: none;
    margin: 0;
    padding-top: 0px;
  }
  .kitchen-management .right-listing {
    // width: 100% !important;
    margin: 5px 0px;
  }
  .kitchen-management .left-listing {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .kitchen-management .add-employee-btn .active-button {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  // .kitchen-management .left-listing {
  //   width: 100% !important;
  // }
  .kitchen-management .item .kitchen-form h6 {
    font-size: 14px;
    width: 47% !important;
  }
}

@media screen and (max-width: 480px) {
  .kitchen-management .item .kitchen-form {
    flex-direction: column;
  }
  .terminal-no input {
    text-align: center !important;
  }
}
.rtl {
  .modal-header .btn-close {
    margin: unset;
  }
}
