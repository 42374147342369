.checkbox-full {
  position: absolute;
  width: 30%;

  &.full-width-mobile {
    @media (max-width: $tablet-max) {
      width: 90%;
    }
  }

  z-index: 10;
  opacity: 0;
  cursor: pointer;
}
