@import 'global_variables';

/* Dish Component Styling */
.lobby {
  display: flex;
  font-size: 18px;
  @media (max-width: $tablet-max) {
    font-size: 14px;
  }

  .lobby-cards {
    margin: 0;
    display: flex;
    flex: 1;
    justify-content: center;
    @media (max-width: $tablet-max) {
      flex-direction: column;
    }

    flex-wrap: wrap;
  }

  .lobby-card {
    display: flex;
    flex: 1;
    flex-direction: column;

    margin: 20px;
    @media (max-width: $tablet-max) {
      margin: 10px 0;
    }

    background-color: $cards-background-color;

    border-radius: 8px;

    /* Media queries */
    max-width: 350px;

    @media (max-width: $laptop-max) {
      max-width: 100%;
    }

    .card-img {
      height: 200px;
      @media (max-width: $tablet-max) {
        height: 80px;
      }

      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;
    }

    .button-wrapper {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      margin: 20px 0;
      @media (max-width: $tablet-max) {
        margin: 5px 0;
      }
    }

    .details {
      display: flex;
      flex: 1;
      flex-direction: column;
      text-align: center !important;
      padding: 15px;
      @media (max-width: $tablet-max) {
        padding: 5px;
      }
      margin-top: 10px;

      .title {
        font-size: 1.2em;
        font-weight: bold;
        margin: 0;
        text-align: center !important;
      }

      .date {
        line-height: 2em;
        letter-spacing: 1.2px;
        text-align: center !important;
      }

      .name {
        border-top: 1px dashed #a6bcd0;
        padding-top: 10px;
        text-align: center !important;
      }
    }
    .stars {
      margin-bottom: 30px;
    }
    .add-review {
      cursor: pointer;
    }

    .last-order {
      height: 40px;
    }

    .order-rating {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: flex-end;

      cursor: pointer;

      .rating-wrapper {
        padding: 25px;
      }
    }

    .lobby-button {
      background-color: transparent;
      border: 1.5px solid #14cd30;
      color: $lighter-black;

      &:hover {
        background-color: white;
      }
    }
  }
}
.ltr .lobby-user-grid .table-btn-section {
  max-width: 144px;
}
.lobby-user-grid {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1166px) {
    flex-wrap: wrap;
    row-gap: 30px;
  }
  .voucher-img {
    margin: auto;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .cibus-img {
    width: 15px;
    height: 15px;
  }
  .lobyy-action-dd {
    display: flex;
  }
  .lobyy-action-dd .btn-success {
    background: transparent;
    border: none;
    padding: 5px;
    width: 25px;
  }
  .lobyy-action-dd .btn-success:active,
  .lobyy-action-dd .btn-success:focus-visible,
  .lobyy-action-dd .btn-success:hover {
    background: transparent;
    border: none;
    box-shadow: none;
  }
  .lobyy-action-dd .btn-success i,
  .lobyy-action-dd .btn-success:active i {
    color: #6d7287;
    font-size: 24px;
  }
  .lobyy-action-dd .btn-success .dropdown-toggle:after {
    display: none;
  }
  .lobyy-action-dd .dropdown-menu {
    padding: 0px !important;
  }
  .lobyy-action-dd .dropdown-item {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    color: #6d7287;
    font-size: 14px;
  }
  .lobyy-action-dd .dropdown-item:active {
    background: #e5ffe9;
    color: #6d7287;
  }
  .lobby-user {
    width: 100%;
  }
  .date-time-lobby {
    display: flex;
    flex-direction: column;
  }
  .fsize-10 {
    font-size: 11px !important;
    white-space: nowrap;
    min-width: 35px;
  }
  .my-orders-table .table-text-section {
    gap: 15px;
  }
  .order-status {
    justify-content: flex-end;
  }
  .my-orders-table .table-text-section p:nth-child(1) {
    max-width: 10% !important;
    width: 100% !important;
    font-size: 12px;
  }
  .my-orders-table .table-text-section p:nth-child(2) {
    max-width: 30% !important;
    width: 100% !important;
  }
  .my-orders-table .table-text-section p:nth-child(3) {
    max-width: 40% !important;
    width: 100% !important;
    justify-content: flex-start;
  }
  .my-orders-table .table-text-section p:nth-child(4) {
    max-width: 14% !important;
    width: 100% !important;
    @media (max-width: 576px) {
      display: none !important;
    }
  }
  .table-title h6 {
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }
  .table-title a {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    i {
      font-size: 20px;
    }
    @media (max-width: $tablet-max) {
      font-size: 14px;
      i {
        font-size: 16px;
      }
    }
  }
  .lobby-order-row .button-wrapper .we-button,
  .rating button {
    border: 1px solid #14cd30;
    border-radius: 6px;
    background: transparent;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #05b820;
    padding: 6px 12px;
    &:hover {
      background: transparent;
      border: 1px solid #14cd30;
      color: #05b820;
    }
  }
  .rating-wrapper svg {
    width: 14px;
    height: 14px;
  }
  .my-orders-table-item:hover {
    background: #e5ffe9 !important;
  }
  @media (max-width: 1366px) {
    .my-orders-table-item {
      padding: 10px 5px !important;
    }
  }
  @media (max-width: 992px) {
    .table-btn-section {
      display: flex !important;
    }
  }
  @media (max-width: 576px) {
    .my-orders-table .table-text-section {
      gap: 3px;
    }
    .my-orders-table .table-text-section p {
      font-size: 12px;
    }
    .my-orders-table .table-text-section p:nth-child(2) {
      max-width: 55% !important;
    }
    .table-btn-section {
      gap: 5px;
    }
    .table-btn-section {
      max-width: 110px !important;
    }
    .table-btn {
      font-size: 12px !important;
    }
    .table-btn img {
      width: 12px;
      height: 12px;
    }
  }
}
.rtl {
  .lobby-user .table-btn-section p,
  .table-btn-section p.user-wallet h6 {
    text-align: left !important;
  }
}

.rating-wrapper > div {
  align-items: flex-start;
  justify-content: flex-start;
}
.lobby-manager.global-data-table .view-more-btn {
  text-align: right;
  padding-right: 12px;
}
.rtl .lobby-manager.global-data-table .view-more-btn {
  text-align: left !important;
  padding-right: 0px;
  padding-left: 12px;
}
.rtl
  .lobby-manager.global-data-table
  .ag-theme-alpine
  .ag-rtl
  .ag-cell:last-child {
  justify-content: flex-end;
}
