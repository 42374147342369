.rtl {
  @include global-variable-overriding;

  .login {
    .label_input_field::before {
      right: 30px;
    }

    .inp_field {
      padding: 27px 64px 27px 22px !important;
    }

    .main_checkbox label {
      padding-right: 33px;
    }
    .main_checkbox label::before {
      right: 9px;
    }

    picture {
      padding-right: 20px;
    }
  }
}
