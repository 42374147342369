@import '../global_variables';

.meal-management {
  display: flex;
  flex: 1;
  flex-direction: column;

  font-size: 20px;

  @media (max-width: $tablet-max) {
    font-size: 16px;
  }

  @media (max-width: $mobile-max) {
    font-size: 14px;
  }

  color: $lighter-black;

  .top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  .meals {
    margin: 20px 0;

    .meal {
      display: flex;
      justify-content: space-between;

      margin: 10px 0;
      padding: 5px 20px;

      @media (max-width: $tablet-max) {
        flex-direction: column;
        padding: 0 20px;
      }

      border-radius: 8px;

      .section1 {
        display: flex;
        flex: 5;
        align-items: center;
        justify-content: space-between;

        padding: 0px 0;

        @media (max-width: $tablet-max) {
          border-bottom: 1px dashed $darker-gray;
        }

        .date-type {
          display: flex;
          flex-wrap: wrap;
          flex: 4;
          justify-content: flex-start;
          gap: 10px;

          @media (max-width: $tablet-max) {
            text-align: right;
          }

          p {
            margin-right: 5px;
          }
        }
      }

      .section2 {
        display: flex;
        flex: 4;
        align-items: center;
        justify-content: space-between;

        padding: 0px 0;

        .rating {
          display: flex;
          flex: 1;
          justify-content: center;

          @media (max-width: $tablet-max) {
            justify-content: flex-start;
          }
        }

        .actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  .no-meals {
    flex: 1;
  }
}

.meal-management .meals .rating.disable-rating {
  pointer-events: none;
  cursor: not-allowed;
}

.meal-management .meals .rating {
  cursor: pointer;
}

.meal-management .top button,
.meal-management .top button:hover,
.meal-management .top button:focus {
  padding: 3px 10px;
  min-width: 113px;
  height: 30px;
  border: 1px solid #6d7287;
  border-radius: 3px;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
}

.meal-management .meals .meal .section1 .date-type p {
  margin: 0;
}

.meal-management .meals .meal .section2 .rating p {
  margin: 0;
  font-size: 12px !important;
}

.meal-management .meals .meal .section1 p {
  font-size: 14px;
}

.meal-management .meals .icon-wrapper img {
  height: 22px !important;
  width: 22px !important;
}

.meal-management .meals .icon-wrapper p {
  font-size: 12px;
}
.min-width-for-btns {
  min-width: unset !important;
}
.past-meals,
.current-meal-management {
  .add-dishes-table {
    margin-top: 0;
    .dishes-header {
      border-bottom: 0;
    }
    .meals {
      margin: 0 0 20px;
      .meal.dish.dish-content {
        border-bottom: 1px solid #e0e3ef;
        section {
          .rating {
            .rating-wrapper {
              p {
                font-weight: 500;
                font-size: 12px !important;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.current-meal-management {
  .dishes-header h6:nth-child(4),
  .dishes-header h6:nth-child(5),
  .dishes-header h6:nth-child(6),
  .add-dishes-table .dish.dish-content section:nth-child(4),
  .add-dishes-table .dish.dish-content section:nth-child(5),
  .add-dishes-table .dish.dish-content section:nth-child(6) {
    width: 10% !important;
  }
  .dishes-header h6:nth-child(7),
  .add-dishes-table .dish.dish-content section:nth-child(7) {
    width: 15% !important;
  }
  .dishes-header h6:last-child,
  .add-dishes-table .dish.dish-content section:last-child {
    width: 15% !important;
  }
}
.current-meal-management .icon-wrapper .we-icon img,
.past-meals .icon-wrapper .we-icon img {
  width: 17px !important;
  height: 17px !important;
}
.view-c-meal-popup .icon-wrapper {
  width: 100%;
  flex-direction: unset;
}
.view-c-meal-popup .icon-wrapper .we-icon {
  width: 20px !important;
  height: 20px !important;
}
.view-c-meal-popup .icon-wrapper .we-icon img {
  width: 17px !important;
  height: 17px !important;
}
.view-c-meal-popup .icon-wrapper .we-icon-text,
.view-c-meal-popup .icon-wrapper .we-icon {
  margin-left: 0;
}
.p-mb-0 {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 768px) {
  .meal-management.current-meal-management .actions .icon-wrapper:first-child {
    display: none;
  }
  .meal-management.current-meal-management .actions .icon-wrapper .we-icon {
    margin: 0;
  }
  .meal-management.current-meal-management .actions p {
    display: none;
  }
}
