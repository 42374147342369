.add-edit-popup.modal.show {
  padding-left: 0 !important;
}
.add-edit-popup .add-edit-dish .form-group {
  width: 100%;
  margin-bottom: 40px !important;
  @media (max-width: 991px) {
    margin-bottom: 20px !important;
  }
}

.add-edit-popup .add-edit-dish {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.add-edit-popup .add-edit-dish .form-group input,
.add-edit-popup .add-edit-dish textarea.form-control {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #6d7287;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  padding: 0 5px;
  height: 38px;
  margin-bottom: 10px;
  resize: none;
}
.add-edit-popup .add-edit-dish .form-group input:hover,
.add-edit-popup .add-edit-dish .form-group input:focus,
.add-edit-popup .add-edit-dish textarea.form-control:hover,
.add-edit-popup .add-edit-dish textarea.form-control:focus {
  outline: none;
  box-shadow: none;
}
.add-edit-popup .dish-left-section {
  width: 48%;
  @media (max-width: 991px) {
    width: 100%;
  }
}

.add-edit-popup .dish-right-section {
  width: 48%;
  @media (max-width: 991px) {
    width: 100%;
  }
}
.add-edit-popup .add-edit-dish .form-group select {
  background: transparent !important;
  padding: 0px !important;
  -webkit-appearance: auto;
  appearance: auto !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 0px !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6d7287;
  @media (max-width: 991px) {
    max-width: 100%;
  }
}
.add-edit-popup .add-edit-dish .form-group select:focus {
  outline: none;
  box-shadow: none;
}
.add-edit-popup .add-edit-dish .form-group label {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #23283c;
}

.add-edit-popup .add-edit-dish .upload-image input {
  height: 0px;
  visibility: hidden;
  display: none;
  width: 0px;
}
.add-edit-popup .add-edit-dish .upload-image label {
  height: 86px;
  background: #d9d9d9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 991px) {
    height: 120px;
  }
}
.add-edit-popup .modal-content .modal-body {
  padding: 20px 30px;
}
.add-edit-popup .add-btn {
  @media (max-width: 991px) {
    margin-top: 10px;
  }
}
.add-edit-popup .add-btn button,
.add-edit-popup .add-btn button:hover,
.add-edit-popup .add-btn button:focus {
  padding: 12px 12px;
  gap: 10px;
  width: fit-content;
  height: 48px;
  background: #14cd30;
  border-radius: 6px;
  border: 1px solid;
  text-align: center !important;
}
.add-edit-popup .modal-header .h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #23283c;
}

.add-edit-popup .modal-header {
  border: none;
  padding: 20px 30px 0px;
}

// rtl CSS
.rtl {
  .add-edit-popup .modal-header .btn-close {
    margin: unset;
  }
}
