@import '../global_variables';

//::-webkit-scrollbar {
//  width: 0;  /* Remove scrollbar space */
//  background: transparent;  /* Optional: just make scrollbar invisible */
//}

.user-management {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 18px;
  @media (max-width: $tablet-max) {
    font-size: 14px;
  }

  .search-line-with-button {
    margin: 10px 0;
  }

  .user-status-filter {
    display: flex;
    align-items: center;
    @media (max-width: $tablet-max) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    margin: 5px;
    width: 100%;

    .css-2b097c-container {
      flex: 1;
      width: 100%;
    }

    .label {
      padding: 5px 10px;
    }
  }

  .add-user {
    margin-top: 10px;
    margin-bottom: 50px;

    .add-user-label {
      text-align: start;
      font-weight: bold;

      &.close {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .table-labels {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 10px 20px;
    margin-top: 40px;
    margin-bottom: 10px;

    @media (max-width: $tablet-max) {
      display: none;
    }

    p {
      grid-column: span 2;
      font-size: 1.2em;
      &.h-name {
        grid-column: span 3;
      }
      &.h-email {
        grid-column: span 4;
      }
      &.h-registrationDate {
        grid-column: span 3;
      }
      &.h-kitchen {
        grid-column: span 5;
      }
    }
  }

  #users-scroll::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  #users-scroll {
    overflow-y: scroll;
    max-height: calc(
      100vh - (77.2px + 72.2px) - (42px + 50px + 30px) - (58.9px) - 150px
    );
  }

  .users,
  .no-users {
    flex: 1;
  }

  .no-users {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .item {
    display: flex;

    background-color: $light-gray;
    &.blocked {
      //opacity: 0.5;
    }

    margin: 20px 0;

    padding: 30px 20px;
    @media (max-width: $tablet-max) {
      padding: 20px 10px;
    }
    border-radius: 8px;

    .user-form {
      display: grid;
      grid-template-columns: repeat(20, 1fr);
      grid-gap: 10px 20px;
      width: 100%;

      @media (max-width: $tablet-max) {
        display: flex;
        flex-direction: column;
      }

      input {
        grid-column: span 2;
        min-width: 0;
        &.name {
          grid-column: span 3;
        }
        &.email {
          grid-column: span 4;
        }
        &.status {
          grid-column: span 3;
        }
        &.registrationDate {
          grid-column: span 3;
        }
        &.registrationDate {
          text-align: center;
        }

        font-size: 1em;
        color: hsl(0, 0%, 20%);
        text-overflow: ellipsis;

        border-radius: 4px;

        padding: 10px 15px;
        margin: 5px;
      }

      .roles {
        grid-column: span 3;
      }
      .kitchens {
        grid-column: span 5;
      }

      input,
      .select,
      .multi-select {
        background-color: transparent;
        border: none;
        outline: none;

        &.edit-mode {
          background-color: $white-color;
          border: 1px solid $darker-gray;
          border-radius: 4px;
          &:focus-within {
            border: 1.5px solid $light-green;
          }
        }
      }

      .actions {
        min-width: 100px;
        grid-column-start: 20;

        &.employee {
          grid-column-start: 15;
        }
        //grid-column: span 2;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: $tablet-max) {
          border-top: 1px dashed $darker-gray;
          margin: 10px 20px 0 20px;
          padding-top: 20px;
        }

        div {
          margin: 2px;
        }

        button {
          background-color: transparent;
          border: none;
          outline: none;
          min-width: 0;
          padding: 0;
        }
      }
    }
  }

  .no-users {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  .user-count {
    font-size: 1.2em;
    margin-top: 10px;
  }
}
.search-user-wrapper {
  display: flex;
  gap: 15px;
  max-width: 610px;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: $tablet-max) {
    max-width: 100%;
    .from-date {
      max-width: 325px;
      width: 100%;
    }
  }
}
.search-user-wrapper select {
  padding: 0px 12px !important;
}
.user-management-table.global-data-table
  .ag-header-cell.resizable-header:last-child,
.user-management-table.global-data-table
  .ag-theme-alpine
  .ag-rtl
  .ag-cell:last-child {
  padding-right: 12px !important;
}
.user-management-table .search-user-wrapper {
  max-width: 650px;
}
.search-user-wrapper-custom-styles {
  width: unset;
  max-width: unset;
}
.total-data {
  display: flex;
  gap: 20px;
  align-items: center;
}
.disp-flex-ai-center {
  display: flex;
  align-items: center;
}
.active-button-styles .total-data p {
  white-space: nowrap;
}
.filter-block {
  display: flex;
  gap: 25px;
}
.user-management-table .search-user-wrapper .from-date.filter-elem-max-width {
  max-width: 176px;
}
@media (max-width: 991px) {
  .active-button-styles {
    flex-direction: column;
  }
}
@media (max-width: 990px) {
  .filter-block-flex {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .active-button-styles {
    flex-direction: row;
    gap: 30px important;
  }
  .user-management-table .search-user-wrapper .from-date {
    max-width: 275px;
  }
  .user-management-table button.search-button {
    margin: 0;
  }
}
.user-management-table button.search-button {
  margin-top: 2px;
  margin-left: 5px;
}
.search-block {
  display: flex;
}
