@import 'global_variables';

.login,
.reset-password {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: calc(100vh - 90px);
  height: 100%;
  margin-top: 0px;
  justify-content: center;
  align-items: center;
  width: 40%;
  position: relative;
  .inputs-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $tablet-max) {
      flex-direction: column;
    }
    //flex-wrap: wrap;
    > * {
      width: 30%;
      @media (max-width: $laptop-max) {
        width: 50%;
      }
      @media (max-width: $tablet-max) {
        width: 80%;
      }

      margin: 0.8rem;
      @media (max-width: $tablet-max) {
        margin: 0.3rem;
      }
    }

    .btn {
      margin-top: 20px;
      width: 80%;
      // height: 50px;
      @media (max-width: $tablet-max) {
        height: 40px;
      }
    }
  }

  // .logo {
  //   height: 120px;
  // }
  .logo {
    text-align: center !important;
  }
  .logo h6 {
    margin: 0;
  }
  img.comapany-secondary-logo {
    max-width: 300px;
    width: 100%;
    @media (max-width: 576px) {
      max-width: 200px;
    }
  }

  .form .input-group .label-input {
    background: transparent;
    padding: 0;
    min-height: unset;
  }
  .form .input-group .label-input .input-section {
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
  }
  .form .upper-placeholder {
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #999999;
  }
  .form .input-group .label-input .input-field {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 21px;
    color: #999999 !important;
    padding-left: 10px;
  }
  .form .input-group .label-input .input-field::placeholder {
    color: #999999 !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .form .input-group .label-input .icon {
    width: 17px;
    height: 17px;
  }
  .form .input-group .label-input.required-field {
    border: none;
    padding: 0;
  }
  .form .error-message {
    font-size: 12px;
    text-align: left;
  }
  .password-info h6 {
    font-size: 12px;
  }

  .stay-connected-wrapper {
    .input-group {
      .label-input {
        background-color: white;
        flex-direction: row-reverse;
        justify-content: center;
        width: 100%;
        .input-field {
          width: unset;
          font-size: 1em;
        }
      }
    }
  }

  &.forget-password-page {
    .form {
      padding-top: 40px !important;
      margin: 0px auto;
      padding: 20px;
      @media (max-width: 576px) {
        padding-top: 30px !important;
      }

      .inputs-wrapper {
        gap: 0;
      }
    }

    .logo {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
  }

  .reset-page-header {
    position: absolute;
    width: 100%;
    padding: 20px 0;
    top: -20px;
    left: 0;
    z-index: 9;
    &.mb-header {
      display: none;
    }
    .inner-header-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .back-btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      width: 70px;
      height: 24px;
      background: transparent;
      color: #000;
      border: 0;
      outline: 0;
      box-shadow: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding: 0;
      &:hover,
      &:focus,
      &:focus-within {
        background: transparent !important;
        color: #000 !important;
        border: 0 !important;
        outline: 0 !important;
        box-shadow: none !important;
      }
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
    .phone-btn {
      color: #14cd30 !important;
      text-decoration: none !important;
      width: auto !important;
      &:active,
      &:visited,
      &:hover,
      &:target,
      &:focus-within {
        color: #14cd30 !important;
      }
      img {
        width: 14px;
        height: 14px;
        transform: none !important;
      }
    }
    h4 {
      font-family: 'Poppins' !important;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #14cd30;
      margin: 0;
      text-align: left;
      padding-bottom: 20px;
      display: none;
    }

    @media (max-width: 576px) {
      h4 {
        display: block;
      }
      .phone-btn {
        display: none !important;
      }
      &.mb-header {
        display: block;
      }
    }

    // @media(max-width: 576px) {top: 40px}
  }
}

.login-form {
  padding-top: 40px !important;
  .logo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
}

.select-login-by {
  display: flex;
  justify-content: space-between;

  margin: 0;

  .login-details {
    font-size: 1.2em;
  }

  .login-identifier-option {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin: 0 5px;

    background-color: $light-gray;

    color: $lighter-black;
    border: 1.5px solid $darker-gray;
    border-radius: 5px;

    transform: skew(-5deg);
    p {
      transform: skew(5deg);
      font-size: 0.6em;
      @media (max-width: $tablet-max) {
        font-size: 0.8em;
      }
      font-weight: bold;
      margin: 0;
    }

    &.selected {
      flex: 1;
      order: -1;
      border: none;
      background-color: transparent;
    }

    &:hover {
      cursor: pointer;
    }

    img {
      width: 12px;
      margin: 0 5px;
    }
  }
}

.flex-column .keep-logged {
  font-size: 0.8em;
  margin-top: 10px;
  color: $lighter-black;
}

.login-action {
  width: fit-content;
  color: $darker-green;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.8em;

  &.forgot-password {
    text-align: left;
  }

  &.no-account {
    justify-self: center;
  }
}

.login-actions {
  display: flex;
  justify-content: space-between;
  width: 50%;
  max-width: 600px;

  @media (max-width: $tablet-max) {
    width: 90%;
  }
}

.login .form,
.reset-password form {
  max-width: 500px;
  margin: 0px auto;
  width: 100%;
  background: #fff;
  padding: 12px 25px 35px;
  border-radius: 10px;
}
.reset-description {
  max-width: 500px;
  margin: 0px auto;
  width: 100%;
  padding: 0px 25px 0px;
}
.reset-description > div {
  margin-bottom: 0px !important;
}
.login h6,
.reset-password h6 {
  margin-bottom: 35px;
  text-align: left;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}
.login .inputs-wrapper > *,
.reset-password .inputs-wrapper > * {
  width: 100%;
}
.logo img.logo-header {
  width: 60px;
  margin-bottom: 20px;
}
.align-right.privacy-text.mob-view-privacy {
  display: none !important;
}
.reset-password p {
  width: 100% !important;
}

.login-page-padding .content-container {
  padding: 22px;
}
.login-heading {
  text-align: left;
  // padding-top: 40px;
  // padding-bottom: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.login-heading h2 {
  font-family: 'Poppins' !important;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
  margin: 0;
}
.login-heading p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #9a9bb5;
  margin: 0;
}

.rememmber-me {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rememmber-me .we-checkbox .unchecked {
  margin-left: 0;
  background: transparent;
  border: 2px solid #14cd30;
  width: 15px;
  height: 15px;
}
.rememmber-me .we-checkbox img {
  margin-left: 0;
  width: 15px;
  height: 15px;
}
.rememmber-me p {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin: 0;
}
.rememmber-me .login-action.forgot-password {
  color: #4d4d4d;
  text-decoration: none;
}
.rememmber-me .we-checkbox {
  position: relative;
}
.new-design-btn .button-wrapper .we-button {
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 53px;
  border: none;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  margin-top: 0.9rem;
  width: 100%;
}
.new-design-btn .button-wrapper .we-button.active {
  background: #14cd30;
}

.login-banner-wrapper {
  position: relative;
  max-width: 735px;
  width: 100%;
}

.login-contact-info {
  position: absolute;
  right: 30px;
  top: 24px;
}
.login-contact-info a {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #14cd30;
  margin: 0;
  text-align: right;
  display: flex;
  align-items: center;
  column-gap: 8px;
  text-decoration: none;
}
.login-welcome-box {
  position: absolute;
  left: 0;
  background: rgba(20, 205, 48, 0.8);
  border-radius: 8px;
  padding: 20px;
  bottom: 63px;
}
.login-welcome-box h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 5px;
}
.login-welcome-box p {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  text-align: left;
}
.login-banner img {
  max-height: 700px;
  height: 100%;
  object-fit: cover;
}

.login-banner {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1440px) {
  .login-banner {
    text-align: right;
  }
  .login-banner img {
    max-width: 600px;
    width: 100%;
  }
}

@media screen and (max-width: 1220px) {
  .login form.form,
  .reset-password form {
    padding: 20px;
    margin: 0px auto;
  }
  .login {
    margin-top: 0px;
  }
}
@media screen and (max-width: 641px) {
  @media screen and (max-height: 600px) {
    .login form.form,
    .reset-password form {
      padding-bottom: 50px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .login .form,
  .reset-password form {
    max-width: 450px;
  }

  .login-welcome-box {
    bottom: 30px;
    left: 35px;
    padding: 15px;
  }
  .login-welcome-box h2 {
    font-size: 30px;
    line-height: 50px;
  }
}
@media screen and (max-width: 768px) {
  .login .form,
  .reset-password form {
    padding: 20px;
  }
  .login {
    margin-top: 0px;
  }
  .lang-label img {
    height: 15px !important;
    width: 15px !important;
  }
  .languages-selection {
    max-width: 100px;
    width: 100%;
  }
  .reset-password p {
    width: 100% !important;
    font-size: 15px;
  }
  .align-right.privacy-text.mob-view-privacy {
    justify-content: center;
    display: flex;
  }
  .privacy-text.mob-view-privacy a {
    font-size: 12px;
  }
  .we-footer .privacy-text a {
    display: none;
  }
  .we-footer p {
    font-size: 10px;
  }
  .privacy-text.mob-view-privacy a:last-child {
    margin-right: 0px;
  }
  .rtl .mob-view-privacy a:last-child {
    margin-right: 15px !important;
  }
  .login,
  .reset-password {
    align-items: flex-start;
  }
  .login-banner-wrapper {
    display: none;
  }
  .login .logo {
    display: flex;
  }
  .login-heading {
    text-align: center;
    padding-top: 30px;
  }
}
@media screen and (max-width: 375px) {
  .login .form,
  .reset-password form {
    padding: 10px;
  }
}

.login .form .input-group .label-input,
.reset-password .form .input-group .label-input {
  width: 100%;
}
.rtl {
  .rememmber-me .we-checkbox img {
    margin-left: 10px;
    margin-right: 0;
  }
}
@media screen and (min-width: 640px) {
  @media screen and (max-height: 700px) {
    .login {
      max-height: 85vh;
    }
    .login-heading {
      padding: 5px 0;
    }
    .login .form .inputs-wrapper {
      gap: 5px;
    }
    .login-form .logo {
      gap: 5px;
    }
    .new-design-btn .button-wrapper .we-button {
      margin-top: 0;
    }
    .login .inputs-wrapper > *,
    .reset-password .inputs-wrapper > * {
      margin: 5px;
    }
  }
}
@media screen and (max-width: 640px) {
  .form .inputs-wrapper {
    gap: 7.5px !important;
  }
}
