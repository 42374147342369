/****************** Your Dish Page *********/

.your-dish-head {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.back a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
  text-decoration: none;
}

.your-dish-heading span {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #6d7287;
}

.your-dish-heading h1 {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #23283c;
}

.back {
  position: absolute;
  left: 0;
}

.your-dish-head {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.back a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #23283c;
  text-decoration: none;
}

.menu-category-heading h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin: 0;
  text-align: left;
  padding-bottom: 20px;
}

.menu-card-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.menu-card {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 106px;
  padding-bottom: 16px;
}

.menu-card p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
  margin: 0;
}

.your-dish-head {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.back a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #23283c;
  text-decoration: none;
  gap: 3px;
}

.rtl .back {
  left: unset;
  right: 0;
}

.rtl .back a img {
  transform: rotate(180deg);
}

.menu-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.menu-category-content {
  max-width: calc(100% - 398px);
}

.menu-category-content .meat-box-ratings {
  flex-direction: column;
}

.menu-category-content .meat-box-raters {
  width: unset;
}

.menu-category.single-dish-area .menu-category-content {
  max-width: 100%;
  width: 100%;
}

.menu-category-heading h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #000000;
  margin: 0;
  text-align: left;
  padding-bottom: 20px;
}

.menu-category-heading h1 span {
  font-weight: 600;
}

.menu-card-box {
  display: flex;
  align-items: center;
  /* justify-content: start; */
  gap: 20px;
  width: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

.menu-card-box::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.menu-card-box::-webkit-scrollbar-track {
  background: #cacaca;
}

.menu-card-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #56b362;
}

.menu-card {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 106px;
  padding-bottom: 16px;
  max-height: 138px;
}

.menu-card img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.menu-card p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.1;
  color: #000000;
  margin: 0;
  padding: 0 3px;
}

.menu-category-meat {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  width: 100%;
  margin-top: 20px;
}

.menu-category.single-dish-area .menu-category-meat {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.menu-category-meat-box {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  cursor: not-allowed;
  pointer-events: none;
}

.menu-category-meat-box .meat-box-img {
  overflow: hidden;
}

.menu-category-meat-box .meat-box-img {
  overflow: hidden;
}
.menu-category-meat-box .meat-box-img {
  overflow: hidden;
  position: relative;
}

.meat-box-img img {
  border-radius: 8px 8px 0 0;
  height: 140px;
  width: 100%;
  object-fit: cover;
}
.meat-box-img img.chef-img {
  position: absolute;
  bottom: 6px;
  right: 6px;
  width: 40px !important;
  height: 40px !important;
  border: 3px solid #000;
  border-radius: 50px;
  background-color: hsla(0, 0%, 100%, 0.5);
  height: 40px;
}
.rtl .meat-box-img img.chef-img {
  left: 6px;
  right: unset;
}
.meat-box-text {
  display: flex;
  /* padding-bottom: 9px; */
}

.meat-box-name h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  margin: 0;
  text-align: left;
}

.meat-box-detail {
  padding: 9px;
  flex-direction: column;
  display: flex;
  gap: 5px;
}

.meat-box-raters span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8d92a3;
  letter-spacing: 0.02em;
  white-space: nowrap;
  font-family: 'OpenSans' !important;
}

.meat-box-ratings {
  max-width: 100px;
  display: flex;
  gap: 3px;
  flex-direction: column;
  pointer-events: none;
  cursor: not-allowed;
}
.meat-box-ratings-dish-popup {
  max-width: 100px;
  display: flex;
  gap: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  cursor: not-allowed;
}
.meat-box-rating {
  display: flex;
}

.meat-box-rating .makeStyles-root-6 {
  padding: 0;
  min-width: 100%;
  justify-content: flex-end;
}

.meat-box-rating .MuiRating-label {
  color: #ffc700;
  font-size: 0.9em;
}

.meat-box-raters {
  display: flex;
  justify-content: flex-end;
}

.rtl .meat-box-raters {
  justify-content: center;
}

.meat-box-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 5px; */
}

.dishInfo-inner {
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 5px;
}

.meat-box-price .dishInfo-inner {
  width: calc(100% - 164px);
}

.rtl .dishInfo-inner .meat-meat,
.rtl .dishInfo-inner .meat-prices {
  justify-content: flex-end;
}

.meat-price,
.meat-prices,
.meat-category,
.meat-meat {
  width: 100%;
}

.meat-price,
.meat-category {
  text-align: left;
}

.meat-prices,
.meat-meat {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.rtl .meat-price,
.rtl .meat-category {
  text-align: right;
}

.rtl .meat-prices,
.rtl .meat-meat {
  text-align: left;
}

.meat-price p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #020202;
  margin: 0;
}

.meat-prices span {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #23283c;
  font-family: 'OpenSans' !important;
}

.meat-category p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #020202;
  margin: 0;
}

.meat-meat span {
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  text-transform: capitalize;
  color: #6d7287;
  display: block;
}

.your-dish-content {
  padding-bottom: 80px;
}

.myorder-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 0px 20px; */
  gap: 30px;
  max-width: 349px;
  width: 100%;
  height: 100vh;
  max-height: 648px;
  position: sticky;
  top: 0px;
}

.myorder-section .menu-category-heading {
  padding: 0 20px 0;
}

.myorder-section .menu-category-heading h1 {
  padding-bottom: 0;
}

.myorder-items-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 340px);
  /* margin: 0 -20px; */
}
@media screen and (max-width: 991px) {
  .myorder-items-info {
    height: fit-content;
    max-height: 650px;
  }
  .myorder-section {
    height: fit-content;
    max-height: 650px;
  }
}
.order-items-list::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.order-items-list::-webkit-scrollbar-track {
  background: #cacaca;
  border-radius: 10px;
}

.order-items-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #56b362;
}

.order-items-list {
  width: 100%;
  text-align: left;
  height: calc(100% - 60px);
  overflow: auto;
  padding: 0 20px;
}

.order-items-list .myorder-item {
  padding: 15px 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.order-items-list .myorder-item .myorder-img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
}

.order-items-list .myorder-item .myorder-info-section {
  width: 100%;
  max-width: calc(100% - 90px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}

.myorder-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.order-items-list .myorder-item .myorder-info h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #23283c;
  margin: 0;
}

.order-items-list .myorder-item .myorder-info span {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #6d7287;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.myorder-info .dishInfo-inner {
  gap: 0;
}

.myorder-info .dishInfo-inner .meat-meat span {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #23283c;
}

.myorder-info .dishInfo-inner .meat-prices span {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #8289a3;
}

.myorder-info-section .myorder-price-section {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.myorder-info-section .myorder-price-section .myorder-quantity {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.rtl .myorder-quantity {
  flex-direction: row-reverse;
}

.myorder-info-section .myorder-price-section .myorder-quantity span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #14cd30;
}

.myorder-quantity button,
.myorder-quantity button:hover,
.myorder-quantity button:focus,
.myorder-quantity button:focus-within {
  width: 22px;
  height: 22px;
  background: #e1ffe5;
  color: #14cd30;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}

.myorder-quantity button:hover {
  opacity: 0.8;
}

.myorder-quantity button img {
  width: 7.67px;
}

.myorder-price-section h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #23283c;
  margin-bottom: 0;
}

.myorder-amt-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px 20px;
}

.myorder-amt-section p {
  margin-bottom: 0;
  color: #6d7287;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.myorder-amt-section h5 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #23283c;
}
.continue-btn-custom {
  min-width: 100px;
  background: #14cd30 !important;
  border: 1px solid #14cd30 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back-btn-custom {
  min-width: 100px;
  background: #757575 !important;
  border: 1px solid #757575 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center {
  display: flex !important;
  justify-content: space-around !important;
}
.m10 {
  margin: 10px;
}
.continue-btn {
  padding: 5px 20px !important;
  width: calc(100% - 40px) !important;
  height: 38px;
  background: #14cd30 !important;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 12px !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: 1px solid #14cd30 !important;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rtl .continue-btn img {
  transform: rotate(180deg);
}

.rtl .continue-btn {
  text-align: center !important;
}

/* 
.continue-btn:hover {
  background: #13bd2c !important;
  border: 1px solid #14cd30 !important;
} */
.continue-btn img {
  width: 18px;
  height: 18px;
}

.view-order-btn {
  display: none;
}

.order-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

/****************** Extras for your meal Popup*********/
.add-extras-popup {
  color: #000;
}

.add-extras-popup .modal-dialog {
  max-width: 420px !important;
}

.add-extras-popup .modal-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 440px;
  overflow: hidden;
}

.add-extras-popup .modal-header,
.add-extras-popup .modal-body,
.add-extras-popup .modal-footer {
  padding: 0;
  border: 0;
}

.add-extras-popup .modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  text-align: center;
  width: 100%;
}

.add-extras-popup .modal-title span {
  color: #6d7287;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  display: block;
}

.add-extras-popup .modal-body {
  height: calc(100% - 83px);
}

.add-extras-popup .modal-body .myorder-items-info {
  height: 100%;
  gap: 10px;
}

.add-extras-popup .modal-body .order-items-list {
  height: 260px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0;
}

.add-extras-popup .modal-body .extras-listing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-extras-popup .modal-body .order-items-list::-webkit-scrollbar {
  width: 3px;
}

.add-extras-popup .order-items-list .myorder-item {
  padding: 0;
  border: 0;
  width: 100%;
  max-width: calc(100% - 43px);
}

.add-extras-popup .order-items-list .extras-listing .icon-wrapper .we-icon {
  width: 30px !important;
}

.add-extras-popup .myorder-item .myorder-img {
  width: 60px;
  height: 60px;
}

.add-extras-popup .myorder-item .myorder-info-section {
  max-width: calc(100% - 70px);
  flex-direction: row;
  align-items: center;
}

.add-extras-popup .myorder-item .myorder-info h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #23283c;
}

.add-extras-popup .myorder-price-section h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #020202;
}

.rtl .myorder-info h4,
.rtl .myorder-info span {
  text-align: right;
  width: 100%;
}

.add-extras-popup .myorder-item .myorder-info-section .myorder-info,
.add-extras-popup .myorder-item .myorder-info-section .myorder-price-section {
  width: 50%;
}

.add-extras-popup .myorder-item .myorder-info-section .myorder-price-section {
  flex-direction: row-reverse;
}

.rtl
  .add-extras-popup
  .myorder-item
  .myorder-info-section
  .myorder-price-section {
  justify-content: flex-start;
}

.add-extras-popup .modal-body .myorder-amt-section {
  padding: 0;
}

.add-extras-popup .modal-body .myorder-amt-section p {
  font-size: 16px;
  line-height: 24px;
}

.add-extras-popup .modal-body .myorder-amt-section h5 {
  font-size: 16px;
  line-height: 24px;
}

.add-extras-popup .modal-footer {
  justify-content: space-between;
}

.add-extras-popup .modal-footer button {
  margin: 0 !important;
}

.skip-btn,
.skip-btn:hover,
.skip-btn:focus,
.skip-btn:focus-within {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 24px !important;
  gap: 10px;
  width: 100%;
  max-width: fit-content;
  height: 38px;
  background: #eff0f7 !important;
  border: 1px solid #eff0f7;
  border-radius: 6px;
  color: #23283c !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}

.skip-btn:hover {
  background: #ededed !important;
}

.add-extras-popup .continue-btn,
.add-extras-popup .continue-btn:hover,
.add-extras-popup .continue-btn:focus,
.add-extras-popup .continue-btn:focus-within {
  max-width: fit-content;
  font-size: 18px !important;
  line-height: 1.2 !important;
}

.add-extras-popup .continue-btn img {
  width: 24px;
  height: 24px;
}

/****************** Order Placed Succesfully Popup*********/
.add-extras-popup .modal-dialog {
  max-width: 450px !important;
}

.order-placed-popup .modal-content {
  height: fit-content;
}

.order-placed-popup .modal-content,
.order-placed-popup .modal-body {
  gap: 20px;
}

.order-placed-popup .modal-header {
  height: 20px;
}

.order-placed-popup .modal-header .btn-close {
  background: url(../assets/newimages/ep_close-bold.svg) !important;
  background-image: url(../assets/newimages/ep_close-bold.svg);
  background-repeat: no-repeat;
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  opacity: 1;
}

.order-placed-popup .modal-body {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
}

.order-placed-popup .modal-body h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: #3d3d3d;
  margin: 0;
}

.order-placed-popup .modal-body p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #23283c;
  margin: 0;
  text-align: center;
}

.order-placed-popup .modal-body ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  gap: 4px;
}

.order-placed-popup .modal-body li {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #333;
  padding: 5px 10px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(51 51 51 / 10%);
}

.order-placed-popup .modal-body li:last-child {
  border-bottom: unset;
}

.order-placed-popup .modal-body li span {
  background: #14cd302b;
  padding: 1px 6px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 13px;
  color: #14cd30;
}

.order-placed-popup .modal-footer {
  justify-content: center;
  display: flex;
  gap: 20px;
}

.order-placed-popup .modal-footer button,
.order-placed-popup .modal-footer button:hover,
.order-placed-popup .modal-footer :focus,
.order-placed-popup .modal-footer button:focus-within {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: initial !important;
  height: 40px;
  max-width: unset !important;
  width: 47% !important;
}

.order-placed-popup .modal-footer .blue-btn.btn {
  background: #0060ca !important;
  border-color: #0060ca !important;
}

.add-extras-popup::-webkit-scrollbar,
.order-placed-popup::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

.add-extras-popup::-webkit-scrollbar-track,
.order-placed-popup::-webkit-scrollbar-track {
  background: #cacaca;
}

.add-extras-popup::-webkit-scrollbar-thumb,
.order-placed-popup::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #56b362;
}

.order-placed-popup .checked-img {
  width: 80px;
  height: 100%;
}

.mobile-comp {
  display: none;
}

/* My Order Popup FOr Mobile */
.myOrders-popup.modal {
  padding: 0 !important;
  height: 100vh;
  width: 100%;
  margin: 0px auto;
}
.hide-modal {
  display: none !important;
}
.order-placed-popup .checked-img {
  width: 80px;
  height: 100%;
}
.myOrders-popup .modal-dialog {
  margin: 0 auto !important;
  height: 100%;
  max-width: 375px;
  width: 100%;
  padding: 10px 0;
}

.myOrders-popup .modal-content {
  padding: 20px;
}

.myOrders-popup .modal-body {
  padding: 0;
}

.myOrders-popup .myorder-items-info {
  height: calc(100% - 100px);
}

/* max-width: 576px;
width: 100%;
height: 100vh;
margin: 0;
display: flex;
align-items: flex-end;
transition: transform .3s ease-out;
} */
.order-placed-popup .modal-body li:last-child {
  border-bottom: unset;
}

.order-placed-popup .modal-body li span {
  background: #14cd302b;
  padding: 1px 6px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 13px;
  color: #14cd30;
}
.myOrders-popup .modal-content {
  padding: 20px;
  bottom: -100vh;
}

.modal-open .myOrders-popup .modal-content {
  bottom: 0;
  position: relative;
}

.myOrders-popup .modal-body {
  padding: 0;
}

.myOrders-popup .myorder-section {
  position: unset;
  max-width: 100%;
  gap: 20px;
  max-height: calc(90vh - 40px);
  overflow: hidden;
}

.myOrders-popup .myorder-items-info {
  height: calc(100% - 102px);
  overflow: auto;
}

.myOrders-popup .order-items-list {
  padding: 0;
  height: calc(100% - 54px);
}

.myOrders-popup .myorder-amt-section {
  padding: 15px 0;
}

.myOrders-popup .myorder-amt-section p,
.myOrders-popup .myorder-amt-section h5 {
  font-size: 16px;
  line-height: 24px;
}

.no-dishes {
  height: 100%;
}

.menu-category-meat-box.pointer {
  cursor: pointer;
  pointer-events: initial;
}

.no-dishes h6 {
  font-size: 18px;
  color: #ff1d1d;
  font-weight: 700;
  text-align: center;
}

.menu-category-meat-box .button-wrapper button {
  font-size: 12px;
}

.menu-category-meat-box .button-wrapper {
  margin-bottom: 12px;
}

.menu-category-meat-box.pointer.selected-dish {
  border: 2px solid #00d200;
}

.disable-value {
  cursor: not-allowed;
  pointer-events: none;
}

.inventory .icon-wrapper .we-icon {
  width: 30px !important;
}

.copymeal-popup {
  z-index: 99999 !important;
}

/* Dish Popup */
.dishModal .modal-dialog {
  max-width: 500px;
}

.myOrders-popup .btn-close,
.dishModal .btn-close {
  background-color: rgba(20, 205, 48, 0.7);
  background-image: url(../assets/icons/closeWhite.svg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  gap: 10px;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  background-position: center;
  opacity: 1;
}

.myOrders-popup .modal-header,
.dishModal .modal-header {
  width: fit-content;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;
  border-bottom: 0;
}

.rtl.modal-open .myOrders-popup .modal-header,
.rtl.modal-open .dishModal .modal-header {
  right: unset;
  left: 20px;
}

.dishModal .modal-content {
  padding: 0;
}

.dishModal .modal-body {
  width: 100%;
  height: 100%;
  padding: 0;
}

.dishModal .menu-category-meat-box {
  position: unset;
}

.dishModal .menu-category-meat-box .meat-box-img img {
  height: 360px;
  max-width: 585px;
  width: 100%;
  object-fit: cover;
  /* object-position: -25px 0; */
}

.dishModal .meat-box-detail {
  padding: 20px;
  gap: 20px;
}

.dishModal .meat-box-detail .meat-box-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%;
}

.dishModal .meat-box-detail .meat-box-text h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #000000;
}

.dishModal .meat-box-detail .meat-box-text span {
  font-weight: 400;
  color: #6d7287;
  letter-spacing: 0.02em;
}

.dishModal .meat-box-detail .meat-box-text span.description {
  font-size: 14px;
  line-height: 18px;
}

.dishModal .meat-box-detail .meat-box-text span.category {
  font-size: 14px;
  line-height: 21px;
}

.dishModal .meat-box-price .dishInfo-inner span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #020202;
}

.dishModal .meat-box-price .meat-box-rating .makeStyles-root-1 {
  min-width: fit-content;
}

.dishModal .continue-btn {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.dishModal .continue-btn span:first-child {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.dishModal .continue-btn span:last-child {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #f8f8f9;
}

/****************************
                  Media Queries
*******************************/

@media (max-width: 1500px) {
  .menu-category-meat {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 1200px) {
  .menu-category-meat {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .menu-category.single-dish-area .menu-category-meat {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .menu-category.single-dish-area
    .menu-category-meat
    .meat-box-price
    .dishInfo-inner {
    width: calc(100% - 110px);
  }

  .menu-category.single-dish-area .menu-category-meat .meat-box-ratings {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .menu-category.single-dish-area .menu-category-meat {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 991px) {
  .menu-category {
    gap: 20px;
  }

  .menu-category-content,
  .myorder-section {
    max-width: 100%;
    width: 100%;
  }

  .menu-category-meat,
  .menu-category.single-dish-area .menu-category-meat {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .menu-category-meat .meat-box-ratings {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .my-orders-page .my-orders-table .table-text-section h5 {
    font-size: 11px;
  }

  .show-previous button {
    font-size: 12px;
  }

  .menu-category-meat {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .menu-category-meat,
  .menu-category.single-dish-area .menu-category-meat {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .modal-open .myOrders-popup .modal-content {
    height: fit-content;
    max-height: 90vh;
    overflow: auto;
    padding: 20px 0;
  }

  .myOrders-popup .order-items-list {
    padding: 0 20px;
  }

  .myOrders-popup .myorder-amt-section {
    padding: 0 20px;
  }

  .myOrders-popup .modal-body {
    height: 100%;
    overflow: hidden;
  }

  .myOrders-popup .continue-btn {
    margin: 0 auto;
  }

  /* Dish Popup */
  .dishModal .modal-dialog {
    max-width: 355px;
    width: 100%;
    height: 100vh;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .modal-open .dishModal .modal-content {
    max-width: 355px;
    width: 100%;
    height: fit-content;
    margin: auto;
    max-height: 100vh;
    position: relative;
  }
}

@media (max-width: 768px) {
  .desktop-comp {
    display: none;
  }
  .back {
    margin-bottom: 25px;
  }
  .your-dish-heading {
    margin-top: 50px;
  }
  .mobile-comp {
    display: block;
  }

  /* .myOrders-popup .modal-dialog,
  .add-extras-popup .modal-dialog {
    margin: 0 auto;
    max-width: 100%;
    transform: translate3d(0, 100vh, 0) !important;
    align-items: flex-end;
    height: 100vh;
  } */
  /* .myOrders-popup.show .modal-dialog,
  .add-extras-popup .modal-dialog {
    transform: translate3d(0, 0, 0) !important;
  } */
  .menu-category-meat {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .your-dish-content {
    padding-bottom: 60px;
    position: relative;
  }

  .your-dish-head {
    padding-bottom: 20px;
  }

  .back a span {
    display: none;
  }

  .your-dish-heading {
    display: flex;
    flex-direction: column-reverse;
    gap: 4px;
  }

  .your-dish-heading h1 {
    margin: 0;
  }

  .menu-category-heading h1 {
    font-size: 16px;
    line-height: 24px;
  }

  .menu-card {
    min-width: 80px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .view-order-btn,
  .view-order-btn:hover,
  .view-order-btn:focus,
  .view-order-btn:focus-within {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    align-items: center;
    padding: 10px;
    background: #14cd30;
    border-radius: 8px;
    bottom: 55px;
    left: 20px;
    position: fixed;
    box-shadow: none !important;
    outline: 0 !important;
    border: none !important;
    cursor: pointer;
  }

  .view-order-btn:hover {
    background: #13bd2c !important;
  }

  .view-order-btn h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #e5ffe9;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0;
  }

  .view-order-btn h5 span {
    background: #e5ffe9;
    width: 16px;
    height: 16px;
    color: #14cd30;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .myorderBtn-total {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #ffffff;
    width: 100%;
    max-width: calc(100% - 120px);
    text-align: right;
  }
}

@media (max-width: 576px) {
  .menu-category-meat {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .your-dish-content {
    padding-bottom: 60px;
    position: relative;
  }

  .your-dish-head {
    padding-bottom: 20px;
  }

  .back a span {
    display: none;
  }

  .your-dish-heading {
    display: flex;
    flex-direction: column-reverse;
    gap: 4px;
    margin-top: 30px;
  }

  .your-dish-heading h1 {
    margin: 0;
  }

  .menu-category-heading h1 {
    font-size: 16px;
    line-height: 24px;
  }

  .menu-card {
    min-width: 80px;
    height: 86px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .menu-card img {
    height: 60px;
    width: 60px;
  }

  .menu-card p {
    font-size: 12px;
  }

  .meat-box-ratings {
    flex-direction: row;
    align-items: center;
  }

  .meat-box-name {
    width: calc(100% - 125px);
  }

  .view-order-btn {
  }

  .menu-category-meat,
  .menu-category.single-dish-area .menu-category-meat {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 576px) {
  .menu-category-meat {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .menu-category-meat .meat-box-ratings {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .your-dish-content {
    padding-bottom: 60px;
  }

  .your-dish-head {
    padding-bottom: 20px;
    align-items: baseline;
  }

  .back a span {
    display: none;
  }

  .your-dish-heading {
    display: flex;
    flex-direction: column-reverse;
    gap: 4px;
  }

  .your-dish-heading h1 {
    margin: 0;
  }

  .menu-category-heading h1 {
    font-size: 16px;
    line-height: 24px;
  }

  .menu-card {
    min-width: 80px;
  }

  .dishModal .menu-category-meat-box .meat-box-img img {
    width: 100%;
    height: 250px;
  }

  .meat-box-img img {
    height: 180px;
    object-fit: cover;
  }

  .back a {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .menu-card img {
    height: 60px;
    width: 60px;
  }

  .menu-card p {
    font-size: 12px;
  }

  .meat-box-ratings {
    flex-direction: row;
    align-items: center;
  }

  .meat-box-name {
    width: calc(100% - 125px);
  }

  .myorder-section {
    gap: 10px;
    position: relative;
  }

  .view-order-btn,
  .view-order-btn:hover,
  .view-order-btn:focus,
  .view-order-btn:focus-within {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    align-items: center;
    padding: 10px;
    background: #14cd30;
    border-radius: 8px;
    bottom: 55px;
    left: 20px;
    position: fixed;
    box-shadow: none !important;
    outline: 0 !important;
    border: none !important;
    cursor: pointer;
  }

  .view-order-btn:hover {
    background: #13bd2c !important;
  }

  .view-order-btn h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #e5ffe9;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0;
  }

  .view-order-btn h5 span {
    background: #e5ffe9;
    width: 16px;
    height: 16px;
    color: #14cd30;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border-radius: 50%;
  }

  .myorderBtn-total {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #ffffff;
    width: 100%;
    max-width: calc(100% - 120px);
    text-align: right;
  }
}

.menu-card img {
  height: 60px;
  width: 60px;
}

.menu-card p {
  font-size: 12px;
}

.meat-box-ratings {
  flex-direction: row;
  align-items: center;
}

.rtl .meat-box-price {
  flex-direction: row-reverse;
}

.meat-box-name {
  width: 100%;
  min-height: 40px;
}

.rtl .meat-box-name {
  /* width: calc(100% - 108px); */
  width: 100%;
}

.myorder-section {
  gap: 10px;
  position: relative;
}

.seleted-dish {
  position: absolute;
  top: 10px;
  right: -9px;
}

.menu-category-meat-box {
  position: relative;
}

.seleted-dish img {
  height: 45px;
}

.selected-text {
  position: relative;
}

.selected-text img {
  object-fit: unset;
}

.selected-text p {
  font-size: 12px;
  position: absolute;
  top: 15px;
  text-align: center !important;
  width: 100%;
  right: 0;
  color: white;
  font-weight: 600;
}
.selected-text .selected-text-p-position {
  top: 10px;
  margin: 0;
  right: -7px;
}
.meat-box-img.selected-img::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: #dbdadaa8;
}

.meat-box-img.selected-img {
  position: relative;
}

.menu-category-meat-box.disable-dish {
  pointer-events: none;
  cursor: not-allowed;
}

.dishModal .menu-category-meat-box {
  pointer-events: initial !important;
  cursor: auto !important;
}

html.you-dish-page .sub-header-wrapper {
  display: none;
}

.myorder-item .order-dish-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.myorder-item .order-dish-name i {
  cursor: pointer;
  font-size: 18px;
  color: #ff2f2f;
}

.show-previous button,
.show-previous button:hover,
.show-previous button:focus {
  background: #14cd30 !important;
  border: 1px solid #14cd30 !important;
  transition: all ease 0.5s;
}
